import { Row, Nav } from "react-bootstrap";
import React, { useState } from "react";
import RenderChildDropDown from "./RenderChildDropDown";

const Navbar = ({ openModal, menus: itemMenus, rut, setShowLoading }) => {
  const [user, setUser] = useState({
    ...JSON.parse(localStorage.getItem("user")),
  });

  return (
    <Row className="justify-content-around w-100">
      <Nav fill variant="pills">
        {itemMenus.map((item, index) => {
          return (
            <RenderChildDropDown
              item={item}
              openModal={openModal}
              user={user}
              rut={rut}
              setShowLoading={setShowLoading}
              key={`drpdwn-${index}`}
            />
          );
        })}
      </Nav>
    </Row>
  );
};

export default Navbar;
