export const download = (tipo, pdf, nombre) => {
  const linkSource = `data:application/${tipo};base64,${pdf}`;
  const downloadLink = document.createElement("a");
  const fileName = nombre;

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const validaRut = (RUT) => {
  if (!RUT || RUT.trim() === "") return undefined;
  if (RUT.length < 8) return false;
  if (RUT.length > 12) return false;
  var largo, crut, rut, dv, i, suma, mul, res, dvi;
  var tmpstr = "";
  var intlargo = RUT;
  if (intlargo.length > 0) {
    crut = RUT;
    largo = crut.length;
    if (largo < 2) {
      return false;
    }
    for (i = 0; i < crut.length; i++)
      if (
        crut.charAt(i) !== " " &&
        crut.charAt(i) !== "." &&
        crut.charAt(i) !== "-"
      ) {
        tmpstr = tmpstr + crut.charAt(i);
      }
    rut = tmpstr;
    crut = tmpstr;
    largo = crut.length;
    if (largo > 2) rut = crut.substring(0, largo - 1);
    else rut = crut.charAt(0);
    dv = crut.charAt(largo - 1);
    if (rut === null || dv === null) return 0;
    var dvr = "0";
    suma = 0;
    mul = 2;
    for (i = rut.length - 1; i >= 0; i--) {
      suma = suma + rut.charAt(i) * mul;
      if (mul === 7) mul = 2;
      else mul++;
    }
    res = suma % 11;
    if (res === 1) dvr = "k";
    else if (res === 0) dvr = "0";
    else {
      dvi = 11 - res;
      dvr = dvi + "";
    }
    if (dvr !== dv.toLowerCase()) {
      return false;
    }
    return true;
  }
};

// export const hideEmail = (email) => {
//   if (email === undefined || email === null || email === "") return null;
//   //Separamos el mail localPart es lo que está antes del arroba,
//   //domain es lo que está después del arroba
//   let [localPart, domain] = email?.split("@");

//   //En la variable parts se crea un array con las partes divididas por el punto
//   // [ Soyjohanposada@gmail,com ]
//   let parts = email.split(".");

//   // Preguntamos si parts tiene algo dentro si si lo tiene nos devuelve
//   //El último elemento del array que serían nuestras extensiones de dominios
//   // com, pe, cl, etc. :)
//   const finish = parts.length > 1 ? parts[parts.length - 1] : null;

//   // Ocultamos parte del localPart del correo electrónico
//   localPart =
//     localPart.length > 2
//       ? localPart.substring(0, 3) + "*".repeat(localPart.length - 3)
//       : localPart;

//   // Ocultamos parte del domain del correo electrónico dependiendo de la longitud de "finish"
//   domain =
//     domain.length > 2 && finish.length < 3
//       ? "*".repeat(domain.length - 2) + domain.substring(domain.length - 3)
//       : domain.length > 2 && finish.length > 2
//       ? "*".repeat(domain.length - 2) + domain.substring(domain.length - 4)
//       : domain;

//   // Retornamos el correo electrónico oculto
//   return `${localPart}@${domain}`;
// };

export const hideEmail = (email) => {
  if (email === undefined || email === null || email === "") return null;
  //Separamos el mail localPart es lo que está antes del arroba,
  //domain es lo que está después del arroba
  let [localPart, domain] = email?.split("@");

  //En la variable parts se crea un array con las partes divididas por el punto
  // [ Soyjohanposada@gmail,com ]
  let parts = email.split(".");

  // Preguntamos si parts tiene algo dentro si si lo tiene nos devuelve
  //El último elemento del array que serían nuestras extensiones de dominios
  // com, pe, cl, etc. :)
  const finish = parts.length > 1 ? parts[parts.length - 1] : null;

  // Ocultamos parte del localPart del correo electrónico
  localPart =
    localPart.length > 2
      ? localPart.substring(0, 3) + "*".repeat(8)
      : localPart;

  // Ocultamos parte del domain del correo electrónico dependiendo de la longitud de "finish"
  domain =
    domain.length > 2 && finish.length < 3
      ? "*".repeat(6) + domain.substring(domain.length - 3)
      : domain.length > 2 && finish.length > 2
      ? "*".repeat(6) + domain.substring(domain.length - 4)
      : domain;

  // Retornamos el correo electrónico oculto
  return `${localPart}@${domain}`;
};

export const formatRut = (actual) => {
  if (actual !== undefined) {
    if (actual !== "" && actual.length > 1) {
      var sinPuntos = actual.replace(/\./g, "");
      var actualLimpio = sinPuntos.replace(/-/g, "");
      var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
      var rutPuntos = "";
      var i = 0;
      var j = 1;
      for (i = inicio.length - 1; i >= 0; i--) {
        var letra = inicio.charAt(i);
        rutPuntos = letra + rutPuntos;
        if (j % 3 === 0 && j <= inicio.length - 1) {
          rutPuntos = "." + rutPuntos;
        }
        j++;
      }
      var dv = actualLimpio.substring(actualLimpio.length - 1);
      rutPuntos = rutPuntos + "-" + dv;
      return rutPuntos;
    }
  }
  return actual;
};

//Convertir k en mayuscula si es que existe en el rut
export const convertLastCharToLowerCase = (RUT) => {
  if (!RUT || RUT.trim() === "") return null;

  const endsWithK = RUT.endsWith("k");

  if (endsWithK) {
    const modifiedRUT = RUT.slice(0, -1) + "K";
    return modifiedRUT;
  }

  return RUT;
};
