// HOOKS - LIBRERIAS
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import useStorage from "../../helpers/useStorage";
import useWindowDimensions from "../../helpers/useWindowDimensions";

// ASSETS
import {
  Bocina,
  Cobranza,
  Computadora2,
  Confirmado,
  Reloj,
  Ejecutivo,
  Error,
  Excedente,
  Liquidacion,
  Logo4,
  Operacion,
  User,
  Usuario,
  PanelControl,
} from "../../assets/icons/dashboard";
import Esquina1 from "../../assets/images/esquina1.png";

// COMPONENTES
import { Col, Container, Form, Row } from "react-bootstrap";
import RegistroSII from "../../components/registro-sii/registro-sii";
import Button from "../../components/buttons/Button";
import HeaderDashboardClientes from "../../components/dashboard-clientes/HeaderDashboardClientes";
import Footer from "../../components/footer/Footer";
import Seccion3 from "../../components/dashboard-clientes/Seccion3";
import Loading from "../../components/loading/Loading";
import {
  CustomModal as Modal,
  ModalSuccess,
  ModalSuccess2,
} from "../../components/modal/Modal";
import Slider from "../../components/slider/Slider";
import {
  Text,
  TextColored,
  TextGeneric,
  Title,
} from "../../components/typography";
import Seccion2DM from "../../components/dashboard-clientes/Seccion2_old";

// Helper
import { abrir } from "../../helpers/termino_condiciones";
import { api } from "../../helpers/api";
import { DecryptData, EncryptData } from "../../helpers/encrypt";
import { AUTOATENCIONAPI, PORTALSAPI } from "../../helpers/url";
import { ThemeContext } from "../../theming";
import {
  mobileSMWidth,
  mobileXSWidth,
} from "../../helpers/responsiveVariables";
import {
  fireAlert,
  generateCdlObj,
  getRut,
  sincronizarDTEs,
} from "../../helpers/autoAtencionHelpers";

const icons = {
  liquidacion: <Liquidacion />,
  excedente: <Excedente />,
  cobranza: <Cobranza />,
  gestion: <Cobranza />,
  detalle: <Cobranza />,
  cuota: <Excedente />,
  usuario: <Usuario />,
  error: <Error />,
  ejecutivo: <Ejecutivo />,
  contrasenia: <Ejecutivo />,
  operacion: <Operacion />,
  bocina: <Bocina />,
  logo4: <Logo4 />,
  computadora2: <Computadora2 />,
  panel_control: <PanelControl />,
};

/* Styles */
const container = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
};

const section = {
  width: "100%",
};

const userModal = {
  padding: "10px",
  borderBottom: "1px solid #dee2e6",
};

const modalInfo = {
  title: "",
  subtitle: "",
  icon: null,
  size: "xl",
  content: null,
  table: { columns: [], rows: [] },
};

const DashboardClientes = () => {
  const history = useHistory();
  const app = useContext(ThemeContext);
  const { body12, body14, body25, body28, body32 } = app.theme.font;
  const { width } = useWindowDimensions();

  const { register, handleSubmit, reset } = useForm();
  const [proveedor, setProveedor] = useState(null);
  const [showLoading, setShowLoading] = useState({ display: "none" });
  const [modalData, setModalData] = useState({});
  const [images, setImages] = useState([]);
  const [contactado, setContactado] = useState("");
  const [prevStatus, setPrevStatus] = useState("");
  const [menus, setMenus] = useState([]);
  const [user, setUser] = useState({});
  const [nombres, setNombres] = useState("");
  const [notificacion, setNotificacion] = useState(0);
  const [notificaciones, setNotificaciones] = useState([]);
  const [tipoBanner, setTipoBanner] = useState(null);

  const goToUrl = (url) => {
    window.open(url, "_blank");
  };

  const goTo = (notificacion) => {
    if (notificacion.tipo === 2) {
      switch (notificacion.enlace) {
        case "modal_sii":
          return openTopModal("datos_SII");
        default:
          break;
      }
    } else if (notificacion.tipo === 1) {
      goToUrl(notificacion.enlace);
    }
  };

  const CerrarSession = (data) => {
    localStorage.removeItem("user");
    localStorage.removeItem("rtoken");
    history.push("/clientes/login");
  };

  /* Sucess modal*/
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const handleCloseModalSuccess = () => setShowModalSuccess(false);

  const handleShowModalSuccess = () => {
    handleCloseTopModal();
    handleClose();
    setShowModalSuccess(true);
  };

  /* Error modal*/
  const [showModalError, setShowModalError] = useState(false);
  const handleCloseModalError = () => setShowModalError(false);
  const handleShowModalError = () => {
    setShowModalError(true);
  };

  /* Low level modal */
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [modal, setModal] = useState(modalInfo);

  /* Top level modal */
  const [showTopModal, setShowTopModal] = useState(false);
  const handleCloseTopModal = () => {
    setShowTopModal(false);
    handleShow();
  };
  const handleShowTopModal = () => {
    handleClose();
    setShowTopModal(true);
  };
  const [topModal, setTopModal] = useState(modalInfo);

  const enviarInformacionHandle = (data) => {
    setShowLoading({ display: "block" });

    if (data.contactar.trim() === "") {
      setShowLoading({ display: "none" });
      setModalData({
        messageLine: false,
        title: "Enviar Información",
        subtitle: "Error",
        color: "red",
        message: "Debe especificar en qué podemos ayudarle.",
        icon: (
          <>
            <Error />
          </>
        ),
      });
      handleShowModalError();
      handleShow();
      return;
    }

    let formComentario = {
      idcontacto: user.idcontacto,
      correo: "",
      mensaje: data.contactar,
    };

    api.post(PORTALSAPI.POST.ENROLAMIENTO, formComentario).then(
      (x) => {
        setShowLoading({ display: "none" });
        setModalData({
          messageLine: false,
          title: "Información Enviada",
          subtitle: "EXITOSAMENTE",
          color: "yellow",
          message: "¡Muchas Gracias!",
          message2: (
            <Col
              style={{
                maxWidth: "300px",
                margin: "auto",
                padding: "30px 0",
              }}>
              <Row>
                <Reloj />
                <Col
                  as='span'
                  style={{
                    fontSize: "0.80rem",
                    fontWeight: "bold",
                  }}>
                  {x.message}
                </Col>
              </Row>
            </Col>
          ),
          icon: (
            <>
              <Confirmado />
            </>
          ),
        });
        handleShowModalError();
        setShowTopModal(false);
        handleShow();
      },
      (error) => {
        setShowLoading({ display: "none" });
        setModalData({
          messageLine: false,
          title: "Contactar",
          subtitle: "Error",
          color: "red",
          message: "No se pudo enviar la información brindada.",
          icon: (
            <>
              <Error />
            </>
          ),
        });
        handleShowModalError();
        setShowTopModal(false);
        handleShow();
      }
    );
  };

  const actualizarDatos = (data) => {
    if (data.terminos === false) {
      setModalData({
        messageLine: false,
        title: "Actualización de datos",
        subtitle: "Error",
        color: "red",
        message:
          "Debe aceptar los términos y condiciones para poder actualizar sus datos.",
        icon: (
          <>
            <Error />
          </>
        ),
      });
      handleShowModalError();
      return;
    }
    let user = JSON.parse(localStorage.getItem("user"));
    let publicURL = PORTALSAPI.GET.PUBLIC_KEY.replace(
      "{idcontacto}",
      user.idcontacto
    ).replace("{tipo}", user.tipo_acceso);
    api.get(publicURL).then((responsePublicKey) => {
      let form = {
        idcontacto: user.idcontacto,
        correo: DecryptData(user.correo, responsePublicKey.data),
        telefono: data.celular,
        nombres: data.nombres,
      };
      let url = PORTALSAPI.PUT.ACTUALIZAR_DATOS_ENROLAMIENTO;
      api.put(url, form).then(
        (res) => {
          let currentUser = JSON.parse(localStorage.getItem("user"));
          let public_key = JSON.parse(sessionStorage.getItem("temporal"));
          currentUser.nombres = EncryptData(form.nombres, public_key);
          setNombres(form.nombres);
          localStorage.setItem("user", JSON.stringify(currentUser));
          setShowTopModal(false);
          setModalData({
            messageLine: false,
            title: "Datos Actualizados",
            subtitle: "EXITOSAMENTE",
            color: "yellow",
            message: "¡Muchas Gracias!",
            icon: (
              <>
                <Confirmado />
              </>
            ),
          });
          handleShowModalError();
        },
        (error) => {
          setModalData({
            messageLine: false,
            title: "Actualización de datos",
            subtitle: "Error",
            color: "red",
            message: "No se pudo actualizar sus datos",
            icon: (
              <>
                <Error />
              </>
            ),
          });
          handleShowModalError();
        }
      );
    });
  };

  // SECCION 2
  const openSuscriptionModal = () => {
    setShowSuscription(true);
  };

  const checkSuscription = async () => {
    try {
      const body = await generateCdlObj();
      const { idcontacto } = JSON.parse(localStorage.getItem("user"));
      const urlSuscriber = AUTOATENCIONAPI.POST.ESTADO_ENROLAMIENTO_CDL.replace(
        "{idcontacto}",
        idcontacto
      );

      const { data } = await api.post(urlSuscriber, body);

      return data;
    } catch (err) {
      console.log(err);
    }
  };

  const [isBlocked, setBlocked] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showSuscriptionModal, setShowSuscription] = useState(false);
  const [status, setStatus] = useState(null);

  const [{ tipo_acceso }] = useStorage("user", "", "local");

  const handleSuscriptionModal = async (s) => {
    try {
      setLoading(true);
      const flag = s ? s : status;
      switch (flag) {
        // Flujo no enrolado
        case 0: {
          openSuscriptionModal();
          break;
        }

        // Flujo - Enrolado
        case 1: {
          const { enrolado, fe_estimada_desbloqueo, bloqueado } =
            await checkSuscription();

          if (fe_estimada_desbloqueo?.length > 0 && bloqueado) {
            setBlocked(fe_estimada_desbloqueo);
          }
          if (!enrolado) {
            setStatus(2);
            openSuscriptionModal();
            break;
          }
          if (enrolado && fe_estimada_desbloqueo?.length > 0 && bloqueado) {
            openSuscriptionModal();
            break;
          }
          await sincronizarDTEs(await getRut());
          sessionStorage.setItem("current-step", 1);
          history.push("autoatencion/seleccion-facturas");
          break;
        }

        // Flujo - Actualizar datos
        case 2: {
          openSuscriptionModal();
          break;
        }

        case 4: {
          openSuscriptionModal();
          break;
        }

        default: {
          break;
        }
      }
      setLoading(false);
    } catch {
      await fireAlert({ icon: "warning" });
      setLoading(false);
    }
  };

  const RenderContent = (typeobj) => {
    const [isInvalidPhone, setIsInvalidPhone] = React.useState(false);
    const mountedRef = React.useRef(false);

    let type = typeobj.type;
    switch (type) {
      case "notificacion": {
        return (
          <>
            <Container style={userModal}>
              {notificaciones.map((notificacion) => {
                return (
                  <>
                    <Row
                      className='justify-content-center'
                      onClick={() => goTo(notificacion)}
                      style={{
                        cursor: "pointer",
                      }}>
                      <Col>
                        <Row>
                          <ul>
                            <li style={{ color: "#fdd135" }}>
                              <Row style={{ marginLeft: "20px" }}>
                                <TextGeneric>
                                  <b> {notificacion.titulo}</b>
                                </TextGeneric>
                              </Row>
                              <Row style={{ marginLeft: "20px" }}>
                                <TextGeneric>
                                  {notificacion.descripcion}
                                </TextGeneric>
                              </Row>
                            </li>
                          </ul>
                        </Row>
                      </Col>
                      <Col lg={2}>
                        <img
                          style={{
                            height: "10px",
                            transform: "rotate(40deg)",
                          }}
                          alt=''
                          src={Esquina1}
                        />
                      </Col>
                    </Row>
                    <hr />
                  </>
                );
              })}
            </Container>
          </>
        );
      }
      case "datos_SII": {
        return (
          <RegistroSII
            closeModal={(response) => {
              if (response) {
                setModalData(response);
                setShowTopModal(false);
                handleShowModalSuccess();
              }
            }}
            endpoint={PORTALSAPI.POST.ACTUALIZARSII}
            setTipoBanner={setTipoBanner}
            setStatus={setStatus}></RegistroSII>
        );
      }
      case "contactar": {
        return (
          <Container>
            <Row className='justify-content-center'>
              <Col
                className='d-flex align-items-center justify-content-start'
                xs={10}>
                <TextColored bold type='gray'>
                  {typeobj.data.length > 1
                    ? "¿Con cuál de nuestros servicios le podemos ayudar?"
                    : ""}
                </TextColored>
              </Col>
            </Row>

            <Form>
              <Row className='justify-content-center mt-3'>
                <Col
                  className='d-flex align-items-center justify-content-start'
                  xs={10}>
                  <TextColored bold type='gray'>
                    ¿En qué podemos ayudarle?:
                  </TextColored>
                </Col>
              </Row>

              <Form.Group
                className='justify-content-center mt-3'
                as={Row}
                controlId='formUser4'>
                <Col
                  className='d-flex align-items-center justify-content-start'
                  xs={10}>
                  <Form.Control
                    name='contactar'
                    rows={3}
                    as='textarea'
                    placeholder=''
                    ref={register({ required: false })}
                  />
                </Col>
              </Form.Group>

              <Row className='justify-content-center mt-3'>
                <Col xs={width > mobileXSWidth ? 4 : 12}>
                  <Button onClick={handleSubmit(enviarInformacionHandle)}>
                    Enviar Información
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>
        );
      }

      case "usuario": {
        return (
          <>
            <Container style={userModal} className='border-0'>
              <Row>
                <Col xs={{ span: 2, offset: 1 }}>
                  <User center={false} />
                </Col>
                <Col xs={5}>
                  <Row>
                    <Text bold size={body14} type='primary'>
                      {DecryptData(
                        typeobj?.data?.data?.nombres,
                        typeobj.public_key
                      )}
                    </Text>
                  </Row>
                  <Row>
                    <Text bold size={body12} type='primary'>
                      {DecryptData(
                        typeobj?.data?.data?.cliente,
                        typeobj.public_key
                      )}
                    </Text>
                  </Row>
                  <Row>
                    <TextColored
                      className='mr-2'
                      bold
                      size={body12}
                      type='gray'>
                      • Email:
                    </TextColored>
                    <TextColored size={body12} type='gray'>
                      {DecryptData(
                        typeobj?.data?.data?.correo,
                        typeobj.public_key
                      )}
                    </TextColored>
                  </Row>
                  <Row>
                    <TextColored
                      className='mr-2'
                      bold
                      size={body12}
                      type='gray'>
                      • Fono:
                    </TextColored>
                    <TextColored size={body12} type='gray'>
                      {DecryptData(
                        typeobj?.data?.data?.celular,
                        typeobj.public_key
                      )}
                    </TextColored>
                  </Row>
                  <br />
                </Col>
                <Col xs={width > mobileSMWidth ? 4 : 12}>
                  <Row className='justify-content-center'>
                    <Col xs={10}>
                      <Button
                        className='w-100'
                        onClick={() =>
                          openModal({ title: "actualizar", data: {} })
                        }>
                        Actualizar Datos
                      </Button>
                    </Col>
                  </Row>
                  {(tipoBanner === 0 || tipoBanner === 2) && (
                    <Row className='mt-3 justify-content-center'>
                      <Col xs={10}>
                        <Button
                          className='w-100'
                          onClick={() => openTopModal("datos_SII")}>
                          Actualizar Contraseña SII
                        </Button>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </Container>
          </>
        );
      }

      case "actualizar": {
        if (!mountedRef.current) {
          mountedRef.current = true;
          reset({
            correo: DecryptData(
              typeobj?.data?.data?.correo,
              typeobj.public_key
            ),
            nombres: DecryptData(
              typeobj?.data?.data?.nombres,
              typeobj.public_key
            ),
            celular: DecryptData(
              typeobj?.data?.data?.celular,
              typeobj.public_key
            ),
          });
        }

        return (
          <Container>
            <Form>
              <Form.Group
                className='justify-content-center'
                as={Row}
                controlId='formUser5'>
                <Col xs={8}>
                  <Form.Control
                    name='nombres'
                    type='text'
                    placeholder='Nombre Usuario'
                    ref={register()}
                  />
                </Col>
              </Form.Group>
              <Form.Group
                className='justify-content-center'
                as={Row}
                controlId='formContact'>
                <Col xs={4}>
                  <Form.Control
                    name='celular'
                    type='text'
                    placeholder='Teléfono'
                    style={{
                      border: isInvalidPhone ? "1px solid red" : "",
                    }}
                    ref={register({ required: true })}
                    onKeyUp={(e) =>
                      setIsInvalidPhone(e.target.value.length !== 9)
                    }
                    onKeyDown={handleKeyPress}
                  />
                  {isInvalidPhone ? (
                    <Row style={{ marginLeft: "2px", marginBottom: "5px" }}>
                      <TextGeneric color='red'>Teléfono inválido</TextGeneric>
                    </Row>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col xs={4}>
                  <Form.Control
                    name='correo'
                    type='text'
                    placeholder='Email'
                    onChange={() => {}}
                    disabled
                    ref={register()}
                  />
                </Col>
              </Form.Group>

              <Row className='justify-content-center'>
                <Col xs={4}>
                  <Row className='justify-content-center'>
                    <Form.Check
                      className='mr-1'
                      type='checkbox'
                      label=''
                      ref={register()}
                      name='terminos'
                      aria-label='terminos'
                    />
                    <TextColored type='gray'>
                      Acepto Política de Privacidad
                    </TextColored>
                  </Row>
                  <Row className='justify-content-center'>
                    <Text type='primary'>
                      <u style={{ cursor: "pointer" }} onClick={() => abrir()}>
                        Leer detalle
                      </u>
                    </Text>
                  </Row>
                  <Row className='mt-3'>
                    <Button
                      onClick={handleSubmit(actualizarDatos)}
                      disabled={isInvalidPhone}>
                      Enviar Información
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Container>
        );
      }

      default: {
        return <></>;
      }
    }
  };

  /* Modal Primer Nivel */
  const openModal = (data_modal) => {
    setShowLoading({ display: "block" });
    let type = data_modal.title;
    const _modalInfo = { table: { columns: [], rows: [] } };
    _modalInfo["size"] = "xl";
    _modalInfo["padding"] = true;
    let data = [];

    switch (type) {
      case "notificacion": {
        _modalInfo["title"] = (
          <Title title='Centro de Notificaciones' pipe={false} subtitle='' />
        );
        _modalInfo["subtitle"] =
          "En esta sección podrás conocer notificaciones importantes para que no te pierdas de nada.";
        _modalInfo["size"] = "xs";
        _modalInfo["table"] = null;

        // let urlMain = PORTALSAPI.GET.PANEL_CONTROL.replace('{idcontacto}', user.idcontacto);

        // api.get(urlMain).then((response) => {
        _modalInfo["content"] = (
          <RenderContent type={type} data={notificacion} />
        );
        _modalInfo["icon"] = icons["bocina"];
        setTopModal(_modalInfo);
        setShowLoading({ display: "none" });
        handleShowTopModal();
        // });
        break;
      }

      case "usuario": {
        _modalInfo["title"] = (
          <Title
            size={body25}
            title='Mi panel de Control'
            pipe={false}
            subtitle=''
          />
        );
        _modalInfo["subtitle"] =
          "Bienvenido a su panel de control, acá podrá cambiar sus datos de cliente Progreso.";
        _modalInfo["size"] = "lg";
        _modalInfo["table"] = null;
        _modalInfo["padding"] = false;
        let url = PORTALSAPI.GET.PANEL_CONTROL_ENROLAMIENTO.replace(
          "{idcontacto}",
          user.idcontacto
        );

        api
          .get(url)
          .then((response) => {
            let publicURL = PORTALSAPI.GET.PUBLIC_KEY.replace(
              "{idcontacto}",
              data_modal.data.idcontacto
            ).replace("{tipo}", user.tipo_acceso);
            api.get(publicURL).then((responsePublicKey) => {
              _modalInfo["content"] = (
                <RenderContent
                  type={type}
                  public_key={responsePublicKey.data}
                  data={response}
                />
              );
              _modalInfo["icon"] = icons["panel_control"];
              setTopModal(_modalInfo);
              setShowLoading({ display: "none" });
              handleShowTopModal();
            });
          })
          .catch(() => {
            setShowLoading({ display: "none" });
            fireAlert({ icon: "warning" });
          });
        break;
      }

      case "contactar": {
        _modalInfo["title"] = (
          <Title title='Cuente con Nosotros' subtitle='Contacto' />
        );
        _modalInfo["subtitle"] =
          "Por este medio podrá ponerse en contacto de forma directa con su(s) ejecutivo(s) asignado(s).";
        _modalInfo["size"] = "lg";
        _modalInfo["table"] = null;

        // let url = PORTALSAPI.GET.CONTACTOS.replace('{idcontactos}', data_modal.data);

        // api.get(url).then(response => {
        _modalInfo["content"] = <RenderContent type={type} data={{}} />;
        _modalInfo["icon"] = icons["ejecutivo"];
        setTopModal(_modalInfo);
        setShowLoading({ display: "none" });
        handleShowTopModal();
        // });
        break;
      }

      case "actualizar": {
        _modalInfo["title"] = (
          <Title
            title='Actualización de Datos Cliente'
            pipe={false}
            subtitle=''
          />
        );
        _modalInfo["subtitle"] =
          "Completa los antecedentes solicitados en el siguiente formulario para actualizar tus datos registrados.";
        _modalInfo["size"] = "lg";
        _modalInfo["table"] = null;

        let url = PORTALSAPI.GET.PANEL_CONTROL_ENROLAMIENTO.replace(
          "{idcontacto}",
          user.idcontacto
        );

        api
          .get(url)
          .then((response) => {
            let user = JSON.parse(localStorage.getItem("user"));
            let publicURL = PORTALSAPI.GET.PUBLIC_KEY.replace(
              "{idcontacto}",
              user.idcontacto
            ).replace("{tipo}", user.tipo_acceso);
            api.get(publicURL).then((responsePublicKey) => {
              _modalInfo["content"] = (
                <RenderContent
                  type={type}
                  public_key={responsePublicKey.data}
                  data={response}
                />
              );
              _modalInfo["icon"] = icons["usuario"];
              setTopModal(_modalInfo);
              setShowLoading({ display: "none" });
              handleShowTopModal();
            });
          })
          .catch(() => {
            setShowLoading({ display: "none" });
            fireAlert({ icon: "warning" });
          });
        break;
      }

      default: {
        break;
      }
    }
  };

  const openTopModal = (type, dataModal) => {
    setShowLoading({ display: "block" });
    const _modalInfo = { table: { columns: [], rows: [] } };
    _modalInfo["padding"] = true;
    _modalInfo["size"] = "xs";
    _modalInfo["headerColor"] = "#f7cf46";
    let data = [];
    let user = JSON.parse(localStorage.getItem("user"));
    switch (type) {
      case "datos_SII": {
        _modalInfo["title"] = (
          <Title pipe={false} title='Registro Datos Portal SII' subtitle='' />
        );
        _modalInfo["size"] = "xs";
        _modalInfo["headerColor"] = "#f7cf46";
        _modalInfo["table"] = null;
        _modalInfo["content"] = <RenderContent type={type} data={{}} />;
        _modalInfo["icon"] = icons["logo4"];
        setTopModal(_modalInfo);
        setShowLoading({ display: "none" });
        handleShowTopModal();

        break;
      }

      default: {
        break;
      }
    }
  };

  useEffect(() => {
    setShowLoading({ display: "block" });
    let user = JSON.parse(localStorage.getItem("user"));
    if (user === undefined || user === null) {
      localStorage.removeItem("user");
      localStorage.removeItem("rtoken");

      history.push("/");
      return;
    }

    let urlMain = PORTALSAPI.GET.ENROLAMIENTO.replace(
      "{idcontacto}",
      user.idcontacto
    );

    api.get(urlMain).then((response) => {
      let publicURL = PORTALSAPI.GET.PUBLIC_KEY.replace(
        "{idcontacto}",
        user.idcontacto
      ).replace("{tipo}", user.tipo_acceso);
      api.get(publicURL).then((responsePublicKey) => {
        /* console.log(response); */
        let currentUser = response.data.usuario;
        currentUser.token = user.token;
        currentUser.tipo_acceso = user.tipo_acceso;
        localStorage.setItem("user", JSON.stringify(currentUser));
        setProveedor(Number(response.data.proveedor.nproveedor));
        setStatus(response.data.state_enrolamiento);
        setUser(currentUser);
        setNombres(
          DecryptData(response.data.usuario.nombres, responsePublicKey.data)
        );
        setImages(response.data["imagenes"]);
        setMenus(response.data["menus"]);
        setTipoBanner(response.data["resumen"]["tipo_banner"]);
        setContactado(response.data["resumen"]?.contactado);
        setNotificaciones(response.data["notificacion"]);
        setNotificacion(
          response.data["notificacion"] === null
            ? 0
            : response.data["notificacion"].length
        );
        setShowLoading({ display: "none" });
      });
    });
  }, [history]);

  const handleKeyPress = (e) => {
    const numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    const alts = ["Backspace", "Tab", "Enter", "ArrowLeft", "ArrowRight"];

    if (numbers.indexOf(e.key) === -1 && alts.indexOf(e.key) === -1) {
      e.preventDefault();
      return;
    }

    if (e.target.value.length === 9 && alts.indexOf(e.key) === -1) {
      e.preventDefault();
      return;
    }
  };

  return (
    <>
      <Loading estilo={showLoading}></Loading>
      <section style={section}>
        <HeaderDashboardClientes
          notificaciones={notificacion}
          openModal={openModal}
          nombres={nombres}
          menus={menus}></HeaderDashboardClientes>

        <div
          style={{
            ...container,
            marginBottom: width > 801 && !!menus.length ? "40px" : "",
          }}>
          <div
            className='popup-btn'
            onClick={() => {
              goToUrl(user.url_comentarios);
            }}>
            <a href='##' style={{ fontSize: "1rem" }}>
              Comentarios
            </a>
          </div>
        </div>

        <div style={container}>
          <Row
            style={{
              width: width >= mobileXSWidth ? "87%" : "100%",
              marginBottom:
                tipoBanner !== 0
                  ? width > mobileXSWidth
                    ? "10px"
                    : "50px"
                  : "26px",
            }}>
            <Slider images={images}></Slider>
          </Row>
        </div>

        <div style={container}>
          <Row style={{ width: width >= 701 ? "87%" : "100%" }}>
            <Seccion2DM
              re_ingreso={tipoBanner}
              openModal={openModal}
              modal='contactar'
              contactado={contactado}
              registroHandle={() => openTopModal("datos_SII")}
              status={status}
              setStatus={setStatus}
              disabled={true}
            />
          </Row>
        </div>

        <div style={container}>
          <Row style={{ width: width >= 701 ? "87%" : "100%" }}>
            <Seccion3></Seccion3>
          </Row>
        </div>

        <Footer />

        {/* Top level modal */}
        <Modal
          headerColor={topModal.headerColor}
          padding={topModal.padding}
          size={topModal.size}
          show={showTopModal}
          icon={topModal.icon}
          title={topModal.title}
          subtitle={topModal.subtitle}
          handleClose={handleCloseTopModal}>
          {topModal.content}
        </Modal>

        <ModalSuccess
          show={showModalError}
          icon={modalData.icon}
          messageLine={modalData.messageLine}
          title={
            <TextColored size={body28} type={modalData.color}>
              {modalData.title}
            </TextColored>
          }
          subtitle={
            <TextColored size={body32} bold type={modalData.color}>
              {modalData.subtitle}
            </TextColored>
          }
          message={
            <Text type='primary' size={body25}>
              {modalData.message}
            </Text>
          }
          message2={modalData.message2}
          handleClose={() => handleCloseModalError()}
        />

        <ModalSuccess2
          show={showModalSuccess}
          icon={icons["computadora2"]}
          iconHeader={icons["logo4"]}
          headerColor='#f7cf46'
          headerTitle={
            <Title pipe={false} title='Registro Datos Portal SII' subtitle='' />
          }
          size={"xs"}
          padding={true}
          title={<Title pipe={false} title='¡Felicidades!' subtitle='' />}
          subtitle={
            <TextColored className='text-center' size={1.1} bold type='black'>
              TUS DATOS HAN SIDO RECIBIDOS
              <br />
              EXITOSAMENTE
            </TextColored>
          }
          message={
            <Text className='mt-2' type='primary' size={0.7}>
              Nuestro ejecutivo comercial se contactará contigo a la brevedad
            </Text>
          }
          handleClose={() => handleCloseModalSuccess()}
        />
      </section>
    </>
  );
};
export default DashboardClientes;
