import Axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import { api } from "./api";
import { DecryptData } from "./encrypt";
import { AUTOATENCIONAPI, PORTALSAPI } from "./url";

export const getToken = async () => {
  const { idcontacto } = JSON.parse(localStorage.getItem("user"));
  const {
    data: {
      Data: { TokenAcceso: Token },
    },
  } = await api.get(
    AUTOATENCIONAPI.GET.TOKEN_DATAMART.replace("{idcontacto}", idcontacto)
  );

  return Token;
};

export const getUserIP = async () => {
  try {
    const { data } = await Axios.get(
      "https://api.ipify.org?format=jsonp&callback=?"
    );

    return data.substring(9, data.lastIndexOf('"'));
  } catch (err) {
    await timeout(1500);
    return await getUserIP();
  }
};

export const createLink = async (setLink, text) => {
  try {
    const { tipo_acceso, idcontacto } = JSON.parse(
      localStorage.getItem("user")
    );
    const telefono = sessionStorage.getItem("telefono-ejecutivo");

    const publicUrl = PORTALSAPI.GET.PUBLIC_KEY.replace(
      "{idcontacto}",
      idcontacto
    ).replace("{tipo}", tipo_acceso);
    const { data: publicKey } = await api.get(publicUrl);

    // const decryptedPhone = DecryptData(telefono, publicKey);
    const decryptedPhone = "";

    if (decryptedPhone.length > 0) {
      const generatedLink = `https://api.whatsapp.com/send?phone=${
        56 + decryptedPhone
      }&text=${encodeURI(text)}`;

      if (setLink) setLink(generatedLink);
      return generatedLink;
    }
    if (setLink) setLink(false);
    return false;
  } catch (err) {
    console.log(err);
  }
};

export const fireAlert = async ({
  moreProps,
  button = "Entendido",
  timer = 10000,
  title = "Ha ocurrido un error",
  text = "Por favor, vuelva a intentar en un momento.",
  icon = "success",
}) => {
  const res = await Swal.fire({
    icon: icon,
    title: title,
    confirmButtonText: `
    <span style="font-size: 1rem, font-weight: 600">
      ${button}
    </span>`,
    timer,
    timerProgressBar: !!timer,
    text,
    customClass: {
      confirmButton: "btn btn-primary-custom",
      timerProgressBar: "bg-yellow",
    },
    buttonsStyling: false,
    ...moreProps,
  });

  return res;
};

export const numberTrimmer = (number) => {
  if (number.length > 0) {
    const splittedNumber = number.split(" ");
    if (number.includes("+56")) {
      if (splittedNumber.length > 2) {
        //+56 9 64841106
        splittedNumber.shift();
      } else if (splittedNumber.length === 1) {
        //+56964841106
        return splittedNumber[0].substring(3);
      }
    }
    //9 64841106
    return Number(splittedNumber.reduce((a, c) => a + c));
  }
  return "";
};

export const sincronizarDTEs = async (rut) => {
  try {
    const { tipo_acceso, idcontacto } = JSON.parse(
      localStorage.getItem("user")
    );

    if (tipo_acceso === 1) {
      const nproveedor = JSON.parse(sessionStorage.getItem("proveedor"));
      if (nproveedor === 1) {
        const syncUrl = AUTOATENCIONAPI.GET.SINCRONIZAR_DTE.replace(
          "{rut}",
          rut
        ).replace("{idcontacto}", idcontacto);
        const { status } = await api.get(syncUrl);
        return status === 200;
      } else {
        const { status } = await getCDLBills(true);
        return status === 200;
      }
    }
    return;
  } catch (err) {
    console.log(err);
  }
};

export const getProveedor = async () => {
  try {
    const { tipo_acceso, idcontacto } = JSON.parse(
      localStorage.getItem("user")
    );
    if (tipo_acceso === 1) {
      const {
        data: { nproveedor },
      } = await api.get(
        AUTOATENCIONAPI.GET.OBTENER_PROVEEDOR.replace(
          "{idcontacto}",
          idcontacto
        )
      );

      sessionStorage.setItem("proveedor", JSON.stringify(Number(nproveedor)));

      return nproveedor;
    }
  } catch (err) {
    console.log(err);
  }
};

export const reloadCosts = async (rut_cliente) => {
  try {
    const { idcontacto } = JSON.parse(localStorage.getItem("user"));

    let storageObj;

    const {
      data: { condicion_comercial },
    } = await api.get(
      AUTOATENCIONAPI.GET.INDICADORES_CLIENTE.replace(
        "{rut}",
        rut_cliente
      ).replace("{idcontacto}", idcontacto)
    );

    if (condicion_comercial) {
      const {
        lbtr,
        mt_anticipo,
        mt_comision,
        mt_gasto_operacional,
        mt_notificacion_notaria,
        mt_tasa,
      } = condicion_comercial;

      // Carga de parametros en la store
      storageObj = {
        lbtr: lbtr < 0 ? null : lbtr,
        anticipo: mt_anticipo < 0 ? null : mt_anticipo,
        comision: mt_comision < 0 ? null : mt_comision,
        gasto_operacional:
          mt_gasto_operacional < 0 ? null : mt_gasto_operacional,
        notificacion_notaria:
          mt_notificacion_notaria < 0 ? null : mt_notificacion_notaria,
        tasa: mt_tasa < 0 ? null : mt_tasa,
      };
    } else {
      storageObj = {
        lbtr: null,
        anticipo: null,
        comision: null,
        gasto_operacional: null,
        notificacion_notaria: null,
        tasa: null,
      };
    }

    sessionStorage.setItem(
      "parametros_indicadores",
      JSON.stringify(storageObj)
    );

    return storageObj;
  } catch (err) {
    console.log(err);
  }
};

export const getPlazosDeudores = async (bills, rut_cliente) => {
  const { idcontacto } = JSON.parse(localStorage.getItem("user"));
  const parametro = JSON.parse(sessionStorage.getItem('parametros_comerciales'));

  const deudores = []; // Utilizado en endpoint de indicadores actual para obtener el plazo de cada deudor

  bills?.forEach((b) => {
    if (!deudores.includes(b.rutRecep)) {
      deudores.push(b.rutRecep);
    }
  });

  const {
    data: { condicion_comercial },
  } = await api.get(
    AUTOATENCIONAPI.GET.INDICADORES_DEUDORES.replace(
      "{ruts}",
      deudores
    ).replace("{idcontacto}", idcontacto)
  );

  const plazosIndicadores = {};
  try {
    if (condicion_comercial.length > 0) {
      condicion_comercial?.forEach(({ valor, rut }) => {
        plazosIndicadores[rut] = valor;
      });
    }

    const date = moment(new Date());

    bills?.forEach((b, i, a) => {
      const actualizedBill = { ...b };

      let plazo = plazosIndicadores[b.rutRecep];
      let fecha = date.clone().add(plazo, "days");
      let fchVencimiento = fecha.day();
      const fechaVerificar = fecha.format('YYYY-MM-DD');

      const { rango, ultimoDiaMes, fchDiaMes } = tempFecha( fecha, fechaVerificar, parametro );

      if (rango || moment(fechaVerificar).isSame(ultimoDiaMes) ) {

        plazo += (moment(ultimoDiaMes).date() - fchDiaMes) + 1;
        fecha = date.clone().add(plazo, "days");
        fchVencimiento = fecha.day();

      }

      if (fchVencimiento === 6 || fchVencimiento === 0) {
        plazo = fchVencimiento === 6 ? plazo + 2 : plazo + 1;
  
        fecha = date.clone().add(plazo, "days");
        const fechaVerificar2 = fecha.format('YYYY-MM-DD');
        const tempRango = tempFecha( fecha, fechaVerificar2, parametro );
  
        fchVencimiento = fecha.day();
  
        if( tempRango.rango || moment(fechaVerificar2).isSame(tempRango.ultimoDiaMes) ){
  
          plazo += (moment(tempRango.ultimoDiaMes).date() - tempRango.fchDiaMes) + 1;
          fecha = date.clone().add(plazo, "days");
          fchVencimiento = fecha.day();

        }
  
      }

      if (fchVencimiento === 6 || fchVencimiento === 0) {
        actualizedBill["plazo"] = fchVencimiento === 6 ? plazo + 2 : plazo + 1;
      } else {
        actualizedBill["plazo"] = plazo;
      }

      a[i] = actualizedBill;
    });

    return bills;
  } catch (err) {
    throw err;
  }
};

const tempFecha = (fecha, fechaVerificar, parametro ) =>{
  
  const fchDiaMes = fecha.date();

  //obtener ultimo dia del mes:
  const ultimoDiaMes = fecha.endOf('month').format('YYYY-MM-DD');

  //Resto los ultimos dias del mes
  const diaRango = moment(ultimoDiaMes).subtract(parametro.DIAS_VENCIMIENTO, 'days').format('YYYY-MM-DD');

  const rango = moment(fechaVerificar).isBetween(diaRango, ultimoDiaMes);

  return { rango, ultimoDiaMes, fchDiaMes }
}

export const getResumeBills = async (bills = null) => {
  try {
    const tmpBills = bills || JSON.parse(sessionStorage.getItem("bills"));
    const rut_cliente = await getRut();
    const newBills = await getPlazosDeudores(tmpBills, rut_cliente);
    sessionStorage.setItem("bills", JSON.stringify([...newBills]));
    return newBills;
  } catch (err) {
    console.log(err);
  }
};

export const timeout = (ms) => {
  // setTimeout para funciones asincronas
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getComertialInfo = async () => {
  // Carga de parametros comerciales en la storage
  const { tipo_acceso, idcontacto } = JSON.parse(localStorage.getItem("user"));
  try {
    let usercontacted = tipo_acceso === 1;
    if (usercontacted) {
      const comertialUrl = AUTOATENCIONAPI.GET.ENROLAMIENTO_RUT.replace(
        "{iduser}",
        idcontacto
      );

      const {
        data: { ok, user, estado_enrolamiento },
      } = await api.get(comertialUrl);

      if (ok) {
        let obj = {};
        user?.parametros_comerciales?.forEach((e) => {
          if (typeof e.valor === "string") {
            if (
              e.nombre_parametro === "MAXIMO_FACTURAS" ||
              e.nombre_parametro === "MINIMO_FACTURAS"
            ) {
              obj[e.nombre_parametro] = Number(e.valor.replaceAll(".", ""));
            } else {
              obj[e.nombre_parametro] = Number(e.valor);
            }
          } else {
            obj[e.nombre_parametro] = e.valor;
          }
          if (e.dataTotal) {
            let desgloceObj = {};
            e.dataTotal.forEach((d) => {
              if (d.nombre.includes("AUTOATENCION")) {
                desgloceObj[d.nombre.split(" ")[0]] = d.valor;
                desgloceObj["ID_" + d.nombre.split(" ")[0]] = d.id_gasto;
              }
            });
            obj[`${e.nombre_parametro}-DESGLOCE`] = desgloceObj;
          }
        });

        sessionStorage.setItem("parametros_comerciales", JSON.stringify(obj));
        sessionStorage.setItem(
          "indicadores_cliente",
          JSON.stringify(user.indicadores_cliente)
        );
        sessionStorage.setItem(
          "lista_cuentas",
          JSON.stringify(user.lista_cuentas)
        );
        sessionStorage.setItem("token-autoatencion", user.token);

        return estado_enrolamiento;
      }
    }
  } catch (err) {
    console.log(err);
  }
};

export const getRut = async () => {
  try {
    const { tipo_acceso, idcontacto } = JSON.parse(
      localStorage.getItem("user")
    );
    if (tipo_acceso === 1 && idcontacto) {
      const comertialUrl = AUTOATENCIONAPI.GET.ENROLAMIENTO_RUT.replace(
        "{iduser}",
        idcontacto
      );

      const {
        data: {
          user: { rut },
        },
      } = await api.get(comertialUrl);

      const publicUrl = PORTALSAPI.GET.PUBLIC_KEY.replace(
        "{idcontacto}",
        idcontacto
      ).replace("{tipo}", tipo_acceso);

      const { data: publicKey } = await api.get(publicUrl);

      return DecryptData(rut, publicKey);
    }
  } catch (err) {
    console.log(err);
  }
};

export const encryptPassword = async (password) => {
  try {
    const encryptKey = "ff1eb517-3499-4841-bf02-dd9ba794b586";
    const context = {
      stage: "Portal progreso",
      purpose: "Encriptacion de data sensible SII",
    };
    return await test.JSCIPHER_Encrypt(password, context, encryptKey);
  } catch (err) {
    console.log(err);
  }
};

export const getPublicKey = async () => {
  try {
    const { tipo_acceso, idcontacto } = JSON.parse(
      localStorage.getItem("user")
    );
    const publicUrl = PORTALSAPI.GET.PUBLIC_KEY.replace(
      "{idcontacto}",
      idcontacto
    ).replace("{tipo}", tipo_acceso);

    const { data: publicKey } = await api.get(publicUrl);

    return publicKey;
  } catch (err) {
    console.log(err);
  }
};

export const decryptPassword = async (password) => {
  try {
    const context = {
      stage: "Portal progreso",
      purpose: "Encriptacion de data sensible SII",
    };
    return await test.JSCIPHER_Decrypt(password, context);
  } catch (err) {
    console.log(err);
  }
};

export const formatRut = (rut) => {
  // XX.XXX.XXX-X
  const newRut = rut.replace(/\./g, "").replace(/\-/g, "").trim().toLowerCase();
  const lastDigit = newRut.substr(-1, 1);
  const rutDigit = newRut.substr(0, newRut.length - 1);
  let format = "";
  for (let i = rutDigit.length; i > 0; i--) {
    const e = rutDigit.charAt(i - 1);
    format = e.concat(format);
    if (i % 3 === 0) {
      format = ".".concat(format);
    }
  }
  return format.concat("-").concat(lastDigit);
};

export const chargeExecutivePhone = async () => {
  try {
    const { idcliente, idcontacto } = JSON.parse(localStorage.getItem("user"));
    if (idcliente !== 0) {
      const {
        data: { telefono, nombre },
      } = await api.get(
        AUTOATENCIONAPI.GET.TELEFONO_EJECUTIVO.replace(
          "{idpersona}",
          idcliente
        ).replace("{idcontacto}", idcontacto)
      );
      sessionStorage.setItem("telefono-ejecutivo", JSON.stringify(telefono));
      sessionStorage.setItem("nombre-ejecutivo", JSON.stringify(nombre));
    }
    return;
  } catch (err) {
    console.log(err);
  }
};

export const getResume = () => {
  const parameters = JSON.parse(
    sessionStorage.getItem("parametros_comerciales")
  );
  const parametros_indicadores = JSON.parse(
    sessionStorage.getItem("parametros_indicadores")
  );

  const bills = JSON.parse(sessionStorage.getItem("bills"));

  if (parameters && parametros_indicadores && bills) {
    // Cantidad de deudores
    const debtsQuantity = Array.from(
      new Set([...bills.map((e) => e.rutRecep)])
    ).length;

    // Costos provenientes de los indicadores
    const {
      anticipo,
      comision,
      gasto_operacional,
      lbtr,
      notificacion_notaria,
      tasa,
    } = parametros_indicadores;

    const operationalExpenses = gasto_operacional;
    const deudorNotification = notificacion_notaria;
    const LBTR = lbtr;
    const ANTICIPO = anticipo;
    const COMISION = comision;
    const TASA = tasa;

    const costs =
      operationalExpenses + LBTR + deudorNotification * debtsQuantity;

    let amountsObj = {
      total: 0,
      financed: 0,
      nonFinanced: 0,
      rate: 0,
      costs,
      liquid: -costs,
      desglocedCosts: {
        priceDiference: 0,
        commission: 0,
        IVA: 0,
        operationalExpenses,
        LBTR,
        deudorNotification: deudorNotification * debtsQuantity,
      },
    };

    bills?.forEach((e) => {
      // En cada iteracion los calculos realizados entre montos e indicadores comerciales del deudor / cliente se agregan al obj principal
      const total = Math.round(e.mntTotal);
      const financed = Math.round((e.mntTotal * ANTICIPO) / 100);
      const nonFinanced = total - financed;

      const costs = {
        priceDiference: Math.round(((financed * TASA) / 100 / 30) * e.plazo),
        commission: Math.round((total * COMISION) / 100),
        IVA: Math.round((((total * COMISION) / 100) * parameters.IVA) / 100),
      };

      amountsObj.desglocedCosts.priceDiference += costs.priceDiference;
      amountsObj.desglocedCosts.commission += costs.commission;
      amountsObj.desglocedCosts.IVA += costs.IVA;

      const costsTotal = Object.values(costs).reduce(
        (total, current) => total + current,
        0
      );

      const liquid = total - costsTotal - nonFinanced;

      amountsObj.rate += Math.round((financed * TASA) / 100);
      amountsObj.total += total;
      amountsObj.financed += financed;
      amountsObj.nonFinanced += nonFinanced;
      amountsObj.costs += costsTotal;
      amountsObj.liquid += liquid;
    });

    return amountsObj;
  }
};

export const generateCdlObj = async (r = false, pass = false) => {
  // Utilizado para suscriber - enrolamiento de cdl
  try {
    const { rut, nombres, idcontacto, idcliente } = JSON.parse(
      localStorage.getItem("user")
    );
    const publicKey = await getPublicKey();

    let dbPassword;
    if (!pass) {
      const {
        data: {
          data: { siipassword },
        },
      } = await api.get(
        AUTOATENCIONAPI.GET.DATA_ENROLAMIENTO.replace("{iduser}", idcontacto)
      );
      dbPassword = DecryptData(siipassword, publicKey);
    }

    return {
      id_persona: idcliente,
      rut: r || DecryptData(rut, publicKey),
      no_razon_social: DecryptData(nombres, publicKey),
      contrasenia: pass || dbPassword,
      usuario: "usuario",
      modulo: "cdl",
      origen: "3",
    };
  } catch (err) {
    console.log(err);
  }
};

export const generateCesionObj = async (
  bills,
  options,
  files = {},
  certificatePassword = null
) => {
  try {
    const {
      mybiller = false,
      registerFintec = false,
      cesionflag = true,
      type = "cesion",
    } = options;

    const { direccion, correo, cliente, idcliente } = JSON.parse(
      localStorage.getItem("user")
    );

    const payment_method = JSON.parse(sessionStorage.getItem("payment_method"));

    const process_id_dm = JSON.parse(sessionStorage.getItem("processId"));

    const { "GASTOS-DESGLOCE": gastosDesgloce } = JSON.parse(
      sessionStorage.getItem("parametros_comerciales")
    );

    const {
      tasa,
      anticipo,
      comision: indicadorComision,
      gasto_operacional,
      lbtr,
      notificacion_notaria,
    } = JSON.parse(sessionStorage.getItem("parametros_indicadores"));

    const dateFormat = "yyyy-MM-DD HH:mm:ss";

    const publicKey = await getPublicKey();

    const {
      financed: anticipado,
      nonFinanced: nofinanciado,
      rate: tasaparametro,
      liquid: total,
      desglocedCosts: {
        commission: comision,
        IVA: iva,
        priceDiference: diferenciaprecio,
        LBTR,
        deudorNotification,
        operationalExpenses,
      },
    } = getResume();

    const rut_cliente = await getRut();

    const date = moment.tz(new Date(), "America/Santiago");

    const helper = [];
    const cesionArray = [];

    for (const b of bills) {
      const {
        enlaceXml: DTE = null,
        mntTotal: MontoCesion,
        folio: Folio,
        rutRecep: rut_deudor,
        plazo,
        rznSocRecep: RasonSocialDeudor,
        PDF = "",
        nombre_pdf = "",
        fchEmis,
        tipoDTE,
      } = b;

      if (type === "scoring" && helper.includes(rut_deudor)) {
        continue;
      } else {
        helper.push(rut_deudor);
        cesionArray.push({
          RUTCedente: rut_cliente,
          RasonSocialCedente: DecryptData(cliente, publicKey)?.substring(
            0,
            100
          ),
          RasonSocialDeudor,
          EmailCedente: DecryptData(correo, publicKey),
          DireccionCedente: DecryptData(direccion, publicKey),
          RUTsAutorizados: null,
          DeclaracionJurada: "",
          RUTCesionario: "90146000-0",
          RazonSocialCesionario: "SERVICIOS FINANCIEROS PROGRESO SA",
          DireccionCesionario: "MIRAFLORES 222",
          EmailCesionario: "janet.jimenez@progreso.cl",
          nombre_archivo: files[Folio]?.xml?.name || null,
          DTE,
          Folio,
          MontoCesion,
          AECAnterior: "",
          MuestraPDF: "",
          OtrasCondiciones: "",
          EmailDeudor: "",
          HabilitarSincronizacionDTE: true,
          ChequearEstadoCertificadoEnvio: true,
          Emision: moment(fchEmis).format(dateFormat),
          UltimoVencimiento: date.clone().add(plazo, "days").format(dateFormat),
          rut_deudor,
          iddeudor: 0,
          currency: "CLP",
          PDF: PDF.name ? await toBase64(PDF) : PDF,
          nombre_pdf,
          tipoDTE: Number(tipoDTE),
        });
      }
    }

    const gastosArray = [
      {
        id_gasto: gastosDesgloce.ID_GASTO,
        monto: gasto_operacional,
      },
      {
        id_gasto: gastosDesgloce.ID_LBTR,
        monto: lbtr,
      },
      {
        id_gasto: gastosDesgloce.ID_NOTIFICACION,
        monto: notificacion_notaria,
      },
    ];

    const prc_anticipo = anticipo;
    const prc_tasa = tasa;
    const prc_comision = indicadorComision;

    return {
      facturas: JSON.stringify(cesionArray),
      certificate: files.certificate ? await toBase64(files.certificate) : "",
      password: certificatePassword || "",
      rut_cliente,
      process_id_dm: process_id_dm || "",
      sim: {
        cantidad_documentos: 0,
        prc_anticipo,
        prc_tasa,
        prc_comision,
        documentos: 0,
        cesion: total + nofinanciado,
        diferenciaprecio,
        anticipado,
        nofinanciado,
        aplicado: 0,
        gastos: LBTR + operationalExpenses + deudorNotification,
        comision,
        iva,
        cxc: 0,
        documentoaplicar: 0,
        cuotaleasing: 0,
        total,
        idmoneda: 0,
        moneda: "",
        nombre_archivo: "",
        base64: "",
        usuario: "",
        fecha: date.clone().format(dateFormat),
        tasaparametro,
        comisionparametro: comision,
        item: 0,
        hora: date.clone().format("HH:mm:ss"),
      },
      id_metodo: payment_method?.id_metodo || 0,
      idcliente,
      monto_metodo: Number(total),
      gastos: gastosArray,
      mybiller,
      registerFintec,
      cesionflag,
    };
  } catch (err) {
    console.log(err);
  }
};

export const getCDLBills = async (sync = false) => {
  try {
    const { idcontacto } = JSON.parse(localStorage.getItem("user"));

    const obj = await generateCdlObj();

    const data = await api.post(
      AUTOATENCIONAPI.POST.FACTURAS_CDL.replace(
        "{idcontacto}",
        idcontacto
      ).replace("{sync}", sync),
      obj
    );

    return data;
  } catch (err) {
    console.log(err);
  }
};

export const toBase64 = (file) => {
  if (file) {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onload = () => {
        resolve(reader.result.split(",")[1]);
      };
      reader.onerror = () => {
        reject(new DOMException("Problem parsing input file."));
      };
      reader.readAsDataURL(file);
    });
  }
  return null;
};

export const toFile = async (base64, filename = "") => {
  if (base64) {
    var arr = base64.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
};

export const stepValidator = () => {
  try {
    // Verifico si el paso que se encuentra dentro del local storage es igual a la url actual, caso contrario redirige al paso correcto
    const storageStep = Number(sessionStorage.getItem("current-step"));
    const currentPath = window.location.pathname;

    const steps = {
      1: "/autoatencion/seleccion-facturas",
      2: "/autoatencion/resumen-facturas",
      3: "/autoatencion/metodo-pago",
      4: "/autoatencion/archivos",
    };

    const numberOfStep = {
      "/autoatencion/seleccion-facturas": 1,
      "/autoatencion/resumen-facturas": 2,
      "/autoatencion/metodo-pago": 3,
      "/autoatencion/archivos": 4,
    };

    if (storageStep && currentPath) {
      if (
        steps[storageStep] !== currentPath &&
        numberOfStep[currentPath] > storageStep
      ) {
        window.location.replace(steps[storageStep]);
        return false;
      }
    } else {
      window.location.replace("/dashboard");
      return false;
    }
    return true;
  } catch (err) {
    console.log(err);
  }
};

export const formatCurrency = (number) => {
  return new Intl.NumberFormat("es-CL", {
    currency: "CLP",
    style: "currency",
  }).format(number);
};

export const loremIpsumPruebas = `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam suscipit felis tortor, ut tempor diam bibendum sed. Mauris fermentum ultricies ligula, non consectetur eros volutpat non. Vivamus pharetra vulputate ullamcorper. Suspendisse ultricies facilisis ligula a laoreet. Duis et tellus ullamcorper, iaculis dui quis, ultricies odio. In in congue tellus. Proin quis sapien finibus, sollicitudin est sit amet, malesuada purus. Aenean id urna viverra, pretium nunc non, facilisis lorem.</p>

<p>Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Aenean quis justo quis mauris pretium semper. Phasellus dignissim eleifend turpis ac aliquam. Morbi sagittis eget turpis volutpat pellentesque. Aenean augue orci, auctor vel ex sit amet, posuere commodo libero. Aliquam vitae odio mauris. Vestibulum sed augue ac libero bibendum mollis. Duis imperdiet, arcu non tempor facilisis, mi tortor posuere sapien, vel aliquet est enim et enim. Cras hendrerit blandit massa, ac efficitur risus consequat vitae. Nullam eget augue posuere, tempus sem non, ullamcorper nisi. Nullam vitae libero sapien. Donec ultrices, nisl ac vehicula rutrum, ligula nulla finibus arcu, ac euismod mi lacus non leo. Fusce faucibus ornare dui nec accumsan.</p>

<p>Nam varius molestie mattis. Suspendisse lobortis nisi sed tellus laoreet tempus. Aliquam ut diam hendrerit, dignissim nisl vitae, posuere arcu. Curabitur lobortis, dui ac sollicitudin convallis, urna magna luctus nibh, non suscipit ex risus nec ante. Fusce turpis nunc, rhoncus a feugiat at, placerat non nulla. Nunc sit amet nulla eu diam hendrerit bibendum non et magna. Nam ac libero tincidunt, egestas dui nec, ultrices nisi. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam tempor, arcu in gravida accumsan, nulla quam accumsan dolor, non pellentesque justo tortor sed sem. Quisque iaculis ullamcorper velit et facilisis. Pellentesque molestie quam ante, a faucibus odio accumsan ut. Aenean porta finibus odio, sed suscipit velit rhoncus non. Proin commodo est nec efficitur ultrices. Etiam tortor ligula, vestibulum tincidunt turpis ut, fermentum hendrerit ipsum. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p>

<p>Nunc sit amet libero pharetra, consectetur ipsum eu, convallis risus. Nam ultricies elementum odio, sit amet pretium magna dignissim vitae. Morbi auctor auctor lorem, sed imperdiet elit tempus efficitur. Aenean at nunc faucibus, mattis nisl at, ultricies enim. Etiam hendrerit mi id lectus vulputate laoreet. Nunc elementum auctor faucibus. Vestibulum finibus elit nec efficitur dictum. Nam lobortis nibh diam, et hendrerit dui hendrerit vitae. Aliquam erat volutpat. Sed at dui feugiat, efficitur metus quis, fermentum magna. Aenean sed egestas arcu, eu ultrices sem. Nunc rhoncus ornare lectus eu suscipit. Aliquam rhoncus augue erat, a commodo sapien rhoncus a.</p>

Quisque pharetra odio vitae feugiat pharetra. Aliquam nec ante sagittis, egestas sapien ac, pellentesque elit. Quisque posuere odio justo. Nullam vestibulum auctor ante id volutpat. Quisque pellentesque pellentesque ornare. Nullam eu ex placerat lectus luctus rhoncus. Integer dignissim et elit eget rutrum. Maecenas libero nisl, hendrerit ut orci sit amet, venenatis vehicula justo. Nullam iaculis augue ac nisl maximus luctus. Aliquam vulputate semper elit eget gravida. Vivamus eget tellus iaculis, venenatis tellus at, aliquet lorem. Fusce vitae fringilla augue. Pellentesque tristique at magna eget cursus.`;
