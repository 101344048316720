// Librerias
import React, { useContext, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
// Helpers
import { api, authHeader } from "../../helpers/api";
import { LOGIN, PORTALSAPI, PRELOGIN } from "../../helpers/url";
import { ThemeContext } from "../../theming";
import { DecryptData, EncryptData } from "../../helpers/encrypt";
import { hideEmail, formatRut, validaRut, convertLastCharToLowerCase } from "../../helpers/functions.js";
import useWindowDimensions from "../../helpers/useWindowDimensions";

//Assets
import Logo2 from "../../assets/images/logo2.png";
import Pipe2 from "../../assets/images/pipe2.png";
import {
  Liquidacion,
  Excedente,
  Cobranza,
  Ejecutivo,
  Usuario,
  Computadora2,
  Error,
  Logo4,
  Operacion,
  Confirmado,
} from "../../assets/icons/dashboard";
import { Text, TextColored, TextGeneric, Title } from "../typography";
import {
  mobileXSWidth,
  mobileSMWidth,
} from "../../helpers/responsiveVariables";
//Componentes
import { Col, Container, Form, Row } from "react-bootstrap";
import CustomButtonWithoutHover from "../buttons/ButtonWithoutHover";
import Input from "../inputs/Input";
import ComputadoraIcon from "../../assets/images/laptop.png";
import { ModalSuccess, ModalSuccess2 } from "../modal/Modal";
import Loading from "../../components/loading/Loading";
import InformativeBanner from "../banner/InformativeBanner.jsx";

const ClienteLogin = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { width } = useWindowDimensions();

  const history = useHistory();
  const app = useContext(ThemeContext);
  const { body25, body28, body32 } = app.theme.font;

  const icons = {
    liquidacion: <Liquidacion />,
    excedente: <Excedente />,
    cobranza: <Cobranza />,
    gestion: <Cobranza />,
    detalle: <Cobranza />,
    cuota: <Excedente />,
    usuario: <Usuario />,
    ejecutivo: <Ejecutivo />,
    contrasenia: <Ejecutivo />,
    operacion: <Operacion />,
    logo4: <Logo4 />,
    computadora2: <Computadora2 />,
  };

  // Información del banner que se pasa por props al componente InformativeBanner
  const [informationBanner, setInformationBanner] = useState({
    hiddenBanner: 0,
    message: "",
    titleButton: "",
    urlButton: "",
    hiddenButton: 0,
    title: "",
    icon: "",
    hiddenIcon: 0,
    colorBanner: "",
  });

  /* modals */
  /* Error modal */
  const [modalData, setModalData] = useState({});
  const [showModalError, setShowModalError] = useState(null);

  const [modalRedirect, setModalRedirect] = useState({});
  const [showModalRedirect, setShowModalRedirect] = useState(false);

  const [modalChangePassword, setModalChangePassword] = useState({});
  const [showModalChangePassword, setShowModalChangePassword] = useState(false);

  /* Modal cuenta inhabilitada */
  const [showModalInhabilited, setShowModalInhabilited] = useState(false);

  /* Controladores */
  const [showLoading, setShowLoading] = useState({ display: "none" });
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");

  /* flags */
  const [haveMasterEmail, setHaveMasterEmail] = useState(false);
  const [flagButton, setFlagButton] = useState(false);
  /* delay */
  const [delay, setDelay] = useState(3);
  /* Estilos */
  const container = {
    width: "450px",
    height: "100%",
    background: "white",
  };

  const buttonLoginStyle = {
    marginTop: "10px",
  };
  useEffect(() => {
  const clearExpired = () => {
    localStorage.removeItem('expired');
  };

  // Agregar un evento beforeunload al componente
  window.addEventListener('beforeunload', clearExpired);

  return () => {
    // Remover el evento antes de que el componente se desmonte
    window.removeEventListener('beforeunload', clearExpired);
  };
  }, []);
  useEffect(()=>()=>{
    localStorage.removeItem('expired');
  },[]);
  const handleModalError = (message = "Problema de Acceso", message2 = "") => {
    setModalData({
      messageLine: false,
      title: "Login",
      subtitle: "Error",
      color: "red",
      message: message,
      message2: message2,
      icon: (
        <>
          <Error />
        </>
      ),
    });
    setShowModalError(true);
  };

  const redirectModal = () => {
    const boolParam = true;
    const rutParam = username.replace(/\./g, "");
    history.push({
      pathname: `/seleccionar-correo-notificacion`,
      search: `?rut=${rutParam}`,
      state: { myBool: boolParam },
    });
  };

  const redirectSelectEmail = () => {
    setModalRedirect({
      messageLine: false,
      title: "Confirma tu correo principal",
      message:
        "Estimado cliente, aún no cuentas con un correo electrónico principal definido en nuestro portal. A continuación, te redireccionaremos a una página en la que podrás completar este proceso.",
      color: "yellow",
      icon: (
        <>
          <Confirmado />
        </>
      ),
      message2: (
        <>
          <CustomButtonWithoutHover onClick={redirectModal}>
            Seleccionar correo principal
          </CustomButtonWithoutHover>
        </>
      ),
    });

    setShowModalRedirect(true);
  };

  const updatePassword = async () => {
    let captchaToken = await executeRecaptcha();
    api.get(PORTALSAPI.GET.OBTENER_TOKEN).then((responseToken) => {
      let url = PORTALSAPI.GET.RECUPERAR_CONTRASENIA.replace(
        "{rut}",
        username.replace(/\./g, "")
      );
      let token = responseToken.data;

      api.post(
        url,
        { captchatoken: captchaToken },
        { headers: { Authorization: `Bearer ${token}` } }
      );
    });

    setShowModalChangePassword(false);
    setUserName("");
    setPassword("");
  };

  const handleChangePassword = (email) => {
    setModalChangePassword({
      messageLine: false,
      title: "Actualizar contraseña",
      message: `Estimado cliente, aún no cuentas con una contraseña definida en nuestro portal. Te enviaremos un correo de verificación a ${email} para completar este proceso.`,
      color: "yellow",
      icon: (
        <>
          <Confirmado />
        </>
      ),
      message2: (
        <>
          <CustomButtonWithoutHover onClick={updatePassword}>
            Aceptar
          </CustomButtonWithoutHover>
        </>
      ),
    });

    setShowModalChangePassword(true);
  };

  const loginInProcess = useRef(false);

  // Validar Rut
  const [errors, setErrors] = useState("");
  const [flagRut, setFlagRut] = useState(false);

  const handleRut = (e) => {
    const value = convertLastCharToLowerCase(formatRut(e.target.value));
    let rutWithoutPoints = value.replace(/\./g, "");
    if(rutWithoutPoints.length <= 10){
      setUserName(value);
      const ValidRut = validaRut(value);
      if (!ValidRut) {
        setErrors("RUT inválido");
        setFlagRut(false);
        setHaveMasterEmail(false);
      } else {
        setErrors("");
        setFlagRut(true);
      }
    }
  };
  useEffect(()=>{
    let expired = localStorage.getItem("expired");
    if(expired != null){
      if(expired == "true"){
        setModalData({
          messageLine: false,
          title: "Tu sesión ha expirado",
          color: "yellow",
          message: "Ingrese nuevamente",
          icon: (
            <>
              <Error />
            </>
          ),
        });
        setShowModalError(true);
      }
    }
  },[]);
  useEffect(()=>{
    if(!showModalError && showModalError != null){
      localStorage.removeItem("expired");
      localStorage.removeItem("rtoken");
    }
  },[showModalError]);
  useEffect(() => {
    let timeOutId;
    const fTimeOut = ()=>{
      timeOutId = setTimeout(handleLogin, delay * 1000);
    }
    if (flagRut) {
      fTimeOut();
    }
    return()=>{
      clearTimeout(timeOutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flagRut, username]);

  const handleLogin = async () => {
    let rutWithoutPoints = username.replace(/\./g, "");
    try {
      if (
        (loginInProcess?.current === false && rutWithoutPoints.length === 10) ||
        rutWithoutPoints.length === 9
      ) {
        loginInProcess.current = true;
        setShowLoading({ display: "block" });

        const token = await executeRecaptcha();
        const bodyPreLogin = {
          username: rutWithoutPoints,
          password: "",
          firebase: "",
          captchatoken: token,
        };

        const data = await api.post(PRELOGIN, bodyPreLogin);

        if (data.status === 200 && data.data.estado_migracion === 2) {
          setHaveMasterEmail(true);
          setFlagButton(true);
        }

        if (
          (data.status === 200 && data.data.estado_migracion === 0) ||
          data.data.estado_migracion === 1
        ) {
          if (data.status === 200 && data.data.estado_migracion === 0) {
            redirectSelectEmail();
          } else if (data.status === 200 && data.data.estado_migracion === 1) {
            const temporalKey = await api.get(
              PORTALSAPI.GET.OBTENER_TEMPORAL_KEY
            );
            handleChangePassword(
              hideEmail(DecryptData(data.data.correo, temporalKey.data))
            );
            setUserName("");
          }
        } else if (flagButton && password != "") {
          const loginBody = {
            username: rutWithoutPoints,
            password: EncryptData(password, data.data.publicKey),
            firebase: "",
            captchatoken: token,
          };

          const { data: user } = await api.post(LOGIN, loginBody);

          if (user) {
            localStorage.setItem("user", JSON.stringify(user));
            sessionStorage.setItem("temporal", JSON.stringify(user.public_key));
            api.defaults.headers = authHeader();

            user?.tipo_acceso === 2
              ? history.push("/main")
              : history.push("/dashboard");
          }
          setFlagButton(false);
          return;
        }
      }
    } catch (err) {
      setFlagRut(false);
      setFlagButton(false);
      setHaveMasterEmail(false);
      setUserName("");
      setPassword("");
      if (err?.response?.status === 500 && err?.response?.data?.data === null) {
        handleModalError();
        return;
      } else if (
        err?.response?.status === 500 &&
        err.response.data?.data?.idcontacto === 0
      ) {
        setShowModalInhabilited(true);
        return;
      } else {
        handleModalError();
      }
    } finally {
      loginInProcess.current = false;
      setShowLoading({ display: "none" });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const bannerInformation = await api.get(
          PORTALSAPI.GET.OBTENER_INFORMACION_BANNER
        );

        //Los puntos comentados abajo es para las validaciones del banner
        setInformationBanner({
          ...informationBanner,
          hiddenBanner: bannerInformation.data.activo /* 0 */,
          message: bannerInformation.data.mensaje /* "" */,
          titleButton: bannerInformation.data.boton_texto /* "" */,
          urlButton: bannerInformation.data.boton_url /* "" */,
          hiddenButton: bannerInformation.data.boton_activo /* 0 */,
          title: bannerInformation.data.titulo /* "" */,
          icon: bannerInformation.data.icono_url /* "" */,
          hiddenIcon: bannerInformation.data.icono_activo /* 0 */,
          colorBanner: bannerInformation.data.color /* "" */,
        });
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const button = document.getElementById("login-button");
    const enterFunction = (e) => {
      if (e.key === "Enter") {
        button?.click();
      }
    };
    if (button) {
      document.addEventListener("keydown", enterFunction);
    }
    return () => {
      document.removeEventListener("keydown", enterFunction);
    };
  }, []);

  return (
    <>
      <>
        <ModalSuccess
          show={showModalError??false}
          icon={modalData.icon}
          title={
            <TextColored size={body28} type={modalData.color}>
              {modalData.title}
            </TextColored>
          }
          subtitle={
            <TextColored size={body32} bold type={modalData.color}>
              {modalData.subtitle}
            </TextColored>
          }
          message={
            <Text type='primary' size={body25}>
              {modalData.message}
            </Text>
          }
          handleClose={() => {setShowModalError(false)}}
        />

        {/* Modal para redirigir a la selección de correo de notificación */}
        <ModalSuccess
          show={showModalRedirect}
          icon={modalRedirect.icon}
          title={
            <TextColored size={body25} type={modalRedirect.color}>
              {modalRedirect.title}
            </TextColored>
          }
          subtitle={
            <TextColored size={body32} bold type={modalRedirect.color}>
              {modalRedirect.subtitle}
            </TextColored>
          }
          message={
            <Text type='primary'>
              <span
                style={{
                  width: "340px",
                  display: "block",
                  marginTop: "10px",
                  fontSize: "12.5px",
                  marginRight: "10px",
                }}>
                {modalRedirect.message}
              </span>
            </Text>
          }
          message2={
            <div
              style={{
                width: "310px",
                marginLeft: "160px",
                marginTop: "-20px",
                zIndex: "1",
              }}>
              {modalRedirect.message2}
            </div>
          }
          handleClose={() => {
            setShowModalRedirect(false);
          }}
        />

        {/* Modal para rcuando el usuario no tenga contraseña para su correo master*/}
        <ModalSuccess
          show={showModalChangePassword}
          icon={modalChangePassword.icon}
          title={
            <TextColored size={body25} type={modalChangePassword.color}>
              {modalChangePassword.title}
            </TextColored>
          }
          subtitle={
            <TextColored size={body32} bold type={modalChangePassword.color}>
              {modalChangePassword.subtitle}
            </TextColored>
          }
          message={
            <Text type='primary'>
              <span
                style={{
                  width: "100%",
                  display: "block",
                  marginTop: "10px",
                  fontSize: "15px",
                }}>
                {modalChangePassword.message}
              </span>
            </Text>
          }
          message2={
            <div
              style={{
                width: "310px",
                marginLeft: "160px",
                marginTop: "5px",
                zIndex: "1",
              }}>
              {modalChangePassword.message2}
            </div>
          }
          handleClose={() => {
            setShowModalChangePassword(false);
          }}
        />

        <Loading estilo={showLoading}></Loading>

        <Col>
          <Row>
            <Container
              style={{
                backgroundColor: "black",
                width: "450px",
                height: "120px",
                marginTop:
                  informationBanner.hiddenBanner === 1 ? "140px" : "40px",
              }}>
              <Row className='mt-4 justify-content-center'>
                <img width={190} alt='' src={Logo2} />
              </Row>
              <Row className='mt-1 mb-2 justify-content-center'>
                <img width={30} height={5} alt='' src={Pipe2} />
              </Row>
              <Row className='justify-content-center pb-2'>
                <TextGeneric size={0.95} color='white'>
                  <b>Acceso a Portal Fintec para Clientes</b>
                </TextGeneric>
              </Row>
            </Container>
          </Row>
          <Row>
            <Container style={container} fluid>
              <Form className='mt-4 '>
                <Form.Group
                  className='justify-content-center'
                  as={Row}
                  controlId='formUser'>
                  <Col xs={10}>
                    <Input
                      style={errors ? { border: "1px solid red" } : {}}
                      containerClass=''
                      side='bottom'
                      type='text'
                      placeholder='RUT del Cliente'
                      autoComplete='new-password'
                      value={username}
                      onChange={(e) => handleRut(e)}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/\s/g, "");
                      }}
                    />
                    {errors && (
                      <span
                        style={{
                          fontSize: "11px",
                          position: "relative",
                          display: "block",
                          width: "100%",
                          top: "-6px",
                          color: "red",
                          textAlign: "left",
                        }}>
                        {errors}
                      </span>
                    )}
                  </Col>

                  <Col xs={10}>
                    <Input
                      disabled={!haveMasterEmail}
                      containerClass=''
                      side='bottom'
                      autoComplete='new-password'
                      type='password'
                      placeholder='Contraseña'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/\s/g, "");
                      }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  className='justify-content-center mt-4'
                  as={Row}
                  controlId='formUser2'>
                  <Col xs={10}>
                    <Row
                      style={buttonLoginStyle}
                      className='justify-content-center'>
                      <Col lg={6}>
                        <CustomButtonWithoutHover
                          disabled={
                            username?.length < 3 ||
                            password?.length === 0 ||
                            errors !== ""
                          }
                          onClick={handleLogin}
                          id='login-button'>
                          Entrar
                        </CustomButtonWithoutHover>
                      </Col>
                    </Row>
                    <Row className='justify-content-center mt-4'>
                      <TextGeneric size={0.7}>
                        <b
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => history.push("/clientes/restaurar")}>
                          ¿Olvidaste / quieres cambiar tu contraseña?
                        </b>
                      </TextGeneric>
                    </Row>
                    <Row className='justify-content-center'>
                      <TextGeneric size={0.7}>
                        ¿No eres cliente?
                        <b onClick={() => history.push("/clientes")}>
                          <span
                            style={{
                              cursor: "pointer",
                            }}>
                            Crea una cuenta
                          </span>
                        </b>
                      </TextGeneric>
                    </Row>
                  </Col>
                </Form.Group>
              </Form>
              <ModalSuccess
                show={showModalError??false}
                icon={modalData.icon}
                messageLine={modalData.messageLine}
                title={
                  <TextColored size={body28} type={modalData.color}>
                    {modalData.title}
                  </TextColored>
                }
                subtitle={
                  modalData.message2 !== "" ? (
                    <></>
                  ) : (
                    <TextColored size={body32} bold type={modalData.color}>
                      {modalData.subtitle}
                    </TextColored>
                  )
                }
                message={
                  <>
                    <Text type='primary' size={body25}>
                      {modalData.message}
                    </Text>
                    {modalData.message2 !== "" ? (
                      <Text type='primary' size={body25}>
                        {modalData.message2}
                      </Text>
                    ) : (
                      <></>
                    )}
                  </>
                }
                handleClose={() => setShowModalError(false)}
              />

              <ModalSuccess2
                show={showModalInhabilited}
                iconHeader={icons["logo4"]}
                headerColor='#1d1d1b'
                headerTitle={
                  <Container
                    style={{
                      backgroundColor: "black",
                      width: "450px",
                      height: "110px",
                    }}>
                    <Row className='mt-4 justify-content-center'>
                      <img width={190} alt='' src={Logo2} />
                    </Row>
                    <Row className='mt-1 mb-2 justify-content-center'>
                      <img width={30} height={5} alt='' src={Pipe2} />
                    </Row>
                    <Row className='justify-content-center pb-2'>
                      <TextGeneric size={0.95} color='white'>
                        <b>Cuenta inhabilitada</b>
                      </TextGeneric>
                    </Row>
                  </Container>
                }
                size={"xs"}
                padding={true}
                title={
                  <>
                    <img
                      src={ComputadoraIcon}
                      alt=''
                      height='100'
                      className='mb-4'
                    />
                    <Title
                      pipe={false}
                      title='Su cuenta se encuentra temporalmente inhabilitada'
                      subtitle=''
                    />
                  </>
                }
                subtitle={
                  <TextColored
                    className='text-center mt-3'
                    size={1.1}
                    bold
                    type='black'>
                    PARA MAYOR INFORMACIÓN
                    <br />
                    COMUNÍCATE AL:
                  </TextColored>
                }
                message={
                  <>
                    <Text className='mt-2 mb-2' type='primary' size={0.9}>
                      [FONO O EMAIL A DEFINIR]
                    </Text>
                    <CustomButtonWithoutHover
                      className='w-50'
                      onClick={() => setShowModalInhabilited(false)}>
                      CERRAR
                    </CustomButtonWithoutHover>
                  </>
                }
                handleClose={() => setShowModalInhabilited(false)}
              />
            </Container>
          </Row>
        </Col>

        <InformativeBanner
          hiddenBanner={informationBanner.hiddenBanner}
          description={informationBanner.message}
          titleButton={informationBanner.titleButton}
          urlButton={informationBanner.urlButton}
          hiddenButton={informationBanner.hiddenButton}
          title={informationBanner.title}
          icon={informationBanner.icon}
          hiddenIcon={informationBanner.hiddenIcon}
          colorBanner={informationBanner.colorBanner}
        />
      </>
    </>
  );
};

export default ClienteLogin;
