import React, { useState, useCallback, useEffect } from "react";

//Funciones - Hooks - URLs
import { useHistory } from "react-router-dom";
import useWindowDimensions from "../../../helpers/useWindowDimensions";
import {
  createLink,
  generateCesionObj,
  getPublicKey,
  stepValidator,
  toBase64,
  toFile,
} from "../../../helpers/autoAtencionHelpers";
import useStorage from "../../../helpers/useStorage";
import { api } from "../../../helpers/api";
import { AUTOATENCIONAPI } from "../../../helpers/url";

//Componentes
import { Container, Row, Card, Col } from "react-bootstrap";
import CustomButton from "../../../components/buttons/Button";
import Footer from "../../../components/footer/Footer";
import HeaderAutoatencion from "../../../components/header-autoatencion/HeaderAutoatencion";
import TitleAutoatencion from "../../../components/header-autoatencion/TitleAutoatencion";
import TablaFacturas from "../../../components/autoatencion/TablaFacturas";
import Certificado from "./Certificado";
import ModalAutoatencion from "../../../components/modal/modal-autoatencion/ModalAutoatencion";
import ModalCesionObservaciones from "../../../components/modal/modal-autoatencion/ModalCesionObservaciones";
import LoadingModal from "../../../components/loading/LoadingModal";
import ModalCesionRechazada from "../../../components/modal/modal-autoatencion/ModalCesionRechazada";
import { TextColored } from "../../../components/typography";
import { DecryptData } from "../../../helpers/encrypt";

import "./styles.css";

const Archivos = () => {
  const history = useHistory();
  const [bills, setBills] = useStorage("bills", [], "session");
  const [{ idcontacto, idcliente }] = useStorage("user", {}, "local");
  const { width } = useWindowDimensions();
  const [step, setStep] = useState(1);
  const [rows, setRows] = useState([]);

  const [loading, setLoading] = useState(false);
  const [inProcess, setInProcess] = useState(false);
  const [modalShow, setModalShow] = useState("");
  const [link, setLink] = useState(null);

  // Manejo de archivos
  const [files, setFiles] = useState({});
  const [certificateForm, setForm] = useState({
    email: "",
    code: "",
    codeStatus: "waiting",
    isEmailConfirmed: false,
    loading: false,
    disabled: false,
  });
  const [certificatePassword, setCertificatePassword] = useState("");
  const [dbHasCertificate, setCertificateFlag] = useState(null);

  const verifyDbCertificate = useCallback(async () => {
    try {
      const url = AUTOATENCIONAPI.GET.OBTENER_CERTIFICADO.replace(
        "{idcliente}",
        idcliente
      ).replace("{idcontacto}", idcontacto);

      const {
        data: { base64, key, password },
      } = await api.get(url);

      if (base64 && key && password) {
        const publicKey = await getPublicKey();
        const parsedFile = await toFile(
          `data:application/x-pkcs12;base64,${base64}`,
          key
        );
        setCertificateFlag(true); // Para mostrar el icono de sincronizacion
        setCertificatePassword(DecryptData(password, publicKey));
        // Cargo credenciales y certificado proveniente desde la db
        setFiles((f) => ({
          ...f,
          certificate: parsedFile,
        }));
        return;
      }
      setCertificateFlag(false);
    } catch (err) {
      console.log(err);
    }
  }, [idcliente, idcontacto]);

  useEffect(() => {
    const correctStep = stepValidator();
    if (correctStep) {
      if (!files.certificate && dbHasCertificate === null) {
        verifyDbCertificate();
      }
      if (link === null) {
        createLink(
          setLink,
          "Hola, mi operación fue enviada para una evaluación comercial. Por favor, ¿Puede indicarme cómo continuar?"
        );
      }
      if (files === {}) {
        setStep(1);
      }
    }
  }, [history, bills, files, link, verifyDbCertificate, dbHasCertificate]);

  const handleSubmit = async () => {
    try {
      if (step === 1) {
        setInProcess(true);
        const newRows = await Promise.all(
          [...rows].map(async (e) => {
            if (e.enlaceXml.name && e.PDF.name) {
              return {
                ...e,
                enlaceXml: await toBase64(e.enlaceXml),
                PDF: await toBase64(e.PDF),
              };
              // Transformo los archivos almacenados en cada factura a b64 para la peticion
            }
            return e;
          })
        );

        setFiles({
          ...files,
          parsedFiles: [...newRows],
        });

        setStep(2);
        setInProcess(false);
      }
      if (step === 2) {
        setInProcess(true);

        const options = {
          mybiller: false,
          registerFintec: false,
          cesionflag: false,
          type: "cesion",
        };

        const obj = await generateCesionObj(
          files.parsedFiles,
          options,
          files,
          certificatePassword
        );

        if (obj) {
          const cesionUrl = AUTOATENCIONAPI.POST.CESION.replace(
            "{idcontacto}",
            idcontacto
          );

          const {
            data: { rejectList },
          } = await api.post(cesionUrl, obj);

          if (rejectList.length > 0) {
            const rejectedBills = [];
            const approvedBills = [];

            const billsObj = {};
            rejectList.forEach((b) => (billsObj[b.folio] = b)); // Guardo el numero de folio con el motivo de rechazo

            bills.forEach((b) => {
              billsObj[b.folio]
                ? rejectedBills.push({
                    ...b,
                    motivo: billsObj[b.folio].message,
                  })
                : approvedBills.push(b);
            });

            sessionStorage.setItem(
              "facturasRechazadas",
              JSON.stringify(rejectedBills)
            );

            if (approvedBills.length > 0) {
              setBills(approvedBills);
              setModalShow("cesion-observaciones");
            } else {
              setModalShow("cesion-fallida");
            }
          } else {
            setModalShow("cesion-exitosa");
          }
          sessionStorage.removeItem("processId");
          setInProcess(false);
        }
      }
    } catch (err) {
      console.log(err);
      setInProcess(false);
    }
  };

  const checkXmls = useCallback(() => {
    // Verifico si todos los folios tienen su archivo cargado
    const response = [...rows].filter(
      (e) => !(e.enlaceXml instanceof File) || !(e?.PDF instanceof File)
    ).length;

    return response === 0 ? false : true;
  }, [rows]);

  const styles = {
    width: `${width < 873 ? "100%" : width < 1367 ? "90%" : "75%"}`,
    transition: "width 0.5s",
  };

  const stepMsg = {
    1: "Carga los archivos XML y PDF faltantes de las facturas.",
    2: "Por último, selecciona el certificado con el que se cederán las facturas. La cesión de las facturas es realizada directamente por el propio Usuario, con su certificado digital. Al efecto, Progreso sólo facilita su plataforma para redireccionar al trámite correspondiente. ",
  };

  return (
    <>
      <LoadingModal
        show={inProcess}
        text="Cesión en curso, este proceso puede tardar un momento."
      />
      <ModalCesionObservaciones show={modalShow === "cesion-observaciones"} />
      <ModalCesionRechazada show={modalShow === "cesion-fallida"} />
      <ModalAutoatencion
        show={modalShow === "cesion-exitosa"}
        type={modalShow}
        utilities={{ link }}
      />
      <Container
        fluid
        className="vh-100 d-flex flex-column"
        style={{ overflowY: "auto" }}
      >
        <Row>
          <HeaderAutoatencion step={4} />
        </Row>
        <Col className="justify-content-center align-items-center d-flex">
          <Card style={styles}>
            <Card.Body className="d-flex flex-column" style={{ gap: "15px" }}>
              <TitleAutoatencion
                info={stepMsg[step]}
                currentStep={4}
                width={width}
              />
              {
                {
                  1: (
                    <TablaFacturas
                      type="archives"
                      rows={rows}
                      setRows={setRows}
                      setFiles={setFiles}
                      files={files}
                      setLoading={setLoading}
                      loading={loading}
                    />
                  ),
                  2: (
                    <Certificado
                      files={files}
                      setFiles={setFiles}
                      setCertificatePassword={setCertificatePassword}
                      certificatePassword={certificatePassword}
                      flag={dbHasCertificate}
                      setFlag={setCertificateFlag}
                      form={certificateForm}
                      setForm={setForm}
                    />
                  ),
                }[step]
              }

              <Row className="d-flex flex-column align-items-center">
                <Col md={3}>
                  <CustomButton
                    children={
                      <TextColored
                        type="dark"
                        size={1.1}
                        children={
                          step === 2 ? "Ingresar Solicitud" : "Continuar"
                        }
                        bold
                      />
                    }
                    onClick={handleSubmit}
                    loading={inProcess}
                    disabled={
                      (step === 1
                        ? checkXmls()
                        : !!!files.certificate ||
                          certificatePassword.length === 0) || inProcess
                    }
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Row>
          <Footer margin={false} />
        </Row>
      </Container>
    </>
  );
};

export default Archivos;
