import React, { useEffect } from 'react';
import jwt_decode from "jwt-decode";
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import TokenExpiration from './TokenExpiration';

const userAut= ()=>{
  let storageUser = localStorage.getItem("user")
  return storageUser? JSON.parse(storageUser):null;
}
  const tienePermiso = (user)=>{
    if(user !== null){
   let token_data = jwt_decode(user.token);
   let expirado = token_data.exp >= Date.now() / 1000;
   localStorage.setItem("expired",!expirado);
   return expirado
  }
  return false
  }
  function Auth({ Render, enroll = false }) {
    const userLocal = userAut();
    return(
        <>{!tienePermiso(userLocal)?<Redirect to="/"/>:<Render />}
        <TokenExpiration enroll={enroll}/>
        </>
    )
}

export default Auth