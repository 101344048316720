import React, { useContext } from "react";
import { Row, Container, Col } from "react-bootstrap";
import { Text, Pipe, Link } from "../typography";
import { ThemeContext } from "../../theming";
import Logo2 from "../../assets/images/logo2.png";
import { useHistory } from "react-router-dom";
import useWindowDimensions from '../../helpers/useWindowDimensions';
import Logo from "../../assets/images/logo_footer.png";
import { mobileMDWidth, mobileXSWidth } from '../../helpers/responsiveVariables';


export default function HeaderClientes({ openModal }) {
  const app = useContext(ThemeContext);

  const { width } = useWindowDimensions();

  const history = useHistory();

  const { body28, body11, body14, body9 } = app.theme.font;

  const header = {
    borderStyle: "solid",
    borderWidth: "1px",
    borderBottomColor: "white",
  };

  const border = {
    borderBottom: `2px solid ${app.theme.primary}`,
    paddingBottom: "3px",
  };

  return (
    <Container fluid style={header} className="sticky-top header-clientes">

      {
        width > mobileXSWidth ?
          <Col>
            <Row
              style={{ height: "100px" }}
              className="align-items-center justify-content-md-center ml-4 mr-4"
            >
              <Col className="" lg={3}>
                <Row className="justify-content-center">
                  <img
                    width="90%" src={Logo2} alt="" />
                  <Pipe marginLeft="10px" size={30} color="yellow" />
                </Row>
              </Col>

              <Col lg={7}>
                <Row className="align-items-center justify-content-md-end">
                  <Col>

                    <Text className="ml-2" size={body28}>
                      Registro de
                </Text>
                    <Text size={body28}>
                      {" "}
                      <b>Clientes</b>
                    </Text>
                  </Col>
                </Row>
              </Col>

              <Col lg={2} className="">
                <Link
                  size={1.0}
                  border={border}
                  onClick={() => history.push("/clientes/login")}
                >
                  ACCESO CLIENTES
            </Link>
              </Col>
            </Row>
          </Col>
          :
          <Col>
            <Row
              style={{ height: "100px" }}
              className={width > mobileMDWidth ?
                "align-items-center justify-content-md-center ml-4 mr-4"
                :
                "align-items-center justify-content-md-center"} >

              <Col className="" xs={3} lg={1}>
                <Row className="justify-content-center">
                  <img width={width > mobileMDWidth ? "180px" : "40px"}
                    src={width > mobileMDWidth ? Logo2 : Logo}
                    alt="" />
                  <Pipe marginLeft={3} size={30} color="yellow" />
                </Row>
              </Col>

              <Col lg={7} xs={5}>
                <Row className="align-items-center justify-content-md-start">
                  <Text className={width > mobileMDWidth ? "ml-2" : 'ml-2'}
                    size={0.7}>
                    Registro de <b>Clientes</b>
                  </Text>
                </Row>
              </Col>

              <Col lg={2} xs={4} className="justify-content-md-end">
                <Row className="justify-content-md-end">
                  <Link
                    size={0.7}
                    border={border}
                    onClick={() => history.push("/clientes/login")}
                  >
                    ACCESO CLIENTES
                 </Link>
                </Row>
              </Col>
            </Row>
          </Col>

      }

    </Container >
  );
}
