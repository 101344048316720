import React, { useContext, useCallback, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { ThemeContext } from "../../../theming";
import { TextColored } from "../../typography";
import { Container, Body, FilterType } from "./styles";
import { AiFillCloseCircle } from "react-icons/ai";
import CustomButton from "../../buttons/Button";
import moment from "moment";

const SidebarFiltro = ({
  show,
  setShow,
  currentParameters = {},
  setRows,
  setCurrentParameters,
  setLoading,
  selectedRows,
  parametrosComerciales,
  currentDate,
  getBills,
  supplier,
}) => {
  const {
    theme: {
      font: { body32 },
    },
  } = useContext(ThemeContext);

  // Nuevos parametros seleccionados (currentParameters son los que ya estan cargados)
  const [{ start, end, disabled }, setForm] = useState({
    start: currentParameters?.start,
    end: currentParameters?.end,
    disabled: true,
  });

  const startLimit = currentDate
    .clone()
    .subtract(parametrosComerciales.ANTIGUEDAD, "days")
    .format("YYYY-MM-DD");

  const handleChange = (newParameter, option = "inicio") => {
    if (option === "inicio") {
      setForm((f) => {
        return { ...f, start: newParameter };
      });
    } else {
      setForm((f) => {
        return { ...f, end: newParameter };
      });
    }
  };

  useEffect(() => {
    if (start !== currentParameters.start || end !== currentParameters.end) {
      // Si la fecha del filtro coincide con la actual lo deshabilito para no hacer llamadas innecesarias
      setForm((f) => {
        return { ...f, disabled: false };
      });
    } else {
      setForm((f) => {
        return { ...f, disabled: true };
      });
    }
    if (!currentParameters.changed && !show) {
      setForm({
        start: currentParameters?.start,
        end: currentParameters?.end,
        disabled: true,
      });
    }
  }, [end, start, currentParameters, show]);

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      const newBills = await getBills(supplier, {
        start: moment(start),
        end: moment(end).add(
          currentDate.clone().format("YYYY-MM-DD") === end ? 1 : 0,
          // Si el filtro es dentro del mismo dia agrego un dia mas ya que las facturas creadas en el dia cuentan para el siguiente.
          "day"
        ),
      });

      if (selectedRows) {
        const filteredRows = selectedRows.filter((r) => !newBills.includes(r));
        setRows(newBills.length > 0 ? [...filteredRows, ...newBills] : []);
      } else {
        setRows(newBills.length > 0 ? [...newBills] : []);
      }

      setCurrentParameters({ start, end, changed: true });
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }, [
    setRows,
    setLoading,
    selectedRows,
    getBills,
    end,
    start,
    setCurrentParameters,
    currentDate,
    supplier,
  ]);

  return (
    <Container show={show}>
      <Button onClick={() => setShow(false)} className="closeButton">
        <AiFillCloseCircle size={20} />
      </Button>
      <div className="header">
        <TextColored children="Filtrar" size={body32} type="secondary" />
      </div>
      <Body>
        <FilterType type="fecha" showType={"fecha"}>
          <label htmlFor="inicio">Desde:</label>
          <input
            type="date"
            id="inicio"
            name="inicio"
            onChange={(e) => handleChange(e.target.value, "inicio")}
            value={start}
            max={end}
            min={startLimit}
          />
          <br />
          <label htmlFor="fin">Hasta:</label>
          <input
            type="date"
            id="fin"
            name="fin"
            onChange={(e) => handleChange(e.target.value, "fin")}
            value={end}
            min={start}
            // max={currentDate.clone().format("YYYY-MM-DD")}
          />
        </FilterType>
        <br />
        <CustomButton
          children="Confirmar"
          onClick={handleSubmit}
          disabled={disabled}
        />
      </Body>
    </Container>
  );
};

export default SidebarFiltro;
