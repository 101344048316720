import React, { useState } from "react";

export const ThemeContext = React.createContext();

export const ThemeContextProvider = (props) => {
  const theme = {
    light: {
      type: "light",
      primary: "#f7cf46",
      primaryDark: "#f7cf46",
      primaryLight: "#f7cf46",
      secondary: "#000",
      secondaryDark: "#000",
      secondaryLight: "#000",
      text: "gray",
      textDark: "black",
      textLight: "white",
      blue: "#27c9fe",
      green: "#88ca1b",
      greenDark: "#07c814",
      red: "#ff1313",
      gray: "#828282",
      black: "black",
      yellow: "#f7cf46",
      font: {
        body32: 2.4,
        body28: 1.5,
        body25: 1.1,
        body14: 1,
        body12: 0.8,
        body11: 0.7,
        body10: 0.6,
        body9: 0.5,
      },
    },
    dark: {
      type: "dark",
      primary: "#212121",
      text: "white",
    },
  };

  const setTheme = (type) => {
    setState({ ...state, theme: type === "dark" ? theme.light : theme.dark });
  };

  const initState = {
    theme: theme.light,
    setTheme: setTheme,
  };

  const [state, setState] = useState(initState);

  return (
    <ThemeContext.Provider value={state}>
      {props.children}
    </ThemeContext.Provider>
  );
};
