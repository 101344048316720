import React from "react";
import CustomButton from "../buttons/Button";
import { TextColored } from "../typography";
import nobills from "../../assets/images/nobills.png";
import moment from "moment";
import { Spinner } from "react-bootstrap";

const NoBills = ({ history, syncDate }) => {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center w-100 h-100"
      style={{ backgroundColor: "white", gap: "1rem" }}
    >
      <div
        className="d-flex text-center justify-content-center"
        style={{ gap: "2px" }}
      >
        {syncDate?.length > 0 ? (
          <>
            <TextColored
              type="gray"
              size={1}
              weight={500}
              children={"Ultima sincronización:"}
            />
            <TextColored
              type="gray"
              size={1}
              children={moment(syncDate).format("DD/MM/YYYY HH:ss")}
            />
          </>
        ) : (
          <div className="d-flex align-items-center" style={{gap: "3px"}}>
            <Spinner animation="border" size="sm" color="gray" />
            <TextColored
              type="gray"
              size={1}
              weight={500}
              children={"Sincronizando facturas"}
            />
          </div>
        )}
      </div>
      <img src={nobills} width="30%" alt="nobills" />
      <div className="d-flex flex-column text-center">
        <TextColored
          type="dark"
          size={1.8}
          children="Aun no tienes facturas disponibles/habilitadas para ceder."
          weight={600}
        />
        <TextColored
          type="dark"
          size={1.3}
          children="¡Vuelve cuando tengas al menos 1 disponible!"
        />
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div style={{ width: "80%" }}>
          <CustomButton
            children="Volver al inicio"
            onClick={() => history.push("/dashboard")}
          />
        </div>
        <span style={{ fontSize: "0.8rem" }}>
          ¿Crees que es un error?{" "}
          <button
            children="Intenta recargando la pagina"
            className="link-button"
            onClick={() => window.location.reload(false)}
          />
          .
        </span>
      </div>
    </div>
  );
};

export default NoBills;
