import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { TextButton } from "../typography";
import { ThemeContext } from "../../theming";

function CustomButtonWithoutHover({
  onClick,
  children,
  size = "md",
  buttonTextSize = 0,
  disabled = false,
  className = "w-100",
  ...otherProps
}) {
  const app = useContext(ThemeContext);
  const { body12 } = app.theme.font;
  const button = {
    background: app.theme.primary,
    color: app.theme.textDark,
    borderColor: app.theme.primary,
  };
  return (
    <Button
      disabled={disabled}
      className={className}
      onClick={onClick}
      style={button}
      size={size}
      {...otherProps}>
      <TextButton bold size={buttonTextSize === 0 ? body12 : buttonTextSize}>
        {children}
      </TextButton>
    </Button>
  );
}

export default CustomButtonWithoutHover;
