import React, { useEffect } from "react";
import ClienteLoginSeccion from "../../components/clientes-login-seccion/ClienteLoginSeccion";
import ClientesLogin from "../../components/clientes/ClientesLogin";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const Clientes = () => {
  useEffect(() => {
    sessionStorage.clear();
    localStorage.removeItem("user");
    localStorage.removeItem("rtoken");
  }, []);

  return (
    <GoogleReCaptchaProvider
        reCaptchaKey={window.RECAPTCHA_KEY}
        scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: 'head', // optional, default to "head", can be "head" or "body",
        nonce: undefined // optional, default undefined
      }}>
      <ClienteLoginSeccion>
        <ClientesLogin></ClientesLogin>
      </ClienteLoginSeccion>
      </GoogleReCaptchaProvider>
  );
};
export default Clientes;
