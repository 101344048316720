export const BASE = window.API_URL;
export const PORTAL = "/Portal";
export const AUTOATENCION = "/api/v1/Autoatencion";
export const FACTORING = "/api/v1/Factoring";
export const LEASING = "/api/v1/Leasing";
export const DMWEBHOOK = "/api/v1/DMWebhook";

export const LOGIN = "/LoginPortal";
export const PRELOGIN = "/LoginPortal/pre";
export const RECUPERAR_CONTRASENIA = "/Portal/recuperarPassword/{correo}";
export const CAMBIAR_CONTRASENIA = "/Portal/resetearPassword";

export const FACTORINGAPI = {
  GET: {
    CONTACTOS: `${BASE}${FACTORING}/listarContacto/{idcliente}`,
  },
};

export const AUTOATENCIONAPI = {
  GET: {
    FACTURAS: `${BASE}${AUTOATENCION}/FacturaAutoatencion/{rut}/listar?fecha_inicial={inicio}&fecha_final={fin}&idcontacto={idcontacto}`,
    PAGOS: `${BASE}${AUTOATENCION}/Pagos/metodos/{rut}/listar?idcontacto={idcontacto}`,
    ENROLAMIENTO_RUT: `${BASE}${AUTOATENCION}/EnrolamientoAutoatencion/usuario/{iduser}/enrolamiento/rut`,
    ESTADO_ENROLAMIENTO: `${BASE}${AUTOATENCION}/EnrolamientoAutoatencion/{rut}/enrolamiento/estado?token={token}&idcontacto={idcontacto}`,
    TOKEN_DATAMART: `${BASE}${AUTOATENCION}/EnrolamientoAutoatencion/enrolamiento/token?idcontacto={idcontacto}`,
    CONEXION_ENROLAMIENTO: `${BASE}${AUTOATENCION}/EnrolamientoAutoatencion/enrolamiento/coneccion?token={token}&idcontacto={idcontacto}`,
    TELEFONO_EJECUTIVO: `${BASE}${AUTOATENCION}/ScoringAutoatencion/scoring/empleado/{idpersona}/telefono?idcontacto={idcontacto}`,
    DATA_ENROLAMIENTO: `${BASE}${AUTOATENCION}/EnrolamientoAutoatencion/usuario/{iduser}/enrolamiento/data`,
    OBTENER_BLOQUEO: `${BASE}${AUTOATENCION}/EnrolamientoAutoatencion/enrolamiento/{idcontacto}/password/bloqueado`,
    OBTENER_ESTADO: `${BASE}${AUTOATENCION}/EnrolamientoAutoatencion/usuario/{rut}/enrolamiento/sesion/{sessionid}/obtener?token={token}&idcontacto={idcontacto}`,
    SINCRONIZAR_DTE: `${BASE}${AUTOATENCION}/FacturaAutoatencion/{rut}/dte/sincronizar?idcontacto={idcontacto}`,
    FECHA_DTESYNC: `${BASE}${DMWEBHOOK}/notificacion/user/{rut}/sync/get?idcontacto={idcontacto}`,
    OBTENER_PROVEEDOR: `${BASE}${AUTOATENCION}/FacturaAutoatencion/proveedor/obtener?idcontacto={idcontacto}`,
    ENVIAR_ALERTA: `${BASE}/api/v1/Persona/enviarCorreoEjecutivoInicioOperacion`,
    OBTENER_CERTIFICADO: `${BASE}/api/v1/Persona/obtenerCertificado/{idcliente}?idcontacto={idcontacto}`,
    INDICADORES_DEUDORES: `${BASE}${AUTOATENCION}/ScoringAutoatencion/indicadores/deudor/{ruts}?idcontacto={idcontacto}`,
    INDICADORES_CLIENTE: `${BASE}${AUTOATENCION}/ScoringAutoatencion/indicadores/cliente/{rut}?idcontacto={idcontacto}`,
  },
  POST: {
    SCORING: `${BASE}${AUTOATENCION}/ScoringAutoatencion/usuario/{rut}/scoring/aprobacion/{razon_social}?idcontacto={idcontacto}`,
    CESION: `${BASE}${AUTOATENCION}/CesionAutoatencion/cesion-dte/ceder?idcontacto={idcontacto}`,
    SESION: `${BASE}${AUTOATENCION}/EnrolamientoAutoatencion/usuario/{rut}/enrolamiento/sesion?idcontacto={idcontacto}&token={token}`,
    PLAZOS: `${BASE}${AUTOATENCION}/FacturaAutoatencion/deudor/plazo?idcontacto={idcontacto}`,
    FACTURAS_CDL: `${BASE}${AUTOATENCION}/FacturaAutoatencion/listar?sync={sync}&idcontacto={idcontacto}`,
    ESTADO_ENROLAMIENTO_CDL: `${BASE}${AUTOATENCION}/EnrolamientoAutoatencion/enrolamiento/suscriptorCDL?idcontacto={idcontacto}`,
    REGISTRAR_ENROLAMIENTO_CDL: `${BASE}${AUTOATENCION}/EnrolamientoAutoatencion/enrolamiento/enrolarCDL?idcontacto={idcontacto}`,
    ACTUALIZAR_ENROLAMIENTO_CDL: `${BASE}${AUTOATENCION}/EnrolamientoAutoatencion/enrolamiento/changePassword?idcontacto={idcontacto}`,
    INDICADORES: `${BASE}${AUTOATENCION}/ScoringAutoatencion/indicadores/obtener?idcontacto={idcontacto}`,
    ENVIAR_CORREO: `${BASE}/api/v1/Persona/enviarCorreoCodigoCertificado/{idcliente}?correo={correo}`,
    GUARDAR_CERTIFICADO: `${BASE}/api/v1/Persona/guardarCertificado/{idcliente}?idcontacto={idcontacto}`,
    VALIDAR_DOCUMENTOS: `${BASE}${AUTOATENCION}/FacturaAutoatencion/validarListaDocumentos?idcontacto={idcontacto}&idpersona={idpersona}`,
    LEAD_DOCUMENTOS: `${BASE}${AUTOATENCION}/FacturaAutoatencion/crearLeadDocumentos?idcontacto={idcontacto}&idpersona={idcliente}`,
  },
  PUT: {
    ACEPTAR_TERMINOS: `${BASE}${AUTOATENCION}/EnrolamientoAutoatencion/enrolamiento/{iduser}/terminos/aceptar`,
    ACTUALIZAR_SII: `${BASE}${AUTOATENCION}/EnrolamientoAutoatencion/enrolamiento/sii/actualizar?iduser={idcontacto}&password={password}`,
    ESTABLECER_BLOQUEO: `${BASE}${AUTOATENCION}/EnrolamientoAutoatencion/enrolamiento/{iduser}/password/bloquear?date={date}`,
    ELIMINAR_BLOQUEO: `${BASE}${AUTOATENCION}/EnrolamientoAutoatencion/enrolamiento/{iduser}/password/desbloquear`,
    ACTUALIZAR_SESION: `${BASE}${AUTOATENCION}/EnrolamientoAutoatencion/usuario/{rut}/enrolamiento/sesion/{sessionid}/actualizar?token={token}&idcontacto={idcontacto}`,
    ACTUALIZAR_ESTADO: `${BASE}${AUTOATENCION}/EnrolamientoAutoatencion/enrolamiento/{iduser}/documento/{state}/estado/actualizar`,
    ACTUALIZAR_DATOS: `${BASE}${AUTOATENCION}/EnrolamientoAutoatencion/usuario/actualizar`,
  },
};

export const PORTALSAPI = {
  GET: {
    PUBLIC_KEY: `${BASE}${PORTAL}/getKey/{idcontacto}/{tipo}`,
    MAIN: `${BASE}${PORTAL}/main/{idcliente}/{idcontacto}`,
    PANEL_CONTROL: `${BASE}${PORTAL}/panelcontrol/{idcontacto}`,
    PANEL_CONTROL_ENROLAMIENTO: `${BASE}/Enrolamiento/panelcontrol/{idcontacto}`,
    LIQUIDACION: `${BASE}${PORTAL}/liquidacion/{idfactoring}/{idcontacto}`,
    EXCEDENTES: `${BASE}${PORTAL}/excedentes/{idfactoring}/{idcontacto}`,
    DETALLE: `${BASE}${PORTAL}/detalle/{idfactoring}/{idcontacto}`,
    COBRANZA: `${BASE}${PORTAL}/cobranza/{idfactoring}/{idcontacto}`,
    COBRANZA_DETALLE: `${BASE}${PORTAL}/cobranzadetalle/{idcobranza}/{idcontacto}`,
    CUOTAS: `${BASE}${PORTAL}/cuotas/{idleasing}/{idcontacto}`,
    ARCHIVOS: `${BASE}${PORTAL}/listarArchivos/{idleasing}/{idcontacto}`,
    SOLICITUDES: `${BASE}${LEASING}/obtenerSolicitudes/{idcontacto}`,
    CONTACTOS: `${BASE}${PORTAL}/contactos/{idcontactos}`,
    ENROLAMIENTO: `${BASE}/enrolamiento/main/{idcontacto}`,
    RECUPERAR_CONTRASENIA: `${BASE}/enrolamiento/recuperarPassword/{rut}`,
    REENVIAR_PIN: `${BASE}/Enrolamiento/reenviarCodigo/{rut}`,
    EXPORTAR_LIQUIDACION: `${BASE}${PORTAL}/exportarLiquidacion/{idcontacto}/{idfactoring}`,
    EXPORTAR_RESUMEN_CARTOLA: `${BASE}${PORTAL}/exportarResumenCartola/{idcontacto}`,
    VER_RESUMEN_CARTOLA: `${BASE}${PORTAL}/verResumenCartola/{idcontacto}`,
    EXPORTAR_CRONOGRAMA: `${BASE}${PORTAL}/exportarCronograma/{idcontacto}/{idleasing}`,
    OBTENER_TOKEN: `${BASE}/enrolamiento/token`,
    OBTENER_INFORMACION_BANNER: `${BASE}/LoginPortal/Banner`,
    OBTENER_TEMPORAL_KEY: `${BASE}${PORTAL}/getTempKey`,
    OBTENER_CORREOS: `${BASE}${PORTAL}/listarCorreos/{rut}`,
    REFRESH_TOKEN: `${BASE}${PORTAL}/rToken/{idcontacto}`,
    REFRESH_TOKEN_ENROLAMIENTO: `${BASE}${PORTAL}/rTokenEnroll/{idcontacto}`,
  },
  POST: {
    CONTACTAR: `${BASE}${PORTAL}/contactar`,
    COMETARIOS: `${BASE}${PORTAL}/enviarComentariosCobranza`,
    ENROLAMIENTO: `${BASE}/Enrolamiento/contactar`,
    ACTUALIZARSII: `${BASE}/Enrolamiento/actualizarSii`,
    ACTUALIZARSII_DASHBOARD: `${BASE}${PORTAL}/actualizarSii`,
    REGISTRAR: `${BASE}/enrolamiento/registrar`,
    REGISTRAR_SOLICITUD: `${BASE}${LEASING}/registrarSolicitud`,
    GENERE_FORMATO_CESION: `${BASE}${LEASING}/generarFormatoCesion`,
    VALIDAR_REGISTRO: `${BASE}/enrolamiento/validarcuenta`,
    OBTENERURLFACTURA: `${BASE}${LEASING}/obtenerDocPaperLess`,
    REDIRECTWEBPAGOS: `${BASE}${LEASING}/redireccionWebPagos/`,
  },
  PUT: {
    ACTUALIZAR_DATOS: `${BASE}${PORTAL}/actualizarDatos`,
    ACTUALIZAR_DATOS_ENROLAMIENTO: `${BASE}/Enrolamiento/actualizar`,
    ACTUALIZAR_PASSWORD: `${BASE}${PORTAL}/actualizarPassword`,
    RESTAURAR_CONTRASENIA: `${BASE}/Enrolamiento/resetearPassword`,
    GRABAR_CORREO_MASTER: `${BASE}/Enrolamiento/GrabarCorreoMaster`,
  },
};
