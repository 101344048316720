//Librerias
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useForm } from "react-hook-form";

//Helpers
import { DecryptData, EncryptData } from "../../helpers/encrypt";
import useWindowDimensions from "../../helpers/useWindowDimensions";
import { ThemeContext } from "../../theming";
import { PORTALSAPI } from "../../helpers/url";
import { api } from "../../helpers/api";
import {
  mobileXSWidth,
  mobileSMWidth,
} from "../../helpers/responsiveVariables";
import {
  columnasArchivosDocumentos,
  columnasGestionCobranza,
} from "../../constants/dashboard";
import { validateEmail, validatePhone } from "../../helpers/validators";
import { abrir } from "../../helpers/termino_condiciones";
import { fireAlert, getPublicKey } from "../../helpers/autoAtencionHelpers";
import Logout from "../../assets/images/Logout.png";

//Assets
import Logo2 from "../../assets/images/logo_footer.png";
import Logo from "../../assets/images/logo.png";
import {
  Bocina,
  Cobranza,
  Computadora2,
  Confirmado,
  Ejecutivo,
  Ejecutivo1,
  Error,
  Excedente,
  Liquidacion,
  Logo4,
  NotificationCliente,
  Operacion,
  PanelControl,
  Reloj,
  User,
  UserCliente,
  Usuario,
} from "../../assets/icons/dashboard";

//Componentes
import { Container, Col, Row, Form } from "react-bootstrap";
import {
  CustomTextWithoutPadding,
  Link,
  Pipe,
  Text,
  TextColored,
  Title,
} from "../typography";
import Loading from "../loading/Loading";
import RegistroSII from "../registro-sii/registro-sii";
import Notificaciones from "../notificaciones/notificaciones";
import RenderContent2 from "../renderContent/RenderContent2";
import Tabla from "../tabla/Tabla";
import {
  CustomModal as Modal,
  ModalSuccess,
  ModalSuccess2,
} from "../modal/Modal";
import Button from "../../components/buttons/Button";
import { useHistory } from "react-router-dom";

const headerHeight = 110;

const icons = {
  liquidacion: <Liquidacion />,
  excedente: <Excedente />,
  cobranza: <Cobranza />,
  gestion: <Cobranza />,
  detalle: <Cobranza />,
  cuota: <Excedente />,
  usuario: <Usuario />,
  ejecutivo: <Ejecutivo />,
  contrasenia: <Ejecutivo />,
  operacion: <Operacion />,
  logo4: <Logo4 />,
  computadora2: <Computadora2 />,
  bocina: <Bocina />,
  panel_control: <PanelControl />,
};

const CerrarSession = () => {
  localStorage.clear();
  sessionStorage.clear();
  window.location = "/";
};

const modalInfo = {
  title: "",
  subtitle: "",
  icon: null,
  size: "xl",
  content: null,
  table: { columns: [], rows: [] },
  tables: [],
};

const RenderContent = ({ utilities, data, public_key, type }) => {
  const {
    theme,
    notificaciones,
    openTopModal,
    userModal,
    register,
    handleSubmit,
    enviarComentario,
    width,
    reset,
    onSubmit,
    onSubmitPassword,
    setModalData,
    setShowTopModal,
    handleShowModalSuccess,
    enviarContacto,
    openModal,
    selecionarEjecutivo,
  } = utilities;

  const [isInvalidPhone, setIsInvalidPhone] = React.useState(false);
  const [isInvalidPhoneFina, setIsInvalidPhoneFina] = React.useState(false);
  const [isInvalidEmail, setIsInvalidEmail] = React.useState(false);
  const mountedRef = React.useRef(false);
  const { body12, body14 } = theme;

  const handleKeyPress = (e) => {
    const numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    const alts = ["Backspace", "Tab", "Enter", "ArrowLeft", "ArrowRight"];

    if (numbers.indexOf(e.key) === -1 && alts.indexOf(e.key) === -1) {
      e.preventDefault();
      return;
    }

    if (e.target.value.length === 9 && alts.indexOf(e.key) === -1) {
      e.preventDefault();
      return;
    }
  };

  switch (type) {
    case "notificacion": {
      return (
        <Notificaciones
          notificaciones={notificaciones}
          openTopModal={openTopModal}
        />
      );
    }

    case "comentario": {
      return (
        <Container style={userModal}>
          <Form>
            <Form.Group
              className='justify-content-center mt-3'
              as={Row}
              controlId='formUser'>
              <Col
                className='d-flex align-items-center justify-content-start'
                xs={10}>
                <Form.Control
                  name='comentario'
                  rows={3}
                  as='textarea'
                  placeholder=''
                  ref={register}
                />
              </Col>
            </Form.Group>
            <Row className='justify-content-center mt-3'>
              <Col xs={4}>
                <Button onClick={handleSubmit(enviarComentario)}>
                  Enviar Información
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      );
    }

    case "usuario": {
      return (
        <>
          <Container style={userModal}>
            <Row>
              <Col xs={{ span: 2, offset: 1 }}>
                <User />
              </Col>
              <Col xs={5}>
                <Row>
                  <Text bold size={body14} type='primary'>
                    {DecryptData(data.data.usuario.cliente, public_key)}
                  </Text>
                </Row>
                <Row>
                  <Text bold size={body12} type='primary'>
                    {DecryptData(data.data.usuario.rut, public_key)}
                  </Text>
                </Row>
                <Row>
                  <TextColored className='mr-2' bold size={body12} type='gray'>
                    • Email:
                  </TextColored>
                  <TextColored size={body12} type='gray'>
                    {DecryptData(data.data.usuario.correo, public_key)}
                  </TextColored>
                </Row>
                <Row>
                  <TextColored className='mr-2' bold size={body12} type='gray'>
                    • Fono:
                  </TextColored>
                  <TextColored size={body12} type='gray'>
                    {DecryptData(data.data.usuario.celular, public_key)}
                  </TextColored>
                </Row>
                <br />
                <Row>
                  <TextColored className='mr-2' bold size={body12} type='gray'>
                    • Nombre encargado de Finanzas:
                  </TextColored>
                  <TextColored size={body12} type='gray'>
                    {DecryptData(data.data.usuario.finanzas_nombre, public_key)}
                  </TextColored>
                </Row>
                <Row>
                  <TextColored className='mr-2' bold size={body12} type='gray'>
                    • Email encargado de Finanzas:
                  </TextColored>
                  <TextColored size={body12} type='gray'>
                    {DecryptData(data.data.usuario.finanzas_correo, public_key)}
                  </TextColored>
                </Row>
                <Row>
                  <TextColored className='mr-2' bold size={body12} type='gray'>
                    • Fono encargado de Finanzas:
                  </TextColored>
                  <TextColored size={body12} type='gray'>
                    {DecryptData(
                      data.data.usuario.finanzas_telefono,
                      public_key
                    )}
                  </TextColored>
                </Row>
              </Col>
              <Col xs={width > mobileSMWidth ? 4 : 12}>
                <Row
                  className={
                    width > mobileSMWidth
                      ? "justify-content-center"
                      : "justify-content-center mt-2"
                  }>
                  <Col xs={10}>
                    <Button
                      className='w-100'
                      onClick={() => openTopModal("datos")}>
                      Actualizar Datos
                    </Button>
                  </Col>
                </Row>
                <Row className='mt-3 justify-content-center'>
                  <Col xs={10}>
                    <Button
                      className='w-100'
                      onClick={() => openTopModal("contrasenia")}>
                      Cambiar Contraseña
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
          <Container className='border-0' style={userModal}>
            <Row>
              <Col xs={{ span: 2, offset: 1 }}>
                <Usuario />
              </Col>
              <Col xs={5}>
                {data?.data?.ejecutivos?.map((ejecutivo, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Row>
                        <TextColored bold size={body12} type='gray'>
                          • Ejecutivo Progreso de{" "}
                          {DecryptData(ejecutivo.negocio, public_key)}:
                        </TextColored>
                      </Row>
                      <Row>
                        <TextColored size={body12} type='gray'>
                          {DecryptData(ejecutivo.nombres, public_key)}
                        </TextColored>
                      </Row>
                      <Row>
                        <TextColored size={body12} type='gray'>
                          {DecryptData(ejecutivo.correo, public_key)}
                        </TextColored>
                      </Row>
                      <Row>
                        <TextColored size={body12} type='gray'>
                          {DecryptData(ejecutivo.celular, public_key)}
                        </TextColored>
                      </Row>
                    </React.Fragment>
                  );
                })}
              </Col>
              <Col xs={width > mobileSMWidth ? 4 : 12}>
                <Row
                  className={
                    width > mobileSMWidth
                      ? "justify-content-center"
                      : "justify-content-center mt-2"
                  }>
                  <Col xs={10}>
                    <Button
                      className='w-100'
                      onClick={() =>
                        openModal({
                          title: "ejecutivo",
                          data: data?.data?.usuario?.idcontacto,
                        })
                      }>
                      Contactar Ejecutivos
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </>
      );
    }
    case "datos": {
      if (!mountedRef.current) {
        mountedRef.current = true;
        reset({
          nombres: DecryptData(data?.usuario?.nombres, public_key),
          celular: DecryptData(data?.usuario?.celular, public_key),
          correo: DecryptData(data?.usuario?.correo, public_key),
          direccion: DecryptData(data?.usuario?.direccion, public_key),
          region: DecryptData(data?.usuario?.region, public_key),
          finanzas_correo: DecryptData(
            data?.usuario?.finanzas_correo,
            public_key
          ),
          finanzas_nombre: DecryptData(
            data?.usuario?.finanzas_nombre,
            public_key
          ),
          finanzas_telefono: DecryptData(
            data?.usuario?.finanzas_telefono,
            public_key
          ),
        });
      }
      return (
        <Container>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group
              className='justify-content-center'
              as={Row}
              controlId='formUser2'>
              <Col xs={8}>
                <Form.Control
                  name='nombres'
                  type='text'
                  placeholder='Nombre Usuario'
                  ref={register({ required: true })}
                />
              </Col>
            </Form.Group>
            <Form.Group
              className='justify-content-center'
              as={Row}
              controlId='formContact'>
              <Col xs={4}>
                <Form.Control
                  name='celular'
                  type='text'
                  placeholder='Fono'
                  ref={register({ required: true })}
                  style={{
                    border: isInvalidPhone ? "2px solid red" : "",
                  }}
                  onKeyUp={(e) =>
                    setIsInvalidPhone(e.target.value.length !== 9)
                  }
                  onKeyDown={handleKeyPress}
                />
              </Col>
              <Col xs={4}>
                <Form.Control
                  name='correo'
                  type='text'
                  placeholder='Email'
                  ref={register({ required: true })}
                  readOnly
                />
              </Col>
            </Form.Group>
            <Form.Group
              className='justify-content-center'
              as={Row}
              controlId='formAdress'>
              <Col xs={8}>
                <Form.Control
                  name='direccion'
                  type='text'
                  placeholder='Dirección'
                  ref={register}
                />
              </Col>
            </Form.Group>
            <Form.Group
              className='justify-content-center'
              as={Row}
              controlId='formAdress2'>
              <Col xs={8}>
                <Form.Control
                  name='region'
                  type='text'
                  placeholder='Región'
                  ref={register}
                />
              </Col>
            </Form.Group>
            <Form.Group
              className='justify-content-center'
              as={Row}
              controlId='formUserFinanzas'>
              <Col xs={8}>
                <Form.Control
                  name='finanzas_nombre'
                  type='text'
                  placeholder='Nombre Finanzas'
                  ref={register}
                />
              </Col>
            </Form.Group>
            <Form.Group
              className='justify-content-center'
              as={Row}
              controlId='formContactFinanzas'>
              <Col xs={4}>
                <Form.Control
                  name='finanzas_telefono'
                  type='text'
                  placeholder='Fono Finanzas'
                  ref={register}
                  style={{
                    border: isInvalidPhoneFina ? "1px solid red" : "",
                  }}
                  onKeyUp={(e) =>
                    setIsInvalidPhoneFina(e.target.value.length !== 9)
                  }
                  onKeyDown={handleKeyPress}
                />
              </Col>
              <Col xs={4}>
                <Form.Control
                  name='finanzas_correo'
                  type='text'
                  placeholder='Email Finanzas'
                  ref={register}
                  style={{
                    border: isInvalidEmail ? "2px solid red" : "",
                  }}
                  onKeyUp={(e) =>
                    setIsInvalidEmail(
                      !e.target.value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)
                    )
                  }
                />
              </Col>
            </Form.Group>

            <Row className='justify-content-center'>
              <Col xs={4}>
                <Row className='justify-content-center'>
                  <Form.Check
                    className='mr-1'
                    type='checkbox'
                    label=''
                    ref={register()}
                    name='terminos'
                    aria-label='terminos'
                  />
                  <TextColored type='gray'>
                    Acepto Política de Privacidad
                  </TextColored>
                </Row>
                <Row className='justify-content-center'>
                  <Text type='primary'>
                    <u style={{ cursor: "pointer" }} onClick={() => abrir()}>
                      Leer detalle
                    </u>
                  </Text>
                </Row>
                <Row className='mt-3'>
                  <Button
                    disabled={
                      isInvalidPhone || isInvalidPhoneFina || isInvalidEmail
                    }
                    onClick={handleSubmit(onSubmit)}>
                    Enviar Información
                  </Button>
                </Row>
              </Col>
            </Row>
          </Form>
        </Container>
      );
    }
    case "contrasenia": {
      return (
        <Container>
          <Form onSubmit={handleSubmit(onSubmitPassword)}>
            <Form.Group
              className='justify-content-center'
              as={Row}
              controlId='formUser2'>
              <Col xs={8}>
                <Form.Control
                  name='password'
                  type='password'
                  placeholder='Contraseña nueva'
                  ref={register({ required: true })}
                />
              </Col>
            </Form.Group>
            <Form.Group
              className='justify-content-center'
              as={Row}
              controlId='formContact'>
              <Col xs={8}>
                <Form.Control
                  className='justify-content-center'
                  name='password_confirmacion'
                  type='password'
                  placeholder='Confirmación Contraseña nueva'
                  ref={register({ required: true })}
                />
              </Col>
            </Form.Group>

            <Row className='justify-content-center'>
              <Col xs={4}>
                <Row className='mt-3'>
                  <Button onClick={handleSubmit(onSubmitPassword)}>
                    Cambiar Contraseña
                  </Button>
                </Row>
              </Col>
            </Row>
          </Form>
        </Container>
      );
    }
    case "ejecutivo": {
      return (
        <Container>
          <Row className='justify-content-center'>
            <Col
              className='d-flex align-items-center justify-content-start'
              xs={10}>
              <TextColored bold type='gray'>
                {data.length > 1
                  ? "¿Con cuál de nuestros servicios le podemos ayudar?"
                  : ""}
              </TextColored>
            </Col>
          </Row>
          <Form>
            {data.length > 1
              ? data?.map((ejecutivo, index) => {
                  return (
                    <Row className='justify-content-center mt-3' key={index}>
                      <Col
                        className='d-flex align-items-center justify-content-end'
                        xs={2}>
                        <Form.Check
                          type={"checkbox"}
                          onChange={(e) =>
                            selecionarEjecutivo(e.target.checked, ejecutivo)
                          }
                        />
                        <Ejecutivo1 />
                      </Col>
                      <Col className='d-flex align-items-center' xs={9}>
                        <Row>
                          <Col xs={12}>
                            <TextColored type='gray'>
                              {DecryptData(ejecutivo.nombres, public_key)} para
                              consultas de{" "}
                              {DecryptData(ejecutivo.negocio, public_key)}
                            </TextColored>
                          </Col>
                          <Col xs={12}>
                            <TextColored type='gray'>
                              {DecryptData(ejecutivo.correo, public_key)} /{" "}
                              {DecryptData(ejecutivo.celular, public_key)}
                            </TextColored>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  );
                })
              : data?.map((ejecutivo, index) => {
                  return (
                    <Row className='justify-content-center mt-3' key={index}>
                      <Col
                        className='d-flex align-items-center justify-content-end'
                        xs={2}>
                        <Form.Control
                          name='ejecutivo_unico'
                          value={ejecutivo.id}
                          ref={register}
                          hidden={true}
                        />

                        <Form.Check
                          type={"checkbox"}
                          checked={true}
                          hidden={true}
                        />
                        <Ejecutivo1 />
                      </Col>
                      <Col className='d-flex align-items-center' xs={9}>
                        <Row>
                          <Col xs={12}>
                            <TextColored type='gray'>
                              {DecryptData(ejecutivo.nombres, public_key)} para
                              consultas de{" "}
                              {DecryptData(ejecutivo.negocio, public_key)}
                            </TextColored>
                          </Col>
                          <Col xs={12}>
                            <TextColored type='gray'>
                              {DecryptData(ejecutivo.correo, public_key)} /{" "}
                              {DecryptData(ejecutivo.celular, public_key)}
                            </TextColored>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  );
                })}

            <Row className='justify-content-center mt-3'>
              <Col
                className='d-flex align-items-center justify-content-start'
                xs={10}>
                <TextColored bold type='gray'>
                  ¿En qué podemos ayudarle?:
                </TextColored>
              </Col>
            </Row>

            <Form.Group
              className='justify-content-center mt-3'
              as={Row}
              controlId='formUser3'>
              <Col
                className='d-flex align-items-center justify-content-start'
                xs={10}>
                <Form.Control
                  name='comentario'
                  rows={3}
                  as='textarea'
                  placeholder=''
                  ref={register({ required: true })}
                />
              </Col>
            </Form.Group>

            <Row className='justify-content-center mt-3'>
              <Col xs={width > mobileXSWidth ? 4 : 12}>
                {" "}
                <Button onClick={handleSubmit(enviarContacto)}>
                  Enviar Información
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      );
    }
    case "datos_SII": {
      return (
        <RegistroSII
          closeModal={(response) => {
            if (response) {
              setModalData(response);
              setShowTopModal(false);
              handleShowModalSuccess();
            }
          }}
          endpoint={PORTALSAPI.POST.ACTUALIZARSII_DASHBOARD}
          //setTipoBanner={setTipoBanner}
        />
      );
    }
    default: {
      break;
    }
  }
};

export default function HeaderAutoatencion({ step, setContactMethod }) {
  const history = useHistory();
  const app = useContext(ThemeContext);
  const { body12, body14, body28, body25, body32 } = app.theme.font;
  const user = JSON.parse(localStorage.getItem("user"));
  const [showLoading, setShowLoading] = useState({ display: "none" });
  const [notificaciones, setNotificaciones] = useState([]);
  let idcobranza = 0;
  /* Data */
  const [dataLeasing, setDataLeasing] = useState([]);
  const [tipoVehiculos, setTipoVehiculos] = useState([]);
  const [modalData, setModalData] = useState({});
  const [notificacion, setNotificacion] = useState(0);
  const [nombres, setNombres] = useState("");
  const [ruts, setRuts] = useState("");
  const [contactarEjecutivo, setContactarEjecutivo] = useState({
    idcontacto: 0,
    contactos: [],
    mensaje: "",
  });

  /* Low level modal */
  const [show, setShow] = useState(false);
  const [modal, setModal] = useState(modalInfo);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  /* Top level modal */
  const [showTopModal, setShowTopModal] = useState(false);
  const handleCloseTopModal = useCallback(() => {
    setShowTopModal(false);
    handleShow();
  }, []);
  const handleShowTopModal = useCallback(() => {
    handleClose();
    setShowTopModal(true);
  }, []);
  const [topModal, setTopModal] = useState(modalInfo);

  /* Error modal*/
  const [showModalError, setShowModalError] = useState(false);
  const handleCloseModalError = () => setShowModalError(false);
  const handleShowModalError = () => {
    setShowModalError(true);
  };

  /* Sucess modal*/
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const handleCloseModalSuccess = () => setShowModalSuccess(false);
  const handleShowModalSuccess = useCallback(() => {
    handleCloseTopModal();
    handleClose();
    setShowModalSuccess(true);
  }, []);

  const openComentario = useCallback((data) => {
    idcobranza = data;
    openModal({ title: "comentario", data: { data } });
  }, []);

  const enviarContacto = useCallback(
    (contactar) => {
      setShowLoading({ display: "block" });
      let user = JSON.parse(localStorage.getItem("user"));
      let publicURL = PORTALSAPI.GET.PUBLIC_KEY.replace(
        "{idcontacto}",
        user.idcontacto
      ).replace("{tipo}", user.tipo_acceso);
      api.get(publicURL).then((response) => {
        let ejecutivos_lista = {
          ...contactarEjecutivo,
          contactos: contactarEjecutivo?.contactos?.map((contacto) => ({
            id: parseInt(DecryptData(contacto?.id, response?.data)),
          })),
        };
        if (contactar.ejecutivo_unico) {
          ejecutivos_lista.contactos.push({
            id: parseInt(
              DecryptData(contactar?.ejecutivo_unico, response?.data)
            ),
          });
        }
        //setContactarEjecutivo(ejecutivos_lista);

        if (ejecutivos_lista.contactos.length === 0) {
          setShowLoading({ display: "none" });
          setModalData({
            messageLine: false,
            title: "Contactar Ejecutivos",
            subtitle: "Error",
            message: "Debe escoger al menos 1 ejecutivo.",
            color: "red",
            icon: (
              <>
                <Error />
              </>
            ),
          });
          handleShowModalError();
          return;
        }

        let dataform = ejecutivos_lista;
        dataform.idcontacto = JSON.parse(
          localStorage.getItem("user")
        ).idcontacto;
        dataform.mensaje = contactar.comentario;
        let url = PORTALSAPI.POST.CONTACTAR;
        api.post(url, dataform).then(
          (res) => {
            setShowLoading({ display: "none" });
            setModalData({
              messageLine: true,
              title: "Información Enviada",
              subtitle: "EXITOSAMENTE",
              message2: "¡Muchas Gracias!",
              color: "yellow",
              icon: (
                <>
                  <Confirmado />
                </>
              ),
              message: (
                <>
                  <Row>
                    <Col xs={2} className='mt-2'>
                      <Reloj />
                    </Col>
                    <Col style={{ paddingLeft: "0px" }}>
                      <CustomTextWithoutPadding
                        className='text-justify'
                        bold
                        size={body12}
                        type='primary'>
                        {res.message}
                      </CustomTextWithoutPadding>
                    </Col>
                  </Row>
                </>
              ),
            });
            setContactarEjecutivo({
              idcontacto: 0,
              contactos: [],
              mensaje: "",
            });
            handleShowModalError();
            handleCloseTopModal();
            handleClose();
          },
          () => {
            setShowLoading({ display: "none" });
            setModalData({
              messageLine: false,
              title: "Contactar Ejecutivos",
              color: "red",
              subtitle: "Error",
              message: "No se pudo enviar su solicitud de contacto.",
              icon: (
                <>
                  <Error />
                </>
              ),
            });
            handleShowModalError();
          }
        );
      });
    },
    [body12, contactarEjecutivo, handleCloseTopModal, history]
  );

  const selecionarEjecutivo = useCallback(
    (checked, ejecutivo) => {
      let ejecutivos_lista = contactarEjecutivo;
      let existe = ejecutivos_lista?.contactos.find(
        (x) => x.id === ejecutivo.id
      );
      if (existe && checked === false) {
        ejecutivos_lista.contactos = ejecutivos_lista?.contactos.filter(
          (x) => x.id !== ejecutivo.id
        );
        setContactarEjecutivo(ejecutivos_lista);
        return;
      }
      ejecutivos_lista.contactos?.push({ id: ejecutivo?.id });
      setContactarEjecutivo(ejecutivos_lista);
    },
    [contactarEjecutivo]
  );

  const onSubmit = useCallback(
    (data) => {
      if (validatePhone(data.celular) === false) {
        setModalData({
          messageLine: false,
          title: "Actualización de datos",
          subtitle: "Error",
          color: "red",
          message: "Fono inválido.",
          icon: (
            <>
              <Error />
            </>
          ),
        });
        handleShowModalError();
        return;
      }

      if (validateEmail(data.correo) === false) {
        setModalData({
          messageLine: false,
          title: "Actualización de datos",
          subtitle: "Error",
          color: "red",
          message: "Email inválido.",
          icon: (
            <>
              <Error />
            </>
          ),
        });
        handleShowModalError();
        return;
      }

      if (data.terminos === false) {
        setModalData({
          messageLine: false,
          title: "Actualización de datos",
          subtitle: "Error",
          color: "red",
          message:
            "Debe aceptar los términos y condiciones para poder actualizar sus datos.",
          icon: (
            <>
              <Error />
            </>
          ),
        });
        handleShowModalError();
        return;
      }

      let url = PORTALSAPI.PUT.ACTUALIZAR_DATOS;
      data.idcontacto = JSON.parse(localStorage.getItem("user")).idcontacto;
      data.idcliente = JSON.parse(localStorage.getItem("user")).idcliente;
      api.put(url, data).then(
        () => {
          let public_key = JSON.parse(sessionStorage.getItem("temporal"));
          user.nombres = EncryptData(data.nombres, public_key);
          localStorage.setItem("user", JSON.stringify(user));
          setNombres(data.nombres);
          setShowTopModal(false);
          setModalData({
            messageLine: false,
            title: "Datos Actualizados",
            subtitle: "EXITOSAMENTE",
            color: "yellow",
            message: "¡Muchas Gracias!",
            icon: (
              <>
                <Confirmado />
              </>
            ),
          });
          handleShowModalError();
        },
        () => {
          setModalData({
            messageLine: false,
            title: "Actualización de datos",
            subtitle: "Error",
            color: "red",
            message: "Las contraseñas no coinciden.",
            icon: (
              <>
                <Error />
              </>
            ),
          });
          handleShowModalError();
        }
      );
    },
    [user]
  );

  const enviarComentario = useCallback(
    (data) => {
      if (data.comentario.trim() === "") {
        setModalData({
          messageLine: true,
          title: "Enviar Comentario",
          subtitle: "Error",
          color: "red",
          message: "El comentario no puede estar vacío.",
          icon: (
            <>
              <Error />
            </>
          ),
        });
        handleShowModalError();
        setShowTopModal(false);
        handleShow();
        return;
      }

      let idcontacto = JSON.parse(localStorage.getItem("user")).idcontacto;
      let formComentario = {
        idcontacto: idcontacto,
        idcobranza: idcobranza,
        mensaje: data.comentario,
      };
      api.post(PORTALSAPI.POST.COMETARIOS, formComentario).then(
        () => {
          setModalData({
            messageLine: false,
            title: "Enviar Comentario",
            subtitle: "Confirmado",
            color: "yellow",
            message: "Su comentario ha sido enviado.",
            icon: (
              <>
                <Confirmado />
              </>
            ),
          });
          handleShowModalError();
          setShowTopModal(false);
          handleShow();
        },
        () => {
          setModalData({
            messageLine: false,
            title: "Enviar Comentario",
            subtitle: "Error",
            color: "red",
            message: "Su comentario no se pudo enviar.",
            icon: (
              <>
                <Error />
              </>
            ),
          });
          handleShowModalError();
          setShowTopModal(false);
          handleShow();
        }
      );
    },
    [idcobranza]
  );

  const { register, handleSubmit, reset } = useForm();
  /* const [usuario, setUsuario] = useState(""); */
  const [rutState, setRutState] = useState("");
  const [cliente, setCliente] = useState("");
  const { width } = useWindowDimensions();

  const validaRut = (obj) => {
    var largo, crut, rut, dv, i, suma, mul, res, dvi;
    var tmpstr = "";
    var intlargo = obj;
    if (intlargo.length > 0) {
      crut = obj;
      largo = crut.length;
      if (largo < 2) {
        return false;
      }
      for (i = 0; i < crut.length; i++)
        if (
          crut.charAt(i) !== " " &&
          crut.charAt(i) !== "." &&
          crut.charAt(i) !== "-"
        ) {
          tmpstr = tmpstr + crut.charAt(i);
        }
      rut = tmpstr;
      crut = tmpstr;
      largo = crut.length;
      if (largo > 2) rut = crut.substring(0, largo - 1);
      else rut = crut.charAt(0);
      dv = crut.charAt(largo - 1);
      if (rut == null || dv == null) return 0;
      var dvr = "0";
      suma = 0;
      mul = 2;
      for (i = rut.length - 1; i >= 0; i--) {
        suma = suma + rut.charAt(i) * mul;
        if (mul === 7) mul = 2;
        else mul++;
      }
      res = suma % 11;
      if (res === 1) dvr = "k";
      else if (res === 0) dvr = "0";
      else {
        dvi = 11 - res;
        dvr = dvi + "";
      }
      if (dvr != dv.toLowerCase()) {
        return false;
      }
      return true;
    }
  };

  const userModal = {
    padding: "10px",
    borderBottom: "1px solid #dee2e6",
  };

  const border = {
    borderBottom: `2px solid ${app.theme.primary}`,
    paddingBottom: "3px",
  };

  const onSubmitSolicitar = useCallback((data) => {
    setShowLoading({ display: "block" });
    switch (data.tipo_solicitud) {
      case "Permisos Internacional":
        if (data.correo.trim() !== "") {
          if (!validateEmail(data.correo)) {
            setModalData({
              messageLine: false,
              title: "Error al registrar",
              subtitle: "Error",
              color: "red",
              icon: (
                <>
                  <Error />
                </>
              ),
              message: "Email inválido.",
            });
            setShowLoading({ display: "none" });
            handleShowModalError();
            return;
          }
        }
        if (data.paises.trim() == "") {
          setModalData({
            messageLine: false,
            title: "Error al registrar",
            subtitle: "Error",
            color: "red",
            icon: (
              <>
                <Error />
              </>
            ),
            message: "Debe elegir un país como mínimo.",
          });
          setShowLoading({ display: "none" });
          handleShowModalError();
          return;
        }
        var url = PORTALSAPI.POST.REGISTRAR_SOLICITUD_PERMISO;
        api
          .post(url, {
            ...data,
            nrooperacion: parseInt(data.nrooperacion),
            metadata: [
              `Operación Fintec: ${data.nrooperacion}`,
              `Bien: ${data.bien}`,
              `Marca: ${data.marca}`,
              `Modelo: ${data.modelo}`,
              `Fecha Inicio: ${data.fecha_inicio}`,
              `Fecha Fin: ${data.fecha_fin}`,
              `Patente: ${data.placa}`,
              `Correo: ${data.correo}`,
              `Paises: ${data.paises}`,
            ],
          })
          .then(
            (res) => {
              setShowLoading({ display: "none" });
              setShowTopModal(false);
              setModalData({
                messageLine: false,
                title: `Registro de solicitud de ${data.tipo_solicitud}`,
                subtitle: "EXITOSAMENTE",
                color: "yellow",
                message: res.message + ` con el ticket: ${res.data}`,
                icon: (
                  <>
                    <Confirmado />
                  </>
                ),
              });
              handleShowModalError();
            },
            (error) => {
              setModalData({
                messageLine: false,
                title: "Error al registrar",
                subtitle: "Error",
                color: "red",
                icon: (
                  <>
                    <Error />
                  </>
                ),
                message: error.response.data.message,
              });
              setShowLoading({ display: "none" });
              handleShowModalError();
              return;
            }
          );

        break;
      case "Certificado Alzamiento de Prenda":
        if (data.correo.trim() !== "") {
          if (!validateEmail(data.correo)) {
            setModalData({
              messageLine: false,
              title: "Error al registrar",
              subtitle: "Error",
              color: "red",
              icon: (
                <>
                  <Error />
                </>
              ),
              message: "Email inválido.",
            });
            setShowLoading({ display: "none" });
            handleShowModalError();
            return;
          }
        }
        data = {
          ...data,
          nrooperacion: parseInt(data.nrooperacion),
          metadata: [
            `Operación Fintec: ${data.nrooperacion}`,
            `Bien: ${data.bien}`,
            `Comentario: ${data.comentario}`,
            `Marca: ${data.prenda_marca}`,
            `Modelo: ${data.prenda_modelo}`,
            `Patente: ${data.prenda_patente}`,
            `Correo: ${data.correo}`,
          ],
        };
        var url = PORTALSAPI.POST.REGISTRAR_SOLICITUD_PRENDA;
        api
          .post(url, {
            ...data,
            nrooperacion: parseInt(data.nrooperacion),
            id_bien: parseInt(data.id_bien),
          })
          .then(
            (res) => {
              setShowLoading({ display: "none" });
              setShowTopModal(false);
              setModalData({
                messageLine: false,
                title: `Registro de solicitud de ${data.tipo_solicitud}`,
                subtitle: "EXITOSAMENTE",
                color: "yellow",
                message: res.message + ` con el ticket: ${res.data}`,
                icon: (
                  <>
                    <Confirmado />
                  </>
                ),
              });
              handleShowModalError();
            },
            (error) => {
              setModalData({
                messageLine: false,
                title: "Error al registrar",
                subtitle: "Error",
                color: "red",
                icon: (
                  <>
                    <Error />
                  </>
                ),
                message: error.response.data.message,
              });
              setShowLoading({ display: "none" });
              handleShowModalError();
              return;
            }
          );
        break;
      case "Certificados Cobertura de Seguros":
        if (data.correo.trim() !== "") {
          if (!validateEmail(data.correo)) {
            setModalData({
              messageLine: false,
              title: "Error al registrar",
              subtitle: "Error",
              color: "red",
              icon: (
                <>
                  <Error />
                </>
              ),
              message: "Email inválido.",
            });
            setShowLoading({ display: "none" });
            handleShowModalError();
            return;
          }
        }
        var url = PORTALSAPI.POST.REGISTRAR_SOLICITUD_SEGURO;
        api
          .post(url, {
            ...data,
            nrooperacion: parseInt(data.nrooperacion),
            metadata: [
              `Operación Fintec: ${data.nrooperacion}`,
              `Bien: ${data.bien}`,
              `Comentario: ${data.comentario}`,
              `Correo: ${data.correo}`,
            ],
          })
          .then(
            (res) => {
              setShowLoading({ display: "none" });
              setShowTopModal(false);
              setModalData({
                messageLine: false,
                title: `Registro de solicitud de ${data.tipo_solicitud}`,
                subtitle: "EXITOSAMENTE",
                color: "yellow",
                message: res.message + ` con el ticket: ${res.data}`,
                icon: (
                  <>
                    <Confirmado />
                  </>
                ),
              });
              handleShowModalError();
            },
            (error) => {
              setModalData({
                messageLine: false,
                title: "Error al registrar",
                subtitle: "Error",
                color: "red",
                icon: (
                  <>
                    <Error />
                  </>
                ),
                message: error.response.data.message,
              });
              setShowLoading({ display: "none" });
              handleShowModalError();
              return;
            }
          );
        break;
      case "Certificado de Tarjeta Estudiantil":
        if (data.correo.trim() !== "") {
          if (!validateEmail(data.correo)) {
            setModalData({
              messageLine: false,
              title: "Error al registrar",
              subtitle: "Error",
              color: "red",
              icon: (
                <>
                  <Error />
                </>
              ),
              message: "Email inválido.",
            });
            setShowLoading({ display: "none" });
            handleShowModalError();
            return;
          }
        }
        if (!validaRut(data.rut)) {
          setModalData({
            messageLine: false,
            title: "Error al registrar",
            subtitle: "Error",
            color: "red",
            icon: (
              <>
                <Error />
              </>
            ),
            message: "Rut inválido.",
          });
          setShowLoading({ display: "none" });
          handleShowModalError();
          return;
        }
        var url = PORTALSAPI.POST.REGISTRAR_SOLICITUD_ESTUDIANTIL;
        api
          .post(url, {
            ...data,
            nrooperacion: parseInt(data.nrooperacion),
            metadata: [
              `Operación Fintec: ${data.nrooperacion}`,
              `Bien: ${data.bien}`,
              `Nombre: ${data.nombre}`,
              `Rut: ${data.rut}`,
              `Correo: ${data.correo}`,
            ],
          })
          .then(
            (res) => {
              setShowLoading({ display: "none" });
              setShowTopModal(false);
              setModalData({
                messageLine: false,
                title: `Registro de solicitud de ${data.tipo_solicitud}`,
                subtitle: "EXITOSAMENTE",
                color: "yellow",
                message: res.message + ` con el ticket: ${res.data}`,
                icon: (
                  <>
                    <Confirmado />
                  </>
                ),
              });
              handleShowModalError();
            },
            (error) => {
              setModalData({
                messageLine: false,
                title: "Error al registrar",
                subtitle: "Error",
                color: "red",
                icon: (
                  <>
                    <Error />
                  </>
                ),
                message: error.response.data.message,
              });
              setShowLoading({ display: "none" });
              handleShowModalError();
              return;
            }
          );
        break;
      case "Certificado de Tarjeta de Combustible":
        if (data.correo.trim() !== "") {
          if (!validateEmail(data.correo)) {
            setModalData({
              messageLine: false,
              title: "Error al registrar",
              subtitle: "Error",
              color: "red",
              icon: (
                <>
                  <Error />
                </>
              ),
              message: "Email inválido.",
            });
            setShowLoading({ display: "none" });
            handleShowModalError();
            return;
          }
        }
        var url = PORTALSAPI.POST.REGISTRAR_SOLICITUD_COMBUSTIBLE;
        api
          .post(url, {
            ...data,
            nrooperacion: parseInt(data.nrooperacion),
            metadata: [
              `Operación Fintec: ${data.nrooperacion}`,
              `Bien: ${data.bien}`,
              `Tipo Vehiculo: ${data.tipo_vehiculo}`,
              `Marca: ${data.marca}`,
              `Modelo: ${data.modelo}`,
              `Año: ${data.anio}`,
              `Motor: ${data.motor}`,
              `Chasis: ${data.chasis}`,
              `Patente: ${data.patente}`,
              `Correo: ${data.correo}`,
            ],
          })
          .then(
            (res) => {
              setShowLoading({ display: "none" });
              setShowTopModal(false);
              setModalData({
                messageLine: false,
                title: `Registro de solicitud de ${data.tipo_solicitud}`,
                subtitle: "EXITOSAMENTE",
                color: "yellow",
                message: res.message + ` con el ticket: ${res.data}`,
                icon: (
                  <>
                    <Confirmado />
                  </>
                ),
              });
              handleShowModalError();
            },
            (error) => {
              setModalData({
                messageLine: false,
                title: "Error al registrar",
                subtitle: "Error",
                color: "red",
                icon: (
                  <>
                    <Error />
                  </>
                ),
                message: error.response.data.message,
              });
              setShowLoading({ display: "none" });
              handleShowModalError();
              return;
            }
          );
        break;
      case 'Certificado "Otros"':
        if (data.correo.trim() !== "") {
          if (!validateEmail(data.correo)) {
            setModalData({
              messageLine: false,
              title: "Error al registrar",
              subtitle: "Error",
              color: "red",
              icon: (
                <>
                  <Error />
                </>
              ),
              message: "Email inválido.",
            });
            setShowLoading({ display: "none" });
            handleShowModalError();
            return;
          }
        }
        var url = PORTALSAPI.POST.REGISTRAR_SOLICITUD_OTROS;
        api
          .post(url, {
            ...data,
            nrooperacion: parseInt(data.nrooperacion),
            metadata: [
              `Operación Fintec: ${data.nrooperacion}`,
              `Bien: ${data.bien}`,
              `Comentario: ${data.comentario}`,
              `Correo: ${data.correo}`,
            ],
          })
          .then(
            (res) => {
              setShowLoading({ display: "none" });
              setShowTopModal(false);
              setModalData({
                messageLine: false,
                title: `Registro de solicitud de ${data.tipo_solicitud}`,
                subtitle: "EXITOSAMENTE",
                color: "yellow",
                message: res.message + ` con el ticket: ${res.data}`,
                icon: (
                  <>
                    <Confirmado />
                  </>
                ),
              });
              handleShowModalError();
            },
            (error) => {
              setModalData({
                messageLine: false,
                title: "Error al registrar",
                subtitle: "Error",
                color: "red",
                icon: (
                  <>
                    <Error />
                  </>
                ),
                message: error.response.data.message,
              });
              setShowLoading({ display: "none" });
              handleShowModalError();
              return;
            }
          );
        break;

      default:
        break;
    }
  }, []);

  const onSubmitPassword = useCallback((data) => {
    if (data.password !== data.password_confirmacion) {
      setModalData({
        messageLine: false,
        title: "Cambio de contraseña",
        subtitle: "Error",
        color: "red",
        message: "Las contraseñas no coinciden.",
        icon: (
          <>
            <Error />
          </>
        ),
      });
      handleShowModalError();
      return;
    }

    let url = PORTALSAPI.PUT.ACTUALIZAR_PASSWORD;
    data.idcontacto = JSON.parse(localStorage.getItem("user")).idcontacto;
    data.password = data.password;
    data.password_confirmacion = data.password_confirmacion;
    api.put(url, data).then(
      () => {
        setShowTopModal(false);
        setModalData({
          messageLine: false,
          title: "Datos Actualizados",
          subtitle: "EXITOSAMENTE",
          color: "yellow",
          message: "¡Muchas Gracias!",
          icon: (
            <>
              <Confirmado />
            </>
          ),
        });
        handleShowModalError();
      },
      (error) => {
        setModalData({
          messageLine: false,
          title: "Cambio de contraseña",
          subtitle: "Error",
          color: "red",
          icon: (
            <>
              <Error />
            </>
          ),
          message: error.response.data.message,
        });
        handleShowModalError();
        return;
      }
    );
  }, []);

  const openTopModal = useCallback(
    (type, dataModal) => {
      setShowLoading({ display: "block" });
      const _modalInfo = { table: { columns: [], rows: [] } };
      _modalInfo["padding"] = true;
      _modalInfo["size"] = "xl";
      let data = [];
      let user = JSON.parse(localStorage.getItem("user"));
      switch (type) {
        case "gestion": {
          _modalInfo["title"] = (
            <Title pipe={false} title='Gestión de ' subtitle='Cobranza' />
          );
          _modalInfo["table"]["columns"] = columnasGestionCobranza;
          const user = JSON.parse(localStorage.getItem("user"));

          if (user === null) {
            window.location.href = "/";
            return;
          }

          let url = PORTALSAPI.GET.COBRANZA_DETALLE.replace(
            "{idcobranza}",
            dataModal
          ).replace("idcontacto", user.idcontacto);
          api.get(url).then((x) => {
            data = x?.data?.map((el) => ({ ...el }));
            data.forEach(
              (el, index) =>
                (el["comentarios"] = (
                  <Button
                    onClick={() => {
                      openComentario(dataModal);
                    }}
                    key={index}>
                    Enviar comentarios
                  </Button>
                ))
            );
            _modalInfo["table"]["rows"] = data;
            _modalInfo["icon"] = icons[type];
            setTopModal(_modalInfo);
            setShowLoading({ display: "none" });
            handleShowTopModal();
          });
          break;
        }

        case "datos_SII": {
          _modalInfo["title"] = (
            <Title pipe={false} title='Registro Datos Portal SII' subtitle='' />
          );
          _modalInfo["size"] = "xs";
          _modalInfo["headerColor"] = "#f7cf46";
          _modalInfo["table"] = null;
          _modalInfo["content"] = (
            <RenderContent utilities={utilities} type={type} data={{}} />
          );
          _modalInfo["icon"] = icons["logo4"];
          setTopModal(_modalInfo);
          setShowLoading({ display: "none" });
          handleShowTopModal();
          break;
        }

        case "datos": {
          _modalInfo["title"] = (
            <Title
              title='Actualización de Datos Cliente'
              pipe={false}
              subtitle=''
            />
          );
          _modalInfo["subtitle"] =
            "Completa los antecedentes solicitados en el siguiente formulario para actualizar tus datos registrados.";
          _modalInfo["size"] = "lg";
          _modalInfo["table"] = null;

          let urlMain = PORTALSAPI.GET.PANEL_CONTROL.replace(
            "{idcontacto}",
            user.idcontacto
          );
          api
            .get(urlMain)
            .then((response) => {
              let user = JSON.parse(localStorage.getItem("user"));
              let publicURL = PORTALSAPI.GET.PUBLIC_KEY.replace(
                "{idcontacto}",
                user.idcontacto
              ).replace("{tipo}", user.tipo_acceso);
              api.get(publicURL).then((responsePublicKey) => {
                _modalInfo["content"] = (
                  <RenderContent
                    utilities={utilities}
                    type={type}
                    public_key={responsePublicKey.data}
                    data={response.data}
                  />
                );
                _modalInfo["icon"] = icons["usuario"];
                setTopModal(_modalInfo);
                setShowLoading({ display: "none" });
                handleShowTopModal();
              });
            })
            .catch(() => {
              setShowLoading({ display: "none" });
              fireAlert({ icon: "warning" });
            });
          break;
        }
        case "contrasenia": {
          _modalInfo["title"] = (
            <Title title='Cambio de contraseña' pipe={false} subtitle='' />
          );
          _modalInfo["subtitle"] =
            "Completa los antecedentes solicitados en el siguiente formulario para actualizar tus datos registrados.";
          _modalInfo["size"] = "lg";
          _modalInfo["table"] = null;
          _modalInfo["content"] = (
            <RenderContent utilities={utilities} type={type} data={{}} />
          );
          _modalInfo["icon"] = icons[type];
          setTopModal(_modalInfo);
          setShowLoading({ display: "none" });
          handleShowTopModal();
          break;
        }
        case "solicitar": {
          _modalInfo["title"] = (
            <Title title='Solicitud de documentos' pipe={false} subtitle='' />
          );
          _modalInfo["subtitle"] =
            "Completa los campos solicitados en el siguiente formulario para registrar su solicitud";
          /*  <>Completa los campos solicitados en el siguiente formulario para registrar su solicitud.<br></br>
          <b style={{fontSize : "0.7rem"}}>Recuerda que solo los contratos Nº 140000 en adelante pueden crear solicitud.</b></> */
          _modalInfo["size"] = "lg";
          _modalInfo["table"] = null;

          let urlMain = PORTALSAPI.GET.PANEL_CONTROL.replace(
            "{idcontacto}",
            user.idcontacto
          );
          api
            .get(urlMain)
            .then((response) => {
              let user = JSON.parse(localStorage.getItem("user"));
              let publicURL = PORTALSAPI.GET.PUBLIC_KEY.replace(
                "{idcontacto}",
                user.idcontacto
              ).replace("{tipo}", user.tipo_acceso);
              api.get(publicURL).then((responsePublicKey) => {
                response.data["leasing"] = dataLeasing;
                response.data["tipo_vehiculo"] = tipoVehiculos;
                _modalInfo["content"] = (
                  <RenderContent2
                    type={type}
                    public_key={responsePublicKey.data}
                    data={response.data}
                    onSubmitSolicitar={onSubmitSolicitar}
                  />
                );
                //_modalInfo["icon"] = icons["usuario"];
                setTopModal(_modalInfo);
                setShowLoading({ display: "none" });
                handleShowTopModal();
              });
            })
            .catch(() => {
              setShowLoading({ display: "none" });
              fireAlert({ icon: "warning" });
            });
          break;
        }
        default: {
          break;
        }
      }
    },
    [
      dataLeasing,
      handleShowTopModal,
      tipoVehiculos,
      onSubmitSolicitar,
      openComentario,
      utilities,
    ]
  );

  const openModal = useCallback(
    (data_modal) => {
      setShowLoading({ display: "block" });
      let type = data_modal?.title;
      const _modalInfo = { table: { columns: [], rows: [] }, tables: [] };
      _modalInfo["size"] = "xl";
      _modalInfo["padding"] = true;
      _modalInfo["buttonsHeader"] = null;
      let data = [];

      switch (type) {
        case "usuario": {
          _modalInfo["title"] = (
            <Title
              size={body25}
              title='Mi panel de Control'
              pipe={false}
              subtitle=''
            />
          );
          _modalInfo["subtitle"] =
            "Bienvenido a su panel de control, acá podrá cambiar sus datos de cliente Progreso.";
          _modalInfo["size"] = "lg";
          _modalInfo["table"] = null;
          _modalInfo["padding"] = false;
          let url = PORTALSAPI.GET.PANEL_CONTROL.replace(
            "{idcontacto}",
            data_modal.data.idcontacto
          );

          api
            .get(url)
            .then((response) => {
              let publicURL = PORTALSAPI.GET.PUBLIC_KEY.replace(
                "{idcontacto}",
                data_modal.data.idcontacto
              ).replace("{tipo}", user.tipo_acceso);
              api.get(publicURL).then((responsePublicKey) => {
                _modalInfo["content"] = (
                  <RenderContent
                    utilities={utilities}
                    type={type}
                    public_key={responsePublicKey.data}
                    data={response}
                  />
                );
                _modalInfo["icon"] = icons["panel_control"];
                setModal(_modalInfo);
                setShowLoading({ display: "none" });
                handleShow();
              });
            })
            .catch(() => {
              setShowLoading({ display: "none" });
              fireAlert({ icon: "warning" });
            });
          break;
        }

        case "ejecutivo": {
          _modalInfo["title"] = (
            <Title title='Cuente con Nosotros' subtitle='Contacto' />
          );
          _modalInfo["subtitle"] =
            "Por este medio podrá ponerse en contacto de forma directa con su(s) ejecutivo(s) asignado(s).";
          _modalInfo["size"] = "lg";
          _modalInfo["table"] = null;

          let url = PORTALSAPI.GET.CONTACTOS.replace(
            "{idcontactos}",
            data_modal.data
          );

          api.get(url).then((response) => {
            let user = JSON.parse(localStorage.getItem("user"));
            let publicURL = PORTALSAPI.GET.PUBLIC_KEY.replace(
              "{idcontacto}",
              user.idcontacto
            ).replace("{tipo}", user.tipo_acceso);
            api.get(publicURL).then((responsePublicKey) => {
              _modalInfo["content"] = (
                <RenderContent
                  utilities={utilities}
                  type={type}
                  public_key={responsePublicKey.data}
                  data={response.data}
                />
              );
              _modalInfo["icon"] = icons[type];
              setModal(_modalInfo);
              setShowLoading({ display: "none" });
              handleShow();
            });
          });
          break;
        }

        case "archivo": {
          _modalInfo["title"] = (
            <Title title='Archivos' subtitle='Documentos' />
          );
          _modalInfo["subtitle"] = "";
          _modalInfo["table"]["columns"] = columnasArchivosDocumentos;
          const user = JSON.parse(localStorage.getItem("user"));

          if (user === null) {
            window.location.href = "/";
            return;
          }

          let url = PORTALSAPI.GET.ARCHIVOS.replace(
            "{idleasing}",
            data_modal.data
          ).replace("{idcontacto}", user.idcontacto);
          api.get(url).then((response) => {
            data = response.data?.map((el) => ({ ...el }));
            data.forEach((el) => {
              el["documento"] = (
                <a href={el.ruta} rel='noopener noreferrer' target='_blank'>
                  {el.nombre_documento}
                </a>
              );
            });
            _modalInfo["table"]["rows"] = data;
            _modalInfo["icon"] = icons[type];
            _modalInfo["search"] = true;
            setModal(_modalInfo);
            setShowLoading({ display: "none" });
            handleShow();
          });
          break;
        }

        case "notificacion": {
          _modalInfo["title"] = (
            <Title title='Centro de Notificaciones' pipe={false} subtitle='' />
          );
          _modalInfo["subtitle"] =
            "En esta sección podrás conocer notificaciones importantes para que no te pierdas de nada.";
          _modalInfo["size"] = "xs";
          _modalInfo["table"] = null;
          _modalInfo["content"] = (
            <RenderContent
              utilities={utilities}
              type={type}
              data={notificacion}
            />
          );
          _modalInfo["icon"] = icons["bocina"];
          setTopModal(_modalInfo);
          setShowLoading({ display: "none" });
          handleShowTopModal();
          // });
          break;
        }
        default: {
          break;
        }
      }
    },
    [body25, handleShowTopModal, notificacion, user.tipo_acceso, utilities]
  );

  useEffect(() => {
    const storageStep = Number(sessionStorage.getItem("current-step"));
    if (step === storageStep) {
      let publicURL = PORTALSAPI.GET.PUBLIC_KEY.replace(
        "{idcontacto}",
        user.idcontacto
      ).replace("{tipo}", user.tipo_acceso);

      api.get(publicURL).then((response) => {
        if (user) {
          if (ruts === "") {
            setRutState(DecryptData(user.rut, response.data));
          } else {
            setRutState(ruts);
          }
          setCliente(DecryptData(user.cliente, response.data));
        }
      });

      let urlMain = PORTALSAPI.GET.MAIN.replace(
        "{idcliente}",
        user.idcliente
      ).replace("{idcontacto}", user.idcontacto);

      api.get(urlMain).then((response) => {
        setDataLeasing(JSON.parse(JSON.stringify(response.data["leasing"])));
        let publicURL2 = PORTALSAPI.GET.PUBLIC_KEY.replace(
          "{idcontacto}",
          user.idcontacto
        ).replace("{tipo}", user.tipo_acceso);
        api.get(publicURL2).then((responsePublicKey) => {
          let currentUser = response.data.usuario;
          currentUser.token = user.token;
          currentUser.tipo_acceso = user.tipo_acceso;
          setNombres(
            DecryptData(response.data.usuario.nombres, responsePublicKey.data)
          );
          setTipoVehiculos(response.data["tipo_vehiculo"]);

          setNotificaciones(response.data["notificacion"]);
          setNotificacion(
            response.data["notificacion"] === null
              ? 0
              : response.data["notificacion"].length
          );
        });
      });
    }
  }, []);

  const header = {
    backgroundColor: app.theme.secondary,
    height: `${headerHeight}px`,
    borderStyle: "solid",
    borderWidth: "1px",
    borderBottomColor: "white",
  };

  const utilities = useMemo(
    () => ({
      theme: app.theme.font,
      notificaciones,
      openTopModal,
      userModal,
      register,
      handleSubmit,
      enviarComentario,
      width,
      reset,
      onSubmit,
      onSubmitPassword,
      setModalData,
      setShowTopModal,
      handleShowModalSuccess,
      enviarContacto,
      openModal,
      selecionarEjecutivo,
    }),
    [
      app,
      notificaciones,
      openTopModal,
      userModal,
      register,
      handleSubmit,
      enviarComentario,
      width,
      reset,
      onSubmit,
      onSubmitPassword,
      setModalData,
      setShowTopModal,
      handleShowModalSuccess,
      enviarContacto,
      openModal,
      selecionarEjecutivo,
    ]
  );

  return (
    <>
      <Modal
        padding={modal.padding}
        size={modal.size}
        show={show}
        icon={modal.icon}
        title={modal.title}
        subtitle={modal.subtitle}
        handleClose={handleClose}
        backdrop='static'>
        {modal?.table ? (
          <>
            {modal?.buttonsHeader && (
              <div class='row justify-content-end'>{modal?.buttonsHeader}</div>
            )}
            <Tabla
              paginacion={true}
              numeroFilas={5}
              busqueda={!!modal?.search}
              datos={modal.table}></Tabla>
          </>
        ) : !modal?.tables?.length ? (
          modal.content
        ) : (
          <div class='container'>
            <div class='row justify-content-end'>{modal?.buttonsHeader}</div>
            <div class='row row-cols-1 row-cols-lg-2'>
              {modal?.tables?.map((table, i) => (
                <div class='col mb-2' key={"div-" + i}>
                  <Title pipe={false} title={table?.title} subtitle='' />
                  <Tabla
                    paginacion={true}
                    numeroFilas={5}
                    busqueda={!!table?.search}
                    datos={table}></Tabla>
                </div>
              ))}
            </div>
          </div>
        )}
      </Modal>
      {/* Top level modal */}
      <Modal
        headerColor={topModal?.headerColor}
        padding={topModal?.padding}
        size={topModal?.size}
        show={showTopModal}
        icon={topModal?.icon}
        title={topModal?.title}
        subtitle={topModal?.subtitle}
        handleClose={() =>
          topModal.table ? handleCloseTopModal : setShowTopModal(false)
        }>
        {topModal.table ? (
          <Tabla
            paginacion={true}
            numeroFilas={5}
            busqueda={false}
            datos={topModal?.table}></Tabla>
        ) : (
          topModal.content
        )}
      </Modal>

      <ModalSuccess
        show={showModalError}
        icon={modalData?.icon}
        messageLine={modalData?.messageLine}
        title={
          <TextColored size={body28} type={modalData?.color}>
            {modalData?.title}
          </TextColored>
        }
        subtitle={
          <TextColored size={body32} bold type={modalData?.color}>
            {modalData?.subtitle}
          </TextColored>
        }
        message={
          <Text type='primary' size={body25}>
            {modalData?.message}
          </Text>
        }
        handleClose={
          modalData?.subtitle === "Error" ? handleCloseModalError : false
        }
      />

      <ModalSuccess
        show={showModalSuccess}
        icon={<Confirmado />}
        title={
          <TextColored size={body28} type='yellow'>
            Datos Actualizados
          </TextColored>
        }
        subtitle={
          <TextColored size={body32} bold type='yellow'>
            EXITOSAMENTE
          </TextColored>
        }
        message={
          <Text type='primary' size={body25}>
            Muchas Gracias!
          </Text>
        }
        handleClose={() => handleCloseModalSuccess()}
      />

      <ModalSuccess2
        show={showModalSuccess}
        icon={icons["computadora2"]}
        iconHeader={icons["logo4"]}
        headerColor='#f7cf46'
        headerTitle={
          <Title pipe={false} title='Registro Datos Portal SII' subtitle='' />
        }
        size={"xs"}
        padding={true}
        title={<Title pipe={false} title='¡Felicidades!' subtitle='' />}
        subtitle={
          <TextColored className='text-center' size={1.1} bold type='black'>
            TUS DATOS HAN SIDO RECIBIDOS
            <br />
            EXITOSAMENTE
          </TextColored>
        }
        message={
          <Text className='mt-2 text-center' type='primary' size={0.7}>
            Nuestro ejecutivo comercial se contactará contigo a la brevedad
          </Text>
        }
        handleClose={() => handleCloseModalSuccess()}
      />
      <Loading estilo={showLoading} />
      <Container fluid style={header}>
        {width >= 830 ? (
          <Row className='align-items-center' style={{ margin: "0 1% 0 1%" }}>
            <Col md='auto' className='align-items-center d-flex'>
              <img width='110px' src={Logo} alt='' />
              <Pipe marginRight='0.5rem' size={30} color='yellow' />
              <Text size={body28}>Autoatención de Factoring</Text>
            </Col>

            <Col className='d-flex flex-column text-right'>
              <Text size={body14} bold>
                Bienvenido, {cliente}
              </Text>
              <Text size={body12}>RUT: {rutState}</Text>
            </Col>

            <Col
              className='align-items-center d-flex justify-content-end'
              md='auto'
              style={{ gap: "5px" }}>
              <a
                href='##'
                onClick={() =>
                  openModal({
                    title: "usuario",
                    data: JSON.parse(localStorage.getItem("user")),
                  })
                }>
                <UserCliente />
              </a>
              <a
                href='##'
                onClick={() =>
                  openModal({
                    title: "notificacion",
                    data: JSON.parse(localStorage.getItem("user")),
                  })
                }>
                <NotificationCliente notificaciones={notificaciones} />
              </a>

              <img
                      style={{
                      width: "42px",
                      height: "42px",
                      marginTop: "2px",
                      marginLeft: "0px",
                      cursor: "pointer"}}
                      onClick={() => CerrarSession()}
                      src={Logout} alt="Logout"
                  />
            </Col>

            <Col className='d-flex' md='auto'>
              <div
                style={{
                  borderLeft: "0.5px solid #3E3E3E",
                  height: "46px",
                  padding: "0.2em",
                }}></div>
              <Link
                size={0.7}
                center={true}
                border={border}
                onClick={() =>
                  openModal({
                    title: "ejecutivo",
                    data: JSON.parse(localStorage.getItem("user")).idcontacto,
                  })
                }>
                CONTACTAR EJECUTIVO
              </Link>
            </Col>
          </Row>
        ) : (
          <Row className='align-items-center'>
            <Col>
              <img width='40px' src={Logo2} alt='' />
            </Col>

            {/*             <Col style={{ textAlign: "center", whiteSpace: "nowrap" }}>
              <Link
                size={0.7}
                border={border}
                onClick={() =>
                  openModal({
                    title: "ejecutivo",
                    data: JSON.parse(localStorage.getItem("user")).idcontacto,
                  })
                }>
                CONTACTAR EJECUTIVO
              </Link>
            </Col> */}

            <Col>
              <Row className='d-flex justify-content-center' md='auto'>
                <a
                  href='##'
                  onClick={() =>
                    openModal({
                      title: "usuario",
                      data: JSON.parse(localStorage.getItem("user")),
                    })
                  }>
                  <UserCliente />
                </a>
                <a
                  className='ml-2'
                  href='##'
                  onClick={() =>
                    openModal({
                      title: "notificacion",
                      data: JSON.parse(localStorage.getItem("user")),
                    })
                  }>
                  <NotificationCliente notificaciones={notificaciones} />
                </a>
                <img
                      style={{
                      width: "42px",
                      height: "42px",
                      marginTop: "32px",
                      cursor: "pointer",
                      zIndex: "1000",
                      marginLeft: "2px",}}
                      onClick={() => CerrarSession()}
                      src={Logout} alt="Logout"
                  />
              </Row>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
}
