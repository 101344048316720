export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validateModeloCar = (e) => {
  const characters = ["-"];
  if (characters.indexOf(e.key) === -1 && !/^[0-9a-zA-Z]+$/.test(e.key)) {
    e.preventDefault();
    return;
  }
};
export const validatePatenteCar = (e) => {
  if (!/^[0-9a-zA-Z]+$/.test(e.key)) {
    e.preventDefault();
    return;
  }
};

export const formatDate = (date, format) => {
  const dateType = date ? new Date(date) : new Date();
  const mes = dateType.getMonth() + 1;
  const dia = dateType.getDate();
  const map = {
    mm: mes < 10 ? mes.toString().padStart(2, "0") : mes,
    dd: dia < 10 ? dia.toString().padStart(2, "0") : dia,
    yyyy: dateType.getFullYear(),
  };
  return format.replace(/mm|dd|yyyy/gi, (matched) => map[matched]);
};
export const validatePhone = (phone) => {
  const re = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})$/;
  return re.test(String(phone).toLowerCase());
};

const compareCrut = (crut, tmpstr) => {
  for (var i = 0; i < crut.length; i++) {
    if (
      crut.charAt(i) != " " &&
      crut.charAt(i) != "." &&
      crut.charAt(i) != "-"
    ) {
      tmpstr = tmpstr + crut.charAt(i);
    }
  }
  return tmpstr;
};
const comparesWithNull = (tmpstr, dv) => {
  return tmpstr == null || dv == null;
};

export const validateRut = (obj) => {
  var largo, crut, dv, i, suma, mul, res, dvi;
  var tmpstr = "";
  var intlargo = obj;
  if (intlargo.length > 0) {
    crut = obj;
    largo = crut.length;
    if (largo < 2) {
      return false;
    }
    tmpstr = compareCrut(crut, tmpstr);
    crut = tmpstr;
    largo = crut.length;
    tmpstr = largo > 2 ? crut.substring(0, largo - 1) : crut.charAt(0);
    dv = crut.charAt(largo - 1);
    if (comparesWithNull(tmpstr, dv)) return 0;
    var dvr = "0";
    suma = 0;
    mul = 2;
    for (i = tmpstr.length - 1; i >= 0; i--) {
      suma = suma + tmpstr.charAt(i) * mul;
      mul = mul == 7 ? 2 : mul + 1;
    }
    res = suma % 11;
    switch (res) {
      case 1:
        dvr = "k";
        break;
      case 0:
        break;
      default:
        dvi = 11 - res;
        dvr = dvi + "";
    }
    return dvr == dv.toLowerCase();
  }
};

export const ordenarMenus = (menus) => {
  let menusPadresOrdered = [];
  let menusOrdered = [];
  menusPadresOrdered = menus
    .filter((menus) => menus.id_padre == 0)
    .sort((a, b) => {
      return a.orden - b.orden;
    });
  menusOrdered = menusPadresOrdered.map((menu) => {
    return {
      ...menu,
      submenu: [
        ...menus
          .filter((menus) => menus.id_padre == menu.id)
          .sort((a, b) => {
            return a.orden - b.orden;
          }),
      ],
    };
  });

  return menusOrdered;
};

const emailValidation = (emailInput) => {
  const emailToLowerCase = emailInput.toLowerCase();
  const regexEmail =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
  const secondRegexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,9})+$/;
  const emailValidator = emailToLowerCase.toLowerCase().includes("@")
    ? emailToLowerCase.split("@")
    : emailToLowerCase.split(".");
  const urlEmailValidator = emailValidator[1]?.split(".");
  if (
    !regexEmail.test(emailToLowerCase) ||
    !secondRegexEmail.test(emailToLowerCase) ||
    urlEmailValidator[0]?.length > 40 ||
    emailValidator[2]?.length > 3 ||
    urlEmailValidator[1]?.length > 20 ||
    urlEmailValidator.length > 3 ||
    emailValidator.length > 3
  ) {
    return false;
  }
  return true;
};
