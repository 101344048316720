import React, { useEffect } from 'react';
import ClienteLoginSeccion from '../../components/clientes-login-seccion/ClienteLoginSeccion';
import ClienteConfirmacionPassword from '../../components/clientes/ClienteConfirmacionPassword';

const ConfirmarPassword = () => {
    useEffect(() => {
    }, []);
    return (
        <>
            <ClienteLoginSeccion >
                <ClienteConfirmacionPassword></ClienteConfirmacionPassword>
            </ClienteLoginSeccion>
        </>

    );
};
export default ConfirmarPassword;