//Hooks - Funciones
import React, { useContext } from "react";
import { ThemeContext } from "../../../theming";
import { useHistory } from "react-router-dom";

//Assets
import Computadora from "../../../assets/images/computadora.png";
import Confirmado from "../../../assets/images/confirmado.png";
import Laptop from "../../../assets/images/laptop-confirmada.png";
import ModalHeader from "react-bootstrap/esm/ModalHeader";

//Componentes
import { Col, Modal, ModalBody, Row } from "react-bootstrap";
import { TextColored } from "../../typography";
import CustomButton from "../../buttons/Button";

import "./styles.css";

const Body = ({ type }) => {
  const history = useHistory();
  const {
    theme: {
      font: { body32, body28, body25 },
      gray,
    },
  } = useContext(ThemeContext);

  const typeObj = {
    "cesion-exitosa": {
      title: `Operación registrada con éxito.`,
      subtitle: "Los detalles serán enviados a su e-mail corporativo",
      button: "Volver al inicio",
      titleSize: body25,
      onClick: () => {
        sessionStorage.removeItem("bills");
        history.push("/dashboard");
      },
    },
    "scoring-fallido": {
      title: `Su operación no cumple con todos los requisitos establecidos para el servicio de Autoatención, por lo que  uno de nuestros ejecutivos se pondrá en contacto con usted para evaluar esta operación.`,
      button: "Aceptar",
      titleSize: body28,
      onClick: () => {
        sessionStorage.removeItem("bills");
        history.push("/dashboard");
      },
    },
    "scoring-exitoso": {
      title: "Evaluación exitosa",
      button: "Continuar",
      titleSize: body32,
      onClick: () => {
        history.push("/autoatencion/metodo-pago");
      },
    },
  };

  return (
    <div className="body">
      {type === "cesion-exitosa" ? (
        <TextColored
          size={body28}
          children={typeObj[type].title}
          type={gray}
          bold
        />
      ) : null}
      <TextColored
        size={typeObj[type]?.titleSize}
        children={
          typeObj[type].subtitle ? typeObj[type].subtitle : typeObj[type].title
        }
        type={gray}
      />
      <Row className="d-flex justify-content-center align-items-center">
        <Col md={5}>
          <CustomButton
            children={typeObj[type]?.button}
            onClick={typeObj[type]?.onClick}
          />
        </Col>
      </Row>
    </div>
  );
};

export const ModalContent = ({ type, utilities }) => {
  const icon = {
    "scoring-fallido": <img src={Computadora} alt="laptop" />,
    "scoring-exitoso": <img src={Laptop} alt="laptop" />,
    "cesion-exitosa": <img src={Confirmado} alt="cesion-confirmada" />,
  };

  return (
    <>
      <ModalHeader>{icon[type] ? icon[type] : null}</ModalHeader>
      <ModalBody>
        <Body type={type} utilities={utilities} />
      </ModalBody>
    </>
  );
};

const ModalAutoatencion = ({ show, type, utilities }) => {
  return (
    <Modal show={show} centered>
      <ModalContent type={type} utilities={utilities} />
    </Modal>
  );
};

export default ModalAutoatencion;
