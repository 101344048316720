import React from "react";
import { Button } from "react-bootstrap";

const IconButton = ({ Icon, className = "", disabled, otherProps, onClick, id }) => {
  const styles = {
    background: "none",
    border: "none",
    color: "grey",
    padding: "0",
    cursor: `${disabled ? "not-allowed" : "pointer"}`,
  };

  return (
    <Button
      className={`icon-button ${className}`}
      style={styles}
      disabled={disabled}
      onClick={onClick}
      id={id}
      {...otherProps}
    >
      {Icon}
    </Button>
  );
};

export default IconButton;
