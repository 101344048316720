import React from "react";
import NumberFormat from "react-number-format";

export const PoliticasDePrivacidad = () => {
  return (
    <div>
      <p>
        La presente Política de Privacidad tiene por finalidad informar a los
        Usuarios del tratamiento de los datos personales que el Usuario
        proporcione al utilizar o contratar los servicios de Progreso, por
        cualquiera de sus canales de comunicación o venta, tanto presenciales
        como remotos, a través del{" "}
        <a href="https://www.progreso.cl/" target="_blank" rel="noreferrer">
          sitio web de Progreso
        </a>
        , su aplicación informática, o por cualquier otro medio electrónico,
        telefónico, video, u otra tecnología y en su caso, a través de agentes,
        promotores, comisionistas, proveedores o socios comerciales. Tanto
        Progreso como los demás terceros autorizados, sólo efectuarán el
        tratamiento de los Datos Personales cuando cuenten con dicha
        autorización del Usuario o la Ley les reconozca otra base de legalidad
        para tratar Datos Personales y, únicamente para los fines autorizados en
        esta Política de Privacidad, en conformidad a la Ley Nº 19.628 sobre
        Protección de la Vida Privada y demás regulación aplicable. El
        responsable por los datos personales que se recojan en conformidad a la
        presente Política de Privacidad es
        <span style={{ fontWeight: 500 }}>
          SERVICIOS FINANCIEROS PROGRESO S.A.
        </span>
        , Rol Único Tributario N.º 90.146.000-0, domiciliado en Miraflores 222
        piso 25 Santiago, en adelante e indistintamente como “Progreso ” En caso
        de cualquier otra consulta acerca de la Política de Privacidad que se
        expone, por favor comunicarse al correo electrónico
      </p>
      <p>
        <ol
          type="I"
          style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
        >
          <li>
            <b>Datos Personales</b>
            <div style={{ textIndent: "15px each-line" }}>
              Se entiende por datos de carácter personal o “Datos Personales”,
              los relativos a cualquier información concerniente a personas
              naturales, identificadas o identificables, que el Usuario entregue
              directamente en el curso de sus relaciones con Progreso y que sean
              necesarios para adquirir y utilizar los servicios que este ofrece
              o para requerir el cumplimiento de acuerdos o contratos suscritos
              con Progreso; tales como nombre, domicilio, teléfono, correo
              electrónico, fecha y lugar de nacimiento, datos migratorios, Rol
              Único Tributario, estado civil, datos académicos, datos de
              identificación y contacto, datos biométricos y de geolocalización,
              datos patrimoniales, datos deuda vigente, motivo del crédito, tipo
              de negocio, claves de acceso que permitan acceder a fuentes de
              ingresos, egresos, declaraciones de pago de impuestos u otra
              información relevante para evaluaciones comerciales en el Servicio
              de Impuestos Internos (“SII”), Tesorería General de la República u
              otros organismos de similar naturaleza, verificación de datos a
              través de redes sociales, información sobre navegación en el sitio
              web y aplicaciones móviles de Progreso, por ejemplo, el tipo de
              dispositivo con el que se conecta, sistema operativo y navegador
              web, el número identificador de dispositivo de red (dirección MAC)
              dirección IP, entre otros.
            </div>
            <div style={{ textIndent: "15px" }}>
              Algunos de los productos o servicios que ofrece Progreso implican
              el tratamiento de datos de terceros, tales como la empresa a la
              cual el Usuario representa y sus representantes legales o
              apoderados,, incluyendo nombre, domicilio, teléfono, correo
              electrónico, Rol Único Tributario, información relativa a
              créditos, datos patrimoniales, datos de deuda vigente, motivo del
              crédito, tipo de negocio, datos de identificación y contacto,
              verificación de datos a través de redes sociales, claves de acceso
              que permitan acceder a fuentes de ingresos, egresos, declaraciones
              de pago de impuestos u otra información relevante para
              evaluaciones comerciales en el Servicio de Impuestos Internos (“
              SII”), Tesorería General de la República u otros organismos de
              similar naturaleza; los cuales, en caso de ser facilitados por el
              Usuario, se entenderán como Datos Personales a efectos de lo
              dispuesto en la presente Política de Privacidad.
            </div>
            <div style={{ textIndent: "15px" }}>
              Al proporcionar Datos Personales de terceros, el Usuario declara
              contar con la autorización necesaria para ello, habiéndole
              informado de las finalidades y de la forma en que dichos Datos
              Personales serán tratados. Adicionalmente, el Usuario, actuando
              por sí y/o por las personas que representa, otorga por este acto
              un mandato especial revocable en cualquier tiempo, para que
              Progreso o los Proveedores, según dicho término se define más
              adelante, y según fuere únicamente necesario a los fines que
              regula esta Política de Privacidad, acceda en su representación a
              las fuentes o bancos de Datos Personales en que éstos se
              contengan, incluyendo tanto organismos o entidades públicos y
              privados y especialmente pero no limitado ante el SII y la
              Tesorería General de la República, a efectos de revisar,
              corroborar, copiar o extraer Datos Personales. Bajo circunstancia
              alguna Progreso o los Proveedores podrán, en el uso del presente
              mandato, realizar operaciones, transacciones, declaraciones,
              rectificaciones ni actividad alguna distinta de aquellas señaladas
              precedentemente. Progreso se obliga a rendir cuenta de este
              mandato en cualquier momento que el Usuario lo solicite.
            </div>
            <div style={{ textIndent: "15px" }}>
              El Usuario es el responsable de mantener actualizados todos los
              Datos Personales que entregue a Progreso, para la debida
              prestación de los servicios que este ofrece. En consecuencia, el
              Usuario garantiza y responde de la veracidad, exactitud, vigencia
              y autenticidad de los Datos Personales facilitados, y se
              compromete a mantenerlos debidamente actualizados, comunicando a
              Progreso cualquier cambio al respecto. Progreso no recaba datos
              personales sensibles, que se refieren a las características
              físicas o morales de las personas o a hechos o circunstancias de
              su vida privada o intimidad, tales como los hábitos personales, el
              origen racial, las ideologías y opiniones políticas, las creencias
              o convicciones religiosas, los estados de salud físicos o
              psíquicos y la vida sexual.
            </div>
          </li>
          <li>
            <b>Uso de Datos</b>
            <div>
              <div style={{ textIndent: "15px" }}>
                Los Datos Personales recabados son utilizados para brindar los
                servicios que Progreso ofrece, incluyéndose especialmente, pero
                no limitado, a:
              </div>
              <ol type="i">
                <li>
                  Gestionar y ejecutar los contratos que el Usuario celebre con
                  o a través de Progreso, así como la administración, operación
                  y seguimiento a los servicios y productos contratados, y para
                  requerir el cumplimiento de acuerdos o contratos suscritos por
                  el Usuario con Progreso;
                </li>
                <li>
                  Responder a las consultas, reclamos, solicitudes del Usuario,
                  u otras acciones que requieran ser realizadas derivadas de la
                  solicitud y/o contratación de los servicios que Progreso
                  ofrece;
                </li>
                <li>
                  Verificar, confirmar y validar la identidad del Usuario y de
                  la persona jurídica a la que representa, asíì como la
                  veracidad de la información que proporciona, referencias,
                  obligados solidarios, avales, según resulte aplicable de
                  acuerdo al servicio contratado con Progreso; para lo cual
                  Progreso podrá realizar los análisis, reportes, videollamadas
                  y/o fotografías, realizar entrevistas presenciales o por
                  medios remotos, medios electrónicos o cualquier otra
                  tecnología;
                </li>
                <li>
                  Contactar al Usuario a través de nuestros canales de
                  comunicación tanto físicos como remotos, o enviar
                  comunicaciones a los datos de contacto que haya informado a
                  Progreso, con el objeto de hacer llegar información sobre los
                  servicios, sobre actualizaciones de los mismos o relacionada
                  con las finalidades descritas en esta Política de Privacidad;
                </li>
                <li>
                  Realizar acciones de marketing, de carácter general o
                  dirigidas personalmente al Usuario, y, así, mejorar los
                  servicios que Progreso ofrece y la experiencia del Usuario
                  como cliente; pudiendo analizar y elaborar estudios
                  estadísticos sobre los intereses y hábitos de compra e
                  inversión de los usuarios, necesidades de capital de trabajo
                  y, en términos generales, sobre materias relevantes para la
                  preparación de estudios de mercado o campañas de marketing
                  propias o de terceros; y enviar ofertas, promociones,
                  boletines y anuncios publicitarios de productos y servicios
                  que puedan ser de interés del Usuario;
                </li>
                <li>
                  Desarrollar inteligencia comercial, elaborar un perfil de
                  usuario, individual o agregado, aplicando o no modelos
                  predictivos, seguimiento de calidad de las plataformas que
                  Progreso pone a disposición de sus Usuarios, realizar
                  encuestas de satisfacción e investigaciones de mercado;
                </li>
                <li>
                  Completar automáticamente los documentos necesarios para
                  realizar transacciones en el{" "}
                  <a
                    href="https://www.progreso.cl/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    sitio web de Progreso
                  </a>
                  , su aplicación informática, u otras plataformas que este
                  habilite para prestar los servicios contratados;
                </li>
                <li>
                  Realizar campañas de actualización de Datos Personales, y
                  acciones de cobranza;
                </li>
                <li>
                  Ejecutar protocolos de seguridad, y para prevenir y detectar
                  usos engañosos, fraudulentos o criminales de nuestros sistemas
                  e instalaciones, lo cual es necesario para proteger los
                  intereses legítimos de Progreso y de sus clientes. III.
                  Transferencia de Datos Personales.
                </li>
              </ol>
            </div>
            <div style={{ textIndent: "15px" }}>
              Progreso podrá contratar a terceros proveedores de servicios tales
              como marketing; evaluación crediticia y comercial; investigaciones
              de fraude y recolección de pagos; recolección, procesamiento y
              disposición de información, entre otros (en adelante, los
              “Proveedores”) quienes desarrollarán tales tareas como encargados
              de Progreso, siguiendo sus instrucciones y de conformidad a un
              contrato vigente de prestación de servicios que este celebre con
              ellos.
            </div>
            <div style={{ textIndent: "15px" }}>
              Al aceptar la presente Política de Privacidad, el Usuario acepta
              que Progreso podrá compartir con los Proveedores los Datos
              Personales del Usuario y de las empresas a las que este
              represente, quienes podrán tratar tales datos para dar
              cumplimiento al encargo específico encomendado por Progreso;
              sometiéndose al efecto, a los términos de esta Política de
              Privacidad y a la Ley N.º 19.628 sobre Protección de la Vida
              Privada, adoptando medidas de seguridad y confidencialidad
              adecuadas para el tratamiento de los Datos Personales que el
              Usuario provea. Adicionalmente, el Usuario acepta que Progreso
              podrá compartir sus Datos Personales con terceros, incluidos
              entidades financieras, para la cesión o descuento de cartera de
              crédito, endoso o cualquier otra forma de negociación de la
              cartera crediticia; con sociedades controladoras de Progreso,
              subsidiarias o afiliadas bajo el control común, su sociedad matriz
              o a cualquier sociedad de su grupo corporativo que opere bajo los
              mismos procesos y políticas internas que Progreso; con otras
              empresas en caso de que Progreso sea adquirido, fusionado o
              cualquier otra operación similar por esa compañía; y con socios
              comerciales con quienes Progreso haya celebrado contratos para la
              comercialización de productos y/o servicios en beneficio de los
              clientes.
            </div>
            <div style={{ textIndent: "15px" }}>
              Lo anterior se entiende sin perjuicio de aquellos casos en que,
              por requerimiento de la autoridad competente, Progreso deba hacer
              entrega de tales datos de conformidad a la normativa aplicable; o
              de los casos de tratamiento de Datos Personales que de conformidad
              a la Ley N.º 19.628 sobre Protección de la Vida Privada, no
              requieran de autorización especial.
            </div>
            <div style={{ textIndent: "15px" }}>
              Progreso será Responsable del tratamiento de los Datos Personales
              que realicen los Proveedores u otros terceros, sin perjuicio de la
              responsabilidad que a cada uno de ellos corresponda por
              incumplimiento de sus obligaciones, de conformidad a la Ley N.º
              19.628 sobre Protección de la Vida Privada.
            </div>
          </li>
          <li>
            <b>Uso de cookies y tecnologías similares</b>
            <br />
            <div style={{ textIndent: "15px" }}>
              Progreso podrá utilizar cookies y tecnologías similares para
              personalizar y mejorar la experiencia del Usuario. Las cookies son
              archivos pequeños de texto que se almacenan en el computador o
              dispositivo móvil a través de los que el Usuario accede al sitio
              web o aplicación de Progreso, de manera que pueden ser reconocidos
              cada vez que el Usuario vuelva a visitar estos sitios o
              aplicaciones móviles, otorgando información sobre preferencias y
              pautas de navegación. El Usuario puede elegir deshabilitar en
              cualquier momento algunas o todas las cookies que Progreso pueda
              utilizar, para lo cual deberá consultar las instrucciones de su
              navegador. Sin embargo, esto podría restringir el uso del sitio
              web y aplicaciones móviles de Progreso, y limitar la experiencia
              del Usuario en los mismos ya que ciertas funcionalidades de estos
              sitios podrían no funcionar con cookies desactivadas.
            </div>
            <div style={{ textIndent: "15px" }}>
              Los medios electrónicos utilizados por Progreso pueden incluir
              enlaces a sitios web de terceros ajenos a ella, respecto de los
              cuales esta última no asume ninguna titularidad, obligación o
              responsabilidad alguna.
            </div>
          </li>
          <li>
            <b>Derechos del Usuario</b>
            <br />
            <div style={{ textIndent: "15px" }}>
              El Usuario tiene derecho a acceder a sus Datos Personales,
              información sobre su procedencia y destinatario, el propósito del
              almacenamiento y la individualización de las personas u organismos
              a los cuales sus datos son transmitidos regularmente; y en caso de
              que los Datos Personales recolectados sean erróneos, inexactos,
              equívocos o incompletos, y así se acredite, tendrá derecho a que
              se modifiquen. Adicionalmente, el Usuario tiene derecho a revocar
              en cualquier momento, aunque sin efecto retroactivo, la
              autorización que entrega a Progreso para el tratamiento de sus
              Datos Personales en conformidad a esta Política de Privacidad, en
              cuyo caso es posible que el Usuario no pueda acceder a alguno o
              todos los servicios que ofrece Progreso. Del mismo modo, el
              Usuario siempre podrá solicitar la suspensión de comunicaciones
              promocionales o publicitarias.
            </div>
            <div style={{ textIndent: "15px" }}>
              Para ejercer sus derechos de acceso, rectificación, cancelación y
              oposición, y todos los demás derechos que le confiera la Ley N.º
              19.628 sobre Protección de la Vida Privada, así como para
              solicitar la suspensión de comunicaciones promocionales o
              publicitarias; el Usuario deberá enviar un correo electrónico a
              <a href="mailto:privacidad@progreso.cl">privacidad@progreso.cl</a>
              , con indicación de su nombre, datos de contacto, e indicación del
              derecho o derechos que se desea ejercer. Progreso podrá solicitar
              información adicional a objeto de acreditar su identidad o en su
              caso, la representación legal que inviste. Progreso podrá negar el
              ejercicio de derechos antes señalados en los supuestos que la Ley
              N.º 19.628 sobre Protección de la Vida Privada lo permita.
            </div>
          </li>
          <li>
            <b>Responsabilidad del Usuario</b>
            <br />
            <div style={{ textIndent: "15px" }}>
              Progreso mantiene salvaguardias físicas, electrónicas y de
              procedimiento con el objeto de salvaguardar los Datos Personales
              del Usuario, y evitar el acceso no autorizado o ilegal, o la
              pérdida accidental, destrucción u ocurrencia de daños; y si bien
              no es posible garantizar lo anterior, los sistemas con los que
              cuenta Progreso han probado ser efectivos en el manejo de
              información reservada, impidiendo hackeos u otras amenazas
              externas. Sin perjuicio de lo antes señalado, se recomienda al
              Usuario no enviar datos desde sitios o equipos públicos o no
              seguros.{" "}
              <span style={{ fontWeight: 500 }}>
                Es exclusiva responsabilidad del Usuario la protección contra el
                acceso no autorizado a sus contraseñas, computadores y
                dispositivos móviles.
              </span>
            </div>
            <div style={{ textIndent: "15px" }}>
              Toda operación que se realice utilizando los datos de acceso y
              claves del Usuario en cualquiera de los canales de Progreso, será
              considerada realizada por el Usuario, salvo que previamente nos
              haya comunicado que su identificación, firma electrónica, cuenta
              de Usuario, contraseña, dispositivo móvil o cualquier otra medida
              de autenticación que se implemente para el uso de los servicios
              que Progreso ofrece, han sido extraviados o podrían estar siendo
              utilizados por un tercero no autorizado, en cuyo caso, activaremos
              los protocolos de seguridad correspondientes.
            </div>
          </li>
          <li>
            <b>Plazo de almacenamiento de Datos Personales</b>
            <br />
            <div style={{ textIndent: "15px" }}>
              Progreso mantendrá los Datos Personales que los Usuarios le
              proporcionen mientras sean necesarios para la finalidad para las
              cuales se recolectaron, salvo aquellos que por disposiciones
              legales o regulatorias deban ser mantenidos por períodos de tiempo
              que van más allá de este término, en cuyo caso se mantendrán por
              el periodo que indique esa normativa.
            </div>
          </li>
          <li>
            <b>Vigencia de la Política de Privacidad</b>
            <br />
            <div style={{ textIndent: "15px" }}>
              La presente Política de Privacidad tiene duración indefinida. Sin
              embargo, Progreso podrá hacer modificaciones, cambios y/o
              actualizaciones a esta Política de Privacidad que sean necesarias
              para atender disposiciones legales aplicables o por otras causas,
              lo cual será informado al Usuario mediante publicación en las
              Plataformas Progreso, o incluso a la dirección de correo
              electrónico informada por el Usuario cuando el cambio en cuestión
              sea significativo.
              <span style={{ fontWeight: 500 }}>
                {" "}
                Es responsabilidad del Usuario la lectura y acatamiento de la
                Política de Privacidad, así como de sus actualizaciones, una vez
                que estas sean informadas de conformidad a lo antes expuesto.
              </span>
            </div>
          </li>
          <li>
            <b>Vigencia de la Política de Privacidad</b>
            <br />
            <div style={{ textIndent: "15px" }}>
              En este acto, el Usuario acepta y reconoce haber revisado
              detenidamente la Política de Privacidad de Progreso y declara que
              al presionar el botón de “Aceptar” siguiente, acepta expresa,
              inequívoca e irrevocablemente las estipulaciones de la misma.
            </div>
            <div style={{ textIndent: "15px" }}>
              En razón de lo anterior, el Usuario autoriza expresamente a
              Progreso a tratar los Datos Personales que proporcione, por
              cualquiera de sus canales de comunicación o venta, tanto
              presenciales como remotos. Asimismo, y siempre que no existan
              restricciones legales para ello, el Usuario autoriza a Progreso a
              compartir sus Datos Personales, de conformidad a lo indicado en la
              Sección III anterior.
            </div>
          </li>
        </ol>
      </p>
    </div>
  );
};

export const TerminosyCondiciones = () => {
  const { MAXIMO_FACTURAS, MINIMO_FACTURAS } = JSON.parse(
    sessionStorage.getItem("parametros_comerciales")
  );
  return (
    <div>
      <p>
        Lee detenidamente los Términos y Condiciones del servicio de
        Autoatención, antes de concluir el proceso a través de la plataforma. El
        envío del presente formulario por parte del Usuario supone expresamente
        la aceptación irrestricta e inapelable de estos Términos y Condiciones y
        de la Política de Privacidad de Progreso disponible en{" "}
        <a
          href="https://www.progreso.cl/compania/politica-privacidad/"
          target="_blank"
          rel="noreferrer"
        >
          https://www.progreso.cl/compania/politica-privacidad/
        </a>
        ; no pudiendo el Usuario alegar falta de conocimiento de los requisitos,
        condiciones, limitaciones y restricciones que constan en los mismos.
      </p>
      <p>
        <ol
          type="I"
          style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
        >
          <li>
            <b>CONSIDERACIONES PREVIAS</b>
            <br /> Los Servicios ofrecidos por{" "}
            <span style={{ fontWeight: 500 }}>
              SERVICIOS FINANCIEROS PROGRESO S.A.
            </span>
            , sociedad anónima constituida bajo las leyes de la República de
            Chile, Rol Único Tributario N.º 90.146.000-0 (en adelante,
            “Progreso”), rigen únicamente para el territorio nacional de la
            República de Chile y están sujetos a la normativa chilena aplicable
            y a los términos y condiciones comerciales establecidas por
            Progreso, para cada servicio. -
          </li>
          <li>
            <b>REQUISITOS DEL SERVICIO DE AUTOATENCIÓN</b>
            <br />
            Son requisitos indispensables del servicio de Autoatención, los
            siguientes:
            <div>
              <ol type="i">
                <li>
                  En caso de ser persona natural, ser mayor de 18 años y
                  legalmente capaz para contratar conforme a las leyes de Chile.
                  En caso de personas jurídicas, encontrarse legalmente
                  constituida y vigente conforme a las leyes de Chile.
                </li>
                <li>
                  Tener domicilio en Chile y contar con una cédula de identidad
                  o rol único tributario válido, según corresponda.
                </li>
                <li>
                  Mantener vigente con Progreso el respectivo{" "}
                  <span style={{ fontWeight: 500 }}>
                    CONTRATO DE FACTORING Y MANDATOS
                  </span>
                  , también denominado como “Contrato Marco”, donde se regula la
                  forma en que el cliente venderá, cederá y transferirá a
                  Progreso las facturas y documentos que las partes convengan.
                  En caso de no haber suscrito previamente el Contrato Marco
                  antes referido, uno de nuestros ejecutivos se pondrá en
                  contacto con el Usuario a efectos de cumplir con tal
                  requisito, previo a cursar cualquier operación en la
                  plataforma.
                </li>
                <li>
                  Leer y aceptar los Términos y Condiciones de la plataforma de
                  Autoatención, y sus modificaciones, según corresponda, así
                  como la Política de Privacidad de Progreso, y cumplir con lo
                  dispuesto en ellos. -
                </li>
              </ol>
            </div>
          </li>
          <li>
            <b>
              CESIÓN DE FACTURA Y RESPONSABILIDAD DEL USUARIO POR LOS CRÉDITOS
              CEDIDOS.
            </b>
            <br />
            <div style={{ textIndent: "15px" }}>
              <span style={{ fontWeight: 500 }}>
                PROGRESO PODRÁ DETERMINAR LIBREMENTE LAS FACTURAS QUE ADQUIRIRÁ,
                RESERVÁNDOSE EL DERECHO DE ADQUIRIR O NO, SIN EXPRESIÓN DE
                CAUSA, LAS FACTURAS QUE EL USUARIO REGISTRE EN LA PLATAFORMA DE
                AUTOATENCIÓN
              </span>
              , registro que se entiende como una oferta de cesión a Progreso,
              para que éste evalúe y eventualmente las adquiera. La sola
              recepción en la plataforma de la información de los créditos y las
              facturas en que constan, sea que incluya o no la documentación que
              lo sustente, no implica aceptación y/o compromiso para adquirir
              dichos créditos por parte de Progreso, lo que deberá en todo caso
              someterse a la correspondiente evaluación y a los Términos y
              Condiciones aplicables.
            </div>
            <div style={{ textIndent: "15px" }}>
              <span style={{ fontWeight: 500 }}>
                LA ADQUISICIÓN EFECTIVA DE CADA UNA DE LAS FACTURAS POR PARTE DE
                PROGRESO QUEDA SUJETA A SU CONFIRMACIÓN COMERCIAL
              </span>
              , esto es, a la verificación que el crédito cedido realmente
              exista y sea íntegramente exigible al deudor, así como al
              cumplimiento de las demás condiciones de cesión contenidas en el
              Contrato Marco correspondiente y en las políticas comerciales y de
              riesgo de Progreso. Al ceder las facturas, el Usuario declara que
              las siguientes condiciones de la cesión fueron las tenidas a la
              vista y aceptadas al momento de cederlas: que la cesión se
              encuentra regulada por los presentes Términos y Condiciones, el
              respectivo Contrato Marco suscrito entre el Usuario y Progreso,
              por las disposiciones de la Ley N.º 19.983 y demás disposiciones
              pertinentes de la legislación chilena.
            </div>
            <div style={{ textIndent: "15px" }}>
              <span style={{ fontWeight: 500 }}>
                CADA CESIÓN QUE SUSCRIBA O CELEBRE EL USUARIO Y PROGRESO, SERÁN
                EFECTUADOS BAJO RESPONSABILIDAD DEL USUARIO CEDENTE.
              </span>{" "}
              Conforme lo anterior, el Usuario se hace responsable de la
              existencia del crédito, del hecho que realmente le pertenecía y
              del pago íntegro y oportuno de todos y cada uno de los créditos
              que, en cumplimiento de los presentes Términos y Condiciones y del
              Contrato Marco; venda, ceda, endose y transfiera a Progreso, así
              como de la solvencia presente y futura de todos y cada uno de los
              deudores de dichos créditos hasta su completo e íntegro pago, y
              por el valor de ellos, esto es, la responsabilidad del cedente
              comprenderá siempre el cien por ciento del valor del documento a
              descontar más los reajustes, multas e intereses correspondientes,
              respondiendo hasta la culpa leve ante Progreso, por el pago total
              y oportuno de todas y cada una de las facturas u otros documentos
              mercantiles que se cedan o endosen a Progreso por los contratos de
              cesión o endoso respectivo.
            </div>
            <div style={{ textIndent: "15px" }}>
              <span style={{ fontWeight: 500 }}>
                LA CESIÓN DE LAS FACTURAS ES REALIZADA DIRECTAMENTE POR EL
                PROPIO USUARIO, CON SU CERTIFICADO DIGITAL.
              </span>{" "}
              Al efecto, Progreso sólo facilita su plataforma para redireccionar
              al trámite correspondiente. Progreso no registra ni almacena en
              forma alguna el certificado digital utilizado al efecto. La
              gestión que haga el Usuario con su certificado digital, es ajena a
              Progreso y de exclusiva responsabilidad del Usuario. Conforme con
              lo anterior, Progreso no tiene relación alguna con el certificado
              digital utilizado por el Usuario, ni con la correcta cesión de la
              factura efectuada por este. En el evento que el Usuario desee
              suspender o evitar la compra de una o más facturas registradas en
              la plataforma de Autoatención, deberá solicitar tal circunstancia
              por escrito a Progreso, sin ulterior responsabilidad para éste, el
              cual cumplirá dicho cometido única y exclusivamente si a la fecha
              de recepción de la solicitud correspondiente, no hubiese aceptado
              la cesión de la factura y/o crédito correspondiente. Aceptada por
              Progreso la adquisición de uno o más créditos representados en
              facturas incluidas en la plataforma de Autoatención, éstas no
              podrán ser objetadas por el Usuario.
            </div>
            <div style={{ textIndent: "15px" }}>
              <span style={{ fontWeight: 500 }}>
                EL USUARIO AUTORIZA A PROGRESO PARA QUE HAGA USO DE TODA LA
                INFORMACIÓN QUE LE ENTREGUE A TRAVÉS DE LA PLATAFORMA DE
                AUTOATENCIÓN, A FIN DE CUMPLIR CON LA OPERACIÓN DE FACTORING.
              </span>
            </div>
          </li>
          <li>
            <b>USO DE LA PLATAFORMA</b>
            <br /> Dado que el sistema de Autoatención opera sobre una
            infraestructura electrónica de acceso remoto vía internet, su acceso
            permanente no puede ser garantizado. Por su naturaleza puede verse
            expuesto a problemas técnicos que afecten su normal ejecución, por
            consiguiente, Progreso no asume obligación ni responsabilidad alguna
            por cualquier interrupción o desperfecto, problemas de conexión,
            falta de cobertura o intermitencia que no sea imputable a Progreso.
            Progreso podrá interrumpir o suspender temporalmente el acceso a la
            plataforma cuando deban efectuarse mantenciones a los sistemas en
            los que se ejecuta o respalda.
          </li>
          <li>
            <b>LIMITES</b>
            <br />
            Podremos establecer límites máximos y mínimos de las facturas que
            pueden ser objeto de cesión mediante el procedimiento de
            Autoatención, del número de operaciones a efectuar diariamente, u
            otras condiciones y requisitos, los que serán oportunamente
            informados y que están sujetos a cambios según las políticas de
            vigentes en Progreso. En caso de no cumplir con los requisitos
            establecidos, recibirás un aviso y un ejecutivo de Progreso se
            pondrá en contacto con el Usuario para evaluar el caso. Son limites
            vigentes:{" "}
            <b>
              <NumberFormat
                value={MINIMO_FACTURAS || ""}
                displayType={"text"}
                prefix={"$"}
                thousandSeparator={"."}
                decimalScale={0}
                decimalSeparator={","}
              />
              {" - "}
              <NumberFormat
                value={MAXIMO_FACTURAS || ""}
                displayType={"text"}
                prefix={"$"}
                thousandSeparator={"."}
                decimalScale={0}
                decimalSeparator={","}
              />
            </b>
          </li>
          <li>
            <b>RESPONSABILIDAD DEL USUARIO</b>
            <br /> El Usuario asume la obligación y declara no efectuar
            operaciones en el sitio web de Progreso o en los sitios a los que
            acceda a través del mismo, que tengan por finalidad, o cuyo
            resultado sea, infringir cualquier ley de la República de Chile o de
            alguna jurisdicción, incurrir en fraudes, prácticas o transacciones
            engañosas, fraudulentas o contrarias a las sanas prácticas
            comerciales, la moral y buenas costumbres generalmente aceptadas y
            las normas de orden público. El Usuario asume como propia la
            responsabilidad que pudiere derivarse de sus propios actos u
            omisiones, eximiendo a Progreso de toda responsabilidad ante
            cualquier reclamo, daño, perjuicio, pérdida, penalización y costo, y
            de cualquier gasto derivado de o relacionado con infracciones a
            estos Términos y Condiciones. Mediante el envío del formulario y
            consecuente aceptación de los Términos y Condiciones, el Usuario
            declara que mantendrá indemne a Progreso, sus personas relacionadas,
            directores, socios, trabajadores, asesores, abogados y agentes
            frente a cualquier reclamo derivado del incumplimiento por parte del
            Usuario de cualquier disposición contenida en los Términos y
            Condiciones y/o de cualquier ley vigente y aplicable, o del
            incumplimiento o violación de los derechos de terceros.
          </li>
          <li>
            <b>RESERVA DE DERECHOS</b>
            <br /> Progreso se reserva el derecho de suspender, temporal y/o
            definitivamente, a aquel Usuario que considere no cumple los
            requisitos mínimos de los servicios prestados, cuyos datos no hayan
            podido ser confirmados, detecte inconsistencias en su registro o
            existan indicios de actividades sospechosas o contrarias a estos
            Términos y Condiciones, a las leyes de Chile o de cualquier otra
            jurisdicción, tales como fraudes o estafas, entrega de información
            falsa, falta de autorizaciones necesarias, o por otros motivos de
            seguridad que se presenten, sin que ello genere derecho a reclamo
            alguno de dicha persona.-
          </li>
          <li>
            <b>VIGENCIA Y TERMINACIÓN ANTICIPADA</b>
            <br /> El contrato que surge entre Progreso y el Usuario, una vez
            aceptados estos Términos y Condiciones, tendrá vigencia indefinida,
            salvo que cualquiera de las partes ponga en conocimiento de la otra
            su intención de no renovarlo, de conformidad a lo señalado en esta
            sección. El Usuario podrá poner término al contrato en cualquier
            momento y sin expresión de causa, dando aviso a Progreso a través de
            los canales habilitados en nuestro sitio web // al correo{" "}
            <a href="mailto:privacidad@progreso.cl">privacidad@progreso.cl</a>.
            Para ello, será necesario que el Usuario no mantenga obligaciones
            pendientes con Progreso en relación a las transacciones que efectúe
            a través de sus servicios y plataformas, o que tengan cualquier otra
            causa u origen. Por su parte, Progreso se reserva el derecho a poner
            fin anticipado al contrato, de manera unilateral, en cualquier
            momento, si el Usuario:
            <ol type="i">
              <li>
                No diera cumplimiento íntegro y oportuno a todas y cada una de
                las obligaciones para con Progreso, reservándose Progreso el
                derecho de reclamar los daños y perjuicios que tal
                incumplimiento le haya causado, sin perjuicio de ejercer las
                acciones legales que correspondan.
              </li>
              <li>
                Incurre en conductas que sean constitutivas de agravio, malos
                tratos, violencia o en cualquier otra forma produzcan una
                afectación a la integridad física o psíquica a cualquier persona
                que dependa o preste servicios a Progreso.
              </li>
              <li>
                Progreso toma conocimiento del fallecimiento o interdicción del
                Usuario, sin perjuicio de los derechos que le asisten a los
                herederos.
              </li>
              <li>
                Se verifica cualquier otra causal de término que los contratos
                suscritos entre las partes, la normativa legal y reglamentaria
                vigente, contemplen. La terminación anticipada a solicitud del
                Usuario según los presentes Términos y Condiciones, no implicará
                la terminación de cualquier otro contrato suscrito entre las
                partes, especialmente pero no limitado al Contrato Marco y los
                Mandatos que conforme al mismo hayan suscrito el Usuario y
                Progreso, los que se mantendrán vigentes mientras no corresponda
                su terminación conforme sus propias normas particulares. No
                obstante, la terminación o pérdida de vigencia del Contrato
                Marco suscrito entre el Usuario y Progreso significará la
                terminación automática de los servicios de Autoatención que aquí
                se acuerdan. -
              </li>
            </ol>
          </li>
          <li>
            <b>COMUNICACIONES Y DOMICILIOS</b>
            <br /> A efectos del uso de la plataforma de Autoatención, Progreso
            podrá realizar notificaciones al Usuario a través de una
            notificación general en el sitio web de Progreso, a través de un
            mensaje de texto o a la dirección de correo electrónico registrada
            por el Usuario. En todo caso, el Usuario podrá en todo momento
            solicitar a Progreso el no envío de notificaciones, para lo cual
            deberá enviar un correo electrónico a la dirección{" "}
            <a href="mailto:privacidad@progreso.cl">privacidad@progreso.cl</a>.
            El domicilio del Usuario que haya sido proporcionado en su
            registro, se entenderá el aplicable para todos los efectos que
            legales derivados de estos Términos y Condiciones, sin perjuicio de
            que podrá ser actualizado a través del correo electrónico{" "}
            <a href="mailto:privacidad@progreso.cl">privacidad@progreso.cl</a>,
            debiendo tratarse siempre de un domicilio dentro del territorio
            nacional. En caso de cualquier duda o problema en relación a la
            prestación de los servicios o a estos Términos y Condiciones, el
            Usuario podrá comunicarse con Progreso por medio de nuestro canal de
            atención al cliente al correo electrónico{" "}
            <a href="mailto:privacidad@progreso.cl">privacidad@progreso.cl</a>.
            No obstante, lo anterior, cualquier notificación que deba ser
            practicada a Progreso, deberá realizarse a Calle Miraflores N.º 222,
            piso 25 Edificio Las Américas, Santiago de Chile; salvo que, con
            posterioridad, se notifique el cambio de domicilio en el sitio web
            de Progreso.
          </li>
          <li>
            <b>JURISDICCIÓN Y LEY APLICABLE</b>
            <br /> Estos Términos y Condiciones se regirán por las leyes
            vigentes en la República de Chile. Cualquier controversia derivada
            del presente acuerdo, su existencia, validez, interpretación,
            alcance o cumplimiento, quedará sujeta a lo que al respecto se
            disponga en el Contrato Marco suscrito entre el Usuario y Progreso.
            <span style={{ fontWeight: 500 }}>
              EN TODO LO NO REGULADO POR LOS PRESENTES TÉRMINOS Y CONDICIONES,
              SE ESTARÁ A LO DISPUESTO EN EL RESPECTIVO CONTRATO MARCO SUSCRITO
              ENTRE EL USUARIO Y PROGRESO, Y A LA REGULACIÓN APLICABLE. EN
              RELACIÓN A CADA UNA DE LAS CESIONES QUE SE CELEBREN, LOS PRESENTES
              TÉRMINOS Y CONDICIONES Y EL RESPECTIVO CONTRATO MARCO SE
              ENTENDERÁN FORMAR PARTE INTEGRANTE DE TODAS ELLAS.
            </span>{" "}
            En caso de producirse cualquier discrepancia entre los documentos
            antes señalados, para efectos de la interpretación y/o aplicación
            del servicio de Autoatención primará lo dispuesto en estos Términos
            y Condiciones.
          </li>
        </ol>
      </p>
    </div>
  );
};
