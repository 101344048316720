import React, { useEffect } from "react";
import FooterRegistro from "../../components/footer/FooterRegistro";
import HeaderClientes from "../../components/header-clientes/HeaderClientes";
import SeccionClientes from "../../components/seccion-clientes/seccion-clientes";
import SeccionClientes2 from "../../components/seccion-clientes2/seccion-clientes2";
import SeccionClientes3 from "../../components/seccion-clientes3/seccion-clientes3";
import ClienteRegistro from "../../components/clientes/ClienteRegistro";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const Clientes = () => {
  useEffect(() => { }, []);
  return (
    <>
      <HeaderClientes />
      <SeccionClientes>
      <GoogleReCaptchaProvider
        reCaptchaKey={window.RECAPTCHA_KEY}
        scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: 'head', // optional, default to "head", can be "head" or "body",
        nonce: undefined // optional, default undefined
      }}>
        <ClienteRegistro></ClienteRegistro>
      </GoogleReCaptchaProvider>
      </SeccionClientes>
      <SeccionClientes2 />
      <SeccionClientes3 />

      <FooterRegistro margin={false} />
    </>
  );
};
export default Clientes;
