import React, { useContext, useState } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import Logo from '../../assets/images/logo.png';
import { useHistory } from "react-router-dom";

import Button from '../../components/buttons/Button';
import { ThemeContext } from '../../theming';
import { ModalSuccess } from '../../components/modal/Modal';
import { Email } from '../../assets/icons/login';
import { Error, Confirmado } from '../../assets/icons/dashboard';
import { TextColored, Text } from '../../components/typography';
import { api } from "../../helpers/api";
import { useForm } from "react-hook-form";
import { RECUPERAR_CONTRASENIA } from '../../helpers/url';

const SolicitarContrasenia = () => {

	const app = useContext(ThemeContext);
	const { body25, body28, body32 } = app.theme.font;

	/* Error modal*/
	const [modalData, setModalData] = useState({});
	const [showModalError, setShowModalError] = useState(false);
	const handleCloseModalError = () => setShowModalError(false)
	const handleShowModalError = () => {
		setShowModalError(true)
	}

	const history = useHistory();

	const { register, handleSubmit } = useForm();

	const container = {
		width: '450px',
		height: '100%',
		background: app.theme.secondary,
		padding: '20px',
	};

	const containerMobile = {
		width: '450px',
		height: '450px',
		background: app.theme.secondary,
		marginLeft: '10px',
		marginRight: '10px'
		// padding: '20px',
	};

	const image = {
		padding: '15px',
		borderBottom: `2px solid ${app.theme.primary}`,
	};

	const container_input = {
		borderBottom: '1px solid #b9b5b5'
	};

	const enviar = (data) => {

		if (data.email.trim() === '') {
			setModalData({
				title: 'Recupera tu contraseña',
				subtitle: 'Error',
				color: 'red',
				message: 'Debe especificar su correo.',
				icon: (<>
					<Error /></>)
			});
			handleShowModalError();
			return;
		}

		let url = RECUPERAR_CONTRASENIA.replace('{correo}', data.email);

		api.get(url).then((res) => {
			setModalData({
				title: 'Recupera tu contraseña',
				subtitle: 'Confirmado',
				color: 'yellow',
				button: (
					<Button onClick={() => history.push('/')
					}>Regresar a inicio de sesión.</Button>
				),
				message: 'Su solicitud de recuperación de contraseña fue enviada a su correo.',
				icon: (<>
					<Confirmado /></>)
			});
			handleShowModalError();


		}, (error) => {
			setModalData({
				title: 'Recupera tu contraseña',
				subtitle: 'Error',
				color: 'red',
				message: 'No se pudo solicitar tu recuperación tu contraseña.',
				icon: (<>
					<Error /></>)
			});
			handleShowModalError();
		});
	};

	return (
		<div className='login'>
			<Container style={container} fluid>
				<Row className='w-100 justify-content-center'>
					<img style={image} src={Logo} alt='Logo Progreso' />
				</Row>
				<Row className='mt-4'>
					<Col className='text-center' sm={12}>
						<Text size={body25} className='d-block' type='secondary'>
							Recupera tu contraseña
						</Text>
					</Col>
				</Row>

				<Form>
					<Form.Group className='justify-content-center mt-4' as={Row} controlId='formUser'>
						<Col xs={10} lg={10}>

							<div style={container_input} className='input-container' >
								<Email />
								<input name="email"
									autoComplete="off"
									className={`input-field input-top`}
									type='text' placeholder='Email'
									ref={register()} />
							</div>

						</Col>
					</Form.Group>
					<Form.Group className='justify-content-center mt-4' as={Row} controlId='formUser'>
						<Col xs={12} lg={6}>
							<Button onClick={handleSubmit(enviar)}>Enviar</Button>
						</Col>
					</Form.Group>
				</Form>

				<ModalSuccess
					show={showModalError}
					icon={modalData.icon}
					title={
						<TextColored size={body28} type={modalData.color}>
							{modalData.title}
						</TextColored>
					}
					subtitle={
						<TextColored size={body32} bold type={modalData.color}>
							{modalData.subtitle}
						</TextColored>
					}
					message={
						<>
							<Text type='primary' size={body25}>
								{modalData.message}
							</Text>
							{modalData.button}
						</>
					}
					handleClose={() => handleCloseModalError()}
				/>

			</Container>
		</div >
	);

};

export default SolicitarContrasenia;
