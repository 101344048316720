//Librerias
import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";

//Assets
import { BiHelpCircle } from "react-icons/bi";

//Helpers
import { getResume } from "../../../helpers/autoAtencionHelpers";

//Componentes
import { Table } from "react-bootstrap";
import ModalDesglose from "../../modal/modal-autoatencion/ModalDesglose";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import IconButton from "../../buttons/IconButton";
import { AiOutlineCloudSync } from "react-icons/ai";

const Resumen = ({ text = "Resumen", loading = false, reload = null }) => {
  const bills = JSON.parse(sessionStorage.getItem("bills"));
  const [amountsObj, setAmountsObj] = useState({});
  const [show, setShow] = useState(false);

  const { ANTICIPO } = JSON.parse(
    sessionStorage.getItem("parametros_comerciales")
  );

  const { anticipo } = JSON.parse(
    sessionStorage.getItem("parametros_indicadores")
  );

  const onClick = async () => {
    try {
      await reload();
      setAmountsObj(getResume());
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setAmountsObj(getResume());
  }, []);

  const Formatt = ({ total }) => {
    return (
      <NumberFormat
        value={total}
        displayType={"text"}
        prefix={"$"}
        thousandSeparator={"."}
        decimalScale={0}
        decimalSeparator={","}
      />
    );
  };

  return (
    <>
      <ModalDesglose show={show} setShow={setShow} />
      <Table style={{ height: "293px" }}>
        <SkeletonTheme width="100px" height="20px">
          <thead
            style={{
              backgroundColor: "#eceaea",
              textAlign: "center",
            }}
          >
            <tr>
              <th colSpan={2}>
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ gap: "5px" }}
                >
                  {text}
                  {reload ? (
                    <IconButton
                      Icon={
                        <AiOutlineCloudSync
                          size={25}
                          title="Recarga tus parametros comerciales."
                        />
                      }
                      onClick={onClick}
                    />
                  ) : null}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {bills.length > 1 ? "Monto documentos" : "Monto documento"}:
              </td>
              <td>
                {!loading ? <Formatt total={amountsObj.total} /> : <Skeleton />}
              </td>
            </tr>
            <tr>
              <td className="d-flex align-items-center">
                Monto no financiado
                <span
                  style={{
                    fontSize: "0.8rem",
                    fontWeight: "600",
                    marginLeft: "0.1rem",
                  }}
                >
                  ({100 - (anticipo || ANTICIPO)}%)
                </span>
                :
              </td>
              <td>
                {!loading ? (
                  <Formatt total={amountsObj.nonFinanced} />
                ) : (
                  <Skeleton />
                )}
              </td>
            </tr>
            <tr>
              <td className="d-flex align-items-center">
                Monto anticipado{" "}
                <span
                  style={{
                    fontSize: "0.8rem",
                    fontWeight: "600",
                    marginLeft: "0.1rem",
                  }}
                >
                  ({anticipo || ANTICIPO}%)
                </span>
                :
              </td>
              <td>
                {!loading ? (
                  <Formatt total={amountsObj.financed} />
                ) : (
                  <Skeleton />
                )}
              </td>
            </tr>
            <tr>
              <td>
                <div
                  className="d-flex align-items-center"
                  style={{ gap: "5px" }}
                >
                  Costo Total:
                  <IconButton
                    Icon={
                      <BiHelpCircle size="20px" title="Ver mas detalles." />
                    }
                    onClick={() => setShow(true)}
                    disabled={loading}
                  />
                </div>
              </td>
              <td>
                {!loading ? <Formatt total={amountsObj.costs} /> : <Skeleton />}
              </td>
            </tr>
            <tr>
              <td>Líquido a pagar:</td>
              <td>
                {!loading ? (
                  <Formatt total={amountsObj.liquid} />
                ) : (
                  <Skeleton baseColor="#a3a3a324" highlightColor="#ccc7c782" />
                )}
              </td>
            </tr>
          </tbody>
        </SkeletonTheme>
      </Table>
    </>
  );
};

export default Resumen;
