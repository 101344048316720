import React from "react";
import { ProgressBar } from "react-bootstrap";
import Step from "./Step";

const Stepper = ({ steps, currentStep, width }) => {
  return (
    <div
      className="d-flex align-items-center w-100"
      style={{
        gap: "3px",
        overflowX: `${width <= 702 ? "auto" : "initial"}`,
        maxWidth: width > 702 ? width * (70 / 100) + "px" : "auto",
      }}
    >
      {steps.map((s, index) => {
        if (index === steps.length - 1) {
          return (
            <Step
              name={s.name}
              icon={s.icon}
              selected={currentStep === index + 1}
              passed={currentStep > index + 1 ? true : false}
              key={`step-${index + 1}`}
              width={width}
            />
          );
        } else {
          return (
            <React.Fragment key={index + 1}>
              <Step
                name={s.name}
                icon={s.icon}
                selected={currentStep === index + 1}
                passed={currentStep > index + 1 ? true : false}
                key={`step-${index + 1}`}
                width={width}
              />
              <ProgressBar
                style={{ flexGrow: 1, height: "3px", minWidth: "1rem" }}
                now={currentStep > index + 1 ? 100 : 0}
                variant="yellow"
                key={`linea-${index + 1}`}
              />
            </React.Fragment>
          );
        }
      })}
    </div>
  );
};

export default Stepper;
