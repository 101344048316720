import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import {
  formatCurrency,
  getResume,
} from "../../../helpers/autoAtencionHelpers";
import CustomButton from "../../buttons/Button";
import { TextColored } from "../../typography";
import "./styles.css";

const ParameterItem = ({ name, value }) => {
  return (
    <div
      style={{
        width: "49%",
        border: "1px solid rgba(0,0,0,.125)",
        borderLeft: "5px solid #F7CF46",
        borderRadius: "5px",
        padding: "5px",
      }}
      className="d-flex flex-column"
    >
      <TextColored children={value} size={1.2} bold type="dark" />
      <TextColored children={name} />
    </div>
  );
};

const ModalDesglose = ({ show, setShow }) => {
  const {
    costs,
    desglocedCosts: {
      IVA,
      commission,
      priceDiference,
      operationalExpenses,
      deudorNotification,
      LBTR,
    },
  } = getResume();

  const { IVA: IVA_PRC } = JSON.parse(
    sessionStorage.getItem("parametros_comerciales")
  );

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header className="costs-header">
        <div className="d-flex justify-content-center w-100">
          <TextColored children="Costos" type="dark" size={2.5} weight={600} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <>
          <div style={{ textAlign: "center" }} className="d-flex flex-column">
            <TextColored
              children={formatCurrency(costs)}
              type="dark"
              size={2.3}
              weight={500}
            />
            <TextColored children="Total" size={1.2} />
          </div>
          <TextColored children="Desglose" type="dark" size={1} weight={500} />
          <div
            className="d-flex flex-wrap justify-content-between"
            style={{ gap: "5px" }}
          >
            <ParameterItem
              name={`Diferencia de precio`}
              value={formatCurrency(priceDiference)}
            />
            <ParameterItem
              name={`Comisión`}
              value={formatCurrency(commission)}
            />
            <ParameterItem
              name={`IVA (${IVA_PRC}%)`}
              value={formatCurrency(IVA)}
            />
            <ParameterItem
              name="Gasto operacional"
              value={formatCurrency(operationalExpenses)}
            />
            <ParameterItem
              name="Notificacion notaria por deudor"
              value={formatCurrency(deudorNotification)}
            />
            <ParameterItem name="LBTR" value={formatCurrency(LBTR)} />
          </div>
          <br />
        </>

        <Row className="w-100 justify-content-center m-auto">
          <Col md={5} sm={5} xs={5}>
            <CustomButton
              children={"Entendido"}
              onClick={() => setShow(false)}
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ModalDesglose;
