import React, { useState, useEffect } from "react";
import Logo from "../../assets/images/logo2.png";
import { TextColored } from "../typography";
import { ProgressBar, Spinner } from "react-bootstrap";

import "./loading.css";

const LoadingScreen = ({
  text,
  progress,
  textFinished,
  noProgress = false,
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => setShow(true), 100);
  }, []);

  const classes = show ? "loading-screen show" : "loading-screen";

  return (
    <div className={classes}>
      <div
        className="d-flex flex-column align-items-center justify-content-center"
        style={{ width: "100%", minHeight: "100%", gap: "2%" }}
      >
        <img src={Logo} alt="logo-progreso" width="auto" />
        <div className="text-center">
          <TextColored
            children={progress === 100 ? textFinished : text}
            type="primary"
            size={2}
          />
          <br />
          <TextColored
            children="Este proceso podría tardar algunos minutos. Por favor no cierre esta página."
            type="textLight"
            size={1.2}
          />
        </div>
        {!noProgress ? (
          <div style={{ width: "35%", minWidth: "600px" }}>
            <ProgressBar animated variant="yellow" now={progress} />
          </div>
        ) : (
          <Spinner animation="border" variant="light" />
        )}
      </div>
    </div>
  );
};

export default LoadingScreen;
