import React, { useContext, useState, useEffect } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Error, Confirmado } from "../../assets/icons/dashboard";
import Logo2 from "../../assets/images/logo2.png";
import Pipe2 from "../../assets/images/pipe2.png";
import { api } from "../../helpers/api";
import { PORTALSAPI } from "../../helpers/url";
// import useWindowDimensions from "../../helpers/useWindowDimensions";
import { ThemeContext } from "../../theming";
import CustomButtonWithoutHover from "../buttons/ButtonWithoutHover";
import Input from "../inputs/Input";
import { ModalSuccess } from "../modal/Modal";
import { Text, TextColored, TextGeneric } from "../typography";
import Button from "../../components/buttons/Button";
import Loading from "../../components/loading/Loading";
import useQueryParam from "../../helpers/useQueryParam";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { formatRut, validaRut } from "../../helpers/functions";

function ClienteCambioPassword() {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const app = useContext(ThemeContext);
  const { body25, body28, body32 } = app.theme.font;
  // const { width } = useWindowDimensions();
  const [rut] = useQueryParam("rut", "");
  const container = {
    width: "450px",
    height: "100%",
    background: "white",
    // padding: '20px'
  };
  /* Error modal*/
  const [modalData, setModalData] = useState({});
  const [showModalError, setShowModalError] = useState(false);
  const handleCloseModalError = () => setShowModalError(false);
  const handleShowModalError = () => {
    setShowModalError(true);
  };
  const [errors, setErrors] = useState(false);

  const history = useHistory();

  const [RUT, setRUT] = useState("");
  const [showLoading, setShowLoading] = useState({ display: "none" });

  // const containerMobile = {
  //   width: "100%",
  //   height: width >= 375 ? "100%" : "",
  //   background: "white",
  //   // padding: '20px',
  // };

  const buttonLoginStyle = {
    marginTop: "10px",
  };

  useEffect(() => {
    setRUT(rut);
  }, [rut]);

  useEffect(() => {
    sessionStorage.removeItem("unnaproved-invoice");
  }, []);

  const restaurar = async () => {
    setShowLoading({ display: "block" });
    try {
      let captchaToken = await executeRecaptcha();
      api
        .get(PORTALSAPI.GET.OBTENER_TOKEN)
        .then((responseToken) => {
          let WithoutPoints = RUT.replace(/\./g, "");
          let url = PORTALSAPI.GET.RECUPERAR_CONTRASENIA.replace(
            "{rut}",
            WithoutPoints
          );
          let token = responseToken.data;

          api
            .post(
              url,
              { captchatoken: captchaToken },
              { headers: { Authorization: `Bearer ${token}` } }
            )
            .then(
              (res) => {
                if (res) {
                  setShowLoading({ display: "none" });
                  setModalData({
                    title: "Recupera tu contraseña",
                    subtitle: "Confirmado",
                    color: "yellow",
                    message:
                      "Se enviará un email de recuperación a su casilla.",
                    message2: (
                      <Button onClick={() => history.push("/clientes/login")}>
                        Regresar a inicio de sesión.
                      </Button>
                    ),
                    icon: (
                      <>
                        <Confirmado />
                      </>
                    ),
                  });
                  handleShowModalError();
                }
              },
              (error) => {
                setShowLoading({ display: "none" });

                setModalData({
                  title: "Recupera tu contraseña",
                  subtitle: "Error",
                  color: "red",
                  message:
                    "No se pudo solicitar tu recuperación tu contraseña.",
                  message2: (
                    <Text type='primary' size={body25}>
                      {error.response.data.message}
                    </Text>
                  ),
                  icon: (
                    <>
                      <Error />
                    </>
                  ),
                });
                handleShowModalError();
              }
            );
        })
        .catch((error) => {
          setShowLoading({ display: "none" });
          setModalData({
            title: "Recupera tu contraseña",
            subtitle: "Error",
            color: "red",
            message: "Usted no tiene permiso para realizar esta acción.",
            icon: (
              <>
                <Error />
              </>
            ),
          });
          handleShowModalError();
        });
    } catch (error) {
      setShowLoading({ display: "none" });
      setModalData({
        title: "Recupera tu contraseña",
        subtitle: "Error",
        color: "red",
        message: "Error en reCaptcha.",
        icon: (
          <>
            <Error />
          </>
        ),
      });
      handleShowModalError();
    }
  };

  const handleRut = (e) => {
    let value = formatRut(e.target.value);
    setRUT(value);
    let ValidRut = validaRut(value);
    if (!ValidRut) {
      setErrors("RUT inválido");
    } else {
      setErrors("");
    }
  };

  return (
    <>
      <Loading estilo={showLoading}></Loading>

      <Col>
        <Row>
          <Container
            style={{
              backgroundColor: "black",
              width: "450px",
              height: "110px",
            }}>
            <Row className='mt-4 justify-content-center'>
              <img width={190} alt='' src={Logo2} />
            </Row>
            <Row className='mt-1 mb-2 justify-content-center'>
              <img width={30} height={5} alt='' src={Pipe2} />
            </Row>
            <Row className='justify-content-center pb-2'>
              <TextGeneric size={0.95} color='white'>
                <b>Cambio de Contraseña</b>
              </TextGeneric>
            </Row>
          </Container>
        </Row>
        <Row>
          <Container style={container} fluid>
            <Row className='mt-4 justify-content-center'>
              <Form>
                <Row className='justify-content-center mt-3 ml-2 mr-2'>
                  <TextGeneric bold size={0.9}>
                    ¿Olvidaste o quieres cambiar tu contraseña?
                  </TextGeneric>
                </Row>
                <Row className='justify-content-center'>
                  <TextGeneric bold size={0.9}>
                    No te preocupes, aquí te ayudamos
                  </TextGeneric>
                </Row>
                <Row className='justify-content-center mt-3'>
                  <TextGeneric size={0.9}>
                    Las instrucciones para restablecer tu contraseña
                  </TextGeneric>
                </Row>
                <Row className='justify-content-center'>
                  <TextGeneric size={0.9}>
                    se enviarán{" "}
                    <span style={{ fontWeight: "500" }}>a tu e-mail</span>
                  </TextGeneric>
                </Row>
                <Row
                  className='justify-content-center mt-3 ml-4 mr-4'
                  as={Row}
                  controlId='formUser'>
                  <Input
                    style={errors ? { border: "1px solid red" } : {}}
                    side='bottom'
                    type='text'
                    placeholder='RUT'
                    value={RUT}
                    onChange={(e) => handleRut(e)}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/\s/g, "");
                    }}
                  />
                  {errors && (
                    <span
                      style={{
                        fontSize: "11px",
                        position: "relative",
                        display: "block",
                        width: "100%",
                        top: "-6px",
                        color: "red",
                        textAlign: "left",
                      }}>
                      {errors}
                    </span>
                  )}
                </Row>

                <Row
                  style={buttonLoginStyle}
                  className='justify-content-center mt-3'>
                  <Col lg={6} style={{ maxWidth: "200px" }}>
                    <CustomButtonWithoutHover
                      onClick={restaurar}
                      disabled={errors !== ""}>
                      ENVIAR
                    </CustomButtonWithoutHover>
                  </Col>
                </Row>

                <Row className='justify-content-center mt-4 mb-4'>
                  <TextGeneric size={0.8}>
                    ¿Recordaste tu contraseña?{" "}
                    <b
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => history.push("/clientes/login")}>
                      Entra aquí
                    </b>
                  </TextGeneric>
                </Row>
              </Form>
            </Row>

            <ModalSuccess
              show={showModalError}
              icon={modalData.icon}
              messageLine={modalData.messageLine}
              title={
                <TextColored size={body28} type={modalData.color}>
                  {modalData.title}
                </TextColored>
              }
              subtitle={
                modalData.message2 !== "" ? (
                  <></>
                ) : (
                  <TextColored size={body32} bold type={modalData.color}>
                    {modalData.subtitle}
                  </TextColored>
                )
              }
              message={
                <>
                  <Text type='primary' size={body25}>
                    {modalData.message}
                    <br />
                    <br />
                  </Text>

                  {Boolean(modalData.message2) && modalData.message2}
                </>
              }
              handleClose={() => handleCloseModalError()}
            />
          </Container>
        </Row>
      </Col>
    </>
  );
}

export default ClienteCambioPassword;
