import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import Logo from "../../assets/images/logo2.png";
import { TextColored } from "../../components/typography/index";
import "./loading.css";

const LoadingModal = ({ show, text }) => {
  return (
    <Modal
      dialogClassName="loading-modal"
      show={show}
      centered
      backdrop={false}
    >
      <Modal.Body
        className="d-flex flex-column align-items-center"
        style={{ gap: "10px", padding: "2rem 0" }}
      >
        <img src={Logo} width="50%" alt="logo" />
        <div className="d-flex flex-column align-items-center text-center">
          <TextColored type="primary" children={text} weight={500} size={1} />
          <TextColored
            type="textLight"
            children="No cierre ni refresque esta página."
            weight={400}
            size={0.9}
          />
        </div>
        <Spinner animation="border" style={{ color: "white" }} />
      </Modal.Body>
    </Modal>
  );
};

export default LoadingModal;
