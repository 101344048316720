import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import ButtonContact from "../../components/buttons/ButtonContact";
import useWindowDimensions from "../../helpers/useWindowDimensions";
import { ThemeContext } from "../../theming";
import { Text } from "../typography";

const cardHeight = 65;

function CardContact({
  title,
  subtitle1,
  subtitle2,
  data = {},
  value2,
  onClick = () => {
    return;
  },
}) {
  const app = useContext(ThemeContext);
  const { width } = useWindowDimensions();

  const { body25 } = app.theme.font;
  const card = {
    width: "100%",
    height: `${cardHeight}px`,
    borderRadius: "7px",
    background: app.theme.primary,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  return (
    <Row
      style={{
        marginRight: "0px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: width >= 801 ? "5px" : "0px",
      }}
    >
      <div style={card}>
        <Col
          style={{ justifyContent: "flex-end" }}
          className="d-flex align-items-center"
          xs={7}
        >
          <Text type="primary" size={width >= 701 ? body25 : 0.7}>
            ¿Deseas contratar <b>{title}</b> ?
          </Text>
        </Col>
        <Col
          style={{ justifyContent: "start" }}
          className="d-flex align-items-center"
        >
          <ButtonContact onClick={onClick}>¡Contactar!</ButtonContact>
        </Col>
      </div>
    </Row>
  );
}

export default CardContact;
