import React, { useContext } from "react";
import { Row, Container, Col } from "react-bootstrap";
import { Text, Pipe } from "../typography";
import { ThemeContext } from "../../theming";
import Logo from "../../assets/images/logo_footer.png";
import useWindowDimensions from "../../helpers/useWindowDimensions";

const height = 110;

function Footer({ margin = true }) {
  const app = useContext(ThemeContext);
  const { body14, body25 } = app.theme.font;
  const { width } = useWindowDimensions();

  const footer = {
    backgroundColor: app.theme.secondary,
    width: "100%",
    height: `${height}px`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const col = {
    lineHeight: "initial",
  };
  return (
    <Container
      fluid
      style={footer}
      className={margin ? "mt-5 align-items-center" : "align-items-center"}>
      <Row className='w-100 align-items-center'>
        <Col className='d-flex justify-content-end'>
          <img
            src={Logo}
            style={{ height: "8%", width: "8%", minWidth: "50px" }}
            alt=''
          />
        </Col>
        <Pipe size={40} color='yellow' />
        <Col className='ml-4' style={col}>
          <Row>
            <Text size={width >= 701 ? body14 : 0.8}>
              contactenos@progreso.cl
            </Text>
          </Row>
          <Row>
            <Text size={width >= 701 ? body25 : 1.0}>www.progreso.cl</Text>
          </Row>
        </Col>
      </Row>
      <Row className='w-100 align-items-center'>
        <Col className='d-flex justify-content-end'>
          <span style={{ fontSize: "0.75rem" }}>Versión v1.0.54.1</span>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
