import React, { useContext } from "react";
import { Button, Spinner } from "react-bootstrap";
import { TextButton } from "../typography";
import { ThemeContext } from "../../theming";

function CustomButton({
  onClick,
  children,
  size = "md",
  style = {}, 
  id = null, 
  type="button",
  sizeText = 0,
  disabled = false,
  className = "w-100 btn-primary-custom",
  loading = false,
  ...props
}) {
  const app = useContext(ThemeContext);
  const { body12 } = app.theme.font;

  const button = {
    background: app.theme.primary,
    color: "#1D1D1B",
    borderColor: app.theme.primary,
    borderRadius: "7px",
  };

  return (
    <Button
      id={id}
      type={type}
      disabled={disabled}
      className={className}
      onClick={onClick}
      style={{...button, ...style}} 
      size={size}
      {...props}
    >
      {loading ? (
        <div style={{padding: "0 5rem"}}>
          <Spinner animation="border" size="sm" />
        </div>
      ) : (
        <TextButton
          bold
          size={sizeText === 0 ? body12 : sizeText}
          type="primary"
        >
          {children}
        </TextButton>
      )}
    </Button>
  );
}

export default CustomButton;
