const style = {
	fillRule: 'evenodd',
};

const svgContainer = {
	display: 'flex',
	justifyContent: 'center',
};
export const Email = () => {
	return (
		<div className="input-icon icon-top" style={svgContainer}>
			<svg style={style} xmlns='http://www.w3.org/2000/svg' width='22.344' height='16.781' viewBox='0 0 22.344 16.781'>
				<path
					id='Capa_41'
					data-name='Capa 41'
					class='cls-1'
					d='M603.411,421.882v-11.7l-5.84,5.771Zm-0.981,1.074-5.863-5.864-0.958.948a2.956,2.956,0,0,1-4.112,0l-0.958-.948-5.865,5.864H602.43Zm0.047-14.158H584.628l7.476,7.471a1.56,1.56,0,0,0,2.9,0Zm-12.944,7.15-5.84-5.771v11.7Zm15.183,7.019a1.516,1.516,0,0,1-.305,1.089,1.4,1.4,0,0,1-1.009.3H583.7a1.318,1.318,0,0,1-.891-0.3,1.37,1.37,0,0,1-.422-1.089V408.826a1.178,1.178,0,0,1,.41-0.9,1.307,1.307,0,0,1,.9-0.378h19.7a1.282,1.282,0,0,1,.986.354,1.3,1.3,0,0,1,.328.92v14.141Z'
					transform='translate(-582.375 -407.563)'
				/>
			</svg>
		</div>
	);
};
export const Password = () => {
	return (
		<div className="input-icon icon-bottom" style={svgContainer}>
			<svg style={style} xmlns='http://www.w3.org/2000/svg' width='9.844' height='17.313' viewBox='0 0 9.844 17.313'>
				<path
					id='Capa_36'
					data-name='Capa 36'
					class='cls-1'
					d='M593.55,466.444a2.823,2.823,0,0,1,2.843,2.794,2.752,2.752,0,0,1-1.113,2.2,1.042,1.042,0,0,0-.393,1.012l1.3,7.169h-5.275l1.3-7.169a1.036,1.036,0,0,0-.392-1.011,2.755,2.755,0,0,1-1.112-2.2,2.821,2.821,0,0,1,2.842-2.794m-4.906,14.029a1.041,1.041,0,0,0,1.022,1.226h7.767a1.043,1.043,0,0,0,1.024-1.226l-1.417-7.813a4.8,4.8,0,0,0,1.434-3.422,4.924,4.924,0,0,0-9.847,0,4.8,4.8,0,0,0,1.434,3.422Z'
					transform='translate(-588.625 -464.375)'
				/>
			</svg>
		</div>
	);
};
