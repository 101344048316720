import React from "react";

import useWindowDimensions from "../../helpers/useWindowDimensions";

import "./loading.css";

function Loading(props) {
  const { width } = useWindowDimensions();

  return (
    <div className="modalProgress" id="divLoading" style={props.estilo}>
      <div className={width >= 701 ? "loading" : "loading-mobile"}>
        <span style={{ color: "white" }}>
          {/* <i className="material-icons spinner">replay</i> */}
          <span className="cargando">
            Cargando información. Por favor espere &nbsp;&nbsp;
          </span>
        </span>
      </div>
    </div>
  );
}

export default Loading;
