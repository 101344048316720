//Librerias
import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";

//Helpers
import { AUTOATENCIONAPI } from "../../helpers/url";
import { api } from "../../helpers/api";
import {
  createLink,
  fireAlert,
  generateCesionObj,
  getPublicKey,
  getRut,
  stepValidator,
  timeout,
} from "../../helpers/autoAtencionHelpers";
import useStorage from "../../helpers/useStorage";
import useWindowDimensions from "../../helpers/useWindowDimensions";

//Componentes
import { Container, Row, Card, Col } from "react-bootstrap";
import Footer from "../../components/footer/Footer";
import HeaderAutoatencion from "../../components/header-autoatencion/HeaderAutoatencion";
import TablaResumen from "../../components/autoatencion/tabla-resumen/TablaResumen";
import TitleAutoatencion from "../../components/header-autoatencion/TitleAutoatencion";
import ModalAutoatencion from "../../components/modal/modal-autoatencion/ModalAutoatencion";
import CustomButton from "../../components/buttons/Button";
import LoadingModal from "../../components/loading/LoadingModal";
import { TextColored } from "../../components/typography";
import { DecryptData } from "../../helpers/encrypt";

const ResumenFacturas = () => {
  const history = useHistory();
  const { width } = useWindowDimensions();
  const bills = JSON.parse(sessionStorage.getItem("bills"));
  const [{ idcontacto, cliente }] = useStorage("user", {}, "local");
  const [show, setShow] = useState(false);
  const [inProcess, setInProcess] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [type, setType] = useState(null);
  const [link, setLink] = useState(null);

  useEffect(() => {
    const correctStep = stepValidator();
    if (correctStep) {
      if (link === null) {
        createLink(
          setLink,
          "Hola, mi operación fue enviada para una evaluación comercial. Por favor, ¿Puede indicarme cómo continuar?"
        );
      }
    }
  }, [history, inProcess, link, type]);

  const handleSubmit = useCallback(async () => {
    try {
      setInProcess(true);
      const publicKey = await getPublicKey();
      const rut_cliente = await getRut();

      const cesionOptions = {
        registerFintec: true,
        myBiller: false,
        cesionFlag: true,
        type: "scoring",
      };

      const obj = await generateCesionObj(bills, cesionOptions);

      const publicURL = AUTOATENCIONAPI.POST.SCORING.replace(
        "{rut}",
        rut_cliente
      )
        .replace(
          "{razon_social}",
          encodeURIComponent(DecryptData(cliente, publicKey))
        )
        .replace("{idcontacto}", idcontacto);

      const { data } = await api.post(publicURL, obj);

      sessionStorage.setItem("processId", JSON.stringify(data?.processid));
      sessionStorage.setItem("current-step", 3);

      await timeout(1000);

      if (data?.state === 1) {
        setType("scoring-exitoso");
      } else {
        setType("scoring-fallido");
      }
      setShow(true);

      setInProcess(false);
      return;
    } catch {
      await fireAlert({ icon: "warning" });
      setInProcess(false);
    }
  }, [bills, idcontacto, cliente]);

  const Button = () => {
    return (
      <div style={{ paddingInline: "10%" }}>
        <CustomButton
          children={
            <TextColored
              type="dark"
              size={1.1}
              children={
                type
                  ? "Evaluacion terminada."
                  : inProcess
                  ? "Evaluando..."
                  : "Empezar evaluación"
              }
              weight={600}
            />
          }
          onClick={handleSubmit}
          disabled={inProcess || tableLoading}
          loading={tableLoading}
        />
      </div>
    );
  };
  return (
    <Container
      fluid
      className="vh-100 d-flex flex-column"
      style={{ overflowY: "auto" }}
    >
      <ModalAutoatencion
        show={show}
        type={type}
      />
      <LoadingModal
        show={inProcess}
        text="Evaluando. Este proceso puede tardar un momento"
      />
      <Row>
        <HeaderAutoatencion step={2} />
      </Row>
      <Col className="justify-content-center align-items-center d-flex">
        <Card style={width <= 768 ? { width: "100%" } : { width: "90%" }}>
          <Card.Body className="d-flex flex-column" style={{ gap: "1rem" }}>
            <TitleAutoatencion
              info="Aquí te mostramos el plazo de pago de cada deudor de tus facturas. En base a esta información se realiza la simulación del financiamiento. Si estás conforme con el líquido a pagar puedes continuar con el proceso de evaluación, de lo contrario puedes contactarte con un ejecutivo para analizar tu caso."
              currentStep={2}
              width={width}
            />

            <TablaResumen
              Button={Button}
              loading={tableLoading}
              setLoading={setTableLoading}
            />
          </Card.Body>
        </Card>
      </Col>
      <Row>
        <Footer margin={false} />
      </Row>
    </Container>
  );
};

export default ResumenFacturas;
