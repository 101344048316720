import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";

import { Text, TextColored } from "../../components/typography";
import Tabla from "../../components/tabla/Tabla";

import { Progreso, ProgresoMobile } from "../../assets/icons/card";
import mobile from "../../assets/images/mobile.png";

import { ThemeContext } from "../../theming";

import useWindowDimensions from "../../helpers/useWindowDimensions";

function CardNegocio({
  title,
  text,
  table = {},
  subtitle = null,
  footer = null,
}) {
  const app = useContext(ThemeContext);
  const { body14, body32, body12 } = app.theme.font;
  const { width } = useWindowDimensions();

  //const instructivoSiniestro = instructivos.filter(instructivo=>instructivo.nombre==="instructivo_siniestros");
  const icono = {
    right: "21px",
    position: "relative",
  };
  const iconoMobile = {
    top: "-50px",
    right: "21px",
    position: "relative",
  };
  return (
    <>
      {width >= 701 && <Progreso style={width >= 701 ? icono : {}} />}
      <Row
        className={
          width >= 701
            ? "mt-5 mx-5 d-flex justify-content-center justify-content-xl-start align-items-center"
            : "d-flex justify-content-center justify-content-xl-start"
        }
      >
        {width >= 701 ? (
          <>
            <Col
              className="d-flex justify-content-end align-items-center"
              xs={3}
              xl={2}
            >
              <Text type="primary" size={body32}>
                {title}:
              </Text>
            </Col>
            <Col className="d-flex justify-content-start" xs={9} xl={9}>
              <TextColored className="text-justify" size={body12} type="gray">
                {text}
              </TextColored>
            </Col>
            {/* {instructivoSiniestro && <Col xs={12} xl={2.5*(instructivoSiniestro?.length)} className='d-flex justify-content-end my-xl-auto mt-4 pr-0'>
							{instructivoSiniestro.map(inst=>(
							<a href={inst.url} target="_blank" rel="noopener noreferrer" className='mx-1'>
								<Instructivo name={inst?.image}/>
							</a>
							))
							}
						</Col>} */}
          </>
        ) : (
          // Mobile vista
          <Col style={{ marginLeft: "60px" }}>
            <Row
              style={{
                marginTop: "-15px",
                marginLeft: "-76px",
              }}
            >
              {<ProgresoMobile style={iconoMobile} />}
            </Row>
            <Row
              style={{ marginTop: "60px" }}
              className="d-flex justify-content-start align-items-center"
              xs={12}
            >
              <Text type="primary" size={body32}>
                {title}:
              </Text>
            </Row>
            <Row className="pr-4">
              <TextColored className="text-justify" size={body12} type="gray">
                {text}
              </TextColored>
            </Row>
            {/* {instructivoSiniestro&& <Row className='d-flex justify-content-end align-items-center mt-3 mr-0'  >
							{instructivoSiniestro.map(inst=>(
							<Col xs={width < 510 ? 12:6} className={`${width < 510 ? "my-1":""}`}>
							<a href={inst.url} target="_blank" rel="noopener noreferrer">
								<Instructivo name={inst.image} />
							</a>
							</Col>
							))}
						</Row>} */}
            <Row className="mr-4">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                {subtitle && (
                  <Text size={0.6} type="primary" bold>
                    Nota:{" "}
                  </Text>
                )}
                <Text size={0.6} type="primary">
                  {subtitle}
                </Text>
              </div>
            </Row>
          </Col>
        )}
      </Row>

      <div className={width >= 701 ? "m-5" : ""}>
        <Row className="mt-5">
          <Col>
            <div>
              {subtitle ? (
                <>
                  {width >= 701 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Text size={body14} type="primary" bold>
                        Nota:{" "}
                      </Text>
                      <Text size={body14} type="primary">
                        {subtitle}
                      </Text>
                    </div>
                  )}

                  <div className="info-mobile">
                    <Col
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Row>
                        <img
                          style={{ marginRight: "5px" }}
                          height="35px"
                          width="56px"
                          alt=""
                          src={mobile}
                        />
                        <p
                          style={{
                            fontSize: "9px",
                            fontWeight: "900",
                            marginTop: "6px",
                          }}
                        >
                          Desliza la tabla para ver más información
                        </p>
                      </Row>
                    </Col>
                  </div>
                </>
              ) : (
                footer
              )}
            </div>

            <Tabla numeroFilas={10} busqueda={false} datos={table}></Tabla>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default CardNegocio;
