import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import Documento from "../../assets/images/documento.png";
import Recu3 from "../../assets/images/landing/recu3.png";
import Recu4 from "../../assets/images/landing/recu4.png";
import Esquina3 from "../../assets/images/esquina3.png";
import Flecha1 from "../../assets/images/flecha1.png";
import Logo3 from "../../assets/images/logo3.png";
import { Text, TextGeneric } from "../../components/typography";
import { ThemeContext } from "../../theming";
import { mobileXSWidth, mobileMDWidth, mobileSMWidth, mobileLDWidth } from '../../helpers/responsiveVariables';
import useWindowDimensions from '../../helpers/useWindowDimensions';
import SeccionClienteItem from './seccion-cliente3Items';

function SeccionClientes3() {
  const app = useContext(ThemeContext);
  const { body25 } = app.theme.font;
  const { width } = useWindowDimensions();

  return (
    <Row
      className="align-items-center justify-content-md-center clientes-3"
      style={{
        width: "100%",
        marginLeft: "0px",
        marginRight: "0px",
      }}
    >

      {
        width > mobileMDWidth ?
          <Col className="mt-4 mb-4" lg={4} md={4}>
            <img height={300} width={300} src={Logo3} alt="" />
          </Col>
          :
          <></>
      }

      {
        width > mobileXSWidth ?

          <Col>
            <Row>
              <TextGeneric
                color={"#FFD135"}
                className="main-color mb-5"
                bold
                type="secondary"
                size={width > mobileXSWidth ? 2.5 : 1.7}
              >
                ¿Por qué confiar en Progreso?
          </TextGeneric>
            </Row>

            <Row>
              <Col lg={1}>
                <img alt="" width={
                  width >= mobileLDWidth ?
                    50 : 40} height={width >= mobileMDWidth ?
                      50 : 40} src={Documento} />
              </Col>
              <Col lg={5} className="">
                <Row>
                  <TextGeneric
                    color={"#FFD135"}
                    bold
                    type="secondary"
                    size={body25}
                  >
                    33 AÑOS DE TRAYECTORIA
              </TextGeneric>
                </Row>
                <Row style={{ textAlign: "start" }}>
                  <Text bold type="secondary" size={0.8}>
                    Somos pioneros en el mercado del <br></br>
                financiamiento no bancario en Chile
              </Text>
                </Row>
              </Col>
              <Col lg={1}>
                <img alt="" height={width >= mobileMDWidth ?
                  40 : 30} src={Recu4} />
              </Col>
              <Col lg={5}>
                <Row style={{ marginLeft: "1px" }}>
                  <TextGeneric
                    color={"#FFD135"}
                    bold
                    type="secondary"
                    size={body25}
                  >
                    SERIEDAD Y TRANSPARENCIA
              </TextGeneric>
                </Row>
                <Row style={{ textAlign: "start", marginLeft: "1px" }}>
                  <Text bold type="secondary" size={0.8}>
                    No jugamos con tu dinero, te entregamos la <br></br> información
                detallada y en línea
              </Text>
                </Row>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col lg={1}>
                <img alt="" width={
                  width >= mobileLDWidth ?
                    50 : 40} height={width >= mobileMDWidth ?
                      50 : 40} src={Recu3} />
              </Col>
              <Col lg={5}>
                <Row>
                  <TextGeneric
                    color={"#FFD135"}
                    bold
                    type="secondary"
                    size={body25}
                  >
                    ATENCIÓN PERSONALIZADA
              </TextGeneric>
                </Row>
                <Row style={{ textAlign: "start" }}>
                  <Text bold type="secondary" size={0.8}>
                    Cada cliente es importante y único, por <br></br> eso les
                brindamos atención a la medida
              </Text>
                </Row>
              </Col>

              {
                width > mobileXSWidth ?
                  <>
                    <img
                      src={Esquina3}
                      style={{
                        marginTop: "6%",
                        marginLeft: "553px",
                        position: "absolute",
                      }}
                      width={15}
                      height={15}
                      alt=""
                    />
                    <img
                      src={Flecha1}
                      style={{
                        marginTop: "6%",
                        marginLeft: "500px",
                        position: "absolute",
                        height: "51px",
                        objectPosition: "top",
                        objectFit: "cover",
                      }}
                      width={100}
                      height={100}
                      alt=""
                    />
                  </>
                  : <></>
              }
            </Row>
          </Col>
          :
          <Col>

            <Row className="justify-content-center mt-4">
              <TextGeneric
                color={"#FFD135"}
                className="main-color mb-5"
                bold
                type="secondary"
                size={width > mobileXSWidth ? 2.5 : 1.7}
              >
                ¿Por qué confiar en Progreso?
              </TextGeneric>
            </Row>

            <SeccionClienteItem
              key={'Seccion3Item1'}
              icono={Documento}
              titulo={"33 AÑOS DE TRAYECTORIA"}
              contenido={`Somos pioneros en el mercado del
              financiamiento no bancario en Chile`}
              marginBottom={'30px'}
            />

            <SeccionClienteItem
              key={'Seccion3Item2'}
              icono={Recu4}
              titulo={"SERIEDAD Y TRANSPARENCIA"}
              contenido={`No jugamos con tu dinero, te entregamos la
              información detallada y en línea`}
              marginBottom={'30px'}
            />

            <SeccionClienteItem
              key={'Seccion3Item3'}
              icono={Recu3}
              titulo={"ATENCIÓN PERSONALIZADA"}
              contenido={`Cada cliente es importante y único, por
              eso les brindamos atención a la medida`}
            />
            <br></br>
          </Col>
      }
    </Row >
  );
}

export default SeccionClientes3;
