import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Modal, Row, Container, Col, Button } from "react-bootstrap";
import { Title, TextColored } from "../typography";
import { ThemeContext } from "../../theming";
import Close from "../../assets/images/close.png";

export const CustomModal = ({
  children,
  padding = true,
  icon,
  headerColor = "white",
  title,
  subtitle = null,
  size = "md",
  handleClose,
  show,
  backdrop,
}) => {
  const app = useContext(ThemeContext);
  const { body12 } = app.theme.font;
  const styleBody = {
    padding: padding ? "1rem" : "1rem 0px",
  };
  return (
    <Modal show={show} onHide={handleClose} size={size} backdrop={backdrop}>
      {headerColor !== "white" ? (
        <>
          <Modal.Header style={{ backgroundColor: headerColor }}>
            <Row>
              {handleClose ? (
                <img
                  style={{
                    position: "absolute",
                    right: "10px",
                    cursor: "pointer",
                  }}
                  src={Close}
                  height={40}
                  alt="imagen"
                  onClick={handleClose}
                />
              ) : null}
            </Row>

            <Container fluid>
              <Row>
                {icon && <Col xs={size === "xl" ? 1 : 2}>{icon}</Col>}
                <Col xs={9}>
                  <Row>
                    <Modal.Title>{title}</Modal.Title>
                  </Row>
                  {subtitle && (
                    <Row>
                      <TextColored
                        size={body12}
                        className="d-block"
                        type="gray"
                      >
                        {subtitle}
                      </TextColored>
                    </Row>
                  )}
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body style={styleBody}>{children}</Modal.Body>
        </>
      ) : (
        <>
          <Modal.Header style={{ backgroundColor: headerColor }} closeButton={!!handleClose}>
            <Container fluid>
              <Row>
                {icon && <Col xs={size === "xl" ? 1 : 2}>{icon}</Col>}
                <Col>
                  <Row>
                    <Modal.Title>{title}</Modal.Title>
                  </Row>
                  {subtitle && (
                    <Row>
                      <TextColored
                        size={body12}
                        className="d-block"
                        type="gray"
                      >
                        {subtitle}
                      </TextColored>
                    </Row>
                  )}
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body style={styleBody}>{children}</Modal.Body>
        </>
      )}
    </Modal>
  );
};

CustomModal.propTypes = {
  // children: PropTypes.node.isRequired,
  // title: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  // subtitle: PropTypes.string,
  icon: PropTypes.node,
  padding: PropTypes.bool,
};

export const ModalSuccess = ({
  size = "md",
  show,
  icon,
  handleClose,
  headerTitle = "Alerta",
  title = "",
  subtitle = "",
  message = "",
  message2 = null,
  messageLine = false,
  buttonModal = null
}) => {
  return (
    <Modal show={show} onHide={handleClose} size={size}>
     <Modal.Header
      style={{display: "block", justifyContent: "space-between", alignItems: "center"}}
      closeButton={!!handleClose}
      >
          <span style={{marginTop: "6px", position: "absolute"}}>
            <Title title={headerTitle} subtitle="" pipe={false} />
          </span>

      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row className="justify-content-center align-content-center">
            <Col xs={3}>{icon}</Col>
            <Col xs={9}>
              <Row className="justify-content-center mb-3">{title}</Row>
              <Row className="justify-content-center">{subtitle} </Row>
              {messageLine === true ? (
                <Row
                  style={{ fontSize: "20px", fontWeight: "600" }}
                  className="justify-content-center"
                >
                  {"¡Muchas Gracias!"}
                </Row>
              ) : (
                <></>
              )}
              {messageLine === false ? (
                <Row className="justify-content-center text-center">
                  {message}
                </Row>
              ) : (
                <></>
              )}
            </Col>
          </Row>
          {messageLine !== false ? (
            <Row className="justify-content-center text-center">{message}</Row>
          ) : (
            <></>
          )}
          <Row>{message2}</Row>
          {buttonModal && (
            <Row className="justify-content-center mt-3">
              <button
                type="button"
                class="btn btn-warning w-50"
                style={{fontWeight: "bold", backgroundColor : "rgb(247, 207, 70)"}}
                onClick={buttonModal.click}>{buttonModal.text}</button>
            </Row>
          )}
        </Container>
      </Modal.Body>
    </Modal>
  );
};
export const ModalSuccessSolicitud = ({
  size = "md",
  show,
  icon,
  handleClose,
  headerTitle = "",
  title = "",
  subtitle = "",
  message = "",
  message2 = null,
  messageLine = false,
}) => {
  return (
    <Modal show={show} onHide={handleClose} size={size}>
      <Modal.Header
        className="align-items-center"
        //style={{borderBottom : "0 !important;", height : "50px",justifyContent : "flex-end !important;"}}
        ref={(el)=>{
          if(el){
            el.style.setProperty('border-bottom',0,'important');
            el.style.setProperty('height','50px','important');
            el.style.setProperty('justify-content','flex-end','important');
          }
        }}
        closeButton
      >
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Col className="justify-content-center align-content-center">
            <Row xs={4} className="justify-content-center">{icon}</Row>
            <Row xs={8}>
              <Col>
              <Row className="justify-content-center">{title}</Row>
              <Row className="justify-content-center">{subtitle} </Row>
              {message && (
                <Row className="justify-content-center text-center">{message}</Row>
              )}
              </Col>
            </Row>
          </Col>
          {messageLine !== false ? (
            <Row className="justify-content-center text-center">{message}</Row>
          ) : (
            <></>
          )}
          <Row className="justify-content-center mt-3"><button
                type="button"
                class="btn btn-warning w-75"
                onClick={handleClose}>Aceptar</button>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
export const ModalSuccess2 = ({
  headerColor = "white",
  iconHeader,
  size = "md",
  show,
  icon,
  handleClose,
  headerTitle = "Alerta",
  title = "",
  subtitle = "",
  message = "",
  messageLine = false,
}) => {
  return (
    <Modal show={show} onHide={handleClose} size={size}>
      <Modal.Header
        style={{ backgroundColor: headerColor }}
        className="justify-content-between flex-start"
        closeButton={!!handleClose}
      >
        <Container fluid>
          <Row>
            {icon && <Col xs={size === "xl" ? 1 : 2}>{iconHeader}</Col>}
            <Col>
              <Row className="justify-content-center">
                <Modal.Title>{headerTitle}</Modal.Title>
              </Row>
            </Col>
          </Row>
        </Container>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row className="justify-content-center align-content-center">
            <Col xs={8}>
              <Row className="justify-content-center">{icon}</Row>
              <Row className="justify-content-center mt-4">{title}</Row>
              <Row className="justify-content-center">{subtitle}</Row>
              {messageLine === true ? (
                <Row
                  style={{ fontSize: "20px", fontWeight: "600" }}
                  className="justify-content-center"
                >
                  {"¡Muchas Gracias!"}
                </Row>
              ) : (
                <></>
              )}
              {messageLine === false ? (
                <Row className="justify-content-center text-center">
                  {message}
                </Row>
              ) : (
                <></>
              )}
            </Col>
          </Row>
          {messageLine !== false ? (
            <Row className="justify-content-center text-center">{message}</Row>
          ) : (
            <></>
          )}
        </Container>
      </Modal.Body>
    </Modal>
  );
};

ModalSuccess.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  icon: PropTypes.node,
  size: PropTypes.string,
  // title: PropTypes.string,
  // subtitle: PropTypes.string,
  // message: PropTypes.string,
};
