import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "../../theming";

/* Colors text */

const colorPrimary = (app) => ({
  color: app.theme.textDark,
});

const colorSecondary = (app) => ({
  color: app.theme.textLight,
});

const colors = (type, app) => ({
  color: app.theme[type],
});
/* Components */

export const Title = ({ title, subtitle, pipe = true }) => {
  const app = useContext(ThemeContext);
  const { body14, body25 } = app.theme.font;
  return (
    <>
      <Text className="text-center" size={body25} type="primary">{`${title} ${
        pipe ? "/" : ""
      } `}</Text>
      <Text size={body14} className="font-weight-bolder" type="primary">
        {subtitle}
      </Text>
    </>
  );
};

Title.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  pipe: PropTypes.bool,
};

/* Text */

export const TextGeneric = ({
  children,
  semi_bold = false,
  bold = false,
  color = "black",
  className = "",
  size = 0.8,
}) => {
  const body = {
    fontSize: `${size}rem`,
    color: color,
    fontWeight: bold ? "700" : semi_bold ? "normal" : "",
  };
  return (
    <span className={`${className}`} style={body}>
      {children}
    </span>
  );
};

export const Text = ({
  children,
  type = "secondary",
  bold = false,
  className = "",
  size = 0.8,
  id = "",
}) => {
  const app = useContext(ThemeContext);
  const primary = { ...colorPrimary(app), ...{ fontSize: `${size}rem`, whiteSpace : "pre-line" } };
  const secondary = { ...colorSecondary(app), ...{ fontSize: `${size}rem`, whiteSpace : "pre-line" } };
  const body = type === "primary" ? primary : secondary;
  return (
    <span
      className={`${className} ${bold ? "font-weight-bolder" : ""}`}
      style={body}
      id={id}
    >
      {children}
    </span>
  );
};

export const TextButton = ({
  children,
  type = "secondary",
  bold = false,
  className = "",
  size = 0.8,
  ...props
}) => {
  const primary = { ...{ fontSize: `${size}rem` } };
  const secondary = { ...{ fontSize: `${size}rem` } };
  const body = type === "primary" ? primary : secondary;
  return (
    <span
      className={`${className} ${bold ? "font-weight-bolder" : ""}`}
      style={body}
      {...props}
    >
      {children}
    </span>
  );
};

export const CustomTextWithoutPadding = ({
  children,
  type = "secondary",
  bold = false,
  className = "",
  size = 0.8,
  style,
}) => {
  const app = useContext(ThemeContext);
  const primary = {
    ...colorPrimary(app),
    ...{ fontSize: `${size}rem`, paddingLeft: "0px", lineHeight: "normal" },
  };
  const secondary = {
    ...colorSecondary(app),
    ...{ fontSize: `${size}rem`, paddingLeft: "0px", lineHeight: "normal" },
  };
  const body = type === "primary" ? primary : secondary;
  return (
    <span
      className={`${className} ${bold ? "font-weight-bolder" : ""}`}
      style={body}
    >
      {children}
    </span>
  );
};

Text.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  bold: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.number,
};

/* Text Colored */
export const TextColored = ({
  children,
  type = "gray",
  bold = false,
  className = "",
  size = 0.8,
  weight = 400,
  moreStyles = {},
  title
}) => {
  const app = useContext(ThemeContext);
  const body = {
    ...colors(type, app),
    ...{ fontSize: `${size}rem`, fontWeight: weight },
  };
  return (
    <span
      className={`${className} ${bold ? "font-weight-bolder" : ""}`}
      style={{ ...body, ...moreStyles }}
      title={title}
    >
      {children}
    </span>
  );
};

export const TextCustomColored = ({
  children,
  color = "gray",
  bold = false,
  className = "",
  size = 0.8,
  pointer,
  onClick = () => {},
}) => {
  const body = {
    ...{
      fontSize: `${size}rem`,
      color: color,
      fontWeight: 400,
      cursor: pointer ? "pointer" : "default",
    },
  };
  return (
    <span
      className={`${className} ${bold ? "font-weight-bolder" : ""}`}
      style={body}
      onClick={onClick}
    >
      {children}
    </span>
  );
};

TextColored.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  bold: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.number,
};
/* Link */
export const Link = ({
  children,
  center = false,
  border = {},
  type = "textLight",
  size = 0.8,
  onClick = () => {},
}) => {
  const app = useContext(ThemeContext);
  const body = {
    ...colors(type, app),
    ...{
      fontSize: `${size}rem`,
      alignSelf: center ? "center" : "",
      ...border,
    },
  };
  return (
    <a href="#" onClick={onClick} style={body}>
      {children}
    </a>
  );
};

Link.propTypes = {
  text: PropTypes.node,
  onClick: PropTypes.func,
  size: PropTypes.number,
  type: PropTypes.string,
  border: PropTypes.object,
};

/* Pipe */
export const Pipe = ({
  size = 20,
  center = false,
  color = "black",
  marginLeft = "0px",
  marginRight = "0px",
}) => {
  const app = useContext(ThemeContext);
  const pipe = {
    marginLeft: marginLeft,
    marginRight: marginRight,
    alignSelf: center ? "center" : "",
    fontSize: `${size}px`,
    ...colors(color, app),
  };
  return <span style={pipe}>|</span>;
};
