//Hooks - Funciones
import React, { useEffect } from "react";
import { Form } from "react-bootstrap";

//Componentes
import Tabla from "../tabla/Tabla";

const TablaMetodos = ({
  setSelectedRow,
  selectedRow,
  rows,
  setRows,
  loading,
  setLoading,
}) => {
  useEffect(() => {
    const getPaymentMethods = async () => {
      try {
        const listaCuentas = JSON.parse(
          sessionStorage.getItem("lista_cuentas")
        );
        if (listaCuentas?.length > 0) {
          setRows([...listaCuentas]);
          listaCuentas.length === 0 && setSelectedRow(listaCuentas[0]);
          return;
        } else {
          setRows([]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getPaymentMethods();
  }, [setRows, setLoading, setSelectedRow]);

  const columns = [
    {
      name: "Seleccionar",
      selector: (row) => {
        return (
          <div
            style={{ display: "grid", placeItems: "center", padding: "5px" }}
          >
            <Form.Check
              type="radio"
              checked={selectedRow?.id_metodo === row.id_metodo}
              onChange={() => setSelectedRow(row)}
            />
          </div>
        );
      },
      width: "120px",
      center: true,
    },
    {
      name: "Banco",
      selector: (row) => row.nombre_banco,
      width: "200px",
      center: true,
    },
    {
      name: "Tipo",
      selector: (row) => row.tipo,
      center: true,
    },
    {
      name: "Cuenta",
      selector: (row) => row.cuenta,
      center: true,
      width: "120px",
    },
    {
      name: "Propietario",
      selector: (row) => row.propietario,
      width: "400px",
      center: true,
    },
    {
      name: "Pertenece a:",
      selector: (row) => row.tipo_cuenta,
      center: true,
      width: "120px",
    },
  ];

  return (
    <div>
      <Tabla
        datos={{ rows, columns }}
        busqueda={false}
        paginacion={rows.length > 7}
        numeroFilas={7}
        loading={loading}
      />
    </div>
  );
};

export default TablaMetodos;
