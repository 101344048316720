import React from "react";
import styles from "./styles.module.css";
import { AiOutlineCheck } from "react-icons/ai";

const Step = ({ name, icon, selected = false, passed = false, width }) => {
  const checkParameters = () => {
    if (selected) {
      return (
        <div
          className="d-flex flex-column align-items-center"
          style={{ marginTop: "-3.5rem" }}
        >
          <div style={{ position: "relative" }}>{icon}</div>
          <div>
            <div className={styles['selectedStep']}>{name}</div>
          </div>
        </div>
      );
    } else if (passed) {
      return (
        <div className={`${styles['iconStep']} ${styles['passedStep']}`}>
          <AiOutlineCheck size={22} />
        </div>
      );
    } else {
      return (
        <div className={width < 1130 ? styles['iconStep'] : styles['step']}>
          {icon}
          {width >= 1130 && name}
        </div>
      );
    }
  };

  return <div>{checkParameters()}</div>;
};

export default Step;
