import React, { useEffect, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Style from "./SelectMasterEmail.module.css";
import { TextGeneric } from "../typography";
import Logo2 from "../../assets/images/logo2.png";
import Pipe2 from "../../assets/images/pipe2.png";
import { Container, Row } from "react-bootstrap";
import { hideEmail } from "../../helpers/functions.js";
import { PORTALSAPI } from "../../helpers/url";
import { api } from "../../helpers/api";
import { DecryptData } from "./../../helpers/encrypt";
import Loading from "../loading/Loading";
import { Confirmado, Error } from "../../assets/icons/dashboard";
import CustomButtonWithoutHover from "../buttons/ButtonWithoutHover";
import { ModalSuccess } from "../modal/Modal";
import { Text, TextColored } from "../typography";
import { ThemeContext } from "../../theming";

const SelectMasterEmail = () => {
  const app = useContext(ThemeContext);
  const { body25, body32 } = app.theme.font;
  const history = useHistory();
  const location = useLocation();
  function useQuery() {
    return new URLSearchParams(location.search);
  }

  const rut = useQuery().get("rut");
  //Booleano que se envía desde clientes login para que no se acceda directamente a la página
  const myBool = location?.state?.myBool;

  const [contact, setContact] = useState({
    idContact: "",
    email: "",
    rutS: "",
  });

  //Emails
  const [allEmails, setAllEmails] = useState([]);

  //Modal
  const [modalPassword, setModalRedirectLogin] = useState({});

  // Flags
  const [loading, setLoading] = useState(false);
  const [showModalRedirectLogin, setShowModalRedirectLogin] = useState(false);

  const closedSelectEmail = () => {
    setShowModalRedirectLogin(false);
    history.push("/");
  };

  const handleGoLogin = (mess) => {
    setModalRedirectLogin({
      messageLine: false,
      title: "Confirma tu correo de notificación",
      //message: "Se enviará un email de verificación a su casilla.",
      message: mess,
      color: "yellow",
      icon: (
        <>
          <Confirmado />
        </>
      ),
      message2: (
        <>
          <CustomButtonWithoutHover onClick={closedSelectEmail}>
            Regresar a inicio de sesión.
          </CustomButtonWithoutHover>
        </>
      ),
    });

    setShowModalRedirectLogin(true);
  };

  const handleClick = () => {
    const sendEmail = async () => {
      let data = {
        idcontacto: contact.idContact.toString(),
        rut: contact.rutS,
      };
      try {
        setLoading(true);
        const{ message } = await api.put(PORTALSAPI.PUT.GRABAR_CORREO_MASTER, data);
        handleGoLogin(message);
      } catch (error) {
        setModalRedirectLogin({
          messageLine: false,
          title: "Confirma tu correo de notificación",
          //message: "Se enviará un email de verificación a su casilla.",
          message: error?.response?.data?.message??"Error en asignación de correo master.",
          color: "red",
          icon: (
            <>
              <Error />
            </>
          ),
        });
    
        setShowModalRedirectLogin(true);
      }finally{
      setLoading(false);
      }
    };
    sendEmail();
  };

  // Para evitar el error Can't perform a React state update on an unmounted component
  useEffect(() => {
    let isMounted = true; // Variable para verificar si el componente está montado

    const listEmails = async () => {
      setLoading(true);
      try {
        const URL = PORTALSAPI.GET.OBTENER_CORREOS.replace("{rut}", rut);
        const emails = await api.get(URL);
        const temporalKey = await api.get(PORTALSAPI.GET.OBTENER_TEMPORAL_KEY);

        if (isMounted) {
          const decryptEmails = emails.data.map((element) => {
            let contacts = {
              idContact: element.idcontacto,
              email: DecryptData(element.correo, temporalKey.data),
            };

            return contacts;
          });

          const EmailsNotEmpty = decryptEmails.filter(
            (element) => element.email !== ""
          );

          setAllEmails(EmailsNotEmpty);
          setLoading(false);
        }
      } catch (error) {
        // Manejar errores si es necesario
        if (isMounted) {
          setLoading(false);
          history.push("/");
        }
      }
    };

    listEmails();

    // Función de limpieza
    return () => {
      isMounted = false; // Marcar el componente como desmontado cuando la función de limpieza se ejecute
    };
  }, [rut]);

  useEffect(() => {
    if (rut === null || rut === undefined || rut === "" || myBool !== true) {
      history.push("/");
    }
  }, [history, myBool, rut]);

  return (
    <div className={`${Style.container} login-clientes`}>
      {loading && <Loading />}

      <ModalSuccess
        show={showModalRedirectLogin}
        icon={modalPassword.icon}
        title={
          <TextColored size={body25} type={modalPassword.color}>
            {modalPassword.title}
          </TextColored>
        }
        subtitle={
          <TextColored size={body32} bold type={modalPassword.color}>
            {modalPassword.subtitle}
          </TextColored>
        }
        message={
          <Text type='primary' size={body25}>
            <span
              style={{
                width: "300px",
                display: "block",
                marginTop: "10px",
              }}>
              {modalPassword.message}
            </span>
          </Text>
        }
        message2={
          <div
            style={{
              width: "310px",
              marginLeft: "160px",
              marginTop: "-45px",
              zIndex: "1",
            }}>
            {modalPassword.message2}
          </div>
        }
        handleClose={() => setShowModalRedirectLogin(false)}
      />
      <div className={Style.containerContent}>
        <Container className={Style.containerHeader}>
          <Row className='mt-4 justify-content-center'>
            <img width={190} alt='' src={Logo2} />
          </Row>
          <Row className='mt-1 mb-2 justify-content-center'>
            <img width={30} height={5} alt='' src={Pipe2} />
          </Row>
          <Row className='justify-content-center pb-2'>
            <TextGeneric size={0.95} color='white'>
              <b>Confirmar correo de notificación</b>
            </TextGeneric>
          </Row>
        </Container>
        <p className={Style.description}>
          Selecciona el email que deseas que sea tu correo principal. A dicha
          casilla llegarán todas las notificaciones del Portal de Clientes
          Progreso.
        </p>
        <div className={Style.containerInputDisabled}>
          <label htmlFor='Rut'>RUT:</label>
          <input id='Rut' type='text' disabled placeholder={rut} />
        </div>
        <div className={Style.containerEmails}>
          {allEmails?.map((element) => (
            <div
              className={Style.containerAllCheckEmail}
              key={element.idContact}>
              <input
                onChange={(e) =>
                  setContact({
                    ...contact,
                    idContact: element.idContact,
                    email: e.target.value,
                    rutS: rut,
                  })
                }
                type='radio'
                name='emailSelection'
                value={element.email}
                disabled={element.email === ""}
              />
              <div className={Style.containerEmail} key={element.idContact}>
                {element.email && hideEmail(element.email)}
              </div>
            </div>
          ))}
        </div>
        <button
          disabled={contact.email === ""}
          onClick={() => {
            handleClick();
          }}>
          ENVIAR
        </button>

        <p className={Style.contactExecutiveText}>
          Si deseas registrar un correo diferente a los que se muestran, por
          favor contacta a tu ejecutivo.
        </p>
      </div>
    </div>
  );
};

export default SelectMasterEmail;
