import React, { useEffect } from "react";
import ClienteLoginSeccion from "../../components/clientes-login-seccion/ClienteLoginSeccion";
import ClienteCambioPassword from "../../components/clientes/ClienteCambioPassword";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const Clientes = () => {
  useEffect(() => {}, []);
  return (
    <>
      <ClienteLoginSeccion>
      <GoogleReCaptchaProvider
        reCaptchaKey={window.RECAPTCHA_KEY}
        scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: 'head', // optional, default to "head", can be "head" or "body",
        nonce: undefined // optional, default undefined
      }}>
        <ClienteCambioPassword></ClienteCambioPassword>
      </GoogleReCaptchaProvider>
      </ClienteLoginSeccion>
    </>
  );
};
export default Clientes;
