import styled from "styled-components";
import { Container } from "react-bootstrap";

export const CustomContainer = styled(Container)`
  .file-info {
    background-color: white;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 8px;
    display: flex;
    .icon-button {
      border: none;
      background: none;
      padding: 0px 7px 3px 7px;
      transition: 0.5s color;
      color: grey;
    }
    .icon-button:hover {
      color: black;
    }
  }
  .form-control:focus {
    box-shadow: none;
  }
  .input-group {
    > button:focus {
      outline: none;
    }
  }
`;

export const FilesReceiver = styled.div`
  padding: 2rem;
  background: ${({ dragActive, dragRejected, fileSelected }) =>
    fileSelected
      ? "#fdd1359c"
      : dragRejected
      ? "#dc354573"
      : dragActive
      ? "#fdd1356e"
      : "#eceaea"};
  border: 2px dashed
    ${({ fileSelected }) => (fileSelected ? "#6c757d" : "black")};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition-property: background, border;
  transition-duration: 0.5s;

  .icon {
    display: flex;
    justify-content: center;
  }

  .text {
    text-align: center;
    > span {
      color: #495057 !important;
    }
  }
`;
