import axios from "axios";
import { BASE } from "./url";
import { Service } from "axios-middleware";
import jwt_decode from "jwt-decode";

export const authHeader = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user?.token) {
    return {
      Authorization: `Bearer ${user?.token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    };
  }
};

export const api = axios.create({
  baseURL: BASE,
  headers: authHeader(),
  responseType: "json",
});
api.interceptors.request.use(
  (config)=>{
  const token = JSON.parse(localStorage.getItem("user"))?.token;
  if(token){
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
  },(error)=>{
    return Promise.reject(error)
  }
);

api.interceptors.response.use(
  (response) => {
    if (response.status === 401 || response.status === 403) {
      window.location.href = "/";
      return;
    }
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401 || error.response.status === 403) {
        window.location.href = "/";
        return;
      }
    }

    return Promise.reject(error);
  }
);

api.interceptors.request.use(
  (request) => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user !== null) {
      let token_data = jwt_decode(user.token);
      if (token_data.exp < Date.now() / 1000) {
        window.location.href = "/";
        return;
      }
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);
/* api.interceptors.request.use(
  async (request) => {
    if(!(request.url.includes("rToken") || request.url.includes("getKey"))){
      let user = JSON.parse(localStorage.getItem("user"));
      if (user !== null) {
        let token_data = jwt_decode(user.token);
        if ( (token_data.exp - (Date.now() / 1000)) < 280) {
          const { data } = await api.get(PORTALSAPI.GET.REFRESH_TOKEN.replace("{idcontacto}", user.idcontacto));
          const userJson = JSON.parse(localStorage.getItem("user"))
          console.log({url : request.url})
          console.log({request})
          localStorage.setItem("user", JSON.stringify({...userJson, token : data.token }));
          sessionStorage.setItem("temporal", JSON.stringify(data.public_key));
          return request;
        }
      }
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
); */
api.interceptors.request.use(
  async (request) => {
    if(!(request.url.includes("rToken") || request.url.includes("getKey"))){
      let user = JSON.parse(localStorage.getItem("user"));
      if (user !== null) {
      let rToken = JSON.parse(localStorage.getItem("rtoken"));
      if(rToken != null){
        let token_data = jwt_decode(user.token);
        if ( (token_data.exp - (Date.now() / 1000)) < window.TIEMPO_OBTENDRA_RTOKEN) {//Si interactúa el usuario, tomará el rtoken obtenido
          try {
            const userJson = JSON.parse(localStorage.getItem("user"))
              localStorage.setItem("user", JSON.stringify({...userJson, token : rToken.t }));
              //sessionStorage.setItem("temporal", rToken.tmp);
              localStorage.removeItem("rtoken");
              
            } catch (error) {
              console.log(error)
            }
            return request;
          }
        }
      }
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);
const service = new Service(api);
service.register({
  onResponse(resp) {
    const {
      data: { data, message },
      status,
    } = resp;
    return { data, status, message };
  },
});
