//Librerias
import React, { useState } from "react";
import useWindowDimensions from "../../../helpers/useWindowDimensions";

//Componentes
import TablaFacturas from "../TablaFacturas";
import Resumen from "../tabla-resumen/Resumen.js";
import { getResumeBills, getRut, reloadCosts } from "../../../helpers/autoAtencionHelpers";

import "./styles.css";

const TablaResumen = ({ Button, loading, setLoading }) => {
  const [rows, setRows] = useState([]);
  const { width } = useWindowDimensions();

  const handleReload = async () => {
    try {
      setLoading(true);
      const newBills = await getResumeBills();
      await reloadCosts(await getRut())
      setRows(newBills);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div
      className={`d-flex ${width <= 950 ? "flex-wrap" : null}`}
      style={{ gap: "1rem" }}
    >
      <div style={{ overflowX: "auto" }}>
        <TablaFacturas
          type="show"
          rows={rows}
          setRows={setRows}
          loading={loading}
          setLoading={setLoading}
        />
      </div>

      <div style={{ flexGrow: 1 }}>
        <Resumen
          text="Simulación de Factoring"
          loading={loading}
          reload={handleReload}
        />
        <Button tableLoading={rows.length === 0 ? true : false} />
      </div>
    </div>
  );
};

export default TablaResumen;
