import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

export const getNumberOfPages = (rowCount, rowsPerPage) =>
  Math.ceil(rowCount / rowsPerPage);
/* const getPageInfo = (rowsPerPage, page, tipo, total = null) => {
	if (tipo === 'first') return rowsPerPage * (page - 1) + 1;
	return getNumberOfPages(total, rowsPerPage) === page ? rowsPerPage * (page - 1) + (total % rowsPerPage) : rowsPerPage * (page - 1) + rowsPerPage;
}; */
function TablePaginationActions({
  count,
  page,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
  paginationRowsPerPageOptions,
}) {
  const [active, setActive] = useState(1);
  const handlePrevious = (page) => {
    if (page > 1) {
      setActive(page - 1);
      onChangePage(page - 1);
    }
  };

  const handleNext = (page) => {
    if (page < getNumberOfPages(count, rowsPerPage)) {
      setActive(page + 1);
      onChangePage(page + 1);
    }
  };
  useEffect(()=>{
    	onChangePage(page);
    	setActive(page);
    },[page]);
  /* const handleRowsPerPage = ({target}) => onChangeRowsPerPage(Number(target.value), page); */

  const handleChangePage = (page) => {
    onChangePage(page);
    setActive(page);
  };

  const ButtonLink = ({ number, handleChangePage }) => {
    const numberLink = {
      display: "flex",
      alignItems: "center",
      color: "black",
      borderRadius: "100%",
    };
    return (
      <li
        className={`d-flex paginate_button page-item ${
          number === active ? "active" : ""
        }`}
        onClick={handleChangePage}
      >
        <a style={numberLink} className="page-item page-link">
          {number}
        </a>
      </li>
    );
  };

  const renderPageNumber = (total, current) => {
    var shownPages =
      getNumberOfPages(count, rowsPerPage) > 5
        ? 5
        : getNumberOfPages(count, rowsPerPage);
    var result = [];
    let button = null;
    let totalPages = getNumberOfPages(count, rowsPerPage);
    let first = (
      <ButtonLink handleChangePage={() => handleChangePage(1)} number={1} key={`paso-${1}`}/>
    );
    let last = (
      <ButtonLink
        handleChangePage={() => handleChangePage(totalPages)}
        number={totalPages}
        key={`paso-${totalPages}`}
      />
    );

    // si la pagina actual === 1 renderiza los primeros 5 + .... + total

    if (current === 1 || current < shownPages) {
      for (let i = 1; i <= shownPages; i++) {
        button = (
          <ButtonLink handleChangePage={() => handleChangePage(i)} number={i} key={`paso-${i}`}/>
        );
        result.push(button);
      }
      if (shownPages !== totalPages) {
        button = (
          <ButtonLink
            handleChangePage={() => handleChangePage(totalPages)}
            number={totalPages}
            key={`paso-${totalPages}`}
          />
        );
        result.push("...", button);
      }
      return result;
    }
    // si la pagina actual === total renderiza 1 + .... + total - 5

    if (totalPages === current || current > totalPages - shownPages) {
      if (shownPages !== totalPages) {
        result.push(first, "...");
      }
      for (let i = shownPages - 1; i >= 0; i--) {
        button = (
          <ButtonLink
            handleChangePage={() => handleChangePage(totalPages - i)}
            number={totalPages - i}
            key={`paso-${totalPages - i}`}
          />
        );
        result.push(button);
      }
      return result;
    }

    result.push(first, "...");
    for (let i = -1; i <= 1; i++) {
      // renderiza 1 + ... + actual - 1 , actual, actual + 1 + ... + total
      button = (
        <ButtonLink
          handleChangePage={() => handleChangePage(current + i)}
          number={current + i}
          key={`paso-${current + i}`}
        />
      );
      result.push(button);
    }
    result.push("...", last);

    return result;
  };

  return (
    <>
      <div className="d-flex justify-content-center mt-3">
        <div
          className="dataTables_paginate paging_simple_numbers"
          style={{ display: "flex", justifyContent: "end" }}
        >
          <ul className="pagination">
            {getNumberOfPages(count, rowsPerPage) === 1 ? (
              <>{renderPageNumber(count, page)}</>
            ) : (
              <>
                <li
                  className="paginate_button page-item previous disabled"
                  onClick={() => handlePrevious(page)}
                  disabled={page === 1}
                  aria-label="first page"
                >
                  <a>{"<"} </a>
                </li>
                {renderPageNumber(count, page)}
                <li
                  className="paginate_button page-item next"
                  onClick={() => handleNext(page)}
                  disabled={page >= getNumberOfPages(count, rowsPerPage)}
                  aria-label="last page"
                >
                  <a> {">"} </a>
                </li>{" "}
              </>
            )}
          </ul>
        </div>
      </div>
    </>
  );
}
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const TablePaginationTest = ({
  ActionsComponent,
  paginationRowsPerPageOptions,
  count,
  onChangePage,
  page,
  rowsPerPage,
  onChangeRowsPerPage,
}) => {
  return (
    <div>
      <ActionsComponent
        paginationRowsPerPageOptions={paginationRowsPerPageOptions}
        onChangeRowsPerPage={onChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        count={count}
        onChangePage={onChangePage}
      />
    </div>
  );
};
export const CustomPaginacion = ({
  rowsPerPage,
  rowCount,
  onChangePage,
  onChangeRowsPerPage,
  currentPage,
}) => (
  <TablePaginationTest
    component="nav"
    count={rowCount}
    rowsPerPage={rowsPerPage}
    page={currentPage}
    onChangePage={onChangePage}
    paginationRowsPerPageOptions={[10, 25, 50, 100]}
    onChangeRowsPerPage={onChangeRowsPerPage}
    ActionsComponent={TablePaginationActions}
  />
);

CustomPaginacion.propTypes = {
  rowsPerPage: PropTypes.number.isRequired, // calculated rows per page state from DataTable
  rowCount: PropTypes.number.isRequired, // calculated row count from DataTable
  onChangePage: PropTypes.func.isRequired, // you want to "callback" the updated page number to DataTable so it can update its state
  onChangeRowsPerPage: PropTypes.func.isRequired, // you want to "callback" the updated rows per (newRowsPerPage, currentPage) to DataTable so it can update its state
  currentPage: PropTypes.number.isRequired, // the current page state from DataTable
};
