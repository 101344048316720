import React from "react";
import DataTable from "react-data-table-component";
import { CustomPaginacion } from "./Paginacion";
import loadingScreen from "../../assets/images/loadingScreen.gif";

import { IconContext } from "react-icons/lib";
import { BiDownArrow } from "react-icons/bi";
import "./tabla.css";

export const xs = "80px";
export const small = "100px";
export const medium = "150px";
export const large = "170px";
export const xl = "200px";
export const xxl = "250px";

const customStyles = {
  headCells: {
    style: {
      textAlign: "center",
    },
  },
  cells: {
    style: {},
  },
  rows: {
    style: {},
  },
};

const paginationComponentOptions = {
  rowsPerPageText: "Registros por página:",
  rangeSeparatorText: "de",
  noRowsPerPage: false,
  selectAllRowsItem: false,
  selectAllRowsItemText: "Todos",
};

const FilterComponent = ({ filterText, onFilter }) => (
  <>
    <div class="col-sm-12 col-md-6">
      <div id="kt_table_factoring_filter" class="dataTables_filter">
        <label className="d-flex">
          <span className="dataTables_search">Buscar: </span>
          <input
            autoComplete="new-off"
            className="form-control form-control-sm"
            id="search"
            type="text"
            value={filterText}
            onChange={onFilter}
          />
        </label>
      </div>
    </div>
  </>
);

const NoDataComponent = () => (
  <p className="m-3 text-muted">No existen registros que mostrar.</p>
);

const CustomLoader = () => {
  return <img src={loadingScreen} alt="loading-screen" />;
};

const Tabla = ({
  busqueda,
  paginacion,
  numeroFilas = 3,
  conditionalRowStyles,
  toggledClearRows,
  type = "radio",
  selectableRowsNoSelectAll,
  selectableRowSelected,
  selectableRowDisabled,
  selectableRowsSingle,
  onSelectedRowsChange,
  selectableRows,
  loading = false,
  datos: { columns = [], rows = [] },
  defaultSortAsc,
  defaultSortFieldId,
  otherProperties,
}) => {
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

  const onSort = (selectedColumn, sortDirection, sortedRows)=>{
  		setResetPaginationToggle(true);
  	}

  const filteredItems = rows.filter((item) =>
  columns.some((col) =>
    {
      const value = ( typeof col.selector(item) === 'object')?(col.selector(item).props.children):(col.selector(item));
       return value &&
       value
        .toString()
        .toLowerCase()
        .includes(filterText.toLowerCase())
        ? item
        : false}
    )
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        filterText={filterText}
      />
    );
  }, [filterText]);

  const SortIcon = () => {
    return (
      <IconContext.Provider value={{ className: "table-sort-icon" }}>
        <BiDownArrow />
      </IconContext.Provider>
    );
  };

  return (
    <DataTable
      title="Bandeja"
      /* Data */
      data={filteredItems}
      columns={columns}
      /* Properties */
      noHeader
      persistTableHead
      noTableHead={false}
      /* Searchbox */
      subHeader={busqueda}
      /* Pagination */
      pagination={paginacion}
      paginationDefaultPage={1}
      paginationResetDefaultPage={resetPaginationToggle}
      paginationComponent={CustomPaginacion}
      paginationPerPage={numeroFilas}
      paginationRowsPerPageOptions={[10, 20, 30, 50]}
      /* Messages */
      paginationComponentOptions={paginationComponentOptions}
      /* Components */
      noDataComponent={<NoDataComponent />}
      subHeaderComponent={busqueda ? subHeaderComponentMemo : <></>}
      /* Checkboxs */
      selectableRows={selectableRows}
      selectableRowDisabled={selectableRowDisabled}
      onSelectedRowsChange={onSelectedRowsChange}
      selectableRowSelected={selectableRowSelected}
      clearSelectedRows={toggledClearRows}
      selectableRowsComponentProps={{ type }}
      /* Radio */
      selectableRowsNoSelectAll={selectableRowsNoSelectAll}
      selectableRowsSingle={selectableRowsSingle}
      /* Conditional styles */
      conditionalRowStyles={conditionalRowStyles}
      /* Styles */
      customStyles={customStyles}
      /*Loading*/
      progressPending={loading}
      progressComponent={<CustomLoader />}
      /*Sorting*/
      sortIcon={<SortIcon />}
      defaultSortAsc={defaultSortAsc}
      onSort={onSort}
      defaultSortFieldId={defaultSortFieldId}
      {...otherProperties}
    />
  );
};

Tabla.defaultProps = {
  datos: {
    rows: [],
    columns: [],
  },
  rows: [],
  columns: [],
  conditionalRowStyles: [],
  paginacion: true,
  busqueda: true,
  selectableRows: false,
  onSelectedRowsChange: () => {
    return;
  },
  selectableRowsComponent: null,
};

export default Tabla;
