import React, { useContext } from 'react'
import { Modal, Row, Container, Col, Button } from "react-bootstrap";
import { Title, TextColored } from "../../components/typography";
import { ThemeContext } from "../../theming";
import Close from "../../assets/images/close.png";

function SessionExpiring({
    size = "md",
    show,
    icon,
    handleClose,
    handleLogOut,
    handleRefresh,
    headerTitle = "Alerta",
    title = "",
    subtitle = "",
    message = "",
    messageLine = false,
  }) {
    return (
        <Modal show={show} onHide={handleClose} size={size}>
         <Modal.Header
          style={{display: "block", justifyContent: "space-between", alignItems: "center"}}
          closeButton={!!handleClose}
          >
              <span style={{marginTop: "6px", position: "absolute"}}>
                <Title title={headerTitle} subtitle="" pipe={false} />
              </span>
    
          </Modal.Header>
          <Modal.Body>
            <Container fluid>
              <Row className="justify-content-center align-content-center">
                <Col xs={4}>{icon}</Col>
                <Col xs={8}>
                  <Row className="justify-content-center">{title}</Row>
                  <Row className="justify-content-center">{subtitle} </Row>
                  {messageLine === false ? (
                    <Row className="justify-content-center text-center">
                      {message}
                    </Row>
                  ) : (
                    <></>
                  )}
                    <Row className="justify-content-center mt-3">
                        <button
                        type="button"
                        class="btn btn-cerrar-sesion w-auto mr-4"
                        onClick={handleLogOut}
                        style={{ backgroundColor : "#e5e5e5",
                                color : "black",
                                fontWeight : 500}}
                        >Cerrar sesión</button>
                        <button
                        type="button"
                        class="btn btn-warning w-auto"
                        onClick={handleRefresh}
                        style={{ fontWeight : 500}}>Continuar</button>
                    </Row>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      );
}

export default SessionExpiring