import { pki, util } from "node-forge";

export const DecryptData = (cipher, privateKey = "") => {
  try {
    var encrypt = util.decode64(cipher);
    var rsa = pki.privateKeyFromPem(`-----BEGIN PRIVATE KEY-----
        ${privateKey}
        -----END PRIVATE KEY-----`);
    var enc = rsa.decrypt(encrypt);
    return util.decodeUtf8(enc);
  } catch (err) {
    //Malformed UTF Data due to incorrect password
    return "";
  }
};

function base64ToHex(str) {
  const raw = atob(str);
  let result = "";
  for (let i = 0; i < raw.length; i++) {
    const hex = raw.charCodeAt(i).toString(16);
    result += hex.length === 2 ? hex : "0" + hex;
  }
  return result.toUpperCase();
}

function toBinary(string) {
  const codeUnits = new Uint16Array(string.length);
  for (let i = 0; i < codeUnits.length; i++) {
    codeUnits[i] = string.charCodeAt(i);
  }
  return btoa(String.fromCharCode(...new Uint8Array(codeUnits.buffer)));
}

export const EncryptData = (text, publicKey) => {
  try {
    var rsa = pki.publicKeyFromPem(`-----BEGIN PUBLIC KEY-----
        ${publicKey}
        -----END PUBLIC KEY-----`);
    var enc = rsa.encrypt(text);
    return window.btoa(enc);
  } catch (err) {
    //Malformed UTF Data due to incorrect password
    return "";
  }
};

export const EncryptDataLocal = (text, publicKey) => {
  try {
    var rsa = pki.publicKeyFromPem(`-----BEGIN PUBLIC KEY-----
        ${publicKey}
        -----END PUBLIC KEY-----`);
    var enc = rsa.encrypt(text);
    return util.encode64(enc);
  } catch (err) {
    //Malformed UTF Data due to incorrect password
    return "";
  }
};

export const DecryptDataLocal = (cipher, privateKey = "") => {
  try {
    var encrypt = util.decode64(cipher);
    var rsa = pki.privateKeyFromPem(`-----BEGIN PRIVATE KEY-----
        ${privateKey}
        -----END PRIVATE KEY-----`);
    return rsa.decrypt(encrypt);
  } catch (err) {
    //Malformed UTF Data due to incorrect password
    return "";
  }
};
