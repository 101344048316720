import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useForm } from "react-hook-form";
import {
  DecryptData,
  EncryptData,
  EncryptDataLocal,
} from "../../helpers/encrypt";
import {
  Row,
  Container,
  Col,
  Card,
  Form,
  Overlay,
  Tooltip,
} from "react-bootstrap";
import Button from "../../components/buttons/Button";
import { formatDate } from "../../helpers/validators";
import {
  tipoSolicitudes,
  paisesCertificadoInternacional,
} from "../../constants/dashboard";
import Tabla from "../../components/tabla/Tabla";
import IconHelp from "../../assets/icons/forms/info-circle.svg";
import { mobileXSWidth } from "../../helpers/responsiveVariables";
import useWindowDimensions from "../../helpers/useWindowDimensions";

import { useRef } from "react";
export default function RenderContent2(typeobj) {
  const { register, setValue, getValues, watch, handleSubmit } = useForm();
  let type = typeobj.type;
  const [showTool, setShowTool] = useState(false);
  const [paises, setPaises] = useState("");
  const [vehiculo, setVehiculo] = useState("");
  const [formato, setFormato] = useState("");
  const [bienSeleccionado, setBienSeleccionado] = useState(null);
  const [bienesId, setBienesId] = useState(null);
  const [tipoPersona, setTipoPersona] = useState("");
  const [select, setSelect] = useState(null);

  const [tipo_solicitud, setTipoSolicitud] = useState("");
  const [nrooperacion, setNrooperacion] = useState(null);

  const target = useRef(null);
  const { width } = useWindowDimensions();

  const {
    /* nrooperacion, */
    /* tipo_solicitud, */
    /* id_bien, */
    /* prendas, */
    rut,
    fecha_inicio,
    fecha_fin,
  } = watch([
    /*  "nrooperacion", */
    /* "tipo_solicitud", */
    /* "id_bien", */
    /* "prendas", */
    "rut",
    "fecha_inicio",
    "fecha_fin",
  ]);

  let meta_data = [];

  let contacto = typeobj.data?.usuario;
  let publicKey = typeobj?.public_key;
  const correoContacto = DecryptData(contacto.correo, publicKey);
  //Defaults
  const [correoDef, setCorreoDef] = useState(correoContacto);
  const [monedaOp, setMonedaOp] = useState("");

  let tipoVehiculo = typeobj.data?.tipo_vehiculo;
  let tipoSolicitud = typeobj.data?.tipo_solicitudes;
  let tipoSolicitudFormulario = typeobj.data?.tipo_solicitudes_formularios;
  let tipoSolicitudWFormulario = tipoSolicitud.map((tipo) => ({
    ...tipo,
    formulario: tipoSolicitudFormulario.filter(
      (formu) => formu.id_tipo_solicitud === tipo.id
    ),
  }));

  tipoSolicitudWFormulario = [...tipoSolicitudes, ...tipoSolicitudWFormulario];
  tipoSolicitudWFormulario = tipoSolicitudWFormulario.map((tipo) => ({
    ...tipo,
    value: tipo.name,
    label: tipo.name,
    formulario: tipo.formulario.sort((a, b) => {
      return a.orden - b.orden;
    }),
  }));
  let tipoSeleccionado = {};

  let operacion = typeobj.data?.leasing.find(
    (operacion) => operacion.numero.toString() === nrooperacion
  );
  let camposFormulario = [];
  tipoSeleccionado = tipoSolicitudWFormulario.find(
    (tipo) => tipo.name === tipo_solicitud
  );
  let tipoPersonaOptions =
    Array.isArray(tipoSeleccionado?.formato) &&
    tipoSeleccionado?.formato !== null
      ? tipoSeleccionado?.formato?.map((f) => ({
          value: f.key_Desc,
          label: f.key_Desc,
        }))
      : tipoSeleccionado?.formato;

  let bienesOpciones = operacion?.lista_bienes.map((bien) => ({
    ...bien,
    isdisabled: !!bien?.cesiones.find(
      (cesion) => cesion.id_tipo_solicitud == tipoSeleccionado?.id
    )?.generado,
  }));
  meta_data = tipoSeleccionado?.formulario.map((input) => ({
    label: input.label,
    value: input.name,
  }));
  camposFormulario = tipoSeleccionado?.formulario
    ? tipoSeleccionado.formulario
    : [];

  let id_operacion = operacion?.idoperacion;
  let formatos = tipoSeleccionado?.formato;
  useEffect(() => {
    if (!Array.isArray(formatos) && formatos !== null) {
      setFormato(formatos);
    }
  }, [formatos]);
  let id_solicitud = tipoSeleccionado?.id;

  register("estado");
  setValue("estado", 0);

  register("contacto");
  setValue("contacto", typeobj.data?.usuario?.idcontacto);

  register("bienes");
  setValue("bienes", bienSeleccionado);

  register("bienes_id");
  setValue("bienes_id", bienesId);

  register("paises");
  setValue("paises", paises);

  register("tipo_vehiculos");
  setValue("tipo_vehiculos", vehiculo);

  register("tipo_personas");
  setValue("tipo_personas", tipoPersona);

  register("tipo_solicitud");
  setValue("tipo_solicitud", tipo_solicitud);

  register("id_nrooperacion");
  setValue("id_nrooperacion", id_operacion);

  register("nrooperacion");
  setValue("nrooperacion", nrooperacion);

  register("formato");
  setValue("formato", formato);

  register("id_solicitud");
  setValue("id_solicitud", id_solicitud);

  register("meta_data");
  setValue("meta_data", meta_data);

  const formateaRut = (actual) => {
    if (actual !== undefined) {
      if (actual != "" && actual.length > 1) {
        var sinPuntos = actual.replace(/\./g, "");
        var actualLimpio = sinPuntos.replace(/-/g, "");
        var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
        var rutPuntos = "";
        var i = 0;
        var j = 1;
        for (i = inicio.length - 1; i >= 0; i--) {
          var letra = inicio.charAt(i);
          rutPuntos = letra + rutPuntos;
          if (j % 3 === 0 && j <= inicio.length - 1) {
            rutPuntos = "." + rutPuntos;
          }
          j++;
        }
        var dv = actualLimpio.substring(actualLimpio.length - 1);
        rutPuntos = rutPuntos + "-" + dv;
        return rutPuntos;
      }
    }
    return actual;
  };
  const resetValues = () => {
    setSelect((prev) => ({
      nrooperacion: prev?.nrooperacion ? (prev.nrooperacion ??= null) : null,
    }));

    //states react
    setValue("paises", "");
    setValue("bienes", "");
    setValue("tipo_vehiculos", "");
    setValue("tipo_personas", "");
    setValue("bienes_id", "");
    setValue("formato", "");

    setPaises("");
    setVehiculo("");
    setTipoPersona("");
    setBienSeleccionado(null);
    setBienesId(null);
    setFormato("");
    //setNrooperacion(null);

    //defaults
    setCorreoDef(correoContacto);

    //constantes
    setValue("nrooperacion", "");
    setValue("rut", "");
    setValue("fecha_inicio", "");
    setValue("fecha_fin", "");

    //let
    setValue("id_nrooperacion", "");
    setValue("meta_data", "");

    tipoSolicitudWFormulario.forEach((tipo) => {
      tipo.formulario.forEach((formu) => {
        setValue(formu.name, "");
      });
    });
    /* console.log(correoContacto)
    tipoSolicitudWFormulario.find(
      (tipo) => tipo.name === tipoSolicitud
    )?.formulario?.forEach(formu => {
      console.log(formu)
      if(formu.name == "correo"){
        setValue("correo", correoContacto)
      }else{
        setValue(formu.name, "")
      }
    }); */

    //setValue("correo",correoContacto);
    /*  setValue("placa", "");
     setValue("marca", "");
     setValue("modelo", "");
     setValue("correo", "");
     setValue("comentario", "");
     setValue("nombre", "");
     setValue("anio", "");
     setValue("motor", "");
     setValue("chasis", "");
     setValue("patente", "");
     setValue("prenda_marca", "");
     setValue("prenda_modelo", "");
     setValue("prenda_patente", ""); */
  };
  const handleKeyPress = (e) => {
    const numbers = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "K",
      "k",
    ];
    const alts = ["Backspace", "Tab", "Enter", "ArrowLeft", "ArrowRight"];

    if (numbers.indexOf(e.key) === -1 && alts.indexOf(e.key) === -1) {
      if (e.key !== "v" || e.ctrlKey !== true) {
        e.preventDefault();
        return;
      }
    }
  };

  const setValueType = (campo) => {
    if (campo?.defaultValue) {
      return getDefaultValue(campo?.defaultValue);
    } else {
      switch (campo.name) {
        case "fecha_inicio":
          return fecha_inicio;
        case "fecha_fin":
          return fecha_fin;
        case "paises":
          return paises;
        case "bienes":
          return bienSeleccionado;
        /*         case "tipo_solicitud":
          return tipoSeleccionado; */
        case "tipo_vehiculos":
          return vehiculo;
        case "tipo_personas":
          return tipoPersona;
        case "rut":
          return rut;
        default:
          return null;
      }
    }
  };
  const getMaxorMin = (minormax) => {
    if (isNaN(minormax)) {
      switch (minormax) {
        case "now":
          return formatDate(null, "yyyy-mm-dd");
        case "fecha_fin":
          return fecha_fin
            ? formatDate(
                new Date(fecha_fin).setDate(new Date(fecha_fin).getDate() + 1),
                "yyyy-mm-dd"
              )
            : null;
        case "fecha_inicio":
          return formatDate(
            new Date(fecha_inicio).setDate(
              new Date(fecha_inicio).getDate() + 1
            ),
            "yyyy-mm-dd"
          );
        default:
          return null;
      }
    } else {
      return minormax;
    }
  };
  const getDefaultValue = (def) => {
    switch (def) {
      case "correoContacto":
        return correoDef;
      case "monedaOp":
        return monedaOp;
      default:
        return null;
    }
  };
  const getOnChangeDefault = (name) => {
    switch(name){
      case "correo":
        return (e) => {
          setValue(name, e.target.value);
          setCorreoDef(e.target.value);
        };
      case "moneda":
        return (e) => {
          setValue(name, e.target.value);
          setMonedaOp(e.target.value);
        };
      default:
        return null;
    }
  };
  const getOnChange = (onchange) => {
    switch (onchange) {
      case "fecha_inicio":
        return (e) => setValue("fecha_inicio", e.target.value);
      case "fecha_fin":
        return (e) => setValue("fecha_fin", e.target.value);
      case "paises":
        return (value) => {
          if (value === null) {
            setSelect((prev) => ({ ...prev, paises: null }));
            setPaises("");
          } else {
            setSelect((prev) => ({ ...prev, paises: value }));
            const values = Array.isArray(value)
              ? value.map((v) => v.value)
              : value.value;
            setPaises(values.toString());
          }
        };
      case "tipo_vehiculos":
        return (value) => {
          if (value === null) {
            setSelect((prev) => ({ ...prev, tipo_vehiculos: null }));
            setVehiculo("");
          } else {
            setSelect((prev) => ({ ...prev, tipo_vehiculos: value }));
            const values = Array.isArray(value)
              ? value.map((v) => v.value)
              : value.value;
            setVehiculo(values.toString());
          }
        };
      case "tipo_personas":
        return (value) => {
          if (value === null) {
            setSelect((prev) => ({ ...prev, tipo_personas: null }));
            setTipoPersona("");
          } else {
            setSelect((prev) => ({ ...prev, tipo_personas: value }));
            const values = Array.isArray(value)
              ? value.map((v) => v.value)
              : value.value;
            setTipoPersona(values.toString());
            //Condicional para cambiar de formato en caso de cesion
            setFormato(formatos.find((f) => f.key_Desc == value.value).value);
          }
        };
      case "bienes":
        return (value) => {
          if (value === null) {
            setSelect((prev) => ({ ...prev, bienes: null }));
            setBienSeleccionado(null);
            setBienesId(null);
          } else {
            setSelect((prev) => ({ ...prev, bienes: value }));
            const values = Array.isArray(value)
              ? value.map((v) => v.label)
              : value.label;
            setBienesId(
              (Array.isArray(value)
                ? value.map((v) => v.value)
                : value.value
              ).toString()
            );
            setBienSeleccionado(values.toString());
          }
        };
      case "tipo_solicitud":
        return (value) => {
          setShowTool(false);
          if (value === null) {
            setTipoSolicitud(null);
          } else {
            const values = Array.isArray(value)
              ? value.map((v) => v.value)
              : value.value;
            setTipoSolicitud(values.toString());
            resetValues();
          }
        };
      case "nrooperacion":
        return (value) => {
          setShowTool(false);
          setSelect((prev) => ({ ...prev, bienes: null }));
          setBienSeleccionado(null);
          setBienesId(null);
          if (value === null) {
            setSelect((prev) => ({ ...prev, nrooperacion: null }));
            setNrooperacion(null);
          } else {
            setSelect((prev) => ({ ...prev, nrooperacion: value }));
            const values = Array.isArray(value)
              ? value.map((v) => v.value)
              : value.value;
            setNrooperacion(values.toString());
          }
        };
      case "rut":
        return (e) => setValue("rut", formateaRut(e.target.value));
      default:
        break;
    }
  };
  const getOnKeyDown = (onkeydown) => {
    switch (onkeydown) {
      case "preventDefault":
        return (e) => e.preventDefault();
      case "limit4":
        return (e) => {
          if (e.target.value.length > 3 && e.key !== "Backspace") {
            e.preventDefault();
            return;
          }
        };
      case "rut":
        return handleKeyPress;
      default:
        break;
    }
  };
  const renderOptionsSelect = (options) => {
    switch (options) {
      case "tipo_vehiculos":
        return tipoVehiculo;
      case "tipo_personas":
        return tipoPersonaOptions;
      case "paises":
        return paisesCertificadoInternacional;
      case "bienes":
        return bienesOpciones;
      default:
        break;
    }
  };
  const   renderType_as = (campo) => {
    let readOnly = campo.readOnly??false;
    switch (campo.type_as) {
      case "select":
        return (
          <Select
            isMulti={campo?.select_isMulti}
            closeMenuOnSelect={campo?.select_closeMenuOnSelect}
            captureMenuScroll={campo?.select_captureMenuScroll}
            name={campo?.name}
            options={renderOptionsSelect(campo?.select_options)}
            isOptionDisabled={(option) => option?.isdisabled}
            className='basic-multi-select'
            classNamePrefix='select'
            required={campo?.required}
            onChange={getOnChange(campo?.name)}
            value={select ? (select[campo?.name] ??= null) : null}
          />
        );
      default:
        return (
          <Form.Control
            className={`justify-content-center ${campo.helpText ? "ml-3" : ""}`}
            name={campo.name}
            as={campo?.type_as}
            placeholder={campo?.placeholder}
            ref={register({ required: campo?.required })}
            maxLength={campo?.max ? campo.max : null}
            rows={campo?.rows ? campo.rows : null}
            readOnly={readOnly}
          />
        );
    }
  };

  const isValidEmail = (email) => {
    // Expresión regular para validar el formato de un correo electrónico
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const renderKindTypes = (campo) => {
    if (campo?.type_as) {
      return renderType_as(campo);
    } else {
      let valid = setValueType(campo);
      let isEmail = isValidEmail(valid);
      let readOnly = campo.readOnly??false;
      return (
        <Form.Control
          className={`justify-content-center ${campo.helpText ? "ml-3" : ""}`}
          name={campo.name}
          type={campo?.type}
          value={setValueType(campo)}
          placeholder={campo?.placeholder}
          ref={register({ required: campo?.required })}
          maxLength={getMaxorMin(campo?.maxLength)}
          rows={campo?.rows ? campo.rows : null}
          min={getMaxorMin(campo?.min)}
          max={getMaxorMin(campo?.max)}
          defaultValue={getDefaultValue(campo?.defaultValue)}
          onChange={
            campo?.defaultValue
              ? getOnChangeDefault(campo?.name)
              : getOnChange(campo?.name)
          }
          onKeyDown={getOnKeyDown(campo?.onkeydown)}
          onPaste={!campo?.onpaste ? (e) => e.preventDefault() : null}
          readOnly={isEmail || readOnly}
        />
      );
    }
  };
  useEffect(()=>{
    setMonedaOp(operacion?.moneda)
  },[nrooperacion]);

  return (
    <Container>
      <style jsx>
        {`
          input[type="text"],
          textarea {
            background-color: #ffffff !important;
          }
          input[type="number"],
          textarea {
            background-color: #ffffff !important;
          }
        `}
      </style>
      <Form onSubmit={handleSubmit(typeobj.onSubmitSolicitar)}>
        <Form.Group
          className='justify-content-center'
          as={Row}
          controlId='solicitudTipo'>
          <Form.Label column sm={4}>
            Tipo de Solicitud:
          </Form.Label>
          <Col xs={12} sm={6}>
            <Select
              isMulti={false}
              closeMenuOnSelect={true}
              captureMenuScroll={false}
              name='tipo_solicitud'
              options={tipoSolicitudWFormulario}
              className='basic-multi-select'
              classNamePrefix='select'
              required={true}
              onChange={getOnChange("tipo_solicitud")}
            />
            {/* <Form.Control
                >
                  <option value="">Seleccione</option>
                  {tipoSolicitudWFormulario.map((tipo) => (
                    <option>{tipo.name}</option>
                  ))}
                </Form.Control> */}
          </Col>
        </Form.Group>
        <Form.Group
          className='justify-content-center'
          as={Row}
          controlId='solicitudOperacion'>
          <Form.Label column sm={4}>
            Nro. Contrato:
          </Form.Label>
          <Col xs={12} sm={6}>
            <Select
              isMulti={false}
              closeMenuOnSelect={true}
              captureMenuScroll={false}
              name='nrooperacion'
              options={typeobj.data?.leasing.map((operacion) => ({
                value: operacion.numero,
                label: operacion.numero,
              }))}
              className='basic-multi-select'
              classNamePrefix='select'
              required={true}
              onChange={getOnChange("nrooperacion")}
              value={select ? (select["nrooperacion"] ??= null) : null}
            />
            {/* <Form.Control
              name="nrooperacion"
              as="select"
              ref={register({ required: true })}
              onChange={() => setSelect((prev)=>({
                ...prev,
                bienes : null
              }))}
            >
              <option value="">Seleccione</option>
              {typeobj.data?.leasing.map((operacion) => (
                <option value={operacion.numero}>{operacion.numero}</option>
              ))}
            </Form.Control> */}
          </Col>
        </Form.Group>

        {/* Listar campos de formulario */}
        {!!camposFormulario.length &&
          camposFormulario.map((campo) => {
            if (campo.type || campo.type_as) {
              return (
                <Form.Group
                  className='justify-content-center'
                  as={Row}
                  controlId={campo.controlId}>
                  <Form.Label column sm={4}>
                    {campo.label}
                  </Form.Label>
                  <Col
                    xs={12}
                    sm={6}
                    className={`${campo.helpText ? "pr-0" : ""}`}>
                    {campo.helpText ? (
                      <Row style={{ flexWrap: "nowrap" }}>
                        {renderKindTypes(campo)}
                        <img
                          ref={target}
                          src={IconHelp}
                          style={{ cursor: "pointer" }}
                          className={`${
                            width > mobileXSWidth ? "ml-3" : "mx-2"
                          }`}
                          alt=''
                          onClick={() => setShowTool(!showTool)}
                        />
                        <Overlay
                          target={target.current}
                          show={showTool}
                          placement={`${
                            width > mobileXSWidth ? "right" : "bottom"
                          }`}>
                          {(props) => (
                            <Tooltip id='overlay' {...props}>
                              {campo.helpText}
                            </Tooltip>
                          )}
                        </Overlay>
                      </Row>
                    ) : (
                      renderKindTypes(campo)
                    )}
                  </Col>
                </Form.Group>
              );
            }
          })}
        <Row className='justify-content-center'>
          <Col xs={4}>
            <Row className='mt-3'>
              <Button onClick={handleSubmit(typeobj.onSubmitSolicitar)}>
                {formatos ? "Descargar" : "Solicitar"}
              </Button>
            </Row>
          </Col>
          {formatos && (
            <Row className='justify-content-center'>
              <Col xs={10}>
                <Row className='mt-3 mb-3'>
                  <i style={{ fontSize: "12px", textAlign: "center" }}>
                    El siguiente documento es un formato tipo y editable, el
                    cual es completado con dato a la fecha actúal.
                  </i>
                </Row>
              </Col>
            </Row>
          )}
        </Row>
      </Form>
    </Container>
  );
}
