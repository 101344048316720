//Librerias
import React, { useCallback, useEffect, useState, useRef } from "react";

//Assets
import Pulgar from "../../assets/images/pulgar.png";
import Si from "../../assets/images/autoatencion.png";

//Helpers
import {
  mobileLDWidth,
  mobileXSWidth,
} from "../../helpers/responsiveVariables";
import useWindowDimensions from "../../helpers/useWindowDimensions";
import useStorage from "../../helpers/useStorage";
import {
  chargeExecutivePhone,
  getComertialInfo,
} from "../../helpers/autoAtencionHelpers";

//Componentes
import Button from "../../components/buttons/Button";
import { Col, Row, Spinner } from "react-bootstrap";
import { TextColored, TextGeneric } from "../typography";

const RenderTyC = ({ status }) => {
  if (status === 0) {
    return (
      <TextColored
        children="Datos 100% confidenciales y encriptados con alta seguridad."
        fontWeight={500}
        type="dark"
        size={0.8}
      />
    );
  }
  return (
    <a
      href="https://www.progreso.cl/compania/terminos-y-condiciones/"
      target="_blank"
      rel="noreferrer"
      style={{ fontWeight: 600, fontSize: "0.8rem", color: "#007bff" }}
    >
      Términos y condiciones de Progreso
    </a>
  );
};

const LoadingButton = () => {
  return (
    <div className="d-flex align-items-center" style={{ gap: "5px" }}>
      <Spinner animation="border" variant="dark" />
      <TextGeneric children={"Cargando"} size={1.3} />
    </div>
  );
};

export default function Seccion2CDL({
  openModal,
  contactado = "",
  modal,
  status = 0,
  setStatus = () => {
    return;
  },
  loading,
  setLoading,
  handleSubmit,
}) {
  const loadingInfo = useRef(false);
  const { width } = useWindowDimensions();
  const [{ tipo_acceso }] = useStorage("user", "", "local");

  const typesObj = {
    0: "¡Iniciar registro!",
    1: "¡Factoriza ahora!",
    2: "Actualizar Datos",
  };

  const getSuscriptorInfo = useCallback(async () => {
    try {
      if (loadingInfo?.current) {
        if (tipo_acceso === 1) {
          // tipo 1 = usuario contactado
          const estado_enrolamiento = await getComertialInfo();
          setStatus(estado_enrolamiento);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }, [tipo_acceso, setStatus]);

  useEffect(() => {
    if (!loadingInfo?.current) {
      setLoading(true);
      loadingInfo.current = true;
      sessionStorage.removeItem("current-step");
      sessionStorage.removeItem("isEmailSended");
      chargeExecutivePhone();
      getSuscriptorInfo();
      setLoading(false);
    }
  }, [getSuscriptorInfo, setLoading]);

  return (
    <>
      <Col
        style={{
          backgroundColor: "#292927",
          marginLeft: "15px",
          marginBottom: width > mobileXSWidth ? "0px" : "15px",
          marginRight: width > mobileXSWidth ? "0px" : "15px",
          borderRadius: "15px",
          padding: "2%",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
        }}
        lg={3}
      >
        <Row className="justify-content-center align-items-center m-auto">
          <Col
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <img
              height={width > mobileLDWidth ? 45 : 35}
              width={width > mobileLDWidth ? 45 : 35}
              alt=""
              src={Pulgar}
            />
            <TextGeneric
              className={width > mobileXSWidth ? "" : "text-center"}
              size={1.2}
              color={"white"}
            >
              <b>
                {contactado === ""
                  ? "¿Te podemos ayudar?"
                  : "Te damos la bienvenida"}
              </b>
            </TextGeneric>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col
            style={{
              textAlign: "center",
            }}
          >
            {contactado === "" ? (
              <TextGeneric size={1.0} color={"white"}>
                Contacta a tu ejecutivo
                <br />
                para lo que necesites,
                <br />
                ¡Estamos contigo!
              </TextGeneric>
            ) : (
              <TextGeneric size={1.0} color={"white"}>
                {contactado}
              </TextGeneric>
            )}
            {contactado === "" ? (
              <Row className="justify-content-center">
                <Button
                  onClick={() =>
                    openModal({
                      title: modal,
                      data: JSON.parse(localStorage.getItem("user")).idcontacto,
                    })
                  }
                  className="mt-3 mb-3 btn-primary-custom2"
                >
                  Contactar
                </Button>
              </Row>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </Col>

      {/* Seccion enrolamiento */}
      <Col
        className="fondo-dahboard-seccion"
        style={{
          marginLeft: "15px",
          marginRight: "15px",
          borderRadius: "10px",
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        {width > 1300 ? (
          <Row
            className="d-flex justify-content-evenly"
            style={{
              height: "100%",
              alignContent: "center",
              gap: "5px",
              padding: "0 2%",
            }}
          >
            <Col
              className="d-flex align-items-center justify-content-end"
              style={{ gap: "3rem" }}
            >
              <img height={width > mobileLDWidth ? 140 : 100} alt="" src={Si} />
              <div
                className="d-flex flex-column"
                style={{ whiteSpace: "nowrap" }}
              >
                <TextGeneric size={width > 1500 ? 1.4 : 1.2}>
                  ¿Deseas <b>factorizar</b>{" "}
                </TextGeneric>
                <TextGeneric size={width > 1500 ? 1.4 : 1.2}>
                  tus documentos de manera
                </TextGeneric>
                <TextGeneric size={width > 1500 ? 2.5 : 2}>
                  <b>rápida y segura?</b>
                </TextGeneric>
              </div>
            </Col>

            <Col
              className="d-flex flex-column justify-content-center align-items-center"
              style={{ paddingTop: "1rem", gap: "0.5rem" }}
              md={true}
            >
              <div className="d-flex justify-content-center">
                <Button
                  onClick={() => handleSubmit()}
                  className="btn-primary-custom2 btn-primary-custom3"
                  sizeText={width > 1500 ? "1.3" : "1"}
                  children={
                    loading ? (
                      <LoadingButton />
                    ) : (
                      typesObj[status] || <LoadingButton />
                    )
                  }
                  disabled={loading || (status === 1 && tipo_acceso !== 1)}
                />
              </div>
              <RenderTyC status={status} />
            </Col>
          </Row>
        ) : (
          <Row
            className="justify-content-center d-flex flex-column m-auto"
            style={{
              gap: "15px",
            }}
          >
            <Row className="d-flex align-items-center">
              <Col xs={"auto"}>
                <img height={80} alt="" src={Si} />
              </Col>

              <Col>
                <TextGeneric size={1.3}>
                  ¿Deseas factorizar tus documentos de manera{" "}
                  <b>rápida y segura</b>?
                </TextGeneric>
              </Col>
            </Row>

            <Col
              className="d-flex flex-column align-items-content"
              style={{ gap: "1rem" }}
            >
              <Row className="justify-content-center">
                <Button
                  onClick={() => handleSubmit()}
                  className="btn-primary-custom2 btn-primary-custom3"
                  sizeText={width > 1500 ? "1.3" : "1"}
                  children={
                    loading ? (
                      <LoadingButton />
                    ) : (
                      typesObj[status] || <LoadingButton />
                    )
                  }
                  disabled={loading || (status === 1 && tipo_acceso !== 1)}
                />
              </Row>
              <Row className="justify-content-center">
                <RenderTyC status={status} />
              </Row>
            </Col>
          </Row>
        )}
      </Col>
      {/* Seccion enrolamiento */}
    </>
  );
}
