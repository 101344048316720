import React from "react";
import Style from "./InformativeBanner.module.css";
import EmptyIcon from "../../assets/icons/empty.svg";

const InformativeBanner = ({
  hiddenBanner,
  description,
  titleButton,
  urlButton,
  hiddenButton,
  title,
  icon,
  hiddenIcon,
  colorBanner,
}) => {
  return (
    <React.Fragment>
      {hiddenBanner === 1 ? (
        <div
          className={Style.containerBanner}
          style={{ backgroundColor: colorBanner }}>
          {hiddenIcon === 0 ? (
            <div className={Style.containerWarning}>
              <img style={{ opacity: "0", marginLeft: "-60px" }} alt='' />
              <h4>{title ? title : null}</h4>
            </div>
          ) : (
            <div className={Style.containerWarning}>
              <img src={icon ? icon : EmptyIcon} alt='Icon' />{" "}
              <h4>{title ? title : null}</h4>
            </div>
          )}

          {description === "" ? null : (
            <p>{description ? description : null}</p>
          )}

          {hiddenButton === 0 ? null : (
            <a href={urlButton ? urlButton : "##"} target="_blank" rel="noreferrer"> {/* rel="noreferrer para prevenir "ataques de rel="noopener"" o "ataques de ventana abierta" */}
              <button>{titleButton ? titleButton : null}</button>
            </a>
          )}
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default InformativeBanner;

// Si no quieren que aparezca algún itmem del componente le mandan por props el string vacío ""
// El componente es reutilizable, solo le mandan por props lo que quieren que aparezca
