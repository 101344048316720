//Hooks - Funciones
import React, { useCallback, useState, useEffect } from "react";
import moment from "moment";
import { formatRut } from "../../helpers/autoAtencionHelpers";
import useWindowDimensions from "../../helpers/useWindowDimensions";

//Assets
import {
  AiFillFileAdd,
  AiFillEye,
  AiFillEdit,
  AiFillFile,
  AiFillFileExclamation,
  AiOutlineEye,
  AiOutlineEyeInvisible,
} from "react-icons/ai";

//Componentes
import Tabla from "../tabla/Tabla";
import NumberFormat from "react-number-format";
import { ButtonGroup, Collapse } from "react-bootstrap";
import { TextColored } from "../typography";
import IconButton from "../buttons/IconButton";

const TablaFacturas = ({
  type = "select",
  rows,
  setRows,
  files,
  setFiles,
  loading = false,
  setLoading,
  selectedRows,
  setSelectedRows,
  options = <></>,
  toggledClearRows = false,
}) => {
  const { height } = useWindowDimensions();
  const [expandedRow, setCurrentExpandedRow] = useState(null);

  const ordenarPorMonto = (a, b) => {
    if (a.mntTotal > b.mntTotal) {
      return 1;
    }
    if (b.mntTotal > a.mntTotal) {
      return -1;
    }
    return 0;
  };

  const CellHeader = ({ name }) => {
    return <span style={{ whiteSpace: "normal" }}>{name}</span>;
  };

  const ordenarPorEmision = (a, b) => {
    return new Date(b.fchEmis) - new Date(a.fchEmis);
  };

  const columns = [
    {
      name: <CellHeader name="Folio" />,
      selector: (row) => row.folio,
      width: "100px",
      center: true,
      sortable: true,
    },
    {
      name: <CellHeader name="Deudor" />,
      selector: (row) => row.rznSocRecep,
      width: "425px",
      center: true,
      sortable: true,
    },
    {
      name: <CellHeader name="RUT Deudor" />,
      selector: (row) => formatRut(row.rutRecep),
      width: "160px",
      center: true,
      sortable: true,
    },
    {
      name: <CellHeader name="Monto" />,
      width: "140px",
      selector: (row) => {
        return (
          <NumberFormat
            value={row.mntTotal}
            displayType={"text"}
            prefix={"$"}
            thousandSeparator={"."}
            decimalScale={0}
            decimalSeparator={","}
          />
        );
      },
      sortFunction: ordenarPorMonto,
      right: true,
      sortable: true,
    },
    {
      id: "emision",
      name: <CellHeader name="Fecha de Emisión" />,
      selector: (row) => moment(row.fchEmis).format("DD/MM/YYYY"),
      center: true,
      sortable: true,
      sortFunction: ordenarPorEmision,
    },
  ];

  const getBills = useCallback(async () => {
    try {
      const bills = JSON.parse(sessionStorage.getItem("bills"));
      const rejectedBills = JSON.parse(
        sessionStorage.getItem("facturasRechazadas")
      );

      if (type === "facturas-rechazadas") {
        // Utilizado en modal para cesion rechazada o con observaciones
        if (rejectedBills.length === 1) {
          setCurrentExpandedRow(rejectedBills[0].folio);
        }
        setRows(rejectedBills);
      } else {
        setRows(bills);
      }

      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }, [type, setRows, setLoading]);

  useEffect(() => {
    if (type !== "select" && rows.length === 0) {
      getBills();
    }
  }, [getBills, type, rows]);

  const handleChange = ({ selectedRows: selectedRow }) => {
    setSelectedRows(selectedRow);
  };

  const handleFile = async (file, rowId, fileType) => {
    // Encargado de administrar la carga de archivos para cada folio
    let obj = {
      ...files,
    };

    obj[rowId] = {
      ...obj[rowId],
      [fileType]: { name: file.name, url: URL.createObjectURL(file) },
    };

    setFiles(obj);

    const newRows = [...rows];

    if (fileType === "xml") {
      newRows.forEach((e) => {
        if (e.folio === rowId) e.enlaceXml = file;
      });
    }
    if (fileType === "pdf") {
      newRows.forEach((e) => {
        if (e.folio === rowId) {
          e.PDF = file;
          e.nombre_pdf = file.name;
        }
      });
    }
    setRows([...newRows]);
  };

  const params =
    "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no";

  const FilesInput = ({ rowId, fileType }) => {
    const mimeTypes = {
      xml: "application/xml",
      pdf: "application/pdf",
    };

    const checkAvailableFile = () => {
      if (files[rowId]) {
        if (files[rowId][fileType]) return true;
      }
    };

    return (
      <div>
        <ButtonGroup>
          <input
            type="file"
            id={`upload-${fileType}${rowId}`}
            className="file-input"
            onChange={(e) => {
              handleFile(e.target.files[0], rowId, fileType);
            }}
            accept={mimeTypes[fileType]}
          />
          <label
            htmlFor={`upload-${fileType}${rowId}`}
            className="upload-xml label"
          >
            {checkAvailableFile() ? <AiFillEdit /> : <AiFillFileAdd />}
          </label>

          <button
            className="view-xml"
            disabled={checkAvailableFile() ? false : true}
            onClick={() =>
              window.open(
                files[rowId][fileType].url,
                files[rowId][fileType].name,
                params
              )
            }
          >
            <AiFillEye />
          </button>
        </ButtonGroup>
      </div>
    );
  };

  const handleExpandRow = (id) => {
    // Busco el boton con el numero de folio
    const button = document.getElementById(`row-expander-${id}`);
    const rowToClick = button?.parentNode;
    if (rowToClick) {
      // Busco el div superior de este boton el cual tiene capacidad de despachar funciones de columna, en este caso el expandable y lo clickeo
      rowToClick?.click();
    }
  };

  const aditionalColumns = {
    verFactura: {
      name: <CellHeader name="Ver factura" />,
      selector: (row) => {
        if (row.enlaceXml) {
          return (
            <a href={row.enlaceXml}>
              <AiFillFile color="black" />
            </a>
          );
        } else {
          return (
            <span>
              <AiFillFileExclamation size="1.5rem" color="grey" />
            </span>
          );
        }
      },
      button: true,
    },
    cargaXml: {
      name: <CellHeader name="XML" />,
      selector: ({ folio }, i) => {
        return <FilesInput rowId={folio} fileType="xml" key={`upXml-${i}`} />;
      },
      center: true,
    },
    cargaPdf: {
      name: <CellHeader name="PDF" />,
      selector: ({ folio }, i) => {
        return <FilesInput rowId={folio} fileType="pdf" key={`upPdf-${i}`} />;
      },
      center: true,
    },
    plazo: {
      name: <CellHeader name="Plazo de pago (días)" />,
      selector: ({ plazo }) => plazo,
      center: true,
      width: "180px",
      sortable: true,
    },
    motivo: {
      name: <CellHeader name="Motivo" />,
      selector: ({ folio }) => {
        return (
          <IconButton
            Icon={
              folio !== expandedRow ? (
                <AiOutlineEyeInvisible size={25} />
              ) : (
                <AiOutlineEye size={25} />
              )
            }
            id={`row-expander-${folio}`}
            onClick={() => handleExpandRow(folio)}
          />
        );
      },
      center: true,
      width: "80px",
    },
  };

  const conditionalStyles = [
    {
      when: (row) => !selectedRows.includes(row),
      classNames: ["notSelected"],
    },
  ];

  const typeObj = {
    select: {
      data: { rows, columns: [...columns] },
      rows: height < 768 ? 4 : 5,
      conditionals: conditionalStyles,
      selectable: true,
      onSelected: handleChange,
    },
    show: {
      data: {
        rows,
        columns: [aditionalColumns.plazo, ...columns],
      },
      rows: height < 768 ? 3 : 5,
    },
    archives: {
      data: {
        rows,
        columns: [
          ...columns,
          aditionalColumns.cargaXml,
          aditionalColumns.cargaPdf,
        ],
      },
      rows: 5,
    },
    archives_myBiller: {
      data: {
        rows,
        columns: [...columns, aditionalColumns.cargaPdf],
      },
      rows: 5,
    },
    "facturas-rechazadas": {
      data: {
        rows,
        columns: [...columns, aditionalColumns.motivo],
      },
      rows: 6,
    },
    "facturas-aceptadas": {
      data: {
        rows,
        columns: [...columns],
      },
      rows: 5,
    },
  };

  // Expandable row props
  const ExpandableComponent = ({ data: { motivo } }) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
      setShow(true);
    }, []);

    return (
      <div>
        <Collapse in={show === true}>
          <div
            className="d-flex"
            style={{
              background: "#f9f9f9",
              paddingInline: "1rem",
              paddingBlock: "10px",
              boxShadow: "rgb(0 0 0 / 14%) 0px 5px 5px 0px inset",
            }}
          >
            <TextColored size={0.9} weight={500} type="dark">
              {motivo}
            </TextColored>
          </div>
        </Collapse>
      </div>
    );
  };

  const handleExpandedRow = (e, { folio }) => {
    if (expandedRow === folio && !e) {
      setCurrentExpandedRow(null);
      return;
    }
    setCurrentExpandedRow(folio);
  };
  //

  // Este objeto se puede usar para mandar propiedades que no esten agregadas en el componente principal (Tabla) para no tener que modificarlo
  const additionalProps = {
    "facturas-rechazadas": {
      expandableRows: true,
      expandableRowsComponent: ExpandableComponent,
      expandOnRowClicked: true,
      expandableIcon: {
        collapsed: <AiOutlineEye size={25} />,
        expanded: <AiOutlineEyeInvisible size={25} />,
      },
      onRowExpandToggled: handleExpandedRow,
      expandableRowExpanded: ({ folio }) =>
        folio === expandedRow || rows?.length === 1,
      expandableRowsHideExpander: true,
    },
  };

  return (
    <div id="tabla-facturas">
      {options}
      <Tabla
        datos={typeObj[type].data}
        busqueda={false}
        paginacion={typeObj[type]?.data?.rows?.length > typeObj[type]?.rows}
        selectableRows={typeObj[type]?.selectable}
        onSelectedRowsChange={typeObj[type]?.onSelected || {}}
        numeroFilas={typeObj[type]?.rows || 0}
        conditionalRowStyles={typeObj[type]?.conditionals || {}}
        loading={loading}
        type="checkbox"
        toggledClearRows={toggledClearRows}
        defaultSortFieldId="emision"
        defaultSortAsc={true}
        otherProperties={additionalProps[type] || {}}
      />
    </div>
  );
};

export default TablaFacturas;
