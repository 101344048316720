import React, { useContext, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { Confirmado, Error } from "../../assets/icons/dashboard";
import Check from "../../assets/images/check.png";
import { default as CustomButtonWithoutHover } from "../../components/buttons/ButtonWithoutHover";
import { ModalSuccess } from "../../components/modal/Modal";
import {
  Link,
  Text,
  TextColored,
  TextGeneric,
} from "../../components/typography";
import { api } from "../../helpers/api";
import useWindowDimensions from "../../helpers/useWindowDimensions";
import { validateRut } from "../../helpers/validators";
import { ThemeContext } from "../../theming";
import { abrir } from "../../helpers/termino_condiciones";
import Loading from "../loading/Loading";

function RegistroSII({ endpoint, closeModal, setTipoBanner, setStatus }) {
  const app = useContext(ThemeContext);

  const { body25, body28, body32 } = app.theme.font;

  const [modalData, setModalData] = useState({});

  /* Error modal*/
  const [showModalError, setShowModalError] = useState(false);
  const handleCloseModalError = () => setShowModalError(false);
  const handleShowModalError = () => {
    setShowModalError(true);
  };
  const [showLoading, setShowLoading] = useState({ display: "none" });
  //End Modal

  const { width } = useWindowDimensions();

  const buttonLoginStyle = {
    marginTop: "10px",
  };

  const { register, handleSubmit, watch, setValue, control } = useForm();

  const { rut } = watch(["rut"]);
  /* Styles */
  const containerMobile = {
    width: "100%",
    height: width >= 375 ? "100%" : "",
    background: "white",
    padding: "20px",
  };

  const container = {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  };

  // Métodos

  const formateaRut = (actual) => {
    if (actual !== undefined) {
      if (actual != "" && actual.length > 1) {
        let sinPuntos = actual.replace(/\./g, "");
        let actualLimpio = sinPuntos.replace(/-/g, "");
        let inicio = actualLimpio.substring(0, actualLimpio.length - 1);
        let rutPuntos = "";
        let i = 0;
        let j = 1;
        for (i = inicio.length - 1; i >= 0; i--) {
          let letra = inicio.charAt(i);
          rutPuntos = letra + rutPuntos;
          if (j % 3 === 0 && j <= inicio.length - 1) {
            rutPuntos = "." + rutPuntos;
          }
          j++;
        }
        let dv = actualLimpio.substring(actualLimpio.length - 1);
        rutPuntos = rutPuntos + "-" + dv;
        return rutPuntos;
      }
    }
    return actual;
  };

  const registrar = (data) => {
    let user = JSON.parse(localStorage.getItem("user"));

    if (data.rut.trim() === "") {
      setModalData({
        messageLine: false,
        title: "Registro",
        subtitle: "Error",
        color: "red",
        message: "Debe especificar un rut.",
        icon: (
          <>
            <Error />
          </>
        ),
      });
      handleShowModalError();
      return;
    }

    if (!validateRut(data.rut.trim())) {
      setModalData({
        messageLine: false,
        title: "Registro",
        subtitle: "Error",
        color: "red",
        message: "Rut inválido.",
        icon: (
          <>
            <Error />
          </>
        ),
      });
      handleShowModalError();
      return;
    }

    if (data.password.trim() === "") {
      setModalData({
        messageLine: false,
        title: "Registro",
        subtitle: "Error",
        color: "red",
        message: "Debe especificar una contraseña.",
        icon: (
          <>
            <Error />
          </>
        ),
      });
      handleShowModalError();
      return;
    }

    let formComentario = {
      idcliente: user.idcontacto,
      rut: data.rut.replace(/\./g, ""),
      password: data.password,
    };
    setShowLoading({ display: "block" });
    api.put(endpoint, formComentario).then(
      () => {
        setTipoBanner(0);
        setStatus(1);
        closeModal({
          messageLine: false,
          title: "Registro",
          subtitle: "Confirmado",
          color: "yellow",
          message: "Registro terminado correctamente.",
          icon: (
            <>
              <Confirmado />
            </>
          ),
        });
      },
      (error) => {
        setTipoBanner(2);
        setModalData({
          messageLine: false,
          title: "Registro",
          subtitle: "Error",
          color: "red",
          message: error.response.data.message,
          icon: (
            <>
              <Error />
            </>
          ),
        });
        setShowLoading({ display: "none" });
        handleShowModalError();
      }
    );
  };

  const handleKeyPress = (e) => {
    const numbers = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "K",
      "k",
    ];
    const alts = ["Backspace", "Tab", "Enter", "ArrowLeft", "ArrowRight"];

    if (numbers.indexOf(e.key) === -1 && alts.indexOf(e.key) === -1) {
      e.preventDefault();
    }
  };

  const handleBlur = (e) => {
    if (!validateRut(e.target.value)) {
      setModalData({
        messageLine: false,
        title: "Registro",
        subtitle: "Error",
        color: "red",
        message: "El RUT ingresado es inválido",
        icon: (
          <>
            <Error />
          </>
        ),
      });
      handleShowModalError();
    }
  };

  return (
    <>
      <Loading estilo={showLoading} />
      <Col>
        <Row>
          <Container style={width >= 701 ? container : containerMobile} fluid>
            <Row className="justify-content-center">
              <Form>
                <Form.Group
                  className="justify-content-center mb-0"
                  as={Row}
                  controlId="formUserRut"
                >
                  <Form.Control
                    className="input-field mb-2"
                    type="text"
                    name="rut"
                    rows={3}
                    as="input"
                    value={rut}
                    autoComplete="new-password"
                    placeholder="Rut Empresa"
                    onKeyDown={handleKeyPress}
                    onBlur={handleBlur}
                    onChange={(e) =>
                      setValue("rut", formateaRut(e.target.value))
                    }
                    ref={register({ required: false })}
                  />
                </Form.Group>
                <Form.Group
                  className="justify-content-center"
                  as={Row}
                  controlId="formUserPassword"
                >
                  <Form.Control
                    className="input-field mb-2"
                    type="password"
                    name="password"
                    autoComplete="new-password"
                    rows={3}
                    as="input"
                    placeholder="Contraseña Portal SII"
                    ref={register({ required: false })}
                  />
                </Form.Group>

                <Controller
                  control={control}
                  name="checkTerms"
                  defaultValue={false}
                  render={({ onChange, value }) => (
                    <>
                      <Form.Group
                        className="justify-content-center"
                        as={Row}
                        controlId="formUser2"
                      >
                        <Col xs={12}>
                          <Row className="justify-content-center">
                            {value ? (
                              <img
                                className="mr-2"
                                height={20}
                                width={20}
                                alt=""
                                src={Check}
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => onChange(false)}
                              />
                            ) : (
                              <div
                                style={{
                                  height: 20,
                                  width: 20,
                                  borderRadius: "4px",
                                  background: "lightgrey",
                                  cursor: "pointer",
                                  marginRight: "8px",
                                }}
                                onClick={() => onChange(true)}
                              />
                            )}

                            <Link size={0.6}>
                              <TextGeneric className="mr-2">
                                <b>Acepto Política de Privacidad</b>
                              </TextGeneric>
                            </Link>
                          </Row>
                        </Col>
                        <Col xs={12}>
                          <Row className="justify-content-center">
                            <TextGeneric>
                              <a href="#" onClick={() => abrir()}>
                                Leer detalle
                              </a>
                            </TextGeneric>
                          </Row>
                        </Col>
                      </Form.Group>

                      <Form.Group
                        className="justify-content-center"
                        as={Row}
                        controlId="formUser3"
                      >
                        <Col xs={12}>
                          <Row
                            style={buttonLoginStyle}
                            className="justify-content-center"
                          >
                            <Col lg={6}>
                              <CustomButtonWithoutHover
                                disabled={!value}
                                onClick={handleSubmit(registrar)}
                              >
                                REGISTRAR
                              </CustomButtonWithoutHover>
                            </Col>
                          </Row>
                          <Row className="justify-content-center mt-4">
                            <TextGeneric size={0.65}>
                              Datos 100% confidenciales y encriptados con alta
                              seguridad
                            </TextGeneric>
                          </Row>
                        </Col>
                      </Form.Group>
                    </>
                  )}
                />
              </Form>
            </Row>
          </Container>
        </Row>
      </Col>

      <ModalSuccess
        show={showModalError}
        icon={modalData.icon}
        messageLine={modalData.messageLine}
        title={
          <TextColored size={body28} type={modalData.color}>
            {modalData.title}
          </TextColored>
        }
        subtitle={
          <TextColored size={body32} bold type={modalData.color}>
            {modalData.subtitle}
          </TextColored>
        }
        message={
          <Text type="primary" size={body25}>
            {modalData.message}
          </Text>
        }
        handleClose={() => handleCloseModalError()}
      />
    </>
  );
}
export default RegistroSII;
