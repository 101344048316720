import React from "react";
import { Container, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Confirmado from "../../assets/images/confirmado.png";
import CustomButtonWithoutHover from "../../components/buttons/ButtonWithoutHover";
import { TextGeneric } from "../../components/typography";
import useWindowDimensions from "../../helpers/useWindowDimensions";

function ClienteConfirmado() {
  const { width } = useWindowDimensions();

  const container = {
    width: "450px",
    height: "430px",
    background: "white",
    padding: "20px",
  };

  const history = useHistory();

  const containerMobile = {
    width: "100%",
    height: width >= 375 ? "100%" : "",
    background: "white",
    padding: "20px",
    marginLeft: "0px"
  };

  const buttonLoginStyle = {
    marginTop: "10px",
  };

  return (
    <Container style={width >= 701 ? container : containerMobile} fluid>
      <Row className="justify-content-center mt-4">
        <Form>
          <Row className="justify-content-center mt-4 mb-3">
            <img height={100} width={120} alt="" src={Confirmado} />
          </Row>
          <Row className="justify-content-center mt-4">
            <TextGeneric size={width >= 701 ? 1.1 : 1.0}>
              ¡Bienvenido a la Familia Progreso!
            </TextGeneric>
          </Row>
          <Row className="justify-content-center mb-3">
            <TextGeneric size={width >= 701 ? 2.0 : 1.5}>
              <b>REGISTRO EXITOSO</b>
            </TextGeneric>
          </Row>

          <Row style={buttonLoginStyle} className="justify-content-center mb-2">
            <CustomButtonWithoutHover
              buttonTextSize={1.0}
              onClick={() => history.push("/clientes/login")}
            >
              INGRESAR A PORTAL DE CLIENTES
            </CustomButtonWithoutHover>
          </Row>
        </Form>
      </Row>
    </Container>
  );
}

export default ClienteConfirmado;
