import React, {useContext} from 'react';
import {Row, Col} from 'react-bootstrap';
import {TextColored} from '../typography';
import {ThemeContext} from '../../theming';
function DashedItem({title, subtitle, color = 'gray', shadow = false, header = false, bold = false, colorTitle = ""}) {
	const app = useContext(ThemeContext);
	const {body12} = app.theme.font;
	const dashed = {
		borderBottom: '0.07rem dashed #ebedf2',
		background: shadow ? (header?'#DEDEDE':'#f7f7f7') : '',
	};
	return (
		<>
			<Row style={dashed} className='text-center'>
				<Col>
					<TextColored size={body12} type={colorTitle || 'gray'} bold={header}>
						{title}
					</TextColored>
				</Col>
				<Col>
					<TextColored size={body12} type={color} bold={header || bold}>
						{subtitle}
					</TextColored>
				</Col>
			</Row>
		</>
	);
}

export default DashedItem;
