import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import useWindowDimensions from "../../helpers/useWindowDimensions";

function Slider({ images = [], openModal }) {
  const AutoplaySlider = withAutoplay(AwesomeSlider);
  const { width } = useWindowDimensions();

  // const slider = {
  // 	width: width >= 701 ? '1136px' : '500px',
  // 	height: width >= 1440 ? "250px" : width >= 701 ? "160px" : width >= 1024 ? "160px" : "90px",
  // 	marginBottom: width >= 1440 ? "70px" : width >= 1200 ? "145px" : width >= 1024 ? "123px" : "70px",
  // }

  return (
    <>
      {/* <div style={slider}> */}
      <AutoplaySlider
        className="aws-btn mr-3 ml-3"
        play={true}
        interval={6000}
        cancelOnInteraction={false}
      >
        {images.map((image, index) => {
          return image.link !== "" ? (
            <div
              style={{ cursor: "pointer" }}
              key={`img${index}`}
              onClick={() => {
                if(image.link.includes("http"))
                {
                  window.open(image.link, "_blank")
                }else{
                  openModal({title : image.link, data : {} })
                }
                }
                }
              data-src={image.imagen}
            ></div>
          ) : (
            <div key={`img${index}`} data-src={image.imagen}></div>
          );
        })}
      </AutoplaySlider>
      {/* </div > */}
    </>
  );
}

export default Slider;
