import React, { useEffect, useState } from "react";
import { slide as Menu } from "react-burger-menu";
import useStorage from "../../helpers/useStorage";
import RenderChildDropDown from "./RenderChildDropDown";
import "./style.css";

function NavBarLateral({ openModal, menus: itemMenus, rut, setShowLoading }) {
  const [user] = useStorage("user", {}, "local");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const burger = document.querySelector(".bm-burger-button span");
    if (open) {
      burger?.classList.add("burger-active");
    } else {
      burger?.classList.remove("burger-active");
    }
  }, [open]);

  return (
    <div className="right mt-1">
      <Menu
        isOpen={open}
        right
        width={280}
        onStateChange={(state) => {
          setOpen(state.isOpen);
          if (state.isOpen) {
            window.scrollTo(0, 0);
            document.body.style.overflow = "hidden";
          } else {
            document.body.style.overflow = "visible";
          }
        }}
      >
        {itemMenus.map((item, index) => {
          return (
            <RenderChildDropDown
              item={item}
              openModal={openModal}
              user={user}
              setOpen={setOpen}
              rut={rut}
              setShowLoading={setShowLoading}
              key={`drpdwn-${index}`}
            />
          );
        })}
      </Menu>
    </div>
  );
}

export default NavBarLateral;
