import React, { useContext } from "react";
import { Row, Container, Col } from "react-bootstrap";
import { Text, Pipe } from "../typography";
import { ThemeContext } from "../../theming";
import Logo from "../../assets/images/logo_footer.png";
import useWindowDimensions from "../../helpers/useWindowDimensions";
const height = 140;
function Footer({ margin = true }) {
  const app = useContext(ThemeContext);
  const { body14, body25 } = app.theme.font;
  const { width } = useWindowDimensions();

  const footer = {
    backgroundColor: app.theme.secondary,
    width: "100%",
    height: `${height}px`,
    display: "flex",
    alignItems: "center",
  };
  const col = {
    lineHeight: "initial",
  };
  return (
    <>
      <Container
        fluid
        style={footer}
        className={margin ? "mt-5 align-items-center" : "align-items-center"}
      >
        <Col className="align-items-center">
          <Row className="justify-content-center">
            <img
              src={Logo}
              style={{
                height: "4%",
                width: "4%",
                minWidth: "50px",
                marginRight: "20px"
              }}
              alt=""
            />
            <Pipe center={true} size={40} color="yellow" />
            <Row className="ml-4">
              <Col style={{ alignSelf: 'center' }}>
                <Row>
                  <Text size={width >= 701 ? body14 : 0.8}>
                    contactenos@progreso.cl
              </Text>
                </Row>
                <Row>
                  <Text size={width >= 701 ? body25 : 1.0}>www.progreso.cl</Text>
                </Row>
              </Col>
            </Row>
          </Row>
        </Col>

      </Container>
      <Container
        fluid
        style={{ background: "#ffffff", padding: "20px 20px 20px" }}
        className={margin ? "mt-5 align-items-center" : "align-items-center"}
      >
        <Row className="align-items-center">
          <Col className="d-flex justify-content-end">
            <Row>
              <span
                style={{
                  padding: "0 20px",
                  textAlign: "center",
                  width: "100%",
                  fontSize: "0.6rem",
                }}
              >
                (*) Tiempo de respuesta en día hábil de 08:45 a 17:00 hrs. (**)
                Condicionado a entrega de pie definido por Progreso.
              </span>
              <span
                style={{
                  padding: "0 20px",
                  textAlign: "center",
                  width: "100%",
                  fontSize: "0.6rem",
                }}
              >
                Todos los beneficios son accesibles una vez que el cliente
                complete el proceso de inscripción incorporando sus datos correctos
                de acceso al SII.
              </span>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Footer;
