import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { NotificationCliente, UserCliente } from "../../assets/icons/dashboard";
import Logo from "../../assets/images/logo.png";
import Logo2 from "../../assets/images/logo_footer.png";
import { DecryptData } from "../../helpers/encrypt";
import { ThemeContext } from "../../theming";
import { Link, Pipe, Text, TextGeneric } from "../typography";
import {
  mobileMDWidth,
  mobileXSWidth,
} from "../../helpers/responsiveVariables";
import useWindowDimensions from "../../helpers/useWindowDimensions";
import { User } from "../../assets/icons/dashboard";
import { PORTALSAPI } from "../../helpers/url";
import { api } from "../../helpers/api";
import Navbar from "../navmenu/Navbar";
import NavbarLateral from "../navmenu/NavBarLateral";
import { ordenarMenus } from "../../helpers/validators";
import Loading from "../../components/loading/Loading";
import Logout from "../../assets/images/Logout.png";

const headerHeight = 110;
const menuHeight = 40;

export default function HeaderDashboardClientes({
  openModal,
  notificaciones,
  nombres = "",
  menus,
  rut = null,
}) {
  const app = useContext(ThemeContext);
  const { width } = useWindowDimensions();

  const { body12, body14, body28 } = app.theme.font;
  const [showLoading, setShowLoading] = useState({ display: "none" });

  const [usuario, setUsuario] = useState("");
  const [cliente, setCliente] = useState("");
  const [menusOrdenados, setMenusOrdenados] = useState([]);

  const header = {
    backgroundColor: app.theme.secondary,
    height: `${headerHeight}px`,
    borderStyle: "solid",
    borderWidth: "1px",
    borderBottomColor: "white",
  };
  const menu = {
    background: app.theme.primary,
    position: "fixed",
    zIndex: 1020,
    height: `${menuHeight}px`,
    display: "flex",
    alignItems: "center",
    //borderStyle: "solid",
    //borderWidth: "1px",
    //borderBottomColor: "white",
  };
  const border = {
    borderBottom: `2px solid ${app.theme.primary}`,
    paddingBottom: "3px",
  };

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      let publicURL = PORTALSAPI.GET.PUBLIC_KEY.replace(
        "{idcontacto}",
        user.idcontacto
      ).replace("{tipo}", user.tipo_acceso);
      api.get(publicURL).then((data) => {
        if (nombres == "") {
          setUsuario(DecryptData(user.nombres, data.data));
        } else {
          setUsuario(nombres);
        }
        setCliente(DecryptData(user.cliente, data.data));
      });
    }
  }, [nombres]);

  useEffect(() => {
    setMenusOrdenados(ordenarMenus(menus));
  }, [menus]);

  const CerrarSession = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location = "/";
  };
  return (
    <>
      <Loading estilo={showLoading}></Loading>
      <Container fluid style={header} className='sticky-top'>
        {width >= mobileMDWidth ? (
          <Col>
            <Row className='align-items-center justify-content-center ml-4 mr-4'>
              <Col className='' lg={1}>
                <img width='80px' src={Logo} alt='' />
              </Col>

              <Col lg={2}>
                <Row className='align-items-center justify-content-md-end'>
                  <Col lg={1}>
                    <Pipe size={30} color='yellow' />
                  </Col>
                  <Col>
                    <Text size={body28}>Fintec</Text>
                  </Col>
                </Row>
              </Col>

              <Col lg={5} className='mr-4'>
                <div className='row justify-content-end'>
                  <Row>
                    <Text size={body14} bold>
                      Bienvenido, {usuario}
                    </Text>
                  </Row>
                </div>
                <div className='row justify-content-end'>
                  <Row>
                    <Text size={body12}>{cliente}</Text>
                  </Row>
                </div>
              </Col>

              <Col
                className='align-items-center d-flex justify-content-end'
                md='auto'
                style={{ gap: "5px" }}>
                <a
                  href='##'
                  onClick={() =>
                    openModal({
                      title: "usuario",
                      data: JSON.parse(localStorage.getItem("user")),
                    })
                  }>
                  <UserCliente />
                </a>
                <a
                  href='##'
                  onClick={() =>
                    openModal({
                      title: "notificacion",
                      data: JSON.parse(localStorage.getItem("user")),
                    })
                  }>
                  <NotificationCliente notificaciones={notificaciones} />
                </a>

                <img
                  style={{
                    width: "42px",
                    height: "42px",
                    marginTop: "2px",
                    marginLeft: "-6px",
                    cursor: "pointer",
                  }}
                  onClick={() => CerrarSession()}
                  src={Logout}
                  alt='Logout'
                />
              </Col>

              {/* <Col lg={2} className="">
                <Row style={{
                  alignItems: 'center'
                }}>
                  <div style={{
                    borderLeft: '0.5px solid #3E3E3E',
                    height: '46px',
                    padding: '0.2em',
                  }}>
                  </div>
                  <Link
                    size={0.7}
                    border={border}
                    onClick={() =>
                      openModal({
                        title: "contactar",
                        data: JSON.parse(localStorage.getItem("user")).idcontacto,
                      })
                    }
                  >
                    CONTACTAR EJECUTIVO
            </Link>
                </Row>

              </Col> */}
            </Row>
          </Col>
        ) : (
          <>
            <Col>
              <Row className='align-items-center justify-content-md-center '>
                <Col
                  style={{ marginTop: "6.5rem", position: "absolute" }}
                  lg={1}
                  xs={!!menusOrdenados?.length ? 7 : 8}
                  sm={8}
                  md={9}>
                  <img width='40px' src={Logo2} alt='' />
                </Col>

                {/* <Col lg={2} xs={6} style={{ textAlign: "end" }}>
                <Link
                  size={"0.7"}
                  border={border}
                  onClick={() =>
                    openModal({
                      title: "contactar",
                      data: JSON.parse(localStorage.getItem("user")).idcontacto,
                    })
                  }
                >
                  CONTACTAR EJECUTIVO
              </Link>
              </Col> */}

                <Col
                  style={{
                    position: "absolute",
                    marginTop: "6.5rem",
                  }}>
                  <Row className='d-flex justify-content-center' md='auto'>
                    <a
                      href='##'
                      onClick={() =>
                        openModal({
                          title: "usuario",
                          data: JSON.parse(localStorage.getItem("user")),
                        })
                      }>
                      <UserCliente />
                    </a>
                    <a
                      className='ml-2'
                      href='##'
                      onClick={() =>
                        openModal({
                          title: "notificacion",
                          data: JSON.parse(localStorage.getItem("user")),
                        })
                      }>
                      <NotificationCliente notificaciones={notificaciones} />
                    </a>
                    <img
                      style={{
                        width: "42px",
                        height: "42px",
                        marginTop: "32px",
                        cursor: "pointer",
                        zIndex: "1000",
                        marginLeft: "2px",
                      }}
                      onClick={() => CerrarSession()}
                      src={Logout}
                      alt='Logout'
                    />
                  </Row>
                </Col>
                <Col xs={1}></Col>
              </Row>
            </Col>
            {!!menusOrdenados.length && (
              <NavbarLateral
                openModal={openModal}
                menus={menusOrdenados}
                rut={rut}
                setShowLoading={setShowLoading}
              />
            )}
          </>
        )}
      </Container>
      {width >= 801 ? (
        !!menusOrdenados.length && (
          <Container fluid style={menu}>
            <Navbar
              openModal={openModal}
              menus={menusOrdenados}
              rut={rut}
              setShowLoading={setShowLoading}
            />
          </Container>
        )
      ) : (
        <></>
      )}
    </>
  );
}
