//Librerias
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

//Helpers
import useWindowDimensions from "../../helpers/useWindowDimensions";

//Componentes
import { Container, Row, Card, Col } from "react-bootstrap";
import Footer from "../../components/footer/Footer";
import HeaderAutoatencion from "../../components/header-autoatencion/HeaderAutoatencion";
import TablaMetodos from "../../components/autoatencion/TablaMetodos";
import TitleAutoatencion from "../../components/header-autoatencion/TitleAutoatencion";
import ModalTipoCesion from "../../components/modal/modal-autoatencion/ModalTipoCesion";
import CustomButton from "../../components/buttons/Button";
import useStorage from "../../helpers/useStorage";
import { TextColored } from "../../components/typography";
import { stepValidator } from "../../helpers/autoAtencionHelpers";

const MetodosDePago = () => {
  const history = useHistory();
  const { width } = useWindowDimensions();
  const [show, setShow] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [metodo, setMetodo] = useStorage("payment_method", {}, "session");
  const [rows, setRows] = useState([]);

  const handleSubmit = async () => {
    try {
      sessionStorage.setItem("current-step", 4);
      setMetodo(selectedRow);
      setShow(true);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    stepValidator();
  }, [history]);

  const styles = {
    width: `${
      width < 701 ? "100%" : width < 1081 ? "80%" : width < 1367 ? "80%" : "65%"
    }`,
    transition: "width 0.5s",
    padding: 0,
  };

  return (
    <Container
      fluid
      className="vh-100 d-flex flex-column"
      style={{ overflowY: "auto" }}
    >
      <ModalTipoCesion show={show} setShow={setShow} metodo={metodo} />
      <Row>
        <HeaderAutoatencion step={3} />
      </Row>
      <Col
        className="justify-content-center d-flex align-items-center"
        style={{ padding: "0" }}
      >
        <Card style={styles}>
          <Card.Body className="d-flex flex-column" style={{ gap: "15px" }}>
            <TitleAutoatencion
              info="Seleccione la cuenta bancaria donde deseas recibir el financiamiento."
              currentStep={3}
              width={width}
            />
            <TablaMetodos
              setSelectedRow={setSelectedRow}
              setRows={setRows}
              rows={rows}
              selectedRow={selectedRow}
            />
            <Row className="justify-content-center">
              <Col md={3}>
                <CustomButton
                  children={
                    <TextColored
                      type="dark"
                      size={1.1}
                      children="Continuar"
                      bold
                    />
                  }
                  onClick={handleSubmit}
                  disabled={!selectedRow || rows.length === 0}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <Row>
        <Footer margin={false} />
      </Row>
    </Container>
  );
};

export default MetodosDePago;
