//Librerias
import React, { useCallback, useEffect, useRef, useState } from "react";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";

//Helpers
import { api } from "../../../helpers/api";
import { AUTOATENCIONAPI } from "../../../helpers/url";
import {
  fireAlert,
  generateCdlObj,
  getRut,
  sincronizarDTEs,
} from "../../../helpers/autoAtencionHelpers";
import useStorage from "../../../helpers/useStorage";
import { validateRut } from "../../../helpers/validators";

//Assets
import {
  AiFillEye,
  AiFillEyeInvisible,
  AiFillCloseCircle,
} from "react-icons/ai";
import { FaUserEdit, FaUserClock } from "react-icons/fa";
import Logo from "../../../assets/images/logo7.png";
import Exito from "../../../assets/images/exito2.png";

// Componentes
import { Fade, Form, InputGroup, Modal } from "react-bootstrap";
import CustomButton from "../../buttons/Button";
import { TextColored, TextCustomColored } from "../../typography";
import IconButton from "../../buttons/IconButton";

const Bloqueado = ({ blockTime, handleClose }) => {
  return (
    <Modal.Body className="d-flex flex-column justify-content-center align-items-center text-center">
      <div className="d-flex flex-row-reverse w-100">
        <IconButton
          Icon={<AiFillCloseCircle size={30} />}
          onClick={handleClose}
        />
      </div>
      <FaUserClock size={155} />
      <TextColored children="Su cuenta ha sido" size={1.5} />
      <TextColored
        children="SUSPENDIDA TEMPORALMENTE"
        size={2.5}
        weight={600}
        type="dark"
      />
      <TextColored children="Vuelva a intentarlo dentro de:" size={1} />
      <TextColored
        children={moment.utc(blockTime).format("HH:mm:ss")}
        weight={500}
        size={2.5}
        type="dark"
      />
    </Modal.Body>
  );
};

const DATOS = "DATOS";
const EXITO = "EXITO";

const CargarDatos = ({
  setShow,
  RUT,
  wrongPassword,
  handleChange,
  blockTime,
  SII,
  registerSuscriber,
  status,
  type,
  handleClose,
  contactado,
  dbRut,
  isEditingData
}) => {
  const history = useHistory();
  const [inputType, setInputType] = useState("password");
  const [checked, setChecked] = useState(false);

  const title = {
    0: "Registrar datos",
    2: "Actualizar datos",
  };

  const info = {
    0: "Para registrar tus datos necesitamos que completes tu RUT y Contraseña del SII.",
    2: "Para actualizar tus datos necesitamos que registres tu RUT y Contraseña del SII.",
  };

  const button = {
    0: "Registrar",
    2: "Actualizar",
  };

  const handleSubmit = () => {
    if (contactado && !isEditingData) {
      sessionStorage.setItem("current-step", 1);
      history.push("autoatencion/seleccion-facturas");
    } else {
      setShow(false);
    }
  };

  const rutFlag = RUT.length === 10 && !validateRut(RUT);

  switch (type) {
    case EXITO: {
      return (
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ gap: "1rem", padding: "1rem", minHeight: "528px" }}
        >
          <img src={Exito} alt="confirmed" width="55%" />
          <div className="d-flex flex-column">
            <TextColored
              children="¡Datos registrados exitosamente!"
              type="dark"
              weight={600}
              size={1.5}
            />

            <TextColored
              children="Ahora puedes factorizar de forma rápida y segura."
              weight={500}
              size={1}
            />
          </div>
          <div style={{ positionItems: "center", width: "60%" }}>
            <CustomButton
              children={
                <TextColored
                  children="Continuar"
                  weight={600}
                  size={1.1}
                  type="dark"
                />
              }
              onClick={handleSubmit}
            />
          </div>
        </div>
      );
    }
    case DATOS: {
      return (
        <>
          <Modal.Header className="d-flex flex-column">
            <div className="d-flex flex-row-reverse w-100">
              <IconButton
                Icon={<AiFillCloseCircle size={25} />}
                onClick={handleClose}
              />
            </div>
            <div
              className="d-flex flex-column w-100 justify-content-center"
              style={{ marginInline: "0.5rem", gap: "0.5rem" }}
            >
              <div className="d-flex align-items-end" style={{ gap: "10px" }}>
                <FaUserEdit size={40} />
                <TextColored
                  size={1.5}
                  children={title[status]}
                  type="dark"
                  weight={600}
                />
              </div>
              <TextColored size={0.9} children={info[status]} type="dark" />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div
              className="d-flex flex-column justify-content-around align-items-center text-center"
              style={{ gap: "0.5rem" }}
            >
              <div
                className="d-flex flex-column justify-content-center text-left"
                style={{ width: "85%" }}
              >
                {/* Formulario */}

                <div
                  className="w-100 d-flex flex-column"
                  style={{ gap: "0.2rem" }}
                >
                  <div>
                    <TextColored
                      weight={500}
                      size={1}
                      children="RUT Empresa:"
                      type="dark"
                    />
                    <Form.Control
                      type="text"
                      placeholder="Introduce tu RUT (Incluye guiones)..."
                      name="RUT"
                      disabled={blockTime}
                      onChange={handleChange}
                      value={RUT}
                      className={`input-modal-enrolamiento input-suscription ${
                        rutFlag ? "error" : "noBorder"
                      }`}
                      readOnly={dbRut}
                      maxLength={10}
                      autoComplete="none"
                    />
                    <Fade in={rutFlag}>
                      <TextCustomColored
                        color="red"
                        children={"Por favor, ingresa un RUT valido."}
                      />
                    </Fade>
                  </div>
                  <div>
                    <TextColored
                      weight={500}
                      size={1}
                      children="Clave Portal SII:"
                      type="dark"
                    />
                    <InputGroup className={wrongPassword && "error"}>
                      <Form.Control
                        type={inputType}
                        placeholder="Introduce tu clave..."
                        value={SII}
                        name="SII"
                        style={{
                          border: "none",
                        }}
                        onChange={handleChange}
                        disabled={blockTime}
                        className="input-modal-enrolamiento input-suscription"
                        autoComplete="new-password"
                      />
                      <button
                        onClick={() =>
                          setInputType(
                            inputType === "text" ? "password" : "text"
                          )
                        }
                        disabled={blockTime}
                        style={{
                          border: "none",
                          backgroundColor: "#eceaea",
                          borderRadius: "0 0.25rem 0.25rem 0",
                        }}
                      >
                        {inputType === "text" ? (
                          <AiFillEye />
                        ) : (
                          <AiFillEyeInvisible />
                        )}
                      </button>
                    </InputGroup>
                    <Fade in={wrongPassword}>
                      <TextCustomColored
                        color="red"
                        children={"La contraseña ingresada es incorrecta."}
                      />
                    </Fade>
                  </div>
                </div>
              </div>

              {/* Botones */}
              <div
                className="d-flex flex-column"
                style={{
                  width: "85%",
                  gap: "1rem",
                  marginBottom: "0.8rem",
                }}
              >
                {status === 0 ? (
                  <label>
                    <input
                      type="checkbox"
                      onChange={() => setChecked(!checked)}
                      checked={checked}
                      style={{ verticalAlign: "middle" }}
                    />

                    <span
                      style={{
                        fontSize: "0.9rem",
                        fontWeight: 500,
                        paddingLeft: "0.25rem",
                      }}
                    >
                      Acepto la{" "}
                      <a
                        href="https://www.progreso.cl/compania/politica-privacidad/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Política de privacidad
                      </a>{" "}
                      de Progreso.
                    </span>
                  </label>
                ) : (
                  <span
                    style={{
                      fontSize: "0.9rem",
                      fontWeight: 500,
                    }}
                  >
                    Al actualizar tus datos aceptas nuestra
                    <a
                      href="https://www.progreso.cl/compania/politica-privacidad/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Política de privacidad
                    </a>
                  </span>
                )}

                <CustomButton
                  children={
                    <TextColored
                      size={1.2}
                      weight={500}
                      children={button[status]}
                      type="dark"
                    />
                  }
                  disabled={
                    SII?.length === 0 ||
                    RUT?.length < 9 ||
                    (status === 0 && !checked) ||
                    rutFlag
                  }
                  onClick={registerSuscriber}
                />
                <a
                  href="https://homer.sii.cl/"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    textDecoration: "underline",
                    color: "black",
                    fontSize: "0.9rem",
                  }}
                >
                  <b>¿Olvidaste tu Clave?</b> Recupérala en el Portal del SII
                </a>
              </div>
            </div>
          </Modal.Body>
        </>
      );
    }
    default: {
      return <></>;
    }
  }
};

const ModalSuscripcionCDL = ({
  show,
  status,
  setStatus,
  setShow,
  isBlocked = false,
  previousState,
  contactado,
  isEditingData,
  setUserDashboardFlag,
}) => {
  const loadingInfo = useRef(false);
  const [{ idcontacto }] = useStorage("user", {}, "local");

  const [type, setType] = useState(DATOS);
  const [loading, setLoading] = useState(true);
  const [wrongPassword, setWrongPass] = useState(false);

  const [{ SII, RUT, dbRut }, setForm] = useState({
    SII: "",
    RUT: "",
    dbRut: false,
  });

  const [blockTime, setBlock] = useState(null);

  const handleChange = (e) => {
    if (wrongPassword) setWrongPass(false);
    e.preventDefault();
    const { value, name } = e.target;
    setForm((f) => {
      return {
        ...f,
        [name]: value,
      };
    });
  };

  const handleClose = () => {
    if (isEditingData && status === 2) {
      // En el caso de que el modal sea abierto desde el dashboard de usuario o el menu de leasing y el status sea modificado, se devuelve al estado anterior
      setStatus(previousState);
      setUserDashboardFlag(false)
    }
    setShow(false);
  };

  const checkBlocked = useCallback(
    (date = "") => {
      if (blockTime === null || blockTime === false) {
        if (isBlocked?.length > 0 || date?.length > 0) {
          const blockTime = moment
            .tz(isBlocked || date, "America/Santiago")
            .local()
            .diff(new Date(), "milliseconds");

          if (blockTime <= 0) {
            setBlock(false);
            return false;
          }
          setBlock(blockTime);
          return true;
        }

        setBlock(false);
        return false;
      }
    },
    [blockTime, isBlocked]
  );

  const registerSuscriber = async () => {
    try {
      setLoading(true);
      const body = await generateCdlObj(RUT, SII);

      const url = (
        status === 2 // Status 0 = No enrolado - Status 2 = Actualizar contraseña
          ? AUTOATENCIONAPI.POST.ACTUALIZAR_ENROLAMIENTO_CDL
          : AUTOATENCIONAPI.POST.REGISTRAR_ENROLAMIENTO_CDL
      ).replace("{idcontacto}", idcontacto);

      const { data } = await api.post(url, body);
      const { enrolado, fe_estimada_desbloqueo, bloqueado } = data;

      if (enrolado && !bloqueado) {
        if (status === 0) {
          const touUrl = AUTOATENCIONAPI.PUT.ACEPTAR_TERMINOS.replace(
            "{iduser}",
            idcontacto
          );
          // En el primer ingreso se acepta la politica de privacidad de Progreso y se registra en la db
          await api.put(touUrl);
        }

        if (status === 2 || status === 0) {
          // Actualizo las credenciales en la bd
          const actualizarSiiUrl = AUTOATENCIONAPI.PUT.ACTUALIZAR_SII.replace(
            "{idcontacto}",
            idcontacto
          ).replace("{password}", SII);
          await api.put(actualizarSiiUrl);
        }

        // Sincronizo las facturas mas recientes
        await sincronizarDTEs(RUT);
        setStatus(1);
        setType(EXITO);
      } else {
        setWrongPass(true);
        if (fe_estimada_desbloqueo?.length > 0 && bloqueado) {
          checkBlocked(fe_estimada_desbloqueo);
        }
        setType(DATOS);
      }
      setTimeout(() => setLoading(false), 250);
    } catch (err) {
      await fireAlert({ icon: "warning" });
      setLoading(false);
    }
  };

  const chargeData = useCallback(async () => {
    //Ni bien se levanta el modal chequeo si existe algun bloqueo para evitar peticiones de mas
    const blocked = await checkBlocked();
    if (!blocked && contactado) {
      //Cargo el rut en el formulario
      const rut = await getRut();
      if (rut.length > 0) {
        setForm((f) => {
          return { ...f, RUT: rut, dbRut: true };
        });
      }
    }
    setLoading(false);
  }, [checkBlocked, contactado]);

  useEffect(() => {
    if (show) {
      if (!loadingInfo.current) {
        loadingInfo.current = true;
        chargeData();
      }
    }
  }, [chargeData, show]);

  useEffect(() => {
    if (blockTime) {
      return setTimeout(() => {
        setBlock((bt) => bt - 1000);
      }, 1000);
    }
  }, [blockTime]);

  if (show) {
    return (
      <Modal show={show} centered>
        <Fade in={loading}>
          <div
            className="d-flex flex-column align-items-center justify-content-center position-absolute w-100 h-100"
            style={{
              gap: "1rem",
              padding: "1rem",
              background: "white",
              zIndex: loading ? 2 : -1,
              borderRadius: "0.3rem",
            }}
          >
            <img src={Logo} alt="logo" width="85%" />
            <div className="d-flex align-items-center" style={{ gap: "5px" }}>
              <TextColored
                size={1.2}
                weight={400}
                children={"Cargando datos"}
                moreStyles={{ animation: "fade 2s infinite" }}
              />
            </div>
          </div>
        </Fade>
        {blockTime ? (
          <Bloqueado handleClose={handleClose} blockTime={blockTime} />
        ) : (
          <CargarDatos
            setShow={setShow}
            handleClose={handleClose}
            RUT={RUT}
            wrongPassword={wrongPassword}
            handleChange={handleChange}
            blockTime={blockTime}
            SII={SII}
            registerSuscriber={registerSuscriber}
            status={status}
            type={type}
            contactado={contactado}
            dbRut={dbRut}
            isEditingData={isEditingData}
          />
        )}
      </Modal>
    );
  }
  return null;
};

export default ModalSuscripcionCDL;
