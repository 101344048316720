import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import Logo from '../../assets/images/logo.png';
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

import Button from '../../components/buttons/Button';
import { ThemeContext } from '../../theming';
import { ModalSuccess } from '../../components/modal/Modal';
import { Password } from '../../assets/icons/login';
import { Confirmado, Error } from '../../assets/icons/dashboard';
import { TextColored, Text } from '../../components/typography';
import { api } from "../../helpers/api";
import { CAMBIAR_CONTRASENIA } from '../../helpers/url';
import useQueryParam from '../../helpers/useQueryParam';
import { DecryptData } from '../../helpers/encrypt';

const RestaurarContrasenia = () => {
    const app = useContext(ThemeContext);
    const { body25, body28, body32 } = app.theme.font;

    /* Error modal*/
    const [modalData, setModalData] = useState({});
    const [showModalError, setShowModalError] = useState(false);
    const handleCloseModalError = () => setShowModalError(false)
    const handleShowModalError = () => {
        setShowModalError(true)
    }

    const history = useHistory();

    const { register, handleSubmit } = useForm();

    const [search] = useQueryParam('c', '');

    const container = {
        width: '450px',
        height: '450px',
        background: app.theme.secondary,
        padding: '20px',
    };

    const container_input = {
        borderBottom: '1px solid #b9b5b5'
    };

    const image = {
        padding: '15px',
        borderBottom: `2px solid ${app.theme.primary}`,
    };

    <ModalSuccess
        show={showModalError}
        icon={modalData.icon}
        title={
            <TextColored size={body28} type={modalData.color}>
                {modalData.title}
            </TextColored>
        }
        subtitle={
            <TextColored size={body32} bold type={modalData.color}>
                {modalData.subtitle}
            </TextColored>
        }
        message={
            <Text type='primary' size={body25}>
                {modalData.message}
            </Text>
        }
        handleClose={() => handleCloseModalError()}
    />

    useEffect(() => {
        if (search === undefined || search === '') {
            history.push('/');
        }
    }, [history, search]);

    const enviar = (data) => {

        if (data.contrasenia.trim() === '' || data.contrasenia_confirmar.trim() === '') {
            setModalData({
                title: 'Recupera tu contraseña',
                subtitle: 'Error',
                color: 'red',
                message: 'Debe especificar ambas contraseñas.',
                icon: (<>
                    <Error /></>)
            });
            handleShowModalError();
            return;
        }

        if (data.contrasenia !== data.contrasenia_confirmar) {
            setModalData({
                title: 'Recupera tu contraseña',
                subtitle: 'Error',
                color: 'red',
                message: 'Las contraseñas no coinciden.',
                icon: (<>
                    <Error /></>)
            });
            handleShowModalError();
            return;
        }

        const correo = DecryptData(search).split("|")[0];

        // Prelogin

        let body = {
            token: search,
            correo: correo,
            password: data.contrasenia
        };

        api.put(CAMBIAR_CONTRASENIA, body).then((res) => {
            setModalData({
                title: 'Cambio de contraseña',
                subtitle: 'Confirmado',
                color: 'yellow',
                button: (
                    <Button onClick={() => history.push('/')
                    }>Regresar a inicio de sesión.</Button>
                ),
                message: 'Su contraseña fue actualizada correctamente.',
                icon: (<>
                    <Confirmado /></>)
            });
            handleShowModalError();

        }, (error) => {
            setModalData({
                title: 'Cambio de contraseña',
                subtitle: 'Error',
                color: 'red',
                message: 'No se pudo actualizar su nueva contraseña.',
                icon: (<>
                    <Error /></>)
            });
            handleShowModalError();
        });
    };

    return (
        <div className='login'>
            <Container style={container} fluid>
                <Row className='w-100 justify-content-center'>
                    <img style={image} src={Logo} alt='Logo Progreso' />
                </Row>
                <Row className='mt-4'>
                    <Col className='text-center' sm={12}>
                        <Text size={body25} className='d-block' type='secondary'>
                            Ingresa tu contraseña
						</Text>
                    </Col>
                </Row>

                <Form>
                    <Form.Group className='justify-content-center mt-4' as={Row} controlId='formUser'>
                        <Col xs={10} lg={10}>
                            <div style={container_input} className='input-container' >
                                <Password />
                                <input name="contrasenia"
                                    autoComplete="new-off"
                                    className={`input-field input-top`}
                                    type='password' placeholder='Contraseña'
                                    ref={register()} />
                            </div>
                        </Col>
                        <Form.Group xs={10} lg={10} className='justify-content-center' as={Col} controlId='formUser'>
                            <div className='input-container' >
                                <Password />
                                <input name="contrasenia_confirmar"
                                    autoComplete="new-off"
                                    className={`input-field input-bottom`}
                                    type='password' placeholder='Confirmar Contraseña'
                                    ref={register()} />
                            </div>
                        </Form.Group>
                    </Form.Group>
                    <Form.Group className='justify-content-center mt-4' as={Row} controlId='formUser'>
                        <Col xs={12} lg={6}>
                            <Button onClick={handleSubmit(enviar)}>CAMBIAR CONTRASEÑA</Button>
                        </Col>
                    </Form.Group>
                </Form>

                <ModalSuccess
                    show={showModalError}
                    icon={modalData.icon}
                    title={
                        <TextColored size={body28} type={modalData.color}>
                            {modalData.title}
                        </TextColored>
                    }
                    subtitle={
                        <TextColored size={body32} bold type={modalData.color}>
                            {modalData.subtitle}
                        </TextColored>
                    }
                    message={
                        <>
                            <Text type='primary' size={body25}>
                                {modalData.message}
                            </Text>
                            {modalData.button}
                        </>
                    }
                    handleClose={() => handleCloseModalError()}
                />

            </Container>
        </div >
    );
};

export default RestaurarContrasenia;
