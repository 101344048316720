//Librerias
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";

//Helpers
import { api } from "../../../helpers/api";
import { AUTOATENCIONAPI, PORTALSAPI } from "../../../helpers/url";
import { DecryptData } from "../../../helpers/encrypt";
import {
  encryptPassword,
  fireAlert,
  getUserIP,
} from "../../../helpers/autoAtencionHelpers";
import useStorage from "../../../helpers/useStorage";

//Assets
import {
  AiFillEye,
  AiFillEyeInvisible,
  AiFillCloseCircle,
} from "react-icons/ai";
import { FaUserEdit, FaUserClock } from "react-icons/fa";

// Componentes
import { Form, InputGroup, Modal } from "react-bootstrap";
import CustomButton from "../../buttons/Button";
import { TextColored } from "../../typography";
import LoadingScreen from "../../loading/LoadingScreen";
import IconButton from "../../buttons/IconButton";

const ModalSuscripcionDM = ({
  show,
  checkProcess,
  setStatus,
  timeout,
  setShow,
  token,
  rut,
  // Flag utilizado para clientes en status 2 "Enrolados" para ir directamente a la seccion de actualizacion de datos
  needUpdate = false,
}) => {
  const history = useHistory();

  const [{ celular, nombres, correo, idcontacto, tipo_acceso, cliente }] =
    useStorage("user", {}, "local");

  const [attemps, setAttemps] = useStorage(
    "intentos-enrolamiento",
    0,
    "session"
  );

  const [{ processType, loading }, setProcess] = useState({
    processType: "verificar",
    loading: true,
  });

  const [progress, setProgress] = useState("verificar");

  const [{ sessionId, wrongPassword }, setData] = useState({
    sessionId: null,
    wrongPassword: false,
  });

  const [{ inputType, SII, RUT }, setForm] = useState({
    inputType: "password",
    SII: "",
    RUT: "",
  });

  const [blocked, setBlock] = useState(null);

  const loadingMsg = {
    verificar: "Verificando suscripción...(Puede tardar un momento)",
    25: "Obteniendo datos...",
    50: "Actualizando datos...",
    75: "Verificando datos...",
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { value, name } = e.target;
    setForm((f) => {
      return {
        ...f,
        [name]: value,
      };
    });
  };

  const handleRejectedSesion = useCallback(async () => {
    try {
      //Data incorrecta. Si es menor de 3 se le suma 1, sino se bloquea
      if (Number(attemps) < 2) {
        setAttemps(Number(attemps) + 1);
      } else {
        const date = moment.utc().add(1, "hour");
        await api.put(
          AUTOATENCIONAPI.PUT.ESTABLECER_BLOQUEO.replace(
            "{iduser}",
            idcontacto
          ).replace(
            "{date}",
            date
              .clone()
              .tz("America/Santiago") //Horario chile
              .format("YYYY-MM-DD HH:mm:ss")
          )
        );
        //Seteo el bloqueo en el componente
        setBlock(date.local().diff(new Date(), "milliseconds"));
      }

      setForm((f) => {
        return { ...f, SII: "" };
      });
      setProcess((p) => {
        return { ...p, loading: false };
      });
      setProgress(0);
    } catch (err) {
      console.log(err);
    }
  }, [attemps, idcontacto, setAttemps]);

  const createRequest = useCallback(
    async (type) => {
      try {
        //Obtengo la contraseña SII almacenada en la bd
        const {
          data: {
            data: { siipassword, idpersona: idConsentimiento },
          },
        } = await api.get(
          AUTOATENCIONAPI.GET.DATA_ENROLAMIENTO.replace("{iduser}", idcontacto)
        );

        const publicUrl = PORTALSAPI.GET.PUBLIC_KEY.replace(
          "{idcontacto}",
          idcontacto
        ).replace("{tipo}", tipo_acceso);

        const { data: publicKey } = await api.get(publicUrl);

        // //Obtener ip
        // const IpClienteWeb = await getUserIP();
        const IpClienteWeb = "";

        //Obtengo contraseña encriptada
        const Clave = await encryptPassword(
          type === "crear" || type === "actualizarSuscripcion"
            ? DecryptData(siipassword, publicKey)
            : SII
        );

        if (
          IpClienteWeb &&
          publicKey &&
          nombres &&
          correo &&
          celular &&
          siipassword &&
          idConsentimiento
        ) {
          //Armo el body para la sesion
          return JSON.stringify({
            IpClienteWeb,
            InformacionDeContacto: {
              IdSuscriptor: rut,
              Nombre:
                DecryptData(nombres, publicKey) ||
                DecryptData(cliente, publicKey),
              Correo: DecryptData(correo, publicKey),
              Telefono: DecryptData(celular, publicKey) || "0",
            },
            Documentos: [
              // Documentos necesarios para enrolamiento
              {
                Codigo: "Cte",
                ExtrasNotificacion: {
                  // idConsentimiento = id de aceptacion TOU progreso.
                  idConsentimiento,
                },
              },
              {
                Codigo: "Dte",
                ExtrasNotificacion: {
                  idConsentimiento,
                },
              },
              {
                Codigo: "DeudaTGR",
                ExtrasNotificacion: {
                  idConsentimiento,
                },
              },
              {
                Codigo: "Rpetc",
                ExtrasNotificacion: {
                  idConsentimiento,
                },
              },
              {
                Codigo: "Djs",
                ExtrasNotificacion: {
                  idConsentimiento,
                },
              },
            ],
            Credenciales: {
              ClaveTributaria: {
                Clave,
              },
            },
            ActualizarSuscriptor: "Actualizar",
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    [SII, celular, cliente, correo, idcontacto, nombres, rut, tipo_acceso]
  );

  const sesionCheck = useCallback(
    async (idSesion, t) => {
      try {
        const estadoUrl = AUTOATENCIONAPI.GET.OBTENER_ESTADO.replace(
          "{rut}",
          RUT || rut
        )
          .replace("{sessionid}", idSesion)
          .replace("{token}", t)
          .replace("{idcontacto}", idcontacto);

        const {
          data: { Sesion },
        } = await api.get(estadoUrl);

        if (Sesion.Estado === "Completada" || Sesion.Estado === "Incompleta") {
          return Sesion;
        } else {
          //Si devuelve Verificando se vuelve a hacer la peticion luego de 2.5s
          await timeout(1500);
          return await sesionCheck(idSesion, t);
        }
      } catch (err) {
        await timeout(1500);
        await sesionCheck(idSesion, t);
      }
    },
    [timeout, RUT, idcontacto, rut]
  );

  const checkSuscription = useCallback(
    async (type) => {
      try {
        if (type === "verificar") {
          //Primera verificacion ni bien se levanta el modal
          const result = await checkProcess(type, token, rut);
          //Enrolado --> autoatencion || No Enrolado --> Creo sesion.
          switch (result) {
            case "NoSuscrito": {
              return await checkSuscription("crear");
            }
            case "ActualizarCredenciales": {
              setProcess((p) => {
                return {
                  ...p,
                  loading: false,
                  processType: "actualizarSuscripcion",
                };
              });
              setProgress(0);
              break;
            }
            case "Suscrito": {
              setProgress(100);
              setTimeout(
                () => history.push("autoatencion/seleccion-facturas"),
                2500
              );
              break;
            }
            default: {
              break;
            }
          }
        } else {
          if (token && rut) {
            type !== "crear" &&
              setProcess((p) => {
                return { ...p, loading: true };
              });
            setProgress(25);
            const request = await createRequest();
            setProgress(50);
            //Guardo el sessionId en un helper para obtener el estado de la sesion creada
            let temporalSessionId = null;
            // type === "actualizarSuscripcion" es lo mismo que type === "crear" solamente que se utiliza en el caso de que ya exista una suscripcion pero los datos SII sean modificados
            if (type === "crear" || type === "actualizarSuscripcion") {
              //Conexion a dm con el token
              await api.get(
                AUTOATENCIONAPI.GET.CONEXION_ENROLAMIENTO.replace(
                  "{token}",
                  token
                ).replace("{idcontacto}", idcontacto)
              );
              //Creacion de la sesion - envio {request} para que no sea parseado a obj
              const {
                data: { IdSesion },
              } = await api.post(
                AUTOATENCIONAPI.POST.SESION.replace("{rut}", RUT || rut)
                  .replace("{token}", token)
                  .replace("{idcontacto}", idcontacto),
                { request }
              );
              //Guardo sessionId
              temporalSessionId = IdSesion;
              setData((d) => {
                return { ...d, sessionId: IdSesion };
              });
            } else {
              // type === "actualizar", actualiza la sesion existente
              const url = AUTOATENCIONAPI.PUT.ACTUALIZAR_SESION.replace(
                "{rut}",
                rut
              )
                .replace("{token}", token)
                .replace("{sessionid}", sessionId)
                .replace("{idcontacto}", idcontacto);
              await api.put(url, { request });
            }
            setProgress(75);
            if (temporalSessionId || sessionId) {
              //Comprobar que la sesion tenga los datos completos y correctos
              const { Estado, CredencialesCompartidas } = await sesionCheck(
                sessionId || temporalSessionId,
                token
              );
              if (
                //Estado de la sesion y de la contraseña SII
                Estado === "Completada" &&
                CredencialesCompartidas[0].Estado === "Correcta"
              ) {
                setProgress(100);
                //Si la data es correcta la actualizo en la bd
                if (type === "actualizar" || type === "actualizarSuscripcion") {
                  await api.put(
                    AUTOATENCIONAPI.PUT.ACTUALIZAR_SII.replace(
                      "{idcontacto}",
                      idcontacto
                    ).replace("{password}", SII)
                  );
                  setShow(false);
                }
                const statusUpdateURL =
                  AUTOATENCIONAPI.PUT.ACTUALIZAR_ESTADO.replace(
                    "{iduser}",
                    idcontacto
                  ).replace("{state}", 1);
                await api.put(statusUpdateURL);
                setStatus(4);
                setTimeout(() => {
                  setShow(false);
                }, 1500);
              } else {
                setData((d) => {
                  return { ...d, wrongPassword: true };
                });
                setProgress(100);
                await timeout(1500);
                await handleRejectedSesion();
                processType !== "actualizar" &&
                  setProcess((p) => {
                    return { ...p, processType: "actualizar" };
                  });
                setData((d) => {
                  return { ...d, wrongPassword: false };
                });
                return;
              }
            } else {
              await fireAlert({
                title: "Hubo un error al generar la sesion",
              });
              setProcess((p) => {
                return { ...p, loading: false };
              });
            }
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    [
      RUT,
      SII,
      checkProcess,
      createRequest,
      handleRejectedSesion,
      history,
      idcontacto,
      processType,
      rut,
      sesionCheck,
      sessionId,
      setShow,
      setStatus,
      timeout,
      token,
    ]
  );

  const checkBlocked = useCallback(async () => {
    try {
      if (blocked === null) {
        const { data } = await api.get(
          AUTOATENCIONAPI.GET.OBTENER_BLOQUEO.replace(
            "{idcontacto}",
            idcontacto
          )
        );
        if (data?.fecha) {
          const blockTime = moment
            .tz(data.fecha, "America/Santiago")
            .local()
            .diff(new Date(), "milliseconds");
          if (blockTime <= 0) {
            const {
              data: { ok },
            } = await api.put(
              AUTOATENCIONAPI.PUT.ELIMINAR_BLOQUEO.replace(
                "{iduser}",
                idcontacto
              )
            );
            if (ok) {
              setBlock(false);
              return false;
            }
          } else {
            setBlock(blockTime);
            return true;
          }
        } else {
          setBlock(false);
          return false;
        }
      }
    } catch (err) {
      console.log(err);
    }
  }, [blocked, idcontacto]);

  const checkFunction = useCallback(async () => {
    //Ni bien se levanta el modal chequeo si existe algun bloqueo para evitar peticiones de mas
    const isBlocked = await checkBlocked();
    //Precargo el rut en el form para darle mas facilidad al usuario
    if (isBlocked || attemps) {
      // Si esta bloqueado cargo el token en el caso de que se cumpla el desbloqueo sin haber reiniciado la pagina
      setProcess((p) => {
        return { ...p, loading: false };
      });
    }
    if (isBlocked === false) {
      if (!needUpdate) {
        await checkSuscription("verificar");
      } else {
        setProcess((p) => ({
          ...p,
          loading: false,
          processType: "actualizarSuscripcion",
        }));
      }
    }
  }, [attemps, checkBlocked, checkSuscription, needUpdate]);

  const params =
    "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no";

  useEffect(() => {
    if (rut.length > 0) {
      setForm((f) => {
        return { ...f, RUT: rut };
      });
    }
    if (show) {
      checkFunction();
    }
  }, [checkFunction, show, rut]);

  useEffect(() => {
    if (blocked) {
      return setTimeout(() => {
        setBlock(blocked - 1000);
      }, 1000);
    }
  }, [blocked]);

  if (show) {
    if (!loading) {
      return (
        <Modal show={show} centered>
          {blocked ? (
            <Modal.Body className="d-flex flex-column justify-content-center align-items-center text-center">
              <div className="d-flex flex-row-reverse w-100">
                <IconButton
                  Icon={<AiFillCloseCircle size={30} />}
                  onClick={() => setShow(false)}
                />
              </div>
              <FaUserClock size={155} />
              <TextColored children="Su cuenta ha sido" size={1.5} />
              <TextColored
                children="SUSPENDIDA TEMPORALMENTE"
                size={2.5}
                weight={600}
                type="dark"
              />
              <TextColored children="Vuelva a intentarlo dentro de:" size={1} />
              <TextColored
                children={moment.utc(blocked).format("HH:mm:ss")}
                weight={500}
                size={2.5}
                type="dark"
              />
            </Modal.Body>
          ) : (
            <>
              <Modal.Header className="d-flex flex-column">
                <div className="d-flex flex-row-reverse w-100">
                  <IconButton
                    Icon={<AiFillCloseCircle size={30} />}
                    onClick={() => setShow(false)}
                  />
                </div>
                <div
                  className="d-flex flex-column w-100 justify-content-center"
                  style={{ marginInline: "0.5rem" }}
                >
                  <div
                    className="d-flex align-items-end"
                    style={{ gap: "10px" }}
                  >
                    <FaUserEdit size={40} />
                    <TextColored
                      size={1.5}
                      children="Actualizar Datos"
                      type="dark"
                      weight={600}
                    />
                  </div>
                  <TextColored
                    size={0.9}
                    children={
                      <span>
                        Para actualizar tus datos necesitamos que registres tu
                        RUT y Contraseña del SII. Revisa nuestra Política de
                        Privacidad{" "}
                        <button
                          children="aquí"
                          className="link-button"
                          onClick={() =>
                            window.open(
                              "https://www.progreso.cl/compania/politica-privacidad/",
                              "Política de privacidad",
                              params
                            )
                          }
                        />
                        .
                      </span>
                    }
                    type="dark"
                  />
                </div>
              </Modal.Header>
              <Modal.Body>
                <div
                  className="d-flex flex-column justify-content-around align-items-center text-center"
                  style={{ gap: "2rem" }}
                >
                  <div
                    className="d-flex flex-column justify-content-center text-left"
                    style={{ width: "85%" }}
                  >
                    <div
                      className="w-100 d-flex flex-column"
                      style={{ gap: "1rem" }}
                    >
                      <div>
                        <TextColored
                          weight={500}
                          size={1}
                          children="RUT Empresa:"
                          type="dark"
                        />
                        <Form.Control
                          type="text"
                          placeholder={
                            blocked
                              ? "Bloqueado."
                              : "Introduce tu RUT (Incluye guiones)..."
                          }
                          name="RUT"
                          disabled={blocked}
                          onChange={handleChange}
                          value={RUT}
                          className={`input-modal-enrolamiento input-suscription ${
                            attemps > 1 && "error"
                          }`}
                          maxLength={10}
                        />
                      </div>
                      <div>
                        <TextColored
                          weight={500}
                          size={1}
                          children="Clave Portal SII:"
                          type="dark"
                        />
                        <InputGroup className={attemps > 1 && "error"}>
                          <Form.Control
                            type={inputType}
                            placeholder="Introduce tu clave..."
                            value={SII}
                            name="SII"
                            style={{
                              border: "none",
                            }}
                            onChange={handleChange}
                            disabled={blocked}
                            className="input-modal-enrolamiento input-suscription"
                            autoComplete="new-password"
                          />
                          <button
                            onClick={() =>
                              setForm((f) => {
                                return {
                                  ...f,
                                  inputType:
                                    inputType === "password"
                                      ? "text"
                                      : "password",
                                };
                              })
                            }
                            disabled={blocked}
                            style={{
                              border: "none",
                              backgroundColor: "#eceaea",
                              borderRadius: "0 0.25rem 0.25rem 0",
                            }}
                          >
                            {inputType === "text" ? (
                              <AiFillEye />
                            ) : (
                              <AiFillEyeInvisible />
                            )}
                          </button>
                        </InputGroup>
                      </div>
                      {attemps > 1 && !blocked && (
                        <label
                          htmlFor="SII"
                          style={{
                            fontSize: "0.8rem",
                            cursor: "default",
                            color: "red",
                          }}
                        >
                          Alguno de los datos es incorrecto.{" "}
                          {3 - attemps >= 2
                            ? `Quedan ${3 - attemps} intentos.`
                            : `Queda 1 intento.`}
                        </label>
                      )}
                    </div>
                  </div>
                  <div
                    className="d-flex flex-column"
                    style={{
                      width: "85%",
                      gap: "1rem",
                      marginBottom: "0.8rem",
                    }}
                  >
                    <CustomButton
                      children={
                        <TextColored
                          size={1.2}
                          weight={500}
                          children="Actualizar"
                          type="dark"
                        />
                      }
                      disabled={blocked || SII?.length === 0 || RUT?.length < 9}
                      onClick={() => checkSuscription(processType)}
                    />
                    <a
                      href="https://homer.sii.cl/"
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        textDecoration: "underline",
                        color: "black",
                        fontSize: "0.9rem",
                      }}
                    >
                      <b>¿Olvidaste tu Clave?</b> Recupérala en el Portal del
                      SII
                    </a>
                  </div>
                </div>
              </Modal.Body>
            </>
          )}
        </Modal>
      );
    } else {
      return (
        <LoadingScreen
          text={loadingMsg[progress]}
          progress={progress}
          textFinished={
            wrongPassword
              ? "Datos incorrectos."
              : "Verificación completada, continuando..."
          }
          noProgress={processType === "verificar"}
        />
      );
    }
  }
  return null;
};

export default ModalSuscripcionDM;
