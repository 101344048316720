import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import {
  Check,
  CheckMobile,
  Next,
  NextMobile,
} from "../../assets/icons/card/index";
import useWindowDimensions from "../../helpers/useWindowDimensions";
import { ThemeContext } from "../../theming";
import { Pipe, Text } from "../typography";

const cardHeight = 65;

const CardDetail = ({ text, value, icon }) => {
  const app = useContext(ThemeContext);
  const { body12, body32 } = app.theme.font;
  const { width } = useWindowDimensions();

  return (
    <Row className="d-flex align-items-center justify-content-center">
      <Col xs={7} className="text-center d-flex justify-content-center">
        {icon}
        <Text
          size={width >= 751 ? body12 : 0.5}
          bold
          type="primary"
          className="d-flex align-items-center m-2"
        >
          {text}
        </Text>
        <Text
          size={width >= 751 ? body32 : 1.0}
          bold
          type="primary"
          className={width >= 701 ? "m-2" : "mt-2 mb-2 mr-4"}
        >
          {value}
        </Text>
      </Col>
      <Col xs={2}></Col>
    </Row>
  );
};

function Card({ title, subtitle1, subtitle2, data = {}, value2 }) {
  const app = useContext(ThemeContext);
  const { body28 } = app.theme.font;
  const { width } = useWindowDimensions();

  const card = {
    width: "100%",
    height: `${cardHeight}px`,
    borderRadius: "7px",
    background: app.theme.primary,
    marginTop: width >= 701 ? "0" : "10px",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  return (
    <div style={card}>
      {width >= 701 ? (
        <Row className="w-100" style={{ justifyContent: "space-between" }}>
          <Col className="d-flex align-items-center" xs={2}>
            <Text type="primary" size={width >= 701 ? body28 : 0.9}>
              {title}
            </Text>
          </Col>
          <Col
            className="d-flex justify-content-center align-items-center "
            xs={1}
          >
            <Pipe marginLeft={"20px"} size={30}></Pipe>
          </Col>
          <Col xs={4} className="d-flex align-items-center">
            <CardDetail
              icon={
                width >= 701 ? (
                  <Check className="m-1 d-none d-xl-block" />
                ) : (
                  <CheckMobile className="m-1 d-none d-xl-block" />
                )
              }
              text={data.resumen ? data.resumen[0].descripcion : ""}
              value={data.resumen ? data.resumen[0].total : ""}
            />
          </Col>
          <Col xs={4}>
            <CardDetail
              icon={
                width >= 701 ? (
                  <Next className="m-1 d-none d-xl-block" />
                ) : (
                  <NextMobile className="m-1 d-none d-xl-block" />
                )
              }
              text={data.resumen ? data.resumen[1].descripcion : ""}
              value={data.resumen ? data.resumen[1].total : ""}
            />
          </Col>
        </Row>
      ) : (
        <Col xs={12} style={{ justifyContent: "space-between" }}>
          <Row>
            <Col xs={2} className="d-flex align-items-center">
              <Text type="primary" size={width >= 701 ? body28 : 0.7}>
                {title}
              </Text>
            </Col>
            <Col
              xs={1}
              className="d-flex justify-content-center align-items-center "
            >
              <Pipe marginLeft={"20px"} size={20}></Pipe>
            </Col>
            <Col xs={6} className="d-flex align-items-center">
              <CardDetail
                icon={
                  width >= 701 ? (
                    <Check className="m-1 d-none d-xl-block" />
                  ) : (
                    <CheckMobile className="m-1 d-none d-xl-block" />
                  )
                }
                text={data.resumen ? data.resumen[0].descripcion : ""}
                value={data.resumen ? data.resumen[0].total : ""}
              />
            </Col>
            <Col xs={2}>
              <CardDetail
                icon={
                  width >= 701 ? (
                    <Next className="m-1 d-none d-xl-block" />
                  ) : (
                    <NextMobile className="m-1 d-none d-xl-block" />
                  )
                }
                text={data.resumen ? data.resumen[1].descripcion : ""}
                value={data.resumen ? data.resumen[1].total : ""}
              />
            </Col>
          </Row>
        </Col>
      )}
    </div>
  );
}

export default Card;
