import React, { useContext, useState } from "react";
import { Modal, Row } from "react-bootstrap";
import { ThemeContext } from "../../../theming";
import { Text, TextColored, Title } from "../../typography";
import Logo from "../../../assets/images/logo6.png";
import Archivo from "../../../assets/images/archivo.png";
import { ModalContent } from "./ModalAutoatencion";
import useWindowDimensions from "../../../helpers/useWindowDimensions";
import { mobileXSWidth } from "../../../helpers/responsiveVariables";
import CustomButton from "../../buttons/Button";
import { useHistory } from "react-router-dom";
import {
  generateCesionObj,
  getRut,
} from "../../../helpers/autoAtencionHelpers";
import useStorage from "../../../helpers/useStorage";
import { AUTOATENCIONAPI } from "../../../helpers/url";
import { api } from "../../../helpers/api";
import { AiFillCloseCircle } from "react-icons/ai";
import IconButton from "../../buttons/IconButton";
import TablaFacturas from "../../autoatencion/TablaFacturas";
import { MdArrowBackIos } from "react-icons/md";

import "./styles.css";

const ModalTipoCesion = ({ show, setShow, metodo }) => {
  // Hooks
  const { width } = useWindowDimensions();
  const history = useHistory();
  const {
    theme: {
      font: { body28, body14 },
    },
  } = useContext(ThemeContext);
  // Storage
  const [bills] = useStorage("bills", [], "session");
  const [{ idcontacto }] = useStorage("user", {}, "local");

  // State
  const [rows, setRows] = useState([]);
  const [files, setFiles] = useState({});

  const missingDocuments = !!bills.find(({ folio }) => !files[folio]);

  const [{ state, loading }, setForm] = useState({
    state: "select",
    loading: false,
  });

  const setState = (prop, value) => {
    setForm((p) => {
      return { ...p, [prop]: value };
    });
  };

  const handleSubmit = async (type) => {
    if (type === "tipo-cesion-facturador") {
      setState("loading", true);

      const rut_cliente = await getRut();

      const options = {
        mybiller: true,
        registerFintec: false,
        cesionflag: false,
      };

      const obj = await generateCesionObj(rows, options);

      const publicURL = AUTOATENCIONAPI.POST.CESION.replace(
        "{rut}",
        rut_cliente
      ).replace("{idcontacto}", idcontacto);

      await api.post(publicURL, obj);

      setForm((f) => {
        return { ...f, state: "finished", loading: false };
      });
    } else {
      history.push("/autoatencion/archivos");
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
        setTimeout(() => {
          setState("state", "select");
          setFiles({});
        }, 200);
      }}
      centered
      dialogClassName={state === "finished" ? "modal-40" : "modal-60w"}
      scrollable
      backdrop={state === "finished" ? "static" : true}
    >
      {
        {
          select: (
            <Modal.Body className="d-flex flex-column justify-content-center align-items-center">
              <Row className="w-100 flex-row-reverse">
                <IconButton
                  Icon={<AiFillCloseCircle size={25} />}
                  onClick={() => setShow(false)}
                />
              </Row>
              <div className={`cesion-body`}>
                <div>
                  <Text
                    children="¿Cómo quieres ceder tus documentos?"
                    type="primary"
                    size={width < mobileXSWidth ? body28 : 2}
                    bold
                  />
                  <br />
                  <TextColored
                    children="Selecciona desde qué plataforma quieres realizar la cesión de tus facturas."
                    size={width < mobileXSWidth ? body14 : 1.2}
                  />
                </div>
                <div
                  className={
                    width < mobileXSWidth ? "options mobile" : "options"
                  }
                >
                  <div
                    className={
                      width < mobileXSWidth
                        ? "option-container mobile-container"
                        : "option-container"
                    }
                  >
                    <div className="option-placeholder">
                      <img src={Logo} alt="logo" width="60%" />
                    </div>
                    <div className=" option-subtitle" style={{ gap: "0.5rem" }}>
                      <TextColored
                        children="Tendrás que cargar los XML y PDF de tus facturas y tu certificado digital."
                        size={body14}
                        moreStyles={{ color: "#1d1d1b" }}
                        weight={500}
                      />
                      <div style={{ width: "80%" }}>
                        <CustomButton
                          children={
                            <TextColored
                              children="Continuar con Progreso"
                              bold
                              size={1}
                              type="dark"
                            />
                          }
                          disabled={loading}
                          onClick={() => handleSubmit("tipo-cesion-progreso")}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      width < mobileXSWidth
                        ? "option-container mobile-container"
                        : "option-container"
                    }
                  >
                    <div className="option-placeholder">
                      <img src={Archivo} alt="logo" width="25%" />
                      <Title
                        title="Desde mi facturador"
                        subtitle="(MiPYME, Acepta, otros.)"
                        pipe={false}
                      />
                    </div>
                    <div className="option-subtitle">
                      <TextColored
                        children="Deberás ceder los documentos a Progreso después de terminar tu solicitud."
                        size={body14}
                        moreStyles={{ color: "#1d1d1b" }}
                        weight={500}
                      />

                      <div style={{ width: "80%" }}>
                        <CustomButton
                          children={
                            <TextColored
                              children="Continuar con Mi Facturador"
                              bold
                              size={1}
                              type="dark"
                            />
                          }
                          disabled={loading}
                          loading={loading}
                          onClick={() =>
                            setState("state", "tipo-cesion-facturador")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="d-flex justify-content-center"
                style={{ width: "30%", minWidth: "250px" }}
              ></div>
            </Modal.Body>
          ),
          "tipo-cesion-facturador": (
            <>
              <Modal.Header className="d-flex flex-column justify-content-center align-items-center text-center">
                <Row className="w-100 flex-row-reverse">
                  <div className="d-flex flex-row-reverse w-50">
                    <IconButton
                      Icon={<AiFillCloseCircle size={25} />}
                      onClick={() => setShow(false)}
                    />
                  </div>
                  <div className="d-flex w-50">
                    <IconButton
                      Icon={<MdArrowBackIos size={25} />}
                      onClick={() => setState("state", "select")}
                    />
                  </div>
                </Row>
                <TextColored size={2} weight={700} type="dark">
                  Archivos Restantes
                </TextColored>
                <TextColored size={1} weight={400} type="dark">
                  Antes de finalizar, debes agregar los archivos PDF necesarios
                  para el registro de la operacion.
                </TextColored>
              </Modal.Header>
              <Modal.Body
                className="d-flex flex-column justify-content-center align-items-center"
                style={{ gap: "1rem" }}
              >
                <div style={{ overflowX: "auto", maxWidth: "100%" }}>
                  <TablaFacturas
                    type="archives_myBiller"
                    rows={rows}
                    setRows={setRows}
                    setFiles={setFiles}
                    files={files}
                    setLoading={(e) => setForm((f) => ({ ...f, loading: e }))}
                    loading={loading}
                  />
                </div>
                <div style={{ width: "30%", minWidth: "300px" }}>
                  <CustomButton
                    children={
                      <TextColored
                        children="Ingresar solicitud"
                        bold
                        size={1}
                        type="dark"
                      />
                    }
                    onClick={() => handleSubmit("tipo-cesion-facturador")}
                    disabled={missingDocuments || loading}
                    loading={loading}
                  />
                </div>
              </Modal.Body>
            </>
          ),
          finished: <ModalContent type="cesion-exitosa" />,
        }[state]
      }
    </Modal>
  );
};

export default ModalTipoCesion;
