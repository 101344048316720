import React from 'react';

const icon = {
  display: "flex",
  alignItems: "center",
};
const svgContainer = {
  display: "flex",
  justifyContent: "center",
};

export const CheckMobile = () => {
  return (
    <div style={icon}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="18"
        height="18"
        viewBox="0 0 28 28"
      >
        <image
          id="Capa_120"
          data-name="Capa 120"
          width="28"
          height="28"
          xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAACn0lEQVRIibWWQYgSURjH/65kYYrsLgaF0CETgo4LnmJZqUOnICJEoksieEsiFtvDXrq5hyJYWfbQRbwEphAEmqcNOoQQnpY8GVIriRgkrbH6j29whtlJZ4Zt+sNjmPe+9/3mm/e+9z4XSdjQOQC3AKwCuAJgCcACgB8A9gG8B1AG8MXSlQBN2mWSBZK/aa0xyTckV8x8zhtYIPmE5KENkFECfk7ytF3gGZKvTwAy6gPJJSugm2TFAZiqTyQDZsCcgzBVpXnAayQn/wEouq9y9GnxEcCKnRw5gb4CuATgcGE6d81J2Hg8Rj6fx87ODqYBXQBwT5+HL538f5lMRihKq1araveefg2/OQXL5/MazOv1stVqqUOSn4sCu+gUrF6v0+PxKDCXy8VCoWA0uSHA607AJJJAIKBFt7m5OcssKcA7/wrr9XoMh8MaLJFIcDKZmWGPBXhb3zMYDLixscFyuWwLNhqNGIvFNFg0GuVwOJxn/kiAa/qebDarrUGpVLIEJpNJDRYKhdjtds3MHwjwvL6nWCwe22WNRmPu7Fwup9n6/X42m02r71tV06Kt702n08e+utPp/DWzUqnQ7XYrNvKUdwvJnXpWBW7rR46OjkzXRSLx+Xza+NbWlhVM9Faf+FHjqGyeSCSiOY3H48rOOzg4UKJW+1OplB2Y6K7xtqgZLSS3lpeXNefr6+tKtOq7/AXZpTa0P71rjwGvyi43ztWfHvomedfv9+1Gd3PeBZyZZb27u6ukiQoMBoP6M9JK21Y1zfYsB7VaTVkv+a3tdtsuTK4KjxVQLuVndj2a6NWsys2sJo2T/H4C0E+SD6cfbrsuVdsiyackTc+rqQYkX5AMmfm0W+qfmpb5aqm/CMANYADgM4A9AO8A/DL1AuAPahsgTGZ6/BwAAAAASUVORK5CYII="
        />
      </svg>
    </div>
  );
};

export const Check = () => {
  return (
    <div style={icon}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="28"
        height="28"
        viewBox="0 0 28 28"
      >
        <image
          id="Capa_120"
          data-name="Capa 120"
          width="28"
          height="28"
          xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAACn0lEQVRIibWWQYgSURjH/65kYYrsLgaF0CETgo4LnmJZqUOnICJEoksieEsiFtvDXrq5hyJYWfbQRbwEphAEmqcNOoQQnpY8GVIriRgkrbH6j29whtlJZ4Zt+sNjmPe+9/3mm/e+9z4XSdjQOQC3AKwCuAJgCcACgB8A9gG8B1AG8MXSlQBN2mWSBZK/aa0xyTckV8x8zhtYIPmE5KENkFECfk7ytF3gGZKvTwAy6gPJJSugm2TFAZiqTyQDZsCcgzBVpXnAayQn/wEouq9y9GnxEcCKnRw5gb4CuATgcGE6d81J2Hg8Rj6fx87ODqYBXQBwT5+HL538f5lMRihKq1araveefg2/OQXL5/MazOv1stVqqUOSn4sCu+gUrF6v0+PxKDCXy8VCoWA0uSHA607AJJJAIKBFt7m5OcssKcA7/wrr9XoMh8MaLJFIcDKZmWGPBXhb3zMYDLixscFyuWwLNhqNGIvFNFg0GuVwOJxn/kiAa/qebDarrUGpVLIEJpNJDRYKhdjtds3MHwjwvL6nWCwe22WNRmPu7Fwup9n6/X42m02r71tV06Kt702n08e+utPp/DWzUqnQ7XYrNvKUdwvJnXpWBW7rR46OjkzXRSLx+Xza+NbWlhVM9Faf+FHjqGyeSCSiOY3H48rOOzg4UKJW+1OplB2Y6K7xtqgZLSS3lpeXNefr6+tKtOq7/AXZpTa0P71rjwGvyi43ztWfHvomedfv9+1Gd3PeBZyZZb27u6ukiQoMBoP6M9JK21Y1zfYsB7VaTVkv+a3tdtsuTK4KjxVQLuVndj2a6NWsys2sJo2T/H4C0E+SD6cfbrsuVdsiyackTc+rqQYkX5AMmfm0W+qfmpb5aqm/CMANYADgM4A9AO8A/DL1AuAPahsgTGZ6/BwAAAAASUVORK5CYII="
        />
      </svg>
    </div>
  );
};
export const Next = () => {
  return (
    <div style={icon}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="27"
        height="28"
        viewBox="0 0 27 28"
      >
        <image
          id="Capa_128"
          data-name="Capa 128"
          width="27"
          height="28"
          xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAcCAYAAACQ0cTtAAACYklEQVRIia3Wz4sSYRgH8G8/CFNEQTAMLAu8bUoZLBJClyiUImNY8CQkeAmCTnXtT/Cf6LKXCMKMBcODB9koPbhIoZnhZmQOgpahfeMVZ3AXnXl194G5Pc/7mZl35n2eEyRhEicB3ARwG8A1AD4AFgB/AHwF8BHAWwA7ACaGSwlsyXWa5COSXygX30g+IXlm2ZrLoA2SZUnkcOyRvC6L3SU5WBPS4jfJLTPsHsm/R4S0GM/WW4j5SfaPCdJiMNuSA5j4KouGtzkeMxqN0uPxMJvNrgJ+IHlqHtsyq2i1WuIfmV4Wi4W5XG4V8OE8titTkU6nddBqtTKfz8tiexq2IVsxmUyYTCYPgIVCQbZ8U2BPZbM527tEIqGDdrudxaLhdmvxXGDbq2AaqCiKDjocDpZKJbOy1wJ7vyomYjQaMR6P66DT6WSlUjEqqQmsvg6mgbFYTAfD4bBRekdgn9fFRPR6velTCcxmsxml7ov28cusxyyL4XAIRVGgquo0IxQKGaX3xJO9WOeJ+v0+I5GI/gpdLpfZnr0U2ONVIVVVp/ujQW63m+WyaUd6JrDLJP/JQt1ul6FQSIfEWVmtVmVKr2jHldS50+l0GAwGdcjr9bJWq8mU7s6fjbfMssVnHggEdMjn87Fel/5rHhzuZ6+MshuNhg75/X42m01ZaGdR8zxH8rtRVSaTYSqVYrvdloV+kLywbCzYPMZuPZitZzjwiMlo/4jQT5I3ZEe58+KUXhMSe6S/OhlMu6Ik30n+h6Kp3Z/NMwvXkxm/RVwCcAfAVQAXAZydjd9NABUAbwB8MlwBwH82iXfgPXrm6AAAAABJRU5ErkJggg=="
        />
      </svg>
    </div>
  );
};

export const NextMobile = () => {
  return (
    <div style={icon}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="17"
        height="18"
        viewBox="0 0 27 28"
      >
        <image
          id="Capa_128"
          data-name="Capa 128"
          width="27"
          height="28"
          xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAcCAYAAACQ0cTtAAACYklEQVRIia3Wz4sSYRgH8G8/CFNEQTAMLAu8bUoZLBJClyiUImNY8CQkeAmCTnXtT/Cf6LKXCMKMBcODB9koPbhIoZnhZmQOgpahfeMVZ3AXnXl194G5Pc/7mZl35n2eEyRhEicB3ARwG8A1AD4AFgB/AHwF8BHAWwA7ACaGSwlsyXWa5COSXygX30g+IXlm2ZrLoA2SZUnkcOyRvC6L3SU5WBPS4jfJLTPsHsm/R4S0GM/WW4j5SfaPCdJiMNuSA5j4KouGtzkeMxqN0uPxMJvNrgJ+IHlqHtsyq2i1WuIfmV4Wi4W5XG4V8OE8titTkU6nddBqtTKfz8tiexq2IVsxmUyYTCYPgIVCQbZ8U2BPZbM527tEIqGDdrudxaLhdmvxXGDbq2AaqCiKDjocDpZKJbOy1wJ7vyomYjQaMR6P66DT6WSlUjEqqQmsvg6mgbFYTAfD4bBRekdgn9fFRPR6velTCcxmsxml7ov28cusxyyL4XAIRVGgquo0IxQKGaX3xJO9WOeJ+v0+I5GI/gpdLpfZnr0U2ONVIVVVp/ujQW63m+WyaUd6JrDLJP/JQt1ul6FQSIfEWVmtVmVKr2jHldS50+l0GAwGdcjr9bJWq8mU7s6fjbfMssVnHggEdMjn87Fel/5rHhzuZ6+MshuNhg75/X42m01ZaGdR8zxH8rtRVSaTYSqVYrvdloV+kLywbCzYPMZuPZitZzjwiMlo/4jQT5I3ZEe58+KUXhMSe6S/OhlMu6Ik30n+h6Kp3Z/NMwvXkxm/RVwCcAfAVQAXAZydjd9NABUAbwB8MlwBwH82iXfgPXrm6AAAAABJRU5ErkJggg=="
        />
      </svg>
    </div>
  );
};

export const Progreso = () => {
  const progreso = {
    fill: "none",
    stroke: "#ffd135",
    strokeMiterlimit: 10,
    strokeWidth: "0.599px",
    fillRule: "evenodd",
    position: "absolute",
  };

  return (
    <div>
      <svg
        style={progreso}
        xmlns="http://www.w3.org/2000/svg"
        width="152.875"
        height="165.19"
        viewBox="0 0 152.875 165.19"
      >
        <path
          id="Capa_67"
          data-name="Capa 67"
          className="cls-1"
          d="M182.569,1002.78H103.934V1126.4l59.426-59.46H140.382v-23.69h63.386v63.43H180.1v-22.99l-76.163,76.21v7.45h49.96v-24.07h28.192c49.9,0,74.121-33.89,74.121-70.13C256.207,1037.69,231.627,1002.78,182.569,1002.78Z"
          transform="translate(-103.625 -1002.47)"
        />
      </svg>
    </div>
  );
};

export const ProgresoMobile = () => {
  const progreso = {
    fill: "none",
    stroke: "#ffd135",
    strokeMiterlimit: 10,
    strokeWidth: "0.599px",
    fillRule: "evenodd",
    position: "absolute",
  };
  return (
    <div>
      <svg
        style={progreso}
        xmlns="http://www.w3.org/2000/svg"
        width="121.875"
        height="135.19"
        viewBox="0 0 152.875 165.19"
      >
        <path
          id="Capa_67"
          data-name="Capa 67"
          class="cls-1"
          d="M182.569,1002.78H103.934V1126.4l59.426-59.46H140.382v-23.69h63.386v63.43H180.1v-22.99l-76.163,76.21v7.45h49.96v-24.07h28.192c49.9,0,74.121-33.89,74.121-70.13C256.207,1037.69,231.627,1002.78,182.569,1002.78Z"
          transform="translate(-103.625 -1002.47)"
        />
      </svg>
    </div>
  );
};
export const Instructivo = ({name})=>{
	const Image = name?require('../../images/'+name):null;
	return (
		<div style={svgContainer}>
			<img alt="" src={Image?.default} style={{ height: '45px', width : '180px' }} />
		</div>
	);
}
