// Extra small devices (xs)
// Phones (<768px)
export const mobileXSWidth = 768;

// Small devices (sm)
// Tablets (≥768px)
export const mobileSMWidth = 768;

// Medium devices (md)
// Desktops (≥992px)
export const mobileMDWidth = 992;

// Large devices (ld)
// Desktops (≥1200px)
export const mobileLDWidth = 1200;
