import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { Text, TextGeneric } from "../../components/typography";
import { ThemeContext } from "../../theming";
import { mobileXSWidth } from '../../helpers/responsiveVariables';
import useWindowDimensions from '../../helpers/useWindowDimensions';

function SeccionClienteItem(
    { icono,
        titulo,
        contenido,
        marginBottom = '0px'
    }
) {
    const app = useContext(ThemeContext);
    const { body25 } = app.theme.font;
    const { width } = useWindowDimensions();

    return (
        <Row style={{ marginBottom: marginBottom }} className='justify-content-center' >
            <Col lg={1} xs={4}>
                <img alt="" width={70}  src={icono} />
            </Col>
            <Col lg={5} xs={8}>
                <Row>
                    <TextGeneric
                        color={"#FFD135"}
                        bold
                        type="secondary"
                        size={width > mobileXSWidth ? body25 : 0.9}
                    >
                        {titulo}
                    </TextGeneric>
                </Row>
                <Row style={{ textAlign: "start" }}>
                    <Text bold type="secondary" size={0.8}>
                        {contenido}
                    </Text>
                </Row>
            </Col>
        </Row >
    );
}

export default SeccionClienteItem;