import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import Esquina1 from "../../assets/images/esquina1.png";
import Esquina2 from "../../assets/images/esquina2.png";
import Flecha4 from "../../assets/images/flecha4.png";

import Recu6 from "../../assets/images/landing/recu6.png";
import Recu9 from "../../assets/images/landing/recu9.png";
import Recu7 from "../../assets/images/landing/recu7.png";
import Recu17 from "../../assets/images/landing/recu17.png";
import Recu18 from "../../assets/images/landing/recu18.png";
import Recu19 from "../../assets/images/landing/recu19.png";
import Recu1 from "../../assets/images/landing/recu1.png";
import Recu10 from "../../assets/images/landing/recu10.png";
import Recu11 from "../../assets/images/landing/recu11.png";
import Recu12 from "../../assets/images/landing/recu12.png";
import Recu13 from "../../assets/images/landing/recu13.png";
import Recu15 from "../../assets/images/landing/recu15.png";
import Recu16 from "../../assets/images/landing/recu15.png";
import Recu14 from "../../assets/images/landing/recu14.png";
import Recu5 from "../../assets/images/landing/recu5.png";
import { TextGeneric } from "../../components/typography";
import { ThemeContext } from "../../theming";
import useWindowDimensions from '../../helpers/useWindowDimensions';
import { mobileXSWidth } from '../../helpers/responsiveVariables';

function SeccionClientes2() {
  const app = useContext(ThemeContext);
  const { body25 } = app.theme.font;
  const { width } = useWindowDimensions();

  return (
    <Col style={{ background: "white" }}>
      <Row
        className="align-items-center justify-content-md-center header-clientes-2"
        style={{
          width: "100%",
          height: "auto",
          marginLeft: "0px",
          marginRight: "0px",
        }}
      >

        {
          width > mobileXSWidth ?
            <Col className="col-4" style={{ marginTop: "30px" }}>
              <Row className="justify-content-end mb-4">
                <p>
                  <TextGeneric className="mb-4 mt-4" bold size={1.4}>
                    Regístrate y obtén todos estos
                  </TextGeneric>
                  <br />
                  <TextGeneric
                    color={"black"}
                    className="main-color mb-12 mt-4"
                    bold
                    type="secondary"
                    size={2.4}
                  >
                    Beneficios <span style={{ color: "#FFD135" }}>Factoring:</span>
                  </TextGeneric>
                </p>
              </Row>
              <Row
                style={{
                  height: "500px",
                }}
                className="justify-content-end"
              >
                <img height={'100%'} width={'100%'} src={Recu6} alt="" />
              </Row>
            </Col>
            :
            <Col className="col-12" style={{ marginTop: "30px" }}>
              <Row className="justify-content-center">
                <p className="text-center">
                  <TextGeneric className="mb-2 mt-2" bold size={0.9}>
                    Regístrate y obtén todos estos
                  </TextGeneric>
                  <br />
                  <TextGeneric
                    color={"black"}
                    className="main-color mt-4"
                    bold
                    type="secondary"
                    size={1.8}
                  >
                    Beneficios <span style={{ color: "#FFD135" }}>Factoring:</span>
                  </TextGeneric>
                </p>
              </Row>
            </Col>

        }

        <Col className={width > mobileXSWidth ? "col-6" : "col-12"} style={{
          paddingTop: width > mobileXSWidth ? "80px" : "0px"
        }}>
          <Row className={width > mobileXSWidth ? "mt-4 justify-content-center" : "justify-content-start"}>

            <Col lg={5}>
              <Row style={{ justifyContent: "flex-end" }}>
                <img height={20} width={20} src={Esquina1} alt="" />
              </Row>

              <Row style={{ justifyContent: "center", marginBottom: "10px" }}>
                <img alt="" width={80} height={80} src={Recu17} />
              </Row>

              <Row style={{ justifyContent: "center" }}>
                <TextGeneric
                  className="ml-1"
                  bold
                  type="secondary"
                  size={body25}
                >
                  RÁPIDO, PERO <br></br> &ensp; DE VERDAD
                </TextGeneric>
              </Row>
              <Row style={{ justifyContent: "center", textAlign: "center" }}>
                <TextGeneric className="ml-2" semi_bold type="secondary" size={0.8}>
                  Cuenta con  aprobación <br></br> de 1 a 3 horas *
                </TextGeneric>
              </Row>
            </Col>
            <Col lg={5}>
              <Row style={{ justifyContent: "flex-end" }}>
                <img height={20} width={20} src={Esquina2} alt="" />
              </Row>
              <Row style={{ justifyContent: "center", marginBottom: "10px" }}>
                <img alt="" width={80} height={80} src={Recu18} />
              </Row>
              <Row style={{ justifyContent: "center" }}>
                <TextGeneric
                  className="ml-2"
                  bold
                  type="secondary"
                  size={body25}
                >
                  NO MÁS ENVÍOS DE <br></br>&emsp; DOCUMENTOS
                </TextGeneric>
              </Row>
              <Row style={{ justifyContent: "center", textAlign: "center" }}>
                <TextGeneric className="ml-4" semi_bold type="secondary" size={0.8}>
                  No es necesario acompañar nuevos <br></br> 
                  documentos para tus próximas <br></br> 
                  operaciones, agilizando el proceso
                  
                </TextGeneric>
              </Row>
            </Col>
          </Row>
          {/* <Row className="mt-4 justify-content-center">
            <Col lg={5}>
              <Row style={{ justifyContent: "flex-end" }}>
                <img height={20} width={20} src={Esquina2} alt="" />
              </Row>
              <Row style={{ justifyContent: "center", marginBottom: "10px" }}>
                <img alt="" width={80} height={80} src={Recu17} />
              </Row>
              <Row style={{ justifyContent: "center" }}>
                <TextGeneric
                  className="ml-4"
                  bold
                  type="secondary"
                  size={body25}
                >
                  RÁPIDO, PERO DE VERDAD
                </TextGeneric>
              </Row>
              <Row style={{ justifyContent: "center", textAlign: "center" }}>
                <TextGeneric className="ml-4" semi_bold type="secondary" size={0.8}>
                  Cuenta con aprobación <br></br> de 1 a 3 horas*
                </TextGeneric>
              </Row>
            </Col>
            <Col lg={5}>
              <Row style={{ justifyContent: "flex-end" }}>
                <img height={20} width={20} src={Esquina1} alt="" />
              </Row>
              <Row style={{ justifyContent: "center", marginBottom: "10px" }}>
                <img alt="" width={80} height={80} src={Recu10} />
              </Row>
              <Row style={{ justifyContent: "center" }}>
                <TextGeneric
                  className="ml-4"
                  bold
                  type="secondary"
                  size={body25}
                >
                  TASAS PREFERENCIALES
                </TextGeneric>
              </Row>
              <Row style={{ justifyContent: "center", textAlign: "center" }}>
                <TextGeneric className="ml-4" semi_bold type="secondary" size={0.8}>
                  Exclusivas para clientes inscritos <br></br> Progreso
                </TextGeneric>
              </Row>
            </Col>
          </Row> */}
          <Row className="mt-4 justify-content-center">
            <Col lg={5}>
              <Row style={{ justifyContent: "flex-end" }}>
                <img height={20} width={20} src={Esquina2} alt="" />
              </Row>
              <Row style={{ justifyContent: "center", marginBottom: "10px" }}>
                <img alt="" width={80} height={80} src={Recu11} />
              </Row>
              <Row style={{ justifyContent: "center" }}>
                <TextGeneric
                  //className="ml-4"
                  bold
                  type="secondary"
                  size={body25}
                >
                  TRANSPARENCIA TOTAL
                </TextGeneric>
              </Row>
              <Row style={{ justifyContent: "center", textAlign: "center" }}>
                <TextGeneric className="ml-2" semi_bold type="secondary" size={0.8}>
                  Puedes ver todas tus operaciones de <br></br> Factoring en
                  detalle y online
                </TextGeneric>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={{ maxWidth: "1400px", margin: "0 auto " }}>
        <hr
          style={{
            width: "100%",
            height: "1px",
            borderTop: "2px solid #FFD135",
            margin: "10px 0 0",
          }}
        />
        <hr
          style={{
            width: "100%",
            height: "1px",
            borderTop: "2px solid gray",
            margin: "10px 0 0",
          }}
        />
      </Row>

      <Row
        className="align-items-center justify-content-md-center header-clientes-2"
        style={{
          width: "100%",
          height: "auto",
          marginLeft: "0px",
          marginRight: "0px",
        }}
      >

        {
          width > mobileXSWidth ?
            <></>
            :
            <Col className="col-12" style={{ marginTop: "30px" }}>
              <Row className="justify-content-center">
                <p className="text-center">
                  <TextGeneric className="mb-2 mt-2" bold size={0.9}>
                    Regístrate y sorpréndete con nuestros
                  </TextGeneric>
                  <br />
                  <TextGeneric
                    color={"black"}
                    className="main-color mt-4"
                    bold
                    type="secondary"
                    size={1.8}
                  >
                    Beneficios <span style={{ color: "#FFD135" }}>Leasing:</span>
                  </TextGeneric>
                </p>
              </Row>
            </Col>

        }

        <Col className={width > mobileXSWidth ? "col-6" : "col-12"}
          style={{ paddingTop: width > mobileXSWidth ? "80px" : "0px" }}>
          <Row className="justify-content-center">
            <Col lg={5}>
              <Row style={{ justifyContent: "flex-end" }}>
                <img height={20} width={20} src={Esquina1} alt="" />
              </Row>
              <Row style={{ justifyContent: "center", marginBottom: "10px" }}>
                <img alt="" width={80} height={80} src={Recu19} />
              </Row>
              <Row style={{ justifyContent: "center" }}>
                <TextGeneric
                  className="ml-2"
                  bold
                  type="secondary"
                  size={body25}
                >
                  1 ª CUOTA HASTA <br></br>
                  &emsp;EN 90 DÍAS
                </TextGeneric>
              </Row>
              <Row style={{ justifyContent: "center", textAlign: "center" }}>
                <TextGeneric className="ml-2" semi_bold type="secondary" size={0.8}>
                  Tómate tu tiempo y retrasa el pago <br></br>
                  de tu primera cuota (condicionado <br></br>
                  a entrega de pie)
                </TextGeneric>
              </Row>
            </Col>
            <Col lg={5}>
              <Row style={{ justifyContent: "flex-end" }}>
                <img height={20} width={20} src={Esquina2} alt="" />
              </Row>
              <Row style={{ justifyContent: "center", marginBottom: "10px" }}>
                <img alt="" width={80} height={80} src={Recu12} />
              </Row>
              <Row style={{ justifyContent: "center" }}>
                <TextGeneric
                  className="ml-2"
                  bold
                  type="secondary"
                  size={body25}
                >
                  BENEFICIOS <br></br>EXCLUSIVOS
                </TextGeneric>
              </Row>
              <Row style={{ justifyContent: "center", textAlign: "center" }}>
                <TextGeneric className="ml-2" semi_bold type="secondary" size={0.8}>
                  Accede a beneficios y tasas <br></br>
                  preferenciales pensadas solo<br></br>
                   para ti **
                </TextGeneric>
              </Row>
            </Col>
          </Row>
          {/* <Row className="mt-4 justify-content-center">
            <Col lg={5}>
              <Row style={{ justifyContent: "flex-end" }}>
                <img height={20} width={20} src={Esquina2} alt="" />
              </Row>
              <Row style={{ justifyContent: "center", marginBottom: "10px" }}>
                <img alt="" width={80} height={80} src={Recu12} />
              </Row>
              <Row style={{ justifyContent: "center" }}>
                <TextGeneric
                  className="ml-4"
                  bold
                  type="secondary"
                  size={body25}
                >
                  BENEFICIOS EXCLUSIVOS
                </TextGeneric>
              </Row>
              <Row style={{ justifyContent: "center", textAlign: "center" }}>
                <TextGeneric className="ml-4" semi_bold type="secondary" size={0.8}>
                  Accede a beneficios y tasas <br></br> preferenciales pensadas
                  solo para ti
                </TextGeneric>
              </Row>
            </Col>
            <Col lg={5}>
              <Row style={{ justifyContent: "flex-end" }}>
                <img height={20} width={20} src={Esquina1} alt="" />
              </Row>
              <Row style={{ justifyContent: "center", marginBottom: "10px" }}>
                <img alt="" width={80} height={80} src={Recu13} />
              </Row>
              <Row style={{ justifyContent: "center" }}>
                <TextGeneric
                  className="ml-4"
                  bold
                  type="secondary"
                  size={body25}
                >
                  ALIANZAS COMERCIALES
                </TextGeneric>
              </Row>
              <Row style={{ justifyContent: "center", textAlign: "center" }}>
                <TextGeneric className="ml-4" semi_bold type="secondary" size={0.8}>
                  Operamos con una importante red de <br></br> proveedores del
                  transporte, minería, <br></br> equipos, maquinarias y otros
                </TextGeneric>
              </Row>
            </Col>
          </Row> */}
          <Row className="mt-4 justify-content-center">
            <Col lg={5}>
              <Row style={{ justifyContent: "flex-end" }}>
                <img height={20} width={20} src={Esquina1} alt="" />
              </Row>
              <Row style={{ justifyContent: "center", marginBottom: "10px" }}>
                <img alt="" width={80} height={80} src={Recu15} />
              </Row>
              <Row style={{ justifyContent: "center" }}>
                <TextGeneric
                  className="ml-2"
                  bold
                  type="secondary"
                  size={body25}
                >
                  TRANSPARENCIA TOTAL
                </TextGeneric>
              </Row>
              <Row style={{ justifyContent: "center", textAlign: "center" }}>
                <TextGeneric className="ml-2" semi_bold type="secondary" size={0.8}>
                  Puedes ver todas tus operaciones, <br></br> condiciones de
                  pago y mucho más
                </TextGeneric>
              </Row>
            </Col>
          </Row>

        </Col>
        {
          width > mobileXSWidth ?
            <Col className="col-4" style={{ marginTop: "30px" }}>
              <Row className="justify-content-start mb-4">
                <p>
                  <TextGeneric className="mb-4 mt-4" bold size={1.4}>
                    Regístrate y sorpréndete con nuestros
                  </TextGeneric>
                  <br />
                  <TextGeneric
                    color={"black"}
                    className="main-color mb-12 mt-4"
                    bold
                    type="secondary"
                    size={2.6}
                  >
                    Beneficios <span style={{ color: "#FFD135" }}>Leasing:</span>
                  </TextGeneric>
                </p>
              </Row>
              <Row
                style={{
                  height: "500px",
                }}
                className="justify-content-start"
              >
                <img height={'100%'} width={'100%'} src={Recu5} alt="" />
              </Row>
            </Col>
            : <div className="mt-3 col-md-12"></div>}
      </Row>
    </Col>
  );
}

export default SeccionClientes2;
