import React, { useContext, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Error } from "../../assets/icons/dashboard";
import Logo2 from "../../assets/images/logo2.png";
import Pipe2 from "../../assets/images/pipe2.png";
import Eye from "../../assets/icons/eye.svg";
import NoEye from "../../assets/icons/no-eye.svg";
import { api } from "../../helpers/api";
import { PORTALSAPI, PRELOGIN } from "../../helpers/url";
import useWindowDimensions from "../../helpers/useWindowDimensions";
import { ThemeContext } from "../../theming";
import CustomButtonWithoutHover from "../buttons/ButtonWithoutHover";
import Input from "../inputs/Input";
import { ModalSuccess } from "../modal/Modal";
import { Text, TextColored, TextGeneric } from "../typography";
import Loading from "../../components/loading/Loading";
import { EncryptData } from "../../helpers/encrypt";
import Style from "../SelectMasterEmail/SelectMasterEmail.module.css";
import { formatRut } from "../../helpers/functions";

function ClienteConfirmacionPassword() {
  const app = useContext(ThemeContext);
  const { body25, body28, body32 } = app.theme.font;
  const { width } = useWindowDimensions();

  const container = {
    width: "450px",
    height: "400px",
    background: "white",
    // padding: '20px'
  };
  /* Error modal*/
  const [modalData, setModalData] = useState({});
  const [showModalError, setShowModalError] = useState(false);
  const handleCloseModalError = () => setShowModalError(false);
  const handleShowModalError = () => {
    setShowModalError(true);
  };

  const history = useHistory();

  const [showLoading, setShowLoading] = useState({ display: "none" });

  function getTokenFromURL(name = "c", url = window.location.href) {
    //eslint-disable-next-line
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2]);
  }

  function getRutFromURL(name = "u", url = window.location.href) {
    //eslint-disable-next-line
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2]);
  }

  const token = getTokenFromURL();
  const RUT = getRutFromURL();
  const RUTFormat = formatRut(RUT);

  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const containerMobile = {
    width: "100%",
    height: "100%",
    background: "white",
  };

  const buttonLoginStyle = {
    marginTop: "10px",
  };

  <ModalSuccess
    show={showModalError}
    icon={modalData.icon}
    title={
      <TextColored size={body28} type={modalData.color}>
        {modalData.title}
      </TextColored>
    }
    subtitle={
      <TextColored size={body32} bold type={modalData.color}>
        {modalData.subtitle}
      </TextColored>
    }
    message={
      <Text type='primary' size={body25}>
        {modalData.message}
      </Text>
    }
    handleClose={() => handleCloseModalError()}
  />;

  const restaurar = () => {
    setShowLoading({ display: "block" });

    if (password === "" || repeatPassword === "") {
      setShowLoading({ display: "none" });

      setModalData({
        messageLine: false,
        title: "Recuperación de contraseña",
        subtitle: "Error",
        color: "red",
        message: "Debe especificar una contraseña",
        message2: "",
        icon: (
          <>
            <Error />
          </>
        ),
      });
      handleShowModalError();
      return;
    }

    if (password !== repeatPassword) {
      setShowLoading({ display: "none" });

      setModalData({
        messageLine: false,
        title: "Recuperación de contraseña",
        subtitle: "Error",
        color: "red",
        message: "Las contraseñas deben coincidir",
        message2: "",
        icon: (
          <>
            <Error />
          </>
        ),
      });
      handleShowModalError();
      return;
    }
    api
      .get(PORTALSAPI.GET.OBTENER_TOKEN)
      .then((responseToken) => {
        let publicURL = PRELOGIN;

        let body = {
          username: RUT,
        };

        var tokenRequested = responseToken.data;
        api.post(publicURL, body).then((response) => {
          let encryptedPassword = EncryptData(
            password,
            response.data.publicKey
          );
          const resBody = {
            rut: RUT,
            password: encryptedPassword,
            token: token,
          };

          api
            .put(PORTALSAPI.PUT.RESTAURAR_CONTRASENIA, resBody, {
              headers: { Authorization: `Bearer ${tokenRequested}` },
            })
            .then(
              () => {
                setShowLoading({ display: "none" });
                history.push("/clientes/password-terminado");
              },
              (error) => {
                setShowLoading({ display: "none" });
                setModalData({
                  messageLine: false,
                  title: "Recuperación de contraseña",
                  subtitle: "Error",
                  color: "red",
                  message: error.response.data.message,

                  icon: (
                    <>
                      <Error />
                    </>
                  ),
                });
                handleShowModalError();
              }
            );
        });
      })
      .catch(() => {
        setShowLoading({ display: "none" });
        setModalData({
          messageLine: false,
          title: "Recuperación de contraseña",
          subtitle: "Error",
          color: "red",
          message: "Usted no tiene permiso para realizar esta acción.",

          icon: (
            <>
              <Error />
            </>
          ),
        });
        handleShowModalError();
      });
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  return (
    <>
      <Loading estilo={showLoading}></Loading>

      <Col>
        <Row>
          <Container
            style={{
              backgroundColor: "black",
              width: "450px",
              height: "110px",
            }}>
            <Row className='mt-4 justify-content-center'>
              <img width={190} alt='' src={Logo2} />
            </Row>
            <Row className='mt-1 mb-2 justify-content-center'>
              <img width={30} height={5} alt='' src={Pipe2} />
            </Row>
            <Row className='justify-content-center pb-2'>
              <TextGeneric size={0.95} color='white'>
                <b>Cambio de Contraseña</b>
              </TextGeneric>
            </Row>
          </Container>
        </Row>
        <Row>
          <Container style={width >= 701 ? container : containerMobile} fluid>
            <Row className='mt-4 justify-content-center'>
              <Form className={width >= 701 ? "" : "ml-4 mr-4 mb-4"}>
                <Row className='justify-content-center mt-1'>
                  <TextGeneric size={0.9}>
                    <b>Ingresa tu nueva contraseña</b>
                  </TextGeneric>
                </Row>
                <Row className='justify-content-center mt-3 mb-3'>
                  <TextGeneric size={0.7}>
                    {width >= 701 ? (
                      <p>
                        Para mayor seguridad, no utilices contraseñas usadas
                        <br />
                        en otros servicios digitales.
                        <b>Idealmente debe contener</b>
                        <br />
                        <b>
                          números, símbolos y letras mayúsculas y minúsculas.
                        </b>
                      </p>
                    ) : (
                      <p className='text-justify ml-2 mr-2'>
                        Para mayor seguridad, no utilices contraseñas usadas en
                        otros servicios digitales.
                        <b>Idealmente debe contener</b>
                        <b>
                          números, símbolos y letras mayúsculas y minúsculas.
                        </b>
                      </p>
                    )}
                  </TextGeneric>
                </Row>

                <div className={`${Style.containerInputDisabled2} `}>
                  <label htmlFor='Rut'>RUT:</label>
                  <input
                    id='Rut'
                    type='text'
                    disabled
                    placeholder={RUTFormat}
                  />
                </div>

                <Row
                  className='justify-content-center mt-4'
                  as={Row}
                  controlId='formUser'>
                  <div style={{ width: "100%", position: "relative" }}>
                    <Input
                      style={{ paddingRight: "40px" }}
                      side='bottom'
                      type={showPassword ? "text" : "password"}
                      placeholder='Ingresar Nueva Contraseña'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <img
                      style={{
                        position: "absolute",
                        height: "24px",
                        width: "24px",
                        top: "19%",
                        right: "10px",
                        cursor: "pointer",
                      }}
                      alt=''
                      src={showPassword ? Eye : NoEye}
                      onClick={() => setShowPassword((show) => !show)}
                    />
                  </div>
                </Row>
                <Row
                  className='justify-content-center mt-2'
                  as={Row}
                  controlId='formUser2'>
                  <div style={{ width: "100%", position: "relative" }}>
                    <Input
                      side='bottom'
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder='Repetir Nueva Contraseña'
                      value={repeatPassword}
                      onChange={(e) => setRepeatPassword(e.target.value)}
                    />
                    <img
                      style={{
                        position: "absolute",
                        height: "24px",
                        width: "24px",
                        top: "19%",
                        right: "10px",
                        cursor: "pointer",
                      }}
                      alt=''
                      src={showConfirmPassword ? Eye : NoEye}
                      onClick={() => setShowConfirmPassword((show) => !show)}
                    />
                  </div>
                </Row>
                <Row
                  style={buttonLoginStyle}
                  className='justify-content-center mt-4'>
                  <Col lg={6}>
                    <CustomButtonWithoutHover onClick={restaurar}>
                      ENVIAR
                    </CustomButtonWithoutHover>
                  </Col>
                </Row>
              </Form>
            </Row>

            <ModalSuccess
              show={showModalError}
              icon={modalData.icon}
              messageLine={modalData.messageLine}
              title={
                <TextColored size={body28} type={modalData.color}>
                  {modalData.title}
                </TextColored>
              }
              subtitle={
                modalData.message2 !== "" ? (
                  <></>
                ) : (
                  <TextColored size={body32} bold type={modalData.color}>
                    {modalData.subtitle}
                  </TextColored>
                )
              }
              message={
                <>
                  <Text type='primary' size={body25}>
                    {modalData.message}
                  </Text>
                  {modalData.message2 !== "" ? (
                    <Text type='primary' size={body25}>
                      {modalData.message2}
                    </Text>
                  ) : (
                    <></>
                  )}
                </>
              }
              handleClose={() => handleCloseModalError()}
            />
          </Container>
        </Row>
      </Col>
    </>
  );
}

export default ClienteConfirmacionPassword;
