import React, { useContext } from "react";
import { Button, Spinner } from "react-bootstrap";
import { TextButton } from "../typography";
import { ThemeContext } from "../../theming";

function SecondaryButton({
  onClick,
  children,
  size = "md",
  sizeText = 0,
  disabled = false,
  className = "w-100 btn-secondary-custom",
  loading = false,
}) {
  const app = useContext(ThemeContext);
  const { body12 } = app.theme.font;

  const button = {
    background: app.theme.gray,
    color: "white",
    border: `2px solid ${app.theme.gray}`,
    borderRadius: "7px",
    transitionProperty: "background color border",
    transitionDuration: "0.5s"
  };

  return (
    <Button
      disabled={disabled}
      className={className}
      onClick={onClick}
      style={button}
      size={size}
    >
      {loading ? (
        <Spinner animation="border" size="sm" />
      ) : (
        <TextButton
          bold
          size={sizeText === 0 ? body12 : sizeText}
          type="primary"
        >
          {children}
        </TextButton>
      )}
    </Button>
  );
}

export default SecondaryButton;
