import React from "react";
import { Col, Row } from "react-bootstrap";
import FlechaCortada from "../../assets/images/flecha-cortada.png";
import { Text } from "../../components/typography";
import useWindowDimensions from "../../helpers/useWindowDimensions";
import { mobileXSWidth } from '../../helpers/responsiveVariables';

function SeccionClientes({ children }) {
  const { width } = useWindowDimensions();

  return (
    <div className="clientes">

      {
        width > mobileXSWidth ?
          <>
            <Col lg={2}>
              <Row>
                <img
                  style={{ marginTop: "131px", marginRight: "40px" }}
                  src={FlechaCortada}
                  alt=""
                ></img>
              </Row>
            </Col>
            <Col style={{ marginLeft: "50px" }}>
              <Row>
                <Text className="text-line mb-4" bold type="secondary" size={4.2}>
                  Súmate al <br></br> Progreso
              </Text>
              </Row>
              <Row>
                <Text bold type="secondary" size={1.5}>
                  Únete a la Nueva Era Digital
              </Text>
              </Row>
              <Row>
                <Text bold type="secondary" size={1.0}>
                  y accede a un mundo de beneficios
              </Text>
              </Row>
            </Col>
            <Col lg={5} style={{ marginRight: "158px" }}>
              <Col>
                {children}
              </Col>
            </Col>
          </>
          :
          <>
            <Col className="mt-4 mb-4">
              <img
                height={120}
                style={{
                  position: "absolute",
                  left: "0px",
                  top: "5px"
                }}
                src={FlechaCortada}
                alt=""
              ></img>
              <Row className="justify-content-center">
                <Text className="text-line-mobile" bold type="secondary"
                  size={2.2}>
                  Súmate al <br></br> Progreso
              </Text>
              </Row>
              {/* <Row className="justify-content-center">
                <Text className="text-line" bold type="secondary"
                  size={2.2}>
                  Progreso
              </Text>
              </Row> */}
              <Row className="mt-4 justify-content-center">
                <Text bold type="secondary" size={1.0}>
                  Únete a la Nueva Era Digital 
              </Text>
              </Row>
              <Row className="justify-content-center">
                <Text bold type="secondary" size={0.8}>
                  y accede a un mundo de beneficios
              </Text>
              </Row>
              <Row className="ml-2 mr-2 mt-4">
                {children}
              </Row>
            </Col>
          </>
      }



    </div>
  );
}

export default SeccionClientes;
