import React, { useState, useEffect } from "react";
import { NavDropdown } from "react-bootstrap";
import { PORTALSAPI } from "../../helpers/url";
import { api } from "../../helpers/api";
import { EncryptData } from "../../helpers/encrypt";

function RenderDropDownItem({
  item,
  openModal,
  user,
  setOpen,
  mobile = false,
  rut,
  setShowLoading,
}) {
  const [url, setUrl] = useState(item.url);

  const handlePostFunction = (url) => {
    switch (url) {
      case "PAGOS":
        return async () => {
          setShowLoading({ display: "block" });
          try {
            const res = await api.post(PORTALSAPI.POST.REDIRECTWEBPAGOS + "1");
            setShowLoading({ display: "none" });
            window.open(res.data, "_blank");
          } catch (error) {
            setShowLoading({ display: "none" });
          }
        };
      default:
        break;
    }
  };

  switch (item.type) {
    case "modal":
      return (
        <NavDropdown.Item
          onClick={() =>
            openModal({ title: item.modal, data: user.idcontacto })
          }
        >
          {item.title}
        </NavDropdown.Item>
      );
    case "link":
      return (
        <NavDropdown.Item
          eventKey={item.id}
          target={`${url.includes("http") ? "_blank" : ""}`}
          href={`${
            url.includes("http")
              ? url
              : "javascript:window.scrollTo({ top : document.getElementById('" +
                url +
                "')?.offsetTop - " +
                (mobile ? "100" : "124") +
                ", behavior : 'smooth'});"
          }`}
          onClick={() => {
            if (mobile) {
              setOpen(false);
            }
          }}
        >
          {item.title}
        </NavDropdown.Item>
      );
    case "post":
      return (
        <NavDropdown.Item eventKey={item.id} onClick={handlePostFunction(url)}>
          {item.title}
        </NavDropdown.Item>
      );
    default:
      return <></>;
  }
}

export default RenderDropDownItem;
