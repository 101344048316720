import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { fireAlert, formatRut } from "../../../helpers/autoAtencionHelpers";
import {
  AiOutlineAudit,
  AiOutlineFileText,
  AiOutlineLeft,
  AiOutlineRight,
} from "react-icons/ai";
import CustomButton from "../../buttons/Button";
import SecondaryButton from "../../buttons/SecondaryButton";
import { TextColored } from "../../typography";
import useWindowDimensions from "../../../helpers/useWindowDimensions";

import "./styles.css";
import useStorage from "../../../helpers/useStorage";
import { AUTOATENCIONAPI } from "../../../helpers/url";
import { api } from "../../../helpers/api";

const ItemDocumento = ({ nroOp, folio, rutDeudor, rznDeudor, estado }) => {
  return (
    <div className="doc-display doc-card">
      <header
        style={{
          background: `${estado === "Girada" ? "#FF7D7D" : "#FFD135"}`,
        }}
        className="doc-display doc-header"
      >
        {estado === "Girada" ? (
          <AiOutlineAudit size={85} color="#333333" />
        ) : (
          <AiOutlineFileText size={85} color="#333333" />
        )}
        <TextColored children={folio} bold size={2} type="dark" />
        <TextColored
          children={`Deudor: ${formatRut(rutDeudor)}`}
          weight={500}
          size={1}
          title={rznDeudor}
          moreStyles={{ cursor: "help" }}
          type="dark"
        />
      </header>
      <section className="doc-display doc-body doc-section">
        <TextColored children={`Op. N° ${nroOp}`} weight={500} size={1} />
        <TextColored children={estado} type="dark" weight={600} size={1.2} />
      </section>
    </div>
  );
};
const RenderItems = ({ array, isOverflowing, canContinue, selectedRows }) => {
  return (
    <div
      className={`d-flex ${
        !isOverflowing ? "justify-content-center" : ""
      } align-items-center`}
      style={{
        gap: "1.5rem",
        padding: "1.3rem 1rem",
        overflowX: "auto",
        flexGrow: 1,
      }}
      id="list"
    >
      {array.map((f, i) => {
        const rznDeudor =
          selectedRows.find((b) => b.rutRecep === f.rut_deudor)?.rznSocRecep ||
          "";

        const Item = () => (
          <ItemDocumento
            nroOp={f.nu_operacion}
            folio={f.nu_documento}
            rutDeudor={f.rut_deudor}
            rznDeudor={rznDeudor}
            estado={f.estado_operacion}
            key={`doc-${i}`}
            id="list"
          />
        );

        if (!canContinue && f.estado_operacion === "Girada") {
          return <Item />;
        }
        if (canContinue) {
          return <Item />;
        }
        return null;
      })}
    </div>
  );
};

const ModalDuplicados = ({ show, setShow, docs, loading }) => {
  const { width } = useWindowDimensions();
  const [{ idcliente, idcontacto }] = useStorage("user", {}, "local");
  const [canContinue, setPass] = useState(true);
  const [isOverflowing, setOverflowFlag] = useState(false);
  const [docsToRender, setDocs] = useState([]);

  const { documentos, selectedRows } = docs;
  const filterByType = useCallback(() => {
    const docsToDisplay = documentos.map((d) => {
      if (d.estado_operacion === "Girada" && canContinue) {
        setPass(false);
      }
      return d;
    });
    setDocs(docsToDisplay);
  }, [canContinue, documentos]);

  const checkOverflow = (el) => {
    const isOverflowing =
      el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;

    return !isOverflowing;
  };

  useEffect(() => {
    filterByType();
  }, [filterByType]);

  useEffect(() => {
    const list = document.getElementById("list");
    if (list) {
      setOverflowFlag(checkOverflow(list));
    }
  }, [width, docsToRender]);

  const onClick = async () => {
    try {
      const url = AUTOATENCIONAPI.POST.LEAD_DOCUMENTOS.replace(
        "{idcontacto}",
        idcontacto
      ).replace("{idcliente}", idcliente);
      await api.post(url, documentos);
      setShow(false)
    } catch {
      await fireAlert({
        icon: "error",
        title: "Hubo un error al contactar el ejecutivo",
      });
    }
  };

  return (
    <Modal show={show} dialogClassName="modal-60w" centered>
      <div
        className="d-flex flex-column align-items-center justify-content-center text-center"
        style={{ padding: "1.2rem", gap: "1rem" }}
      >
        <div className="d-flex flex-column">
          <TextColored
            children="Las siguientes facturas requieren atención"
            bold
            size={2}
            type="dark"
          />
          <TextColored
            children={`Detectamos que una o varias de las facturas seleccionadas ya se encuentran registradas en una operación de Fintec. ${
              canContinue
                ? "Si deseas continuar con la operación seras contactado por un ejecutivo"
                : ""
            }`}
            size={1.2}
          />
        </div>
        <section
          className="d-flex align-items-center justify-content-center doc-list w-100"
          style={{ maxWidth: "100%" }}
        >
          {!isOverflowing ? (
            <div className="d-flex flex-column align-items-center h-100">
              <AiOutlineLeft size={30} color="grey" />
            </div>
          ) : null}

          <RenderItems
            array={docsToRender}
            canContinue={canContinue}
            selectedRows={selectedRows}
          />

          {!isOverflowing ? (
            <div className="d-flex flex-column align-items-center h-100">
              <AiOutlineRight size={30} color="grey" />
            </div>
          ) : null}
        </section>
        <div
          className="d-flex w-50 justify-content-between justify-content-center align-items-center"
          style={{ gap: "1rem" }}
        >
          {canContinue ? (
            <>
              <SecondaryButton
                children={
                  <TextColored
                    children="Volver al listado"
                    type="dark"
                    size={1}
                    bold
                  />
                }
                onClick={() => setShow(false)}
                disabled={loading}
              />
              <CustomButton
                children={
                  <TextColored children="Continuar" type="dark" size={1} bold />
                }
                onClick={onClick}
                disabled={loading}
                loading={loading}
              />
            </>
          ) : (
            <div className="d-flex flex-column justify-content-center align-items-center m-auto" style={{maxWidth: "70%"}}>
              <TextColored type="dark" size={0.9} children={"Debe seleccionar facturas que no se encuentren en operaciónes giradas."} />
              <CustomButton
                children={
                  <TextColored
                    children="Volver al listado"
                    type="dark"
                    size={1}
                    bold
                  />
                }
                onClick={() => setShow(false)}
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ModalDuplicados;
