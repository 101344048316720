import React from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import { ThemeContextProvider } from "./theming";


//Componentes
import Dashboard from "./pages/dashboard/Dashboard";
import Clientes from "./pages/clientes/Clientes";
import ClientesConfirmacion from "./pages/clientes/ClientesConfirmacion";
import ClientesConfirmado from "./pages/clientes/ClientesConfirmado";
import RestaurarPassword from "./pages/clientes/RestaurarPassword";
import ConfirmarPassword from "./pages/clientes/ConfirmarPassword";
import ClientePasswordTerminado from "./pages/clientes/ClientePasswordTerminado";
import LoginClientes from "./pages/clientes/LoginClientes";
import DashboardClientes from "./pages/clientes/DashboardClientes";
import RestaurarContrasenia from "./pages/restaurar_contrasenia/RestaurarContrasenia";
import SolicitarContrasenia from "./pages/solicitar_contrasenia/SolicitarContrasenia";
import SeleccionFacturas from "./pages/autoatencion/SeleccionFacturas";
import ResumenFacturas from "./pages/autoatencion/ResumenFacturas";
import MetodosDePago from "./pages/autoatencion/MetodosDePago";
import Archivos from "./pages/autoatencion/ArchivosWizard/Archivos";
import TerminosyCondicionesPage from "./pages/terminos_condiciones/TerminosyCondicionesPage";
import SelectMasterEmail from "./components/SelectMasterEmail/SelectMasterEmail";
//

import "./App.css";
import { Redirect, Switch } from "react-router-dom/cjs/react-router-dom.min";
import Auth from "./pages/login/Auth";


const App = () => {
  return (
    <ThemeContextProvider>
      <Router>
        <Switch>
        <Route exact path="/" component={LoginClientes} />
        <Route exact path="/dashboard">
          <Auth Render={Dashboard} />
        </Route>
        <Route exact path="/main">
          <Auth Render={DashboardClientes} enroll={true} />
        </Route>
        <Route exact path="/clientes" component={Clientes} />
        <Route exact path="/clientes/login" component={LoginClientes} />
        <Route exact path="/login" component={LoginClientes} />
        <Route exact path="/clientes/restaurar" component={RestaurarPassword} />
        <Route
          exact
          path='/clientes/password-terminado'
          component={ClientePasswordTerminado}
        />
        <Route
          path="/clientes/confirmar-password"
          component={ConfirmarPassword}
        />
        <Route
          exact
          path='/clientes/confirmacion'
          component={ClientesConfirmacion}
        />
        <Route
          exact
          path='/clientes/confirmado'
          component={ClientesConfirmado}
        />
        <Route
          path="/restaurar-contrasenia"
          component={RestaurarContrasenia}
        />
        <Route
          exact
          path='/solicitar-contrasenia'
          component={SolicitarContrasenia}
        />
        <Route
          exact
          path='/autoatencion/seleccion-facturas'
          component={SeleccionFacturas}
        >
          <Auth Render={SeleccionFacturas} />
        </Route>
        <Route
          exact
          path='/autoatencion/resumen-facturas'
        >
          <Auth Render={ResumenFacturas} />
        </Route>
        <Route
          exact
          path='/autoatencion/metodo-pago'
          component={MetodosDePago}
        >
          <Auth Render={MetodosDePago} />
        </Route>
        <Route
          exact
          path='/seleccionar-correo-notificacion'
          component={SelectMasterEmail}
        />
        <Route exact path="/autoatencion/archivos" component={Archivos} >
          <Auth Render={Archivos} />
        </Route>
        <Route exact path="/tyc/:tipo" component={TerminosyCondicionesPage} />
        <Redirect to="/"/>
        </Switch>
      </Router>
    </ThemeContextProvider>
  );
};

export default App;
