import React, { useEffect, useState, useContext } from 'react'
import { ThemeContext } from "../../theming";
import jwt_decode from "jwt-decode";
import ModalSessionExpiring from "./SessionExpiring";
import { Error } from "../../assets/icons/dashboard";
import {
    TextColored,
    Text
  } from "../../components/typography";
import { useHistory } from "react-router-dom";
import { api } from "../../helpers/api";

import { PORTALSAPI } from "../../helpers/url";
import worker_script from "./worker.js";
const timerWorker = new Worker(worker_script);
let normalTimerInterval;
const userAut= ()=>{
  let storageUser = localStorage.getItem("user")
  return storageUser? JSON.parse(storageUser):null;
}
const DecodeJWT = (history, user)=>{
  try {
    if(user !== null){
      const decoded = jwt_decode(user.token);
      return decoded;
    }else{
    history.push("/");
    return;
    }
  } catch (error) {
    history.push("/");
    return;
  }
}

function TokenExpiration({enroll}) {
    const urlRtoken = enroll?PORTALSAPI.GET.REFRESH_TOKEN_ENROLAMIENTO : PORTALSAPI.GET.REFRESH_TOKEN;

    const [showAlert, setShowAlert ] = useState(false);
    const [tokenExpiration, setTokenExpiration] = useState(null);
    const [countdown, setCountdown] = useState("");

    const [webWorkerTime, setWebWorkerTime] = useState("");
    useEffect(() => {
      timerWorker.onmessage = ({ data: { time } }) => {
        setCountdown(time);
      };
    }, []);
    useEffect(()=>{
      console.log("WW: "+ countdown);
    },[countdown])
    const startWebWorkerTimer = (timetoexpirate) => {
      timerWorker.postMessage({ turn: "on", timeToExpirate : timetoexpirate });
    };

    const resetWebWorkerTimer = () => {
      timerWorker.postMessage({ turn: "off" });
    };
    
    const userLocal = userAut();
    const app = useContext(ThemeContext);
    const history = useHistory();
    const { body25 } = app.theme.font;

/*     useEffect(()=>{
      startWebWorkerTimer()
    },[]) */

    const handleCloseModal = () => setShowAlert(false);
    useEffect(()=>{
        if(tokenExpiration != null){
            const currentTime = Date.now() / 1000;
            const timeToExpirate = Math.floor(tokenExpiration - currentTime);
            const resto = (timeToExpirate - window.TIEMPO_ALERTA_EXPIRARA);
            const resto_rt = (timeToExpirate - window.TIEMPO_OBTENDRA_RTOKEN)
            if(resto > 0)setShowAlert(false);
            let countdownTimer = null;
            /* if(countdown != "")startWebWorkerTimer(countdown);
            else startWebWorkerTimer(timeToExpirate); */
            startWebWorkerTimer(timeToExpirate - 2);
            const timer = setTimeout(()=>{
                setShowAlert(true);
            }, (resto) * 1000);
            /* countdownTimer = setInterval(() => {
                setCountdown(prevCountdown =>{
                    if(prevCountdown != "")return prevCountdown - 1
                    else return timeToExpirate;
                });
              }, 1000); */
              return () => {
                clearTimeout(timer);
                resetWebWorkerTimer();
                //clearInterval(countdownTimer);
              };
        }
    }, [ tokenExpiration ])
    const handleRefresh = async ()=>{
      try {
          
          const rToken = JSON.parse(localStorage.getItem("rtoken"))
          if(rToken != null){
            const userJson = JSON.parse(localStorage.getItem("user"))
            localStorage.setItem("user", JSON.stringify({...userJson, token : rToken.t }));
            sessionStorage.setItem("temporal", JSON.stringify(rToken.tmp));
            localStorage.removeItem("rtoken");
          }else{
            const { data } = await api.get(urlRtoken.replace("{idcontacto}",userLocal.idcontacto ));
            const userJson = JSON.parse(localStorage.getItem("user"))
            localStorage.setItem("user", JSON.stringify({...userJson, token : data.token }));
            //sessionStorage.setItem("temporal", JSON.stringify(data.public_key));
            localStorage.removeItem("rtoken");
          }
      } catch (error) {
          console.log(error)
      }
    }
    useEffect(()=>{
        if(countdown === 0){
            localStorage.setItem("expired", true);
            resetWebWorkerTimer();
            history.push("/");
        }
    },[countdown])
    useEffect(()=>{
      const decoded = DecodeJWT(history, userLocal);
      if(decoded){
        const expirationTime = decoded.exp;
        setTokenExpiration(expirationTime);
      }
    },[]);
    useEffect(()=>{
      try {
        if(JSON.parse(localStorage.getItem("rtoken")) != null){
          const userLocalS = JSON.parse(localStorage.getItem("rtoken"));
          const tokenDecoded = jwt_decode(userLocalS?.t);
          const expirationTime = tokenDecoded.exp;
          /* console.log("RTOKEN User Cambia -> token que tomará :" + userLocalS.t.slice(-6))
          console.log("RTOKEN Mismo Token? :" + JSON.parse(localStorage.getItem("user"))?.token.slice(-6))
          console.log("RTOKEN rtoken :" + localStorage.getItem("rtoken"))
          console.log("RTOKEN expTime :" + (expirationTime - (Date.now() / 1000))) */
          setTokenExpiration(expirationTime);
          //resetWebWorkerTimer();
          //setCountdown("");
        }else{
          //const userLocalS = userAut();
          //const tokenDecoded = jwt_decode(userLocalS?.token);
          const tokenDecoded = DecodeJWT(history, userLocal);
          const expirationTime = tokenDecoded.exp;
          /* console.log("User Cambia -> token que tomará :" + userLocalS.token.slice(-6))
          console.log("Mismo Token? :" + JSON.parse(localStorage.getItem("user"))?.token.slice(-6))
          console.log("rtoken :" + localStorage.getItem("rtoken"))
          console.log("expTime :" + (expirationTime - (Date.now() / 1000))) */
          setTokenExpiration(expirationTime);
          //resetWebWorkerTimer();
          //setCountdown("");
        }
      } catch (error) {
      }
    },[JSON.parse(localStorage.getItem("user"))?.token])

    useEffect(async()=>{
       /*  console.log("GEN RTOKEN", {countdown})
        console.log("GEN RTOKEN", {rtoken: JSON.parse(localStorage.getItem("rtoken"))}) */
        if(countdown < (window.TIEMPO_OBTENDRA_RTOKEN + 5) && localStorage.getItem("rtoken") == null && countdown != ""  && (countdown % 5 ) == 0 ){//Se ejecutará para obtener rToken auxiliarmente hasta que interactúe con la web
        console.log("GEN RTOKEN", "!!!GENERA")
          const { data } = await api.get(urlRtoken.replace("{idcontacto}",userLocal.idcontacto ));
          localStorage.setItem("rtoken",JSON.stringify({t : data.token, tmp : JSON.stringify( data.public_key)}));
          };
        if(countdown > (window.TIEMPO_OBTENDRA_RTOKEN + 5) && localStorage.getItem("rtoken") != null && countdown != "" ){//Se ejecutará para obtener rToken auxiliarmente hasta que interactúe con la web
          localStorage.removeItem("rtoken");
        };
    },[countdown]);
  return (
        <ModalSessionExpiring
          show = {showAlert}
          icon = {<Error />}
          color = "red"
          subtitle = {<TextColored size={body25} type="yellow" bold>
            {"Hola, ¿Sigues aquí?"}
          </TextColored>}
          message = {<><Text type="primary" size={body25}>
          {`Por tu seguridad, la sesión se cerrará en`}
        </Text>
        <Text type="primary" size={body25} bold>
        {`${countdown} seg.`}
      </Text></>
        }
          handleRefresh={()=> {handleRefresh(); handleCloseModal()}}
          handleClose={() => handleCloseModal()}
          handleLogOut={() => history.push("/")}
         />
    )
}

export default TokenExpiration