// Hooks - funciones
import React, { useContext, useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import useWindowDimensions from "../../../helpers/useWindowDimensions";
import { ThemeContext } from "../../../theming";
import { api } from "../../../helpers/api";
import { DecryptData } from "../../../helpers/encrypt";
import { TextColored, TextGeneric } from "../../../components/typography";
import { getPublicKey, toBase64 } from "../../../helpers/autoAtencionHelpers";
import useStorage from "../../../helpers/useStorage";

// Assets
import {
  AiFillEye,
  AiFillDelete,
  AiFillEyeInvisible,
  AiOutlineSend,
  AiOutlineClose,
  AiOutlineCheck,
  AiOutlineCloudDownload,
} from "react-icons/ai";
import { BiHelpCircle } from "react-icons/bi";
import { BsFillCloudArrowUpFill } from "react-icons/bs";

// Helpers
import { mobileXSWidth } from "../../../helpers/responsiveVariables";
import { AUTOATENCIONAPI } from "../../../helpers/url";

// Componentes
import {
  Col,
  Fade,
  Form,
  FormCheck,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { CustomContainer, FilesReceiver } from "../StyledComponents";
import IconButton from "../../../components/buttons/IconButton";

const ConfirmarEmail = ({
  show,
  certificate,
  certificatePassword,
  form,
  setForm,
}) => {
  const [{ idcontacto, correo, idcliente }] = useStorage("user", {}, "local");
  const { email, code, isEmailConfirmed, codeStatus, loading } = form;
  const [timer, setTimer] = useState(false);

  const borders = {
    waiting: "2px solid #eceaea",
    wrong: "2px solid #FF0000",
    correct: "2px solid #34CD71",
  };

  const handleChange = async ({ value, name }) => {
    try {
      setForm((f) => ({ ...f, [name]: value }));
      if (name === "code") {
        if (value.length === 6 && certificate) {
          // Si se encuentra el codigo completo y el codigo cargado se verifica la contraseña
          setForm((f) => ({ ...f, loading: true }));
          const body = {
            base64: await toBase64(certificate),
            nombrecertificado: certificate.name,
            contrasena: certificatePassword,
            codigo: value,
            correo: email,
          };

          const url = AUTOATENCIONAPI.POST.GUARDAR_CERTIFICADO.replace(
            "{idcliente}",
            idcliente
          ).replace("{idcontacto}", idcontacto);

          const { data } = await api.post(url, body);
          return setForm((f) => ({
            ...f,
            codeStatus: data ? "correct" : "wrong",
            loading: false,
          }));
        }
        if (value.length < 6) {
          setForm((f) => ({ ...f, codeStatus: "waiting" }));
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const loadEmail = useCallback(async () => {
    try {
      const publicKey = await getPublicKey();
      setForm((f) => ({ ...f, email: DecryptData(correo, publicKey) }));
      return;
    } catch (err) {
      console.log(err);
    }
  }, [correo, setForm]);

  const handleMailSubmit = async (resend = false) => {
    try {
      setForm((f) => ({ ...f, loading: true }));

      const mailUrl = AUTOATENCIONAPI.POST.ENVIAR_CORREO.replace(
        "{idcliente}",
        idcliente
      ).replace("{correo}", email);
      await api.post(mailUrl);
      if (!resend) {
        setForm((f) => ({
          ...f,
          isEmailConfirmed: true,
        }));
      } else {
        setTimer(240000);
      }

      setForm((f) => ({ ...f, loading: false }));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    loadEmail();
  }, [loadEmail]);

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => setTimer((t) => t - 1000), 1000);
    } else {
      setTimer(false);
    }
  }, [timer]);

  return (
    <Fade in={show}>
      {!isEmailConfirmed ? (
        <InputGroup style={{ maxWidth: "300px" }}>
          <Form.Control
            id="inlineFormInputGroup"
            placeholder="E-mail"
            aria-describedby="passwordHelpBlock"
            type="text"
            name="email"
            style={{ border: "none" }}
            value={email}
            onChange={({ target }) => handleChange(target)}
          />
          <button
            className="d-flex align-items-center justify-content-center"
            style={{
              border: "none",
              backgroundColor: "#FFD135",
              borderRadius: "0 0.25rem 0.25rem 0",
              gap: "2px",
              minWidth: "70px",
            }}
            onClick={() => handleMailSubmit(false)}
          >
            {loading ? (
              <Spinner
                animation="border"
                role="status"
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                  color: "#595D6E",
                }}
              />
            ) : (
              <>
                <TextColored children="Enviar" weight={500} type="dark" />
                <AiOutlineSend />
              </>
            )}
          </button>
        </InputGroup>
      ) : (
        <div className="d-flex flex-column align-items-left">
          <InputGroup
            style={{
              maxWidth: "220px",
              border: borders[codeStatus],
              borderRadius: "0.25rem",
              transition: "500ms border ease-in-out",
              background: "#eceaea",
            }}
          >
            <Form.Control
              id="inlineFormInputGroup"
              autoComplete="off"
              name="code"
              style={{
                border: "none",
                letterSpacing: "20px",
                fontWeight: 600,
                fontSize: "0.8rem",
                background: "transparent",
              }}
              type="text"
              value={code}
              onChange={({ target }) => handleChange(target)}
              maxLength={6}
              disabled={loading}
            />
            <div
              className="d-flex align-items-center"
              style={{
                borderRadius: "0 0.25rem 0.25rem 0",
                padding: "5px",
              }}
            >
              {
                {
                  waiting: (
                    <Spinner
                      animation="border"
                      role="status"
                      style={{
                        width: "1.2rem",
                        height: "1.2rem",
                        color: "#595D6E",
                      }}
                    />
                  ),
                  correct: <AiOutlineCheck color="#34CD71" size="1.2rem" />,
                  wrong: <AiOutlineClose color="#FF0000" size="1.2rem" />,
                }[codeStatus]
              }
            </div>
          </InputGroup>
          <div className="d-flex align-items-center" style={{ gap: "2px" }}>
            {!timer ? (
              <>
                {" "}
                <TextColored children="¿No recibió el código?" size={0.8} />
                <button
                  children="Reenviar"
                  className="link-button"
                  style={{ fontSize: "0.8rem" }}
                  onClick={() => handleMailSubmit(true)}
                  disabled={loading}
                />{" "}
              </>
            ) : (
              <TextColored
                children={`Volver a enviar en ${new Date(timer)
                  .toISOString()
                  .substr(14, 5)}`}
                size={0.8}
              />
            )}
          </div>
        </div>
      )}
    </Fade>
  );
};

const GuardarCertificado = ({
  certificate,
  certificatePassword,
  form,
  setForm,
}) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <div
        className="d-flex flex-column align-items-left"
        style={{ gap: "2px" }}
      >
        <div className="d-flex align-items-center">
          <FormCheck onChange={() => setShow(!show)} />
          <TextColored
            type="dark"
            weight={500}
            size={1}
            children="Guardar certificado"
          />
          <BiHelpCircle
            size="20px"
            color="gray"
            title="El certificado sera almacenado por única vez para su uso en operaciones futuras."
          />
        </div>
        <ConfirmarEmail
          show={show}
          certificate={certificate}
          certificatePassword={certificatePassword}
          form={form}
          setForm={setForm}
        />
      </div>
    </>
  );
};

const Certificado = ({
  files,
  setFiles,
  setCertificatePassword,
  certificatePassword,
  flag,
  setFlag,
  form,
  setForm,
}) => {
  const {
    theme: {
      font: { body14, body12, body25 },
    },
  } = useContext(ThemeContext);
  const { width } = useWindowDimensions();
  const [formType, setFormType] = useState("password");

  const onDrop = useCallback(
    (file) => {
      if (flag) setFlag(false);
      setFiles({
        ...files,
        certificate: file[0],
      });
    },
    [setFiles, files, flag, setFlag]
  );

  const deleteCertificate = () => {
    const actualizedFiles = { ...files };
    delete actualizedFiles.certificate;
    setFiles(actualizedFiles);
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: {
      "application/x-pkcs12": [".pfx"],
    },
  });

  return (
    <CustomContainer fluid>
      <Row>
        <Col
          className="d-flex flex-column"
          md={5}
          style={{ transition: "all 500ms", gap: "15px" }}
        >
          {files?.certificate ? (
            <>
              <div
                className="d-flex align-items-center file-info"
                style={{ gap: "5px" }}
              >
                <div
                  style={{
                    maxWidth: "90%",
                    display: "inline-block",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  <TextColored
                    children={files?.certificate.name}
                    size={body14}
                    type="dark"
                  />
                </div>
                {flag ? (
                  <AiOutlineCloudDownload
                    size={17}
                    color="#0D6EFD"
                    title="Este certificado fue sincronizado desde nuestros registros."
                  />
                ) : null}

                <IconButton
                  Icon={<AiFillDelete size={20} />}
                  onClick={deleteCertificate}
                />
              </div>
              <div className="d-flex flex-column" style={{ gap: "6px" }}>
                <InputGroup>
                  <Form.Control
                    type={formType}
                    id="inlineFormInputGroup"
                    placeholder="Contraseña"
                    aria-describedby="passwordHelpBlock"
                    style={{ border: "none" }}
                    value={certificatePassword}
                    onChange={(e) => {
                      if (flag) setFlag(false); // Desaparece el icono de sincronizacion ya que se esta modificando la contraseña
                      setCertificatePassword(e.target.value);
                    }}
                    autoComplete="new-password"
                  />
                  <button
                    onClick={() =>
                      setFormType(formType === "password" ? "text" : "password")
                    }
                    type="button"
                    style={{
                      border: "none",
                      backgroundColor: "#eceaea",
                      borderRadius: "0 0.25rem 0.25rem 0",
                    }}
                  >
                    {formType === "text" ? (
                      <AiFillEye />
                    ) : (
                      <AiFillEyeInvisible />
                    )}
                  </button>
                </InputGroup>

                {!flag && certificatePassword.length > 0 ? (
                  <GuardarCertificado
                    certificate={files?.certificate}
                    certificatePassword={certificatePassword}
                    form={form}
                    setForm={setForm}
                  />
                ) : null}
              </div>
            </>
          ) : (
            <>
              <div className="file-info">
                <div>
                  <TextGeneric
                    children="Ningun archivo seleccionado."
                    size={body14}
                  />
                </div>
              </div>
            </>
          )}
        </Col>
        <Col>
          <FilesReceiver
            {...getRootProps()}
            dragActive={isDragActive && isDragAccept}
            dragRejected={isDragReject}
            fileSelected={!!files.certificate}
            style={{ cursor: "pointer" }}
          >
            <input id="file-input" {...getInputProps()} />
            <div className="icon">
              <BsFillCloudArrowUpFill size="120px" />
            </div>
            <div className="text">
              <TextColored
                children={
                  !!files.certificate
                    ? width <= mobileXSWidth
                      ? "Presiona para seleccionar un archivo nuevo"
                      : "Arrastra tu archivo o clickea para seleccionar uno nuevo"
                    : isDragReject
                    ? "Tipo de archivo no admitido"
                    : isDragAccept
                    ? "Suelta el archivo para confirmar"
                    : width <= mobileXSWidth
                    ? "Presiona para seleccionar un archivo"
                    : "Arrastra tu archivo o clickea para seleccionar"
                }
                weight={500}
                type="secondary"
                size={body25}
              />
              <br />
              <TextColored children="(.PFX)" type="secondary" size={body12} />
            </div>
          </FilesReceiver>
        </Col>
      </Row>
    </CustomContainer>
  );
};

export default Certificado;
