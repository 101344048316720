import { useState, useEffect } from "react";

const getStorageValue = (key, defaultValue, type = "local") => {
  // getting stored value
  const saved =
    type === "local" ? localStorage.getItem(key) : sessionStorage.getItem(key);
  
  return !saved ? defaultValue : JSON.parse(saved);
};

const useStorage = (key, defaultValue = "", type = "local") => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue, type);
  });

  useEffect(() => {
    type === "local"
      ? localStorage.setItem(key, JSON.stringify(value))
      : sessionStorage.setItem(key, JSON.stringify(value));
  }, [key, value, type]);

  return [value, setValue];
};

export default useStorage;
