import React, { useContext, useEffect, useState } from "react";
import { Row, Container, Col } from "react-bootstrap";
import { ThemeContext } from "../../theming";
import Logo from "../../assets/images/logo.png";
import Logo2 from "../../assets/images/logo_footer.png";
import { DecryptData } from "../../helpers/encrypt";
import useWindowDimensions from "../../helpers/useWindowDimensions";
import { NotificationCliente, UserCliente } from "../../assets/icons/dashboard";
import { Pipe, Text } from "../typography";
import { mobileXSWidth } from "../../helpers/responsiveVariables";
import { ordenarMenus } from "../../helpers/validators";
import { PORTALSAPI } from "../../helpers/url";
import { api } from "../../helpers/api";
import Navbar from "../navmenu/Navbar";
import NavbarLateral from "../navmenu/NavBarLateral";
import Loading from "../../components/loading/Loading";
import Logout from "../../assets/images/Logout.png";

const headerHeight = 110;
const menuHeight = 40;
export default function Header({
  openModal,
  notificaciones,
  nombres,
  menus,
  rut,
}) {
  const app = useContext(ThemeContext);

  const { body12, body14, body28 } = app.theme.font;
  const [showLoading, setShowLoading] = useState({ display: "none" });

  /* const [usuario, setUsuario] = useState(""); */
  const [rutState, setRutState] = useState("");
  const [cliente, setCliente] = useState("");
  const [menusOrdenados, setMenusOrdenados] = useState([]);
  const { width } = useWindowDimensions();

  const header = {
    backgroundColor: app.theme.secondary,
    height: `${headerHeight}px`,
    borderStyle: "solid",
    borderWidth: "1px",
    borderBottomColor: "white",
  };
  const menu = {
    background: app.theme.primary,
    position: "fixed",
    zIndex: 1020,
    height: `${menuHeight}px`,
    display: "flex",
    alignItems: "center",
    //borderStyle: "solid",
    //borderWidth: "1px",
    //borderBottomColor: "white",
  };

  const border = {
    borderBottom: `2px solid ${app.theme.primary}`,
    paddingBottom: "3px",
  };

  const CerrarSession = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location = "/";
  };

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    let publicURL = PORTALSAPI.GET.PUBLIC_KEY.replace(
      "{idcontacto}",
      user.idcontacto
    ).replace("{tipo}", user.tipo_acceso);
    api.get(publicURL).then((response) => {
      if (user) {
        if (rut === "") {
          setRutState(DecryptData(user.rut, response.data));
        } else {
          setRutState(rut);
        }
        //setRut(DecryptData(user.rut, response.data))
        setCliente(DecryptData(user.cliente, response.data));
      }
    });
  }, [rut]);

  useEffect(() => {
    setMenusOrdenados(ordenarMenus(menus));
  }, [menus]);

  return (
    <>
      <Loading estilo={showLoading}></Loading>
      <Container fluid style={header} className='sticky-top'>
        {width >= 992 ? (
          <Col>
            <Row className='align-items-center justify-content-md-center ml-4 mr-4'>
              <Col className='' lg={1} md={1}>
                <img width='80px' src={Logo} alt='' />
              </Col>

              <Col lg={2} md={2}>
                <Row className='align-items-center justify-content-md-end flex-nowrap'>
                  <Col lg={1}>
                    <Pipe size={30} color='yellow' />
                  </Col>
                  {width > mobileXSWidth ? (
                    <Col>
                      <Text size={body28}>Fintec</Text>
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>
              </Col>

              <Col lg={6} md={6}>
                <div className='row justify-content-end'>
                  <Row>
                    <Text size={body14} bold>
                      Bienvenido, {cliente}
                    </Text>
                  </Row>
                </div>
                <div className='row justify-content-end'>
                  <Row>
                    <Text size={body12}>RUT: {rutState}</Text>
                  </Row>
                </div>
              </Col>

              {/* <Col lg={1}>
								<a href='#' onClick={() => openModal({ title: 'usuario', data: JSON.parse(localStorage.getItem("user")) })}>
									<User center={true} />
								</a>
							</Col> */}

              <Col lg={3} md={2} className='align-items-center'>
                <Row className='row justify-content-center'>
                  <a
                    href='##'
                    onClick={() =>
                      openModal({
                        title: "usuario",
                        data: JSON.parse(localStorage.getItem("user")),
                      })
                    }>
                    <UserCliente />
                  </a>
                  <a
                    className='ml-2'
                    href='##'
                    onClick={() =>
                      openModal({
                        title: "notificacion",
                        data: JSON.parse(localStorage.getItem("user")),
                      })
                    }>
                    <NotificationCliente  notificaciones={notificaciones} />

                  </a>

                  <img
                      style={{
                      width: "42px",
                      height: "42px",
                      marginTop: "32px",
                      marginLeft: "2px",
                      cursor: "pointer"}}
                      onClick={() => CerrarSession()}
                      src={Logout} alt="Logout"
                  />

                  {/* <div className="d-flex align-items-center" style={{ marginLeft : "4.5rem"}}>
                <form target="_blank" action={WEBPAGOSAPI.GET.REDIRECT} method="get">
                  <input type="text" id="r" name="r" hidden  value={rut}/>
                  <input type="number" id="w" name="w" hidden value={1}/>
                  <ButtonProgreso
                    id="idButtonPagueAqui"
                    className='noprint'
                    type="submit"
                    sizeText={1}>
                        PAGUE AQUI
                   </ButtonProgreso>
                </form>
                </div> */}
                </Row>
              </Col>

              {/* <Col lg={2} className="">
              <Row>
                <div style={{
                  borderLeft: '0.5px solid #3E3E3E',
                  height: '46px',
                  padding: '0.2em',
                }}>
                </div>
                <Link
                  size={0.7}
                  center={true}
                  border={border}
                  onClick={() =>
                    openModal({
                      title: "ejecutivo",
                      data: JSON.parse(localStorage.getItem("user")).idcontacto,
                    })
                  }
                >
                  CONTACTAR EJECUTIVO
              </Link>
              </Row>
            </Col> */}
            </Row>
          </Col>
        ) : (
          <>
              <Col>
                <Row className='align-items-center justify-content-md-center'>
                  <Col
                    className=''
                    lg={1}
                    xs={
                      !!menusOrdenados?.length
                        ? width < 390
                          ? width < 350
                            ? 5
                            : 6
                          : 7
                        : 8
                    }
                    sm={8}
                    md={9}
                  >
                    <div className="d-flex align-items-center r">
                      <img width='40px' src={Logo2} alt='' />
                    </div>
                  </Col>

                  <Col lg={1} xs={3}>
                    <div className="d-flex align-items-center justify-content-center">
                      <a
                        href='##'
                        onClick={() =>
                          openModal({
                            title: "usuario",
                            data: JSON.parse(localStorage.getItem("user")),
                          })
                        }
                      >
                        <UserCliente center={true} />
                      </a>
                      <a
                        className='ml-2'
                        href='##'
                        onClick={() =>
                          openModal({
                            title: "notificacion",
                            data: JSON.parse(localStorage.getItem("user")),
                          })
                        }
                      >
                        {<NotificationCliente notificaciones={notificaciones} />}
                      </a>
                      <img
                        style={{
                          width: "42px",
                          height: "42px",
                          marginTop: "4px",
                          marginRight: "4rem",
                          marginLeft: "2px",
                          cursor: "pointer"
                        }}
                        onClick={() => CerrarSession()}
                        src={Logout}
                        alt="Logout"
                      />
                    </div>
                  </Col>
                  <Col xs={1}></Col>
                </Row>
              </Col>

            {!!menusOrdenados.length && (
              <NavbarLateral
                openModal={openModal}
                menus={menusOrdenados}
                rut={rut}
                setShowLoading={setShowLoading}
              />
            )}
          </>
        )}
      </Container>
      {width >= 992 ? (
        !!menusOrdenados.length && (
          <Container fluid style={menu}>
            <Navbar
              openModal={openModal}
              menus={menusOrdenados}
              rut={rut}
              setShowLoading={setShowLoading}
            />
          </Container>
        )
      ) : (
        <></>
      )}
    </>
  );
}
