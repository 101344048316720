import React from "react";
import { useParams } from "react-router-dom";
import Logo from "../../assets/images/logo2.png";
import { TextColored } from "../../components/typography";
import {
  PoliticasDePrivacidad,
  TerminosyCondiciones,
} from "../../helpers/termsTexts";

const TerminosyCondicionesPage = () => {
  const { tipo } = useParams();

  const typeObj = {
    terms: { text: TerminosyCondiciones, title: "Términos y condiciones" },
    privacy: { text: PoliticasDePrivacidad, title: "Política de privacidad" },
  };

  return (
    <div className="d-flex flex-column" style={{ height: "100vh" }}>
      <div
        className="d-flex w-100 justify-content-center"
        style={{
          background: "#1D1D1B",
          height: "fit-content",
          padding: "1rem",
        }}
      >
        <TextColored
          children={typeObj[tipo]?.title}
          size={2.5}
          bold
          type="primary"
          moreStyles={{ textAlign: "center" }}
        />
      </div>

      <div
        className="d-flex flex-column w-100"
        style={{
          margin: "50px 0",
          padding: "0 5% 0 5%",
          flexGrow: 1,
        }}
      >
        <TextColored
          id="text-container"
          size={1}
          children={typeObj[tipo]?.text()}
          type="dark"
        />
      </div>
      <div
        className="d-flex w-100 justify-content-center"
        style={{
          background: "#1D1D1B",
          height: "fit-content",
          padding: "1rem",
          margin: "0",
        }}
      >
        <img src={Logo} width="300px" alt="logo" />
      </div>
    </div>
  );
};

export default TerminosyCondicionesPage;
