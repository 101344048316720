//Librerias
import React from "react";
import { Fade } from "react-bootstrap";

//Assets
import Logo from "../../assets/images/logo7.png";

//Components
import { TextColored } from "../typography";

//Styles
import "./loading.css";

const AutoatencionLoading = ({ show }) => {
  return (
    <Fade in={show}>
      <div
        className="d-flex position-absolute flex-column justify-content-center align-items-center w-100 h-100"
        style={{ backgroundColor: "white", margin: "-1.25rem", zIndex: show ? 100 : -100 }}
      >
        <img
          src={Logo}
          alt="Logo"
          style={{ margin: "3rem", maxHeight: "300px" }}
        />
        <div className="d-flex flex-column text-center">
          <TextColored
            type="dark"
            size={2}
            children={"Autoatención de Factoring"}
            weight={500}
          />
          <TextColored
            type="dark"
            size={1}
            children={"Cargando"}
            moreStyles={{ animation: "fade 2s infinite" }}
          />
        </div>
      </div>
    </Fade>
  );
};

export default AutoatencionLoading;
