// Librerias
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// Helpers - Hooks
import useWindowDimensions from "../../../helpers/useWindowDimensions";

// Componentes - Assets
import { Col, Container, Modal, ModalBody, Nav, Row } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import Resumen from "../../autoatencion/tabla-resumen/Resumen";
import TablaFacturas from "../../autoatencion/TablaFacturas";
import CustomButton from "../../buttons/Button";

import "./styles.css";

const ModalCesionObservaciones = ({ show }) => {
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const [type, setType] = useState("2");
  const { width } = useWindowDimensions();
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      show={show}
      dialogClassName={width <= 800 ? "modal-100w" : "modal-70w"}
      centered
    >
      <ModalHeader className="failed-header">
        <span>
          Operación registrada con observaciones
        </span>
      </ModalHeader>
      <ModalBody className="failed-body">
        <Container fluid>
          <Row className="justify-content-center">
            <Col style={{ minWidth: "65%" }}>
              <Nav
                variant="tabs"
                activeKey={type}
                onSelect={(e) => setType(e)}
                justify
              >
                <Nav.Item>
                  <Nav.Link as="label" eventKey="1" disabled={type === "1"}>
                    Cedidas
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link as="label" eventKey="2" disabled={type === "2"}>
                    No cedidas
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <TablaFacturas
                type={type === "1" ? "facturas-aceptadas" : "facturas-rechazadas"}
                rows={rows}
                setRows={setRows}
                loading={loading}
                setLoading={setLoading}
              />
            </Col>
            <Col className="align-items-center">
              <Resumen text="Cotización actualizada" />
              <div
                style={{
                  display: "flex",
                  maxWidth: "50%",
                  justifyContent: "center",
                  margin: "auto",
                }}
              >
                <CustomButton
                  children="Finalizar"
                  onClick={() => {
                    history.replace("/")
                    history.push("/dashboard");
                  }}
                  disabled={loading}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </ModalBody>
    </Modal>
  );
};

export default ModalCesionObservaciones;
