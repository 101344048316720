//Librerias
import React, { useCallback, useEffect, useState, useRef } from "react";

//Assets
import Pulgar from "../../assets/images/pulgar.png";
import Si from "../../assets/images/autoatencion.png";
import Archivo from "../../assets/images/archivo.png";
import Reingreso from "../../assets/images/re_ingreso.png";

//Helpers
import {
  mobileLDWidth,
  mobileXSWidth,
} from "../../helpers/responsiveVariables";
import useWindowDimensions from "../../helpers/useWindowDimensions";
import useStorage from "../../helpers/useStorage";
import { DecryptData } from "../../helpers/encrypt";
import { AUTOATENCIONAPI } from "../../helpers/url";
import { api } from "../../helpers/api";
import {
  chargeExecutivePhone,
  getComertialInfo,
  getPublicKey,
  getRut,
  getToken,
  numberTrimmer,
  sincronizarDTEs,
  timeout,
} from "../../helpers/autoAtencionHelpers";

//Componentes
import Button from "../../components/buttons/Button";
import { Col, Row, Spinner, Modal } from "react-bootstrap";
import { TextGeneric } from "../typography";
import ModalSuscripcionDM from "../modal/modal-autoatencion/ModalSuscripcion_old";
import ModalActualizarDatos from "../modal/modal-autoatencion/ModalActualizarDatos";
import { useHistory } from "react-router-dom";

export default function Seccion2DM({
  openModal,
  contactado = "",
  modal,
  registroHandle = () => {
    return;
  },
  re_ingreso,
  status = 0,
  setStatus = () => {
    return;
  },
  disabled = false,
}) {
  const history = useHistory();
  const loadingInfo = useRef(false);
  const checkingState = useRef(false);
  const needUpdate = useRef(false);
  const { width } = useWindowDimensions();
  const [{ idcontacto }] = useStorage("user", "", "local");
  const [loading, setLoading] = useState(null);
  const [{ token, rut }, setInfo] = useState({
    token: null,
    rut: null,
  });

  const [dbInfo, setDbInfo] = useState({
    nombres: "",
    celular: "",
    correo: "",
    direccion: "",
    siipassword: "",
    region: "",
    idcliente: 0,
    idcontacto: 0,
  });

  const [{ TOU, checkSuscription, documents, updateInfo }, setValidation] =
    useState({
      TOU: false,
      checkSuscription: false,
      documents: false,
      updateInfo: false,
    });

  const typesObj = {
    1: "¡Factoriza ahora!",
    2: "¡Factoriza ahora!",
    3: "¡Enrolate ahora!",
    5: "¡Factoriza ahora!",
    6: "Actualizar Datos",
  };

  const params =
    "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no";

  const isInfoComplete = async () => {
    try {
      // Verificar si la informacion del cliente esta completa
      setLoading(!loading);
      const {
        celular,
        nombres,
        direccion,
        region,
        correo,
        idcliente,
        idcontacto,
      } = JSON.parse(localStorage.getItem("user"));

      const publicKey = await getPublicKey();

      const {
        data: {
          data: { siipassword },
        },
      } = await api.get(
        AUTOATENCIONAPI.GET.DATA_ENROLAMIENTO.replace("{iduser}", idcontacto)
      );

      const decryptedData = {
        nombres: DecryptData(nombres, publicKey),
        celular: numberTrimmer(DecryptData(celular, publicKey)),
        correo: DecryptData(correo, publicKey),
        direccion: DecryptData(direccion, publicKey),
        region: DecryptData(region, publicKey),
        siipassword: DecryptData(siipassword, publicKey),
        idcliente,
        idcontacto,
      };

      setLoading(false);

      if (
        !decryptedData.nombres.length > 0 ||
        !decryptedData.celular.toString().length > 0 ||
        !decryptedData.correo.length > 0 ||
        !decryptedData.direccion.length > 0 ||
        !decryptedData.siipassword.length > 0
      ) {
        setDbInfo(decryptedData);
        return false;
      } else {
        return true;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const checkProcess = useCallback(
    async (type, t, r) => {
      try {
        if (!checkingState?.current) {
          checkingState.current = true;
          const {
            data: {
              EstadoDeSuscriptor: { Documentos },
            },
          } = await api.get(
            AUTOATENCIONAPI.GET.ESTADO_ENROLAMIENTO.replace("{rut}", r)
              .replace("{token}", t)
              .replace("{idcontacto}", idcontacto)
          );

          //Reduzco el array de documentos a un menor exponente para identificar el estado
          const response = [
            ...Array.from(new Set(Documentos.map(({ Estado }) => Estado))),
          ][0];

          const conditional =
            type === "verificar"
              ? response === "Actualizando"
              : response === "Actualizando" || response === "NoSuscrito";

          checkingState.current = false;

          if (conditional) {
            //Si devuelve Actualizando se vuelve a hacer la peticion luego de 2.5s
            await timeout(1500);
            return await checkProcess(type, t, r);
          } else {
            if (response === "Suscrito") {
              await sincronizarDTEs(r);
              sessionStorage.setItem("current-step", 1);
            }
            return response;
          }
        }
      } catch (err) {
        await timeout(1500);
        await checkProcess(type, t, r);
      }
    },
    [idcontacto]
  );

  const getInfo = useCallback(async () => {
    try {
      // Cargar RUT y token de datamart
      if (loadingInfo?.current) {
        const { tipo_acceso } = JSON.parse(localStorage.getItem("user"));

        let usercontacted = tipo_acceso === 1;

        if (usercontacted) {
          const estado_enrolamiento = !status
            ? await getComertialInfo()
            : status;

          const r = await getRut();
          const t = await getToken();

          setInfo({
            token: t,
            rut: r,
          });

          if (estado_enrolamiento === 2) {
            const isSuscribed = await checkProcess("verificar", t, r);
            if (
              isSuscribed === "ActualizarSuscripcion" ||
              isSuscribed === "NoSuscrito"
            ) {
              needUpdate.current = true;
              setStatus(6);
            } else {
              setStatus(5);
            }
            return;
          } else {
            return setStatus(estado_enrolamiento);
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }, [setStatus, checkProcess, status]);

  const getSuscription = useCallback(async () => {
    try {
      const isSuscribed = await checkProcess("confirmar", token, rut);
      if (isSuscribed === "Suscrito") {
        const statusUpdateURL = AUTOATENCIONAPI.PUT.ACTUALIZAR_ESTADO.replace(
          "{iduser}",
          idcontacto
        ).replace("{state}", 0);
        await api.put(statusUpdateURL);
        setStatus(5);
      }
      return;
    } catch (err) {
      console.log(err);
    }
  }, [checkProcess, rut, token, setStatus, idcontacto]);

  const openSuscriptionModal = () => {
    document.body.classList.add("modal-open");
    setValidation((s) => {
      return { ...s, checkSuscription: true };
    });
  };

  const handleSubmit = async () => {
    switch (status) {
      case 1: {
        try {
          setLoading(true);
          const url = AUTOATENCIONAPI.PUT.ACEPTAR_TERMINOS.replace(
            "{iduser}",
            idcontacto
          );
          const {
            data: { ok },
          } = await api.put(url);
          if (ok) {
            setStatus(2);
            setLoading(false);
            if (await isInfoComplete()) {
              openSuscriptionModal();
            } else {
              setValidation((s) => {
                return { ...s, updateInfo: true };
              });
            }
          }
        } catch (err) {
          console.log(err);
        }
        break;
      }
      case 2: {
        if (await isInfoComplete()) {
          openSuscriptionModal();
        } else {
          setValidation((s) => {
            return { ...s, updateInfo: true };
          });
        }
        break;
      }
      case 5: {
        sessionStorage.setItem("current-step", 1);
        history.push("autoatencion/seleccion-facturas");
        break;
      }
      case 6: {
        if (await isInfoComplete()) {
          openSuscriptionModal();
        } else {
          setValidation((s) => {
            return { ...s, updateInfo: true };
          });
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  const LoadingButton = () => {
    return (
      <div className="d-flex align-items-center" style={{ gap: "5px" }}>
        <Spinner animation="border" variant="dark" />
        <TextGeneric
          children={status === 4 ? "Obteniendo documentos" : "Cargando"}
          size={1.3}
        />
      </div>
    );
  };

  const flag = disabled ? false : !status || !token || !rut;

  const ModalDocumentos = () => {
    return (
      <Modal
        show={documents}
        centered
        dialogClassName="modal-40w"
        onHide={() =>
          setValidation((v) => {
            return { ...v, documents: false };
          })
        }
      >
        <Modal.Header>
          <img src={Archivo} alt="sii" />
        </Modal.Header>
        <Modal.Body
          className="d-flex flex-column text-center"
          style={{ padding: "2rem 0" }}
        >
          <TextGeneric
            children={<b>¡Aun estamos trabajando en obtener tus documentos!</b>}
            size={1.5}
          />
          <TextGeneric
            children={
              "Este proceso se realiza una unica vez y puede tardar un momento."
            }
            size={1}
          />
        </Modal.Body>
      </Modal>
    );
  };

  const RenderTyC = ({ status }) => {
    switch (status) {
      case 1: {
        return (
          <label
            style={{
              visibility: `${status === 1 ? "visible" : "hidden"}`,
            }}
          >
            <input
              type="checkbox"
              onChange={() =>
                setValidation((v) => {
                  return { ...v, TOU: !TOU };
                })
              }
              checked={TOU}
              style={{ verticalAlign: "middle" }}
            />
            <span
              style={{
                fontSize: "0.8rem",
                fontWeight: 500,
                paddingLeft: "0.25rem",
              }}
            >
              Acepto los{" "}
              <button
                children="Términos y condiciones"
                className="link-button"
                onClick={() =>
                  window.open(
                    "/tyc/terms",
                    "Términos y condiciones de progreso",
                    params
                  )
                }
              />{" "}
              y la{" "}
              <button
                children="Política de privacidad"
                className="link-button"
                onClick={() =>
                  window.open("/tyc/privacy", "Política de privacidad", params)
                }
              />{" "}
              de Progreso.
            </span>
          </label>
        );
      }
      case 2: {
        return (
          <button
            children={
              <span style={{ fontSize: "0.8rem", fontWeight: 500 }}>
                Términos y condiciones.
              </span>
            }
            className="link-button"
            onClick={() =>
              window.open(
                "/tyc/terms",
                "Términos y condiciones de progreso",
                params
              )
            }
          />
        );
      }
      case 4: {
        return (
          <button
            children={
              <span style={{ fontSize: "0.8rem", fontWeight: 500 }}>
                ¿Que es esto?
              </span>
            }
            className="link-button"
            onClick={() =>
              setValidation((v) => {
                return { ...v, documents: true };
              })
            }
          />
        );
      }
      case 5: {
        return (
          <button
            children={
              <span style={{ fontSize: "0.8rem", fontWeight: 500 }}>
                Términos y condiciones.
              </span>
            }
            className="link-button"
            onClick={() =>
              window.open(
                "/tyc/terms",
                "Términos y condiciones de progreso",
                params
              )
            }
          />
        );
      }
      default:
        return <></>;
    }
  };

  useEffect(() => {
    if (!disabled) {
      sessionStorage.removeItem("current-step");
      if (!loadingInfo?.current) {
        loadingInfo.current = true;
        chargeExecutivePhone();
        if (!token && !rut) {
          getInfo();
        }
      }
      if (status === 4) {
        getSuscription();
      }
    } else {
      setStatus(1);
      setLoading(false);
    }
  }, [getInfo, getSuscription, rut, status, token, disabled, setStatus]);

  return (
    <>
      <Col
        style={{
          backgroundColor: "#292927",
          marginLeft: "15px",
          marginBottom: width > mobileXSWidth ? "0px" : "15px",
          marginRight: width > mobileXSWidth ? "0px" : "15px",
          borderRadius: "15px",
          padding: "2%",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
        }}
        lg={3}
      >
        <Row className="justify-content-center align-items-center m-auto">
          <Col
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <img
              height={width > mobileLDWidth ? 45 : 35}
              width={width > mobileLDWidth ? 45 : 35}
              alt=""
              src={Pulgar}
            />
            <TextGeneric
              className={width > mobileXSWidth ? "" : "text-center"}
              size={1.2}
              color={"white"}
            >
              <b>
                {contactado === ""
                  ? "¿Te podemos ayudar?"
                  : "Te damos la bienvenida"}
              </b>
            </TextGeneric>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col
            style={{
              textAlign: "center",
            }}
          >
            {contactado === "" ? (
              <TextGeneric size={1.0} color={"white"}>
                Contacta a tu ejecutivo
                <br />
                para lo que necesites,
                <br />
                ¡Estamos contigo!
              </TextGeneric>
            ) : (
              <TextGeneric size={1.0} color={"white"}>
                {contactado}
              </TextGeneric>
            )}
            {contactado === "" ? (
              <Row className="justify-content-center">
                <Button
                  onClick={() =>
                    openModal({
                      title: modal,
                      data: JSON.parse(localStorage.getItem("user")).idcontacto,
                    })
                  }
                  className="mt-3 mb-3 btn-primary-custom2"
                >
                  Contactar
                </Button>
              </Row>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </Col>
      {re_ingreso === 0 ? (
        <>
          {checkSuscription && (
            <ModalSuscripcionDM
              show={checkSuscription}
              checkProcess={checkProcess}
              timeout={timeout}
              setStatus={setStatus}
              setShow={(e) =>
                setValidation((v) => {
                  return { ...v, checkSuscription: e };
                })
              }
              token={token}
              rut={rut}
              needUpdate={needUpdate.current}
            />
          )}
          <ModalActualizarDatos
            show={updateInfo}
            setShow={(e) =>
              setValidation((v) => {
                return { ...v, updateInfo: e };
              })
            }
            dbValues={dbInfo}
            next={openSuscriptionModal}
          />
          <ModalDocumentos />

          <Col
            className="fondo-dahboard-seccion"
            style={{
              marginLeft: "15px",
              marginRight: "15px",
              borderRadius: "10px",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            {width > 1300 ? (
              <Row
                className="d-flex justify-content-evenly"
                style={{
                  height: "100%",
                  alignContent: "center",
                  gap: "5px",
                  padding: "0 2%",
                }}
              >
                <Col
                  className="d-flex align-items-center justify-content-end"
                  style={{ gap: "3rem" }}
                >
                  <img
                    height={width > mobileLDWidth ? 140 : 100}
                    alt=""
                    src={Si}
                  />
                  <div style={{ whiteSpace: "nowrap" }}>
                    <TextGeneric size={width > 1500 ? 1.4 : 1.2}>
                      ¿Deseas <b>factorizar</b>{" "}
                    </TextGeneric>
                    <br />
                    <TextGeneric size={width > 1500 ? 1.4 : 1.2}>
                      tus documentos de manera
                    </TextGeneric>
                    <br />
                    <TextGeneric size={width > 1500 ? 2.5 : 2}>
                      <b>rápida y segura?</b>
                    </TextGeneric>
                  </div>
                </Col>

                <Col
                  className="d-flex flex-column justify-content-center align-items-center"
                  style={{ paddingTop: "1rem", gap: "0.5rem" }}
                  md={true}
                >
                  <div className="d-flex justify-content-center">
                    <Button
                      onClick={() => handleSubmit()}
                      className="btn-primary-custom2 btn-primary-custom3"
                      sizeText={width > 1500 ? "1.3" : "1"}
                      children={flag ? <LoadingButton /> : typesObj[status]}
                      disabled={
                        flag || (status === 1 && !TOU) || loading || disabled
                      }
                      loading={loading}
                    />
                  </div>
                  {disabled ? null : <RenderTyC status={status} />}
                </Col>
              </Row>
            ) : (
              <Row
                className="justify-content-center d-flex flex-column m-auto"
                style={{
                  gap: "15px",
                }}
              >
                <Row className="d-flex align-items-center">
                  <Col xs={"auto"}>
                    <img height={80} alt="" src={Si} />
                  </Col>

                  <Col>
                    <TextGeneric size={1.3}>
                      ¿Deseas factorizar tus documentos de manera{" "}
                      <b>rápida y segura</b>?
                    </TextGeneric>
                  </Col>
                </Row>

                <Col
                  className="d-flex flex-column align-items-content"
                  style={{ gap: "1rem" }}
                >
                  <Row className="justify-content-center">
                    <Button
                      onClick={() => handleSubmit()}
                      className="btn-primary-custom2 btn-primary-custom3"
                      sizeText="1.3"
                      children={flag ? <LoadingButton /> : typesObj[status]}
                      disabled={
                        flag || (status === 1 && !TOU) || loading || disabled
                      }
                      loading={loading}
                    />
                  </Row>
                  <Row className="justify-content-center">
                    {disabled ? null : <RenderTyC status={status} />}
                  </Row>
                </Col>
              </Row>
            )}
          </Col>
        </>
      ) : (
        <>
          <Col
            className="fondo-dahboard-seccion"
            style={{
              marginLeft: "15px",
              marginRight: "15px",
              borderRadius: "10px",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            {re_ingreso &&
              (width > mobileXSWidth ? (
                <Row
                  className="justify-content-center"
                  style={{
                    height: "100%",
                    alignContent: "center",
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                >
                  <Col
                    lg={2}
                    style={{ textAlign: "center", marginTop: "20px" }}
                  >
                    <img
                      height={width > mobileLDWidth ? 100 : 70}
                      alt=""
                      src={re_ingreso === 2 ? Reingreso : Si}
                    />
                  </Col>

                  <Col style={{ alignSelf: "center" }}>
                    <Row>
                      {re_ingreso === 2 ? (
                        <TextGeneric size={1.1}>
                          ¡UPS! ha ocurrido algo…
                        </TextGeneric>
                      ) : (
                        <TextGeneric size={1.1}>
                          Registra tu clave y rut
                        </TextGeneric>
                      )}
                    </Row>
                    <Row>
                      {re_ingreso === 2 ? (
                        <TextGeneric size={1.4}>
                          <b>
                            Has cambiado tus datos de ingreso al Portal del SII
                          </b>
                        </TextGeneric>
                      ) : (
                        <TextGeneric size={1.8}>
                          <b>del Portal del SII</b>
                        </TextGeneric>
                      )}
                    </Row>
                    <Row>
                      {re_ingreso === 2 ? (
                        <TextGeneric size={0.9}>
                          Actualízalos para no perder tus beneficios
                        </TextGeneric>
                      ) : (
                        <TextGeneric size={0.9}>
                          para obtener beneficios exclusivos
                        </TextGeneric>
                      )}
                    </Row>
                  </Col>
                  <Col
                    style={{
                      marginTop: "20px",
                      marginBottom: "20px",
                      marginRight: "20px",
                    }}
                  >
                    <Row className="justify-content-center">
                      <Button
                        onClick={registroHandle}
                        className="btn-primary-custom2 btn-primary-custom3"
                        sizeText={width > 1500 ? "1.3" : "1"}
                      >
                        {re_ingreso === 2
                          ? "¡Reingresar datos!"
                          : "¡Iniciar Registro!"}
                      </Button>
                    </Row>
                    <Row className="justify-content-center">
                      <TextGeneric className="mt-2 text-center" size={0.9}>
                        Datos 100% confidenciales y encriptados con alta
                        seguridad
                      </TextGeneric>
                    </Row>
                  </Col>
                </Row>
              ) : (
                <Row
                  className="justify-content-center"
                  style={{
                    height: "100%",
                    alignContent: "center",
                  }}
                >
                  <Col
                    lg={2}
                    xs={4}
                    style={{ textAlign: "left", marginTop: "20px" }}
                  >
                    <img
                      height={80}
                      alt=""
                      src={re_ingreso === 2 ? Reingreso : Si}
                    />
                  </Col>

                  <Col xs={8} style={{ alignSelf: "center" }}>
                    <Row>
                      {re_ingreso === 2 ? (
                        <TextGeneric size={0.9}>
                          ¡UPS! ha ocurrido algo…
                        </TextGeneric>
                      ) : (
                        <TextGeneric size={0.9}>
                          Registra tu clave y rut
                        </TextGeneric>
                      )}
                    </Row>
                    <Row>
                      {re_ingreso === 2 ? (
                        <TextGeneric size={1.2}>
                          <b>
                            Has cambiado tus datos de ingreso al Portal del SII
                          </b>
                        </TextGeneric>
                      ) : (
                        <TextGeneric size={1.2}>
                          <b>del Portal del SII</b>
                        </TextGeneric>
                      )}
                    </Row>
                    <Row>
                      {re_ingreso === 2 ? (
                        <TextGeneric size={0.72}>
                          <b>Actualízalos para no perder tus beneficios</b>
                        </TextGeneric>
                      ) : (
                        <TextGeneric size={0.72}>
                          <b>para obtener beneficios exclusivos</b>
                        </TextGeneric>
                      )}
                    </Row>
                  </Col>

                  <Col
                    style={{
                      // marginTop: "20px",
                      marginBottom: "20px",
                      marginRight: "20px",
                    }}
                  >
                    <Row className="justify-content-center">
                      <Button
                        onClick={registroHandle}
                        className="btn-primary-custom2 btn-primary-custom3"
                        sizeText="1.3"
                      >
                        {re_ingreso === 2
                          ? "¡Reingresar datos!"
                          : "¡Iniciar Registro!"}
                      </Button>
                    </Row>
                    <Row className="justify-content-center">
                      <TextGeneric className="mt-2 text-center" size={0.7}>
                        Datos 100% confidenciales y encriptados con alta
                        seguridad
                      </TextGeneric>
                    </Row>
                  </Col>
                </Row>
              ))}
          </Col>
        </>
      )}
    </>
  );
}
