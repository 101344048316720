//Hooks - Funciones
import React, { useContext, useEffect } from "react";
import useWindowDimensions from "../../helpers/useWindowDimensions";

//Assets - Theme
import Pipe2 from "../../assets/images/pipe2.png";
import { ThemeContext } from "../../theming";
import { mobileXSWidth } from "../../helpers/responsiveVariables";
import {
  AiOutlineFileSearch,
  AiOutlineFileDone,
  AiOutlineDollarCircle,
  AiOutlineFileText,
} from "react-icons/ai";

//Componentes
import { Text } from "../typography";
import Stepper from "../stepper/Stepper";

import "./styles.css";

const TitleAutoatencion = ({ title, info = "", currentStep, width }) => {
  const app = useContext(ThemeContext);
  const { body14 } = app.theme.font;

  useEffect(() => {
    const infoText = document.getElementById("info");
    if (infoText) {
      infoText.innerHTML = info;
    }
  }, [title, info]);

  const stepsArray = [
    {
      name: "Selección de Facturas",
      icon: (
        <AiOutlineFileSearch
          size={currentStep === 1 ? 55 : 22}
          color={currentStep === 1 ? "#f7cf46" : "black"}
        />
      ),
    },
    {
      name: "Resumen de Operación",
      icon: (
        <AiOutlineFileDone
          size={currentStep === 2 ? 55 : 22}
          color={currentStep === 2 ? "#f7cf46" : "black"}
        />
      ),
    },
    {
      name: "Cuenta de destino",
      icon: (
        <AiOutlineDollarCircle
          size={currentStep === 3 ? 55 : 22}
          color={currentStep === 3 ? "#f7cf46" : "black"}
        />
      ),
    },
    {
      name: "Carga de Archivos",
      icon: (
        <AiOutlineFileText
          size={currentStep === 4 ? 55 : 22}
          color={currentStep === 4 ? "#f7cf46" : "black"}
        />
      ),
    },
  ];

  return (
    <div
      className="d-flex flex-column w-100"
      style={{ gap: "1rem", marginTop: `${width > 702 ? "3rem" : "0"}` }}
    >
      <div className="d-flex justify-content-center" style={{flexGrow: 1}}>
        <Stepper steps={stepsArray} currentStep={currentStep} width={width} />
      </div>
      <div className="d-flex flex-column">
        <Text
          size={body14}
          type="primary"
          className={width < mobileXSWidth ? "mobile" : ""}
          id="info"
          moreStyles={{ fontWeight: 400 }}
        />
        <img
          src={Pipe2}
          alt="linea"
          height="2px"
          style={{ filter: "grayscale(1)" }}
        />
      </div>
    </div>
  );
};

export default TitleAutoatencion;
