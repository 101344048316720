import React, { useState } from "react";
import { Accordion, Dropdown, Card } from "react-bootstrap";
import RenderDropDownItem from "./RenderDropDownItem";
import ArrowRight from "../../assets/icons/menu/arrow-right.svg";
import ArrowDown from "../../assets/icons/menu/arrow-down.svg";
function RenderAccordionMenu({
  item,
  openModal,
  user,
  setOpen,
  rut,
  setShowLoading,
}) {
  const [selected, setSelected] = useState(false);
  return (
    <Accordion
      onSelect={(key) => {
        setSelected(key !== null);
      }}
      className="accordion-menu"
    >
      <Card>
        <Accordion.Toggle
          as={Card.Header}
          eventKey={item.id}
          style={{
            backgroundColor: selected ? "rgb(253, 209, 53)" : "",
          }}
        >
          {item.title}
          <img src={selected ? ArrowDown : ArrowRight} />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={item.id}>
          <>
            {item.submenu.map((child, index, arr) => {
              return (
                <>
                  <RenderDropDownItem
                    item={child}
                    openModal={openModal}
                    user={user}
                    setOpen={setOpen}
                    mobile={true}
                    rut={rut}
                    setShowLoading={setShowLoading}
                  />
                  {/* {(index != arr.length - 1) &&<Dropdown.Divider />} */}
                </>
              );
            })}
          </>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

export default RenderAccordionMenu;
