import React, { useContext, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Computadora from "../../assets/images/computadora.png";
import Logo2 from "../../assets/images/logo2.png";
import Pipe2 from "../../assets/images/pipe2.png";
import useWindowDimensions from "../../helpers/useWindowDimensions";
import { ThemeContext } from "../../theming";
import CustomButtonWithoutHover from "../buttons/ButtonWithoutHover";
import { ModalSuccess } from "../modal/Modal";
import { Text, TextColored, TextGeneric } from "../typography";

function ClientePasswordRespuesta() {
  const app = useContext(ThemeContext);
  const { body25, body28, body32 } = app.theme.font;
  const { width } = useWindowDimensions();

  const container = {
    width: "450px",
    height: "350px",
    background: "white",
    // padding: '20px'
  };
  /* Error modal*/
  const [modalData] = useState({});
  const [showModalError, setShowModalError] = useState(false);
  const handleCloseModalError = () => setShowModalError(false);

  const history = useHistory();

  const containerMobile = {
    width: "100%",
    height: width >= 375 ? "100%" : '100%',
    background: "white",
    // padding: '20px',
  };

  const buttonLoginStyle = {
    marginTop: "10px",
  };

  <ModalSuccess
    show={showModalError}
    icon={modalData.icon}
    title={
      <TextColored size={body28} type={modalData.color}>
        {modalData.title}
      </TextColored>
    }
    subtitle={
      <TextColored size={body32} bold type={modalData.color}>
        {modalData.subtitle}
      </TextColored>
    }
    message={
      <Text type="primary" size={body25}>
        {modalData.message}
      </Text>
    }
    handleClose={() => handleCloseModalError()}
  />;

  return (
    <>
      <Col>
        <Row>
          <Container
            style={{
              backgroundColor: "black",
              width: "450px",
              height: "110px",
            }}
          >
            <Row className="mt-4 justify-content-center">
              <img width={190} alt="" src={Logo2} />
            </Row>
            <Row className="mt-1 mb-2 justify-content-center">
              <img width={30} height={5} alt="" src={Pipe2} />
            </Row>
            <Row className="justify-content-center pb-2">
              <TextGeneric size={0.95} color="white">
                <b>Cambio de Contraseña</b>
              </TextGeneric>
            </Row>

            {/* <Col>

                    </Col> */}
          </Container>
        </Row>
        <Row>
          <Container style={width >= 701 ? container : containerMobile} fluid>
            <Row className="mt-4 justify-content-center">
              <Form>
                <Row className="justify-content-center mt-4">
                  <Form>
                    <Row className="justify-content-center mt-4 mb-3">
                      <img height={100} width={120} alt="" src={Computadora} />
                    </Row>
                    <Row className="justify-content-center mt-4">
                      <TextGeneric size={1.1}>¡Felicidades!</TextGeneric>
                    </Row>
                    <Row className="justify-content-center mb-3">
                      <TextGeneric size={1.2}>
                        <b>CONTRASEÑA CAMBIADA</b>
                      </TextGeneric>
                    </Row>

                    <Row
                      style={buttonLoginStyle}
                      className="justify-content-center mb-4"
                    >
                      <CustomButtonWithoutHover
                        buttonTextSize={1.0}
                        onClick={() => history.push("/clientes/login")}
                      >
                        INGRESAR A PORTAL DE CLIENTES
                      </CustomButtonWithoutHover>
                    </Row>
                  </Form>
                </Row>

                <ModalSuccess
                  show={showModalError}
                  icon={modalData.icon}
                  messageLine={modalData.messageLine}
                  title={
                    <TextColored size={body28} type={modalData.color}>
                      {modalData.title}
                    </TextColored>
                  }
                  subtitle={
                    modalData.message2 !== "" ? (
                      <></>
                    ) : (
                      <TextColored size={body32} bold type={modalData.color}>
                        {modalData.subtitle}
                      </TextColored>
                    )
                  }
                  message={
                    <>
                      <Text type="primary" size={body25}>
                        {modalData.message}
                      </Text>
                      {modalData.message2 !== "" ? (
                        <Text type="primary" size={body25}>
                          {modalData.message2}
                        </Text>
                      ) : (
                        <></>
                      )}
                    </>
                  }
                  handleClose={() => handleCloseModalError()}
                />
              </Form>
            </Row>
          </Container>
        </Row>
      </Col>
    </>
  );
}

export default ClientePasswordRespuesta;
