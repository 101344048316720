import React, { useContext, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Error } from "../../assets/icons/dashboard";
import Check from "../../assets/images/check.png";
import CustomButtonWithoutHover from "../../components/buttons/ButtonWithoutHover";
import Input from "../../components/inputs/Input";
import { ModalSuccess } from "../../components/modal/Modal";
import {
  Link,
  Text,
  TextColored,
  TextGeneric,
} from "../../components/typography";
import { api } from "../../helpers/api";
import { PORTALSAPI } from "../../helpers/url";
import useWindowDimensions from "../../helpers/useWindowDimensions";
import { ThemeContext } from "../../theming";
import Loading from "../../components/loading/Loading";
import { abrir } from "../../helpers/termino_condiciones";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { convertLastCharToLowerCase, validaRut, formatRut } from "../../helpers/functions";

function ClienteRegistro() {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const app = useContext(ThemeContext);
  const { body25, body28, body32 } = app.theme.font;
  const { width } = useWindowDimensions();

  const container = {
    width: "450px",
    height: `100%`,
    background: "white",
    padding: "5px",
  };
  /* Error modal*/
  const [modalData, setModalData] = useState({});
  const [showModalError, setShowModalError] = useState(false);
  const handleCloseModalError = () => setShowModalError(false);
  const handleShowModalError = () => {
    setShowModalError(true);
  };

  const history = useHistory();

  const formRef = React.useRef();

  const rutValido = validaRut(formRef.current?.elements["rut"]?.value ?? "");

  const [checkTerms, setCheckTerms] = useState(false);
  const [errors /* , setErrors */] = useState({
    email: "",
    razonsocial: "",
    password: "",
    telefono: "",
    rut: "",
  });

  const [showLoading, setShowLoading] = useState({ display: "none" });

  const containerMobile = {
    width: "100%",
    height: width >= 375 ? "100%" : "",
    background: "white",
    padding: "10px",
  };

  const buttonLoginStyle = {
    marginRight: "-5px",
    marginTop: "10px",
  };

  const handleKeyPress = (e) => {
    const numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    const alts = ["Backspace", "Tab", "Enter", "ArrowLeft", "ArrowRight"];

    if (numbers.indexOf(e.key) === -1 && alts.indexOf(e.key) === -1) {
      e.preventDefault();
      return;
    }

    if (e.target.value.length === 9 && alts.indexOf(e.key) === -1) {
      e.preventDefault();
      return;
    }
  };


  const [rutValue, setRutValue] = useState("");

  const handleRutChange = (e) => {
    const actualRut = e.target.value;
    const rutFormateado = formatRut(actualRut);
    setRutValue(rutFormateado);
  };

  const [formValidations, setFormValidations] = React.useState({
    razonsocial: false,
    email: false,
    telefono: false,
    password: false,
    rut: false,
  });

  const disabled =
    !checkTerms || Object.values(formValidations).some((el) => el) || rutValido === false;

  const handleFocus = (value) => () =>
    setFormValidations((form) => ({ ...form, [value]: false }));

  const handleBlur =
    (input, customValidation = () => true) =>
    () => {
      const inputValue = formRef.current?.elements[input]?.value ?? "";
      const isInvalid = inputValue === "" || !customValidation(inputValue);

      setFormValidations((form) => ({ ...form, [input]: isInvalid }));
    };

  const registrar = async () => {
    setShowLoading({ display: "block" });

    const { email, razonsocial, password, telefono, rut } =
      formRef.current.elements;

    if (errors.razonsocial !== "") {
      setModalData({
        messageLine: false,
        title: "Registro",
        subtitle: "Error",
        color: "red",
        message: errors.razonsocial,
        message2: "",
        icon: (
          <>
            <Error />
          </>
        ),
      });
      handleShowModalError();
      setShowLoading({ display: "none" });
      return;
    }

    if (errors.email !== "") {
      setModalData({
        messageLine: false,
        title: "Registro",
        subtitle: "Error",
        color: "red",
        message: errors.email,
        message2: "",
        icon: (
          <>
            <Error />
          </>
        ),
      });
      handleShowModalError();
      setShowLoading({ display: "none" });
      return;
    }

    if (errors.telefono !== "") {
      setModalData({
        messageLine: false,
        title: "Registro",
        subtitle: "Error",
        color: "red",
        message: errors.telefono,
        message2: "",
        icon: (
          <>
            <Error />
          </>
        ),
      });
      handleShowModalError();
      setShowLoading({ display: "none" });
      return;
    }

    if (errors.password !== "") {
      setModalData({
        messageLine: false,
        title: "Registro",
        subtitle: "Error",
        color: "red",
        message: errors.password,
        message2: "",
        icon: (
          <>
            <Error />
          </>
        ),
      });
      handleShowModalError();
      setShowLoading({ display: "none" });
      return;
    }

    if (errors.rut !== "" || rutValido === false) {
      setModalData({
        messageLine: false,
        title: "Registro",
        subtitle: "Error",
        color: "red",
        message: errors.rut,
        message2: "",
        icon: (
          <>
            <Error />
          </>
        ),
      });
      handleShowModalError();
      setShowLoading({ display: "none" });
      return;
    }

    try {
      let captchaToken = await executeRecaptcha();
      let rutValidation = convertLastCharToLowerCase(rut.value);
      let rutWithoutPoints = rutValidation.replace(/\./g, "");
      const body = {
        idcontacto: 0,
        rut: rutWithoutPoints,
        razonsocial: razonsocial.value,
        correo: email.value,
        telefono: telefono.value,
        password: password.value,
        captcha: captchaToken,
      };

      api
        .get(PORTALSAPI.GET.OBTENER_TOKEN)
        .then((responseToken) => {
          let token = responseToken.data;
          let url = PORTALSAPI.POST.REGISTRAR;
          api
            .post(url, body, { headers: { Authorization: `Bearer ${token}` } })
            .then(
              (res) => {
                setShowLoading({ display: "none" });
                history.push(`/clientes/confirmacion?email=${email.value}&rut=${rut.value.replace(/\./g, "")}`);
              },
              (error) => {
                setShowLoading({ display: "none" });
                setModalData({
                  messageLine: false,
                  title: "Registro",
                  subtitle: "Error",
                  color: "red",
                  message: error.response.data.message,
                  icon: (
                    <>
                      <Error />
                    </>
                  ),
                });
                handleShowModalError();
              }
            );
        })
        .catch((error) => {
          setShowLoading({ display: "none" });
          setModalData({
            messageLine: false,
            title: "Registro",
            subtitle: "Error",
            color: "red",
            message: "Usted no tiene permiso para realizar esta acción.",
            icon: (
              <>
                <Error />
              </>
            ),
          });
          handleShowModalError();
        });
    } catch (error) {
      setShowLoading({ display: "none" });
      setModalData({
        messageLine: false,
        title: "Registro",
        subtitle: "Error",
        color: "red",
        message: "Error en reCaptcha.",
        icon: (
          <>
            <Error />
          </>
        ),
      });
      handleShowModalError();
    }
  };

  return (
    <>
      <Loading estilo={showLoading}></Loading>
      <Container style={width >= 701 ? container : containerMobile} fluid>
        <Row className='mt-3'>
          <Col className='text-center' sm={12}>
            <TextGeneric size={body25} className='d-block '>
              Regístrate como cliente <b>en solo 1 minuto</b>
            </TextGeneric>
          </Col>
        </Row>

        <Form
          className='mt-2'
          ref={formRef}
          id='form-registro'
          name='form-registro'>
          <Form.Group
            className='justify-content-center'
            as={Row}
            controlId='formUser'>
            <Col xs={10}>
              <Input
                containerClass=''
                side='bottom'
                type='text'
                placeholder='RUT del Cliente'
                onFocus={(event) => {
                  event.target.setAttribute("autocomplete", "off");
                  handleFocus("rut");
                }}
                name='rut'
                onBlur={handleBlur("rut")}
                style={{
                  border: formValidations.rut || rutValido === false ? "1px solid red" : "",
                }}
                onChange={(e) => handleRutChange(e)}
                value={rutValue}
              />
              {formValidations.rut || rutValido === false ? (
                <Row style={{ marginLeft: "2px", marginBottom: "5px" }}>
                  <TextGeneric color='red'>RUT inválido</TextGeneric>
                </Row>
              ) : (
                <></>
              )}
            </Col>

            <Col xs={10}>
              <Input
                containerClass=''
                side='bottom'
                type='text'
                placeholder='Razón Social'
                onFocus={(event) => {
                  event.target.setAttribute("autocomplete", "off");
                  handleFocus("razonsocial");
                }}
                name='razonsocial'
                onBlur={handleBlur("razonsocial")}
                style={{
                  border: formValidations.razonsocial ? "1px solid red" : "",
                }}
              />
              {formValidations.razonsocial ? (
                <Row style={{ marginLeft: "2px", marginBottom: "5px" }}>
                  <TextGeneric color='red'>Razón social inválida</TextGeneric>
                </Row>
              ) : (
                <></>
              )}
            </Col>
            <Col xs={10}>
              <Input
                containerClass=''
                side='bottom'
                type='text'
                placeholder='Email Principal'
                autoComplete='new-password'
                name='email'
                onBlur={handleBlur("email", (value) =>
                  value.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)
                )}
                onFocus={handleFocus("email")}
                style={{
                  border: formValidations.email ? "1px solid red" : "",
                }}
              />
              {formValidations.email ? (
                <Row style={{ marginLeft: "2px", marginBottom: "5px" }}>
                  <TextGeneric color='red'>Email inválido</TextGeneric>
                </Row>
              ) : (
                <></>
              )}
            </Col>
            <Col xs={10}>
              <Input
                key='telefono'
                side='bottom'
                type='text'
                placeholder='Telefono'
                name='telefono'
                onKeyDown={handleKeyPress}
                onBlur={handleBlur("telefono", (value) => value.length === 9)}
                onFocus={handleFocus("telefono")}
                style={{
                  border: formValidations.telefono ? "1px solid red" : "",
                }}
              />
              {formValidations.telefono ? (
                <Row style={{ marginLeft: "2px", marginBottom: "5px" }}>
                  <TextGeneric color='red'>Teléfono inválido</TextGeneric>
                </Row>
              ) : (
                <></>
              )}
            </Col>
            <Col xs={10}>
              <Input
                key='password'
                side='bottom'
                type='password'
                placeholder='Contraseña'
                name='password'
                autoComplete='new-password'
                onBlur={handleBlur("password")}
                onFocus={handleFocus("password")}
                style={{
                  border: formValidations.password ? "1px solid red" : "",
                }}
              />
              {formValidations.password ? (
                <Row style={{ marginLeft: "2px", marginBottom: "5px" }}>
                  <TextGeneric color='red'>Contraseña inválida</TextGeneric>
                </Row>
              ) : (
                <></>
              )}
            </Col>
          </Form.Group>
          <Form.Group
            className='justify-content-center'
            as={Row}
            controlId='formUser2'>
            <Col xs={12}>
              <Row className='justify-content-center'>
                {checkTerms ? (
                  <img
                    className='mr-2'
                    height={20}
                    width={20}
                    alt=''
                    src={Check}
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => setCheckTerms(false)}
                  />
                ) : (
                  <div
                    style={{
                      height: 20,
                      width: 20,
                      borderRadius: "4px",
                      background: "lightgrey",
                      cursor: "pointer",
                      marginRight: "8px",
                    }}
                    onClick={() => setCheckTerms(true)}
                  />
                )}

                <Link size={0.6}>
                  <TextGeneric className='mr-2'>
                    <b>Acepto Política de Privacidad</b>
                  </TextGeneric>
                </Link>
                {/* </Col> */}
              </Row>
            </Col>
            <Col xs={12}>
              <TextGeneric>
                <a href='##' onClick={() => abrir()}>
                  Leer detalle
                </a>
              </TextGeneric>
            </Col>
          </Form.Group>
          <Form.Group
            className='justify-content-center mt-1'
            as={Row}
            controlId='formUser3'>
            <Col xs={width >= 701 ? 8 : 9}>
              <Row style={buttonLoginStyle}>
                <CustomButtonWithoutHover
                  disabled={disabled}
                  onClick={registrar}>
                  ¡QUIERO REGISTRARME!
                </CustomButtonWithoutHover>
              </Row>
              <Row className='justify-content-center mt-2'>
                <TextGeneric>
                  ¿Ya estás registrado?
                  <b onClick={() => history.push("/clientes/login")}>
                    <span
                      style={{
                        cursor: "pointer",
                      }}>
                      Entra aquí
                    </span>
                  </b>
                </TextGeneric>
              </Row>
            </Col>
          </Form.Group>
        </Form>

        <ModalSuccess
          show={showModalError}
          icon={modalData.icon}
          messageLine={modalData.messageLine}
          title={
            <TextColored size={body28} type={modalData.color}>
              {modalData.title}
            </TextColored>
          }
          subtitle={
            modalData.message2 !== "" ? (
              <></>
            ) : (
              <TextColored size={body32} bold type={modalData.color}>
                {modalData.subtitle}
              </TextColored>
            )
          }
          message={
            <>
              <Text type='primary' size={body25}>
                {modalData.message}
              </Text>
              {modalData.message
                ?.toString()
                .includes("ya se encuentra registrada") ? (
                <CustomButtonWithoutHover
                  className='mt-2'
                  onClick={() => {
                    const { email, rut } = formRef.current.elements;
                    history.push(`/clientes/confirmacion?email=${email.value}&rut=${rut.value.replace(/\./g, "")}`);
                  }}>
                  Aceptar
                </CustomButtonWithoutHover>
              ) : (
                <></>
              )}
              {modalData.message2 !== "" ? (
                <Text type='primary' size={body25}>
                  {modalData.message2}
                </Text>
              ) : (
                <></>
              )}
            </>
          }
          handleClose={() => handleCloseModalError()}
        />
      </Container>
    </>
  );
}

export default ClienteRegistro;
