//Hooks - Funciones
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import moment from "moment";
import useWindowDimensions from "../../helpers/useWindowDimensions";
import { useHistory } from "react-router-dom";
import {
  fireAlert,
  formatCurrency,
  getCDLBills,
  getResumeBills,
  getRut,
  reloadCosts,
  stepValidator,
  timeout,
} from "../../helpers/autoAtencionHelpers.js";
import useStorage from "../../helpers/useStorage";
import { AUTOATENCIONAPI } from "../../helpers/url";
import { api } from "../../helpers/api";

//Assets
import { FiFilter } from "react-icons/fi";

//Componentes
import { Container, Row, Card, Col, Button, Spinner } from "react-bootstrap";
import Footer from "../../components/footer/Footer";
import HeaderAutoatencion from "../../components/header-autoatencion/HeaderAutoatencion";
import TablaFacturas from "../../components/autoatencion/TablaFacturas";
import TitleAutoatencion from "../../components/header-autoatencion/TitleAutoatencion";
import SidebarFiltro from "../../components/sidebar/autoatencion/SidebarFiltro";
import CustomButton from "../../components/buttons/Button";
import AutoatencionLoading from "../../components/loading/AutoatencionLoading";
import NoBills from "../../components/autoatencion/NoBills";
import { TextColored } from "../../components/typography";
import { AiOutlineCloudSync } from "react-icons/ai";
import ModalDuplicados from "../../components/modal/modal-autoatencion/ModalDuplicados";

const TableFunctions = ({ syncDate, setShow }) => {
  // Opciones de filtrado - fecha de sincronizacion
  const functionStyle = {
    color: "#1D1D1B",
    border: "none",
    marginBottom: "3px",
    fontWeight: "500",
    alignItems: "center",
    display: "flex",
    boxShadow: "none",
    gap: "3px",
    background: "lightgrey",
  };

  return (
    <Row>
      <Col
        className="d-flex flex-row-reverse align-items-center"
        style={{ gap: "0.5rem" }}
      >
        <Button onClick={() => setShow((s) => !s)} style={functionStyle}>
          <FiFilter /> Filtrar
        </Button>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            background: "lightgrey",
            borderRadius: "0.25rem",
            gap: "0.2rem",
            padding: ".375rem .75rem",
            marginBottom: "3px",
          }}
        >
          {syncDate?.length > 0 ? (
            <>
              {" "}
              <AiOutlineCloudSync size={20} />{" "}
              <TextColored
                children="Ult. Sincronización:"
                type="dark"
                weight={500}
                size={1}
              />
              {moment(syncDate).format("DD/MM/YYYY HH:mm")}
            </>
          ) : (
            <>
              <Spinner animation="border" size="sm" />{" "}
              <TextColored
                children="Sincronizando facturas"
                type="dark"
                weight={500}
                size={1}
              />
            </>
          )}
        </div>
      </Col>
    </Row>
  );
};

const SeleccionFacturas = () => {
  const history = useHistory();
  const updatingSync = useRef(false);
  const loadingData = useRef(false);
  const [proveedor] = useStorage("proveedor", 0, "session");
  const [{ idcontacto, idcliente }] = useStorage("user", {}, "local");
  const [isEmailSended, setSended] = useStorage(
    "isEmailSended",
    false,
    "session"
  );
  const [parametros] = useStorage("parametros_comerciales", {}, "session");
  const { width } = useWindowDimensions();
  const [{ filter, docs }, setShow] = useState({ filter: false, docs: false });
  const [rut, setRut] = useState(null);

  const [rows, setRows] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [duplicateBills, setDuplicate] = useState([]);
  const [toggledClearRows, setToggleClearRows] = useState(false);

  const [{ tableLoading, loading }, setLoading] = useState({
    tableLoading: false,
    loading: true,
  });

  const currentDate = useMemo(() => moment(new Date()), []);
  const [syncDate, setSyncDate] = useState(null);
  const [currentParameters, setCurrentParameters] = useState({
    start: currentDate
      .clone()
      .subtract(parametros.ANTIGUEDAD, "days")
      .format("YYYY-MM-DD"),
    end: currentDate.clone().format("YYYY-MM-DD"),
    changed: false,
    // Parametros actuales para el listado de facturas (Importante respetar el formato)
  });

  const checkBills = async () => {
    const req = [...selectedRows].map((b) => {
      return {
        tipo_documento: 1, // tipo 1 = Factura
        rut_cliente: rut,
        nu_documento: b.folio.toString(),
        rut_deudor: b.rutRecep,
      };
    });

    // Verifico si los documentos seleccionados ya se encuentran en una operación de fintec.
    const {
      data: {
        result: { documentos },
      },
    } = await api.post(
      AUTOATENCIONAPI.POST.VALIDAR_DOCUMENTOS.replace(
        "{idcontacto}",
        idcontacto
      ).replace("{idpersona}", idcliente),
      { documentos: req }
    );

    if (documentos.length > 0) {
      setDuplicate({ documentos, selectedRows });
      return true;
    }
    return false;
  };

  const getBills = useCallback(
    async (type, { start, end = currentDate.clone().add(1, "days") }) => {
      try {
        const bills = [];
        switch (
          type // Tipo proveedor
        ) {
          case 1: {
            const publicURL = AUTOATENCIONAPI.GET.FACTURAS.replace("{rut}", rut)
              .replace("{inicio}", start.format("YYYYMMDD"))
              .replace(
                "{fin}",
                end
                  //Agrego un dia mas por si se emite una factura en el mismo dia.
                  .format("YYYYMMDD")
              )
              .replace("{idcontacto}", idcontacto);

            const {
              data: { body },
            } = await api.get(publicURL);

            bills.push(...body);
            break;
          }
          case 2: {
            const { data } = await getCDLBills(false);
            const arr = [];

            Object.keys(data).forEach((k) => arr.push(...data[k]));
            const translatedArr = arr.map((b) => {
              const {
                mt_total,
                folio,
                rut_receptor,
                no_empresa,
                fe_documento,
                tipo_doc,
                evento,
              } = b;

              const formatDate = (date) => {
                const splitted = date.split("/");
                return `${splitted[1]}/${splitted[0]}/${splitted[2]}`;
              };

              const parsedDate = moment(formatDate(fe_documento)).tz(
                "America/Santiago"
              );

              const filteredStates = [
                "Reclamado",
                "Contado/Gratuito",
              ];

              if (
                // Verifico que este entre las fechas del filtro
                parsedDate.isSameOrAfter(start, "day") &&
                parsedDate.isSameOrBefore(end, "day") &&
                !filteredStates.includes(evento)
              ) {
                // "Traduccion" de las propiedades provenientes de la api CDL para que queden iguales a las de DM
                return {
                  mntTotal: Number(mt_total),
                  folio: Number(folio),
                  rutRecep: rut_receptor,
                  rznSocRecep: no_empresa,
                  fchEmis: parsedDate.toISOString(),
                  tipoDTE: tipo_doc,
                  enlacePdf: null,
                  enlaceXml: null,
                };
              }
              return false;
            });

            bills.push(...translatedArr.filter(Boolean));
            break;
          }
          default:
            break;
        }

        return (
          bills?.filter(
            (b) =>
              currentDate.diff(moment(b.fchEmis), "days") <=
                parametros.ANTIGUEDAD &&
              // Filtro las facturas que no posean la cantidad minima de dias de emision
              Number(b.mntTotal) <= parametros.MAXIMO_FACTURAS &&
              Number(b.mntTotal) >= parametros.MINIMO_FACTURAS &&
              // Filtro las facturas que no cumplan con el monto maximo y minimo
              b.rutRecep !== rut
            // Filtro facturas que tengan al cliente como deudor
          ) || []
        );
      } catch (err) {
        await fireAlert({
          icon: "error",
          title: "Hubo un error al obtener las facturas.",
        });
      }
    },
    [parametros, idcontacto, rut, currentDate]
  );

  const chargeBills = useCallback(
    async (type = "load") => {
      let newBills;
      try {
        if (type === "reload") {
          // type "reload" cuando sincronizo las facturas o quiero limpiar los filtros
          setLoading((l) => {
            return { ...l, tableLoading: true };
          });

          // Limpio las facturas ya seleccionadas
          setSelectedRows([]);
          setToggleClearRows(true);
          //

          if (currentParameters.changed) {
            setShow(false);
            // Si hay filtros aplicados los vuelvo a los valores default
            setCurrentParameters({
              start: currentDate
                .clone()
                .subtract(parametros.ANTIGUEDAD, "days")
                .format("YYYY-MM-DD"),
              end: currentDate.clone().format("YYYY-MM-DD"),
              changed: false,
            });
          }
          setToggleClearRows(false);
        }

        // Obtengo las facturas
        newBills = await getBills(proveedor, {
          // Proveedor 1 = Datamart - 2 = CDL
          start: currentDate.clone().subtract(parametros.ANTIGUEDAD, "days"),
        });

        if (newBills.length > 0 && !isEmailSended) {
          const payload = {
            idpersona: idcliente,
          };
          setSended(true);
          await api.post(AUTOATENCIONAPI.GET.ENVIAR_ALERTA, payload);
          // Envio alerta al ejecutivo cuando un cliente inicia el proceso de AA
        }

        setRows([...newBills.reverse()]); // Se hace un reverse para que las facturas se ordenen de mas nueva a mas antigua

        return setLoading((l) => {
          if (type === "reload") {
            return { ...l, tableLoading: false }; // Al ser reload no hace falta poner la pantalla de carga entera.
          } else {
            return { ...l, loading: false };
          }
        });
      } catch {
        setRows([...newBills.reverse()]);
        return setLoading((l) => {
          if (type === "reload") {
            return { ...l, tableLoading: false };
          } else {
            return { ...l, loading: false };
          }
        });
      }
    },
    [
      getBills,
      currentParameters,
      parametros,
      currentDate,
      proveedor,
      idcliente,
      setSended,
      isEmailSended,
    ]
  );

  const getSyncDate = useCallback(async () => {
    try {
      if (rut) {
        const url = AUTOATENCIONAPI.GET.FECHA_DTESYNC.replace(
          "{rut}",
          rut
        ).replace("{idcontacto}", idcontacto);

        const {
          data: { prg_dat_fechanotificacion: fecha },
        } = await api.get(url);

        //En el caso de que la fecha cargada en el estado sea igual, el mismo no se actualiza
        if (syncDate !== fecha) setSyncDate(fecha);

        return fecha;
      }
    } catch (err) {
      console.log(err);
    }
  }, [idcontacto, rut, syncDate]);

  const validateNewSyncDate = useCallback(async () => {
    try {
      if (
        window.location.pathname === "/autoatencion/seleccion-facturas" &&
        // Verifico el path ya que sino se hacia la peticion en otras pantallas
        !updatingSync?.current
      ) {
        updatingSync.current = true;
        const newDate = await getSyncDate();
        // Si devuelve dia nuevo recargo las facturas
        updatingSync.current = false;
        if (newDate !== syncDate) {
          return await chargeBills("reload");
        } else {
          await timeout(10000);
          return await validateNewSyncDate();
        }
      }
    } catch (err) {
      console.log(err);
    }
  }, [chargeBills, getSyncDate, syncDate]);

  const handleSubmit = async () => {
    setLoading((l) => ({ ...l, loading: true }));
    const areBillsDuplicate = await checkBills();
    if (areBillsDuplicate) {
      // Si hay facturas duplicadas, no es necesario cargar los costos
      setShow((p) => ({ ...p, docs: true }));
    } else {
      await reloadCosts(await getRut());
      await getResumeBills(selectedRows);
      sessionStorage.setItem("current-step", 2);
      history.push("/autoatencion/resumen-facturas");
    }
    setLoading((l) => ({ ...l, loading: false }));
  };

  useEffect(() => {
    const correctStep = stepValidator();
    if (correctStep) {
      if (!rut) {
        getRut().then((rut) => setRut(rut));
      }
      sessionStorage.removeItem("processId");
      if (!rows && rut) {
        if (!loadingData.current) {
          chargeBills();
          loadingData.current = true;
        }
      }
    }
  }, [rows, chargeBills, rut, history]);

  useEffect(() => {
    if (syncDate !== null && Number(proveedor) === 1) {
      // Condicional utilizada para DM ya que la sincronizacion no es en linea sino que proviene de un web socket, que eventualmente se actualiza
      setTimeout(() => {
        validateNewSyncDate();
      }, 10000);
    } else {
      if (rut) {
        getSyncDate();
      }
    }
  }, [syncDate, validateNewSyncDate, getSyncDate, rut, rows, proveedor]);

  return (
    <>
      <SidebarFiltro
        show={filter}
        setShow={(e) => setShow((p) => ({ ...p, filter: e }))}
        currentParameters={currentParameters}
        setCurrentParameters={setCurrentParameters}
        setRows={setRows}
        setLoading={(e) =>
          setLoading((l) => {
            return { ...l, tableLoading: e };
          })
        }
        currentDate={currentDate}
        parametrosComerciales={parametros}
        selectedRows={selectedRows}
        getBills={getBills}
        supplier={proveedor}
      />
      {docs ? (
        <ModalDuplicados
          show={docs}
          setShow={(e) => setShow((p) => ({ ...p, docs: e }))}
          docs={duplicateBills}
          loading={loading}
          handleSubmit={handleSubmit}
        />
      ) : null}
      <Container
        fluid
        className="d-flex flex-column"
        style={{ height: "100vh", overflowY: "auto" }}
      >
        <Row>
          <HeaderAutoatencion step={1} />
        </Row>

        <Col
          className="justify-content-center align-items-center d-flex"
          style={{ flexGrow: 1 }}
        >
          <Card
            style={{
              width: `${
                width <= 1260
                  ? "100%"
                  : width <= 1620
                  ? "80%"
                  : width < 1367
                  ? "85%"
                  : "60%"
              }`,
              transition: "width 0.5s",
            }}
          >
            <Card.Body className="d-flex flex-column" style={{ gap: "15px" }}>
              <AutoatencionLoading show={loading} />
              {rows?.length > 0 ? (
                <>
                  <br />
                  <TitleAutoatencion
                    info={`Aquí te mostramos tus facturas habilitadas para el proceso de factoring. Los criterios para la habilitación de la factura incluyen un monto entre ${formatCurrency(
                      parametros.MINIMO_FACTURAS
                    )} y ${formatCurrency(
                      parametros.MAXIMO_FACTURAS
                    )} además de una cantidad de días desde su emisión menor a ${
                      parametros.ANTIGUEDAD
                    }.`}
                    currentStep={1}
                    width={width}
                  />
                  <TablaFacturas
                    setShow={setShow}
                    rows={rows}
                    loading={tableLoading}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    options={
                      <TableFunctions syncDate={syncDate} setShow={setShow} />
                    }
                    toggledClearRows={toggledClearRows}
                  />
                  <Row
                    className="d-flex flex-column justify-content-center align-items-center"
                    style={{ gap: "5px" }}
                  >
                    <TextColored
                      type="dark"
                      size={0.9}
                      children={
                        selectedRows.length > 0 ? (
                          <span>
                            Facturas seleccionadas: <b>{selectedRows.length}</b>
                          </span>
                        ) : (
                          "No hay facturas seleccionadas"
                        )
                      }
                    />
                    <Col md={3}>
                      <CustomButton
                        children={
                          <TextColored
                            type="dark"
                            size={1.1}
                            children="Continuar"
                            bold
                          />
                        }
                        onClick={() => handleSubmit(false)}
                        loading={loading}
                        disabled={selectedRows?.length === 0}
                      />
                    </Col>
                  </Row>
                </>
              ) : (
                <NoBills history={history} syncDate={syncDate} />
              )}
            </Card.Body>
          </Card>
        </Col>

        <Row>
          <Footer margin={false} />
        </Row>
      </Container>
    </>
  );
};

export default SeleccionFacturas;
