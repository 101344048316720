import React, { useEffect } from 'react';
import ClienteLoginSeccion from '../../components/clientes-login-seccion/ClienteLoginSeccion';
import ClientePasswordRespuesta from '../../components/clientes/ClientePasswordRespuesta';

const ClientePasswordTerminado = () => {
    useEffect(() => {
    }, []);
    return (
        <>
            <ClienteLoginSeccion >
                <ClientePasswordRespuesta></ClientePasswordRespuesta>
            </ClienteLoginSeccion>
        </>

    );
};
export default ClientePasswordTerminado;