import React, { useContext, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { Error } from "../../assets/icons/dashboard";
import Computadora from "../../assets/images/computadora.png";
import CustomButtonWithoutHover from "../../components/buttons/ButtonWithoutHover";
import Input from "../../components/inputs/Input";
import { ModalSuccess } from "../../components/modal/Modal";
import { Text, TextColored, TextGeneric } from "../../components/typography";
import { api } from "../../helpers/api";
import { PORTALSAPI } from "../../helpers/url";
import useWindowDimensions from "../../helpers/useWindowDimensions";
import { ThemeContext } from "../../theming";
import Loading from "../../components/loading/Loading";
import { mobileXSWidth } from "../../helpers/responsiveVariables";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ClientePendiente() {
  const app = useContext(ThemeContext);
  const { body25, body28, body32 } = app.theme.font;
  const { width } = useWindowDimensions();

  const container = {
    width: "450px",
    height: "480px",
    background: "white",
    padding: "20px",
  };
  /* Error modal*/
  const [modalData, setModalData] = useState({});
  const [showModalError, setShowModalError] = useState(false);
  const handleCloseModalError = () => setShowModalError(false);
  const handleShowModalError = () => {
    setShowModalError(true);
  };
  const email = useQuery().get("email");
  const rut = useQuery().get("rut");

  const history = useHistory();

  const [pin, setPin] = useState("");

  const [showLoading, setShowLoading] = useState({ display: "none" });

  const containerMobile = {
    width: "100%",
    height: width >= 375 ? "100%" : "",
    background: "white",
    padding: "20px",
  };

  const buttonLoginStyle = {
    marginTop: "10px",
  };

  <ModalSuccess
    show={showModalError}
    icon={modalData.icon}
    title={
      <TextColored size={body28} type={modalData.color}>
        {modalData.title}
      </TextColored>
    }
    subtitle={
      <TextColored size={body32} bold type={modalData.color}>
        {modalData.subtitle}
      </TextColored>
    }
    message={
      <Text type="primary" size={body25}>
        {modalData.message}
      </Text>
    }
    handleClose={() => handleCloseModalError()}
  />;

  const reenviar = () => {
    setShowLoading({ display: "block" });
    api
      .get(PORTALSAPI.GET.OBTENER_TOKEN)
      .then((responseToken) => {
        let token = responseToken.data;
        let url = PORTALSAPI.GET.REENVIAR_PIN.replace("{rut}", rut);

        api.get(url, { headers: { Authorization: `Bearer ${token}` } }).then(
          (res) => {
            setShowLoading({ display: "none" });
          },
          (error) => {
            setShowLoading({ display: "none" });

            setModalData({
              messageLine: false,
              title: "Registro",
              subtitle: "Error",
              color: "red",
              message: error.response.data.message,
              icon: (
                <>
                  <Error />
                </>
              ),
            });
            handleShowModalError();
          }
        );
      })
      .catch((error) => {
        setShowLoading({ display: "none" });
        setModalData({
          messageLine: false,
          title: "Registro",
          subtitle: "Error",
          color: "red",
          message: "Usted no tiene permiso para realizar esta acción.",
          icon: (
            <>
              <Error />
            </>
          ),
        });
        handleShowModalError();
      });
  };

  const confirmar = () => {
    setShowLoading({ display: "block" });
    api
      .get(PORTALSAPI.GET.OBTENER_TOKEN)
      .then((responseToken) => {
        const body = {
          rut: rut,
          pin: Number(pin),
        };
        let token = responseToken.data;
        api
          .post(PORTALSAPI.POST.VALIDAR_REGISTRO, body, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(
            (res) => {
              setShowLoading({ display: "none" });

              history.push("/clientes/confirmado");
            },
            (error) => {
              setShowLoading({ display: "none" });

              setModalData({
                messageLine: false,
                title: "Registro",
                subtitle: "Error",
                color: "red",
                message: error.response.data.message,
                icon: (
                  <>
                    <Error />
                  </>
                ),
              });
              handleShowModalError();
            }
          );
      })
      .catch((error) => {
        setShowLoading({ display: "none" });
        setModalData({
          messageLine: false,
          title: "Registro",
          subtitle: "Error",
          color: "red",
          message: "Usted no tiene permiso para realizar esta acción.",
          icon: (
            <>
              <Error />
            </>
          ),
        });
        handleShowModalError();
      });
  };

  const handleKeyPress = (e) => {
    const alts = ["Backspace", "Tab", "Enter", "ArrowLeft", "ArrowRight"];

    if (e.target.value.length === 4 && alts.indexOf(e.key) === -1) {
      e.preventDefault();
      return;
    }
  };

  return (
    <>
      <Loading estilo={showLoading}></Loading>

      <Container style={width >= 701 ? container : containerMobile} fluid>
        <Row className="mt-3">
          <Col className="text-center" sm={12}>
            <TextGeneric size={body25} className="d-block ">
              Regístrate como cliente <b>en solo 1 minuto</b>
            </TextGeneric>
          </Col>
        </Row>

        <Row className="justify-content-center mt-4">
          <Form className={width > mobileXSWidth ? "" : "ml-4 mr-4"}>
            <Row className="justify-content-center mt-2 mb-3">
              <img height={100} width={100} alt="" src={Computadora} />
            </Row>
            <Row>
              <TextGeneric>
                <b>Para confirmar tu identidad, te hemos enviado un</b>
                <br></br>
                <b>código a tu email:</b>
              </TextGeneric>
            </Row>
            <Row className="justify-content-center mt-2 mb-2">
              <TextGeneric size={1.2} color={"#ff8000"}>
                <b> {email} </b>
              </TextGeneric>
            </Row>
            <Row className="mt-2">
              <TextGeneric>
                Ingresa dicho código en el siguiente campo de texto:
              </TextGeneric>
            </Row>
            <Row>
              <Input
                side="bottom"
                style={{
                  marginTop: "20px",
                }}
                type="number"
                placeholder="Ingresar código"
                value={pin}
                onChange={(e) => setPin(e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </Row>
            <Row
              style={buttonLoginStyle}
              className="justify-content-center mb-2"
            >
              <Col lg={9}>
                <CustomButtonWithoutHover
                  buttonTextSize={1.0}
                  onClick={confirmar}
                >
                  FINALIZAR REGISTRO
                </CustomButtonWithoutHover>
              </Col>
            </Row>
            <Row className="justify-content-center mb-2">
              <TextGeneric size={0.7}>
                ¿No ha recibido este email?
                <b style={{ cursor: "pointer" }} onClick={reenviar}>
                  Click aquí para reenviar.
                </b>
                <br />
                Asegúrate de revisar también tu carpeta de Spam
              </TextGeneric>
            </Row>
          </Form>
        </Row>

        <ModalSuccess
          show={showModalError}
          icon={modalData.icon}
          messageLine={modalData.messageLine}
          title={
            <TextColored size={body28} type={modalData.color}>
              {modalData.title}
            </TextColored>
          }
          subtitle={
            modalData.message2 !== "" ? (
              <></>
            ) : (
              <TextColored size={body32} bold type={modalData.color}>
                {modalData.subtitle}
              </TextColored>
            )
          }
          message={
            <>
              <Text type="primary" size={body25}>
                {modalData.message}
              </Text>
              {modalData.message2 !== "" ? (
                <Text type="primary" size={body25}>
                  {modalData.message2}
                </Text>
              ) : (
                <></>
              )}
            </>
          }
          handleClose={() => handleCloseModalError()}
        />
      </Container>
    </>
  );
}

export default ClientePendiente;
