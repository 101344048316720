import React from "react";
import { Col, Row } from "react-bootstrap";
import Esquina2 from "../../assets/images/esquina2.png";
import ListaWizard from "../../assets/images/lista_wizard.png";
import ListaWizard2 from "../../assets/images/wizard2.png";
import Logo3 from "../../assets/images/logo3.png";
import "./classes.css";
import { TextGeneric } from "../typography";
import {
  mobileLDWidth,
} from "../../helpers/responsiveVariables";
import useWindowDimensions from "../../helpers/useWindowDimensions";

export default function Seccion3({ openModal }) {
  const { width } = useWindowDimensions();

  return (
    <>
      {width > mobileLDWidth ? (
        <Col
          style={{
            backgroundColor: "white",
            marginTop: "40px",
            marginLeft: "15px",
            marginRight: "15px",
            borderRadius: "15px",
          }}
        >
          <Row className="justify-content-center">
            <Col
              style={{
                textAlign: "left",
                padding: 0,
                flexGrow: 0,
                flexShrink: 1,
              }}
            >
              <img alt="" width={150} src={Logo3} />
            </Col>
            <Col
              className=""
              style={{ textAlign: "left", margin: "60px 30px 40px" }}
            >
              <Row>
                <TextGeneric size={1.0}>
                  <b>¡Bienvenido a nuestro</b>
                </TextGeneric>
              </Row>
              <Row>
                <TextGeneric color={"#fdd135"} size={2.1}>
                  Portal de Clientes!
                </TextGeneric>
              </Row>
              <Row>
                <TextGeneric size={1.0}>
                  Aquí encontrarás la información detallada de
                </TextGeneric>
              </Row>
              <Row>
                <TextGeneric size={1.8}>tus servicios contratados</TextGeneric>
              </Row>
            </Col>
          </Row>
          <Row style={{ padding: "0 40px", flexWrap: "nowrap" }}>
            <Col
              style={{
                flexGrow: 0,
                flexShrink: 1,
              }}
            >
              <Row>
                <Col>
                  <Row style={{ backgroundColor: "#fdd135", width: "230px" }}>
                    <TextGeneric className="ml-4" size={1.2}>
                      Factoring
                    </TextGeneric>
                    <img
                      style={{
                        position: "absolute",
                        left: "-3px",
                        top: "21px",
                      }}
                      src={Esquina2}
                      height={10}
                      alt=""
                    />
                  </Row>
                  <Row>
                    <ul
                      style={{
                        color: "gray",
                        fontWeight: "bold",
                        lineHeight: "0.8rem",
                        padding: "10px 15px 0",
                      }}
                    >
                      <li className="li-indicator">
                        <TextGeneric semi_bold size={0.6} color="#666666">
                          &nbsp;&nbsp;&nbsp; Detalle de cada operación
                        </TextGeneric>
                      </li>
                      <li className="li-indicator">
                        <TextGeneric semi_bold size={0.6} color="#666666">
                          &nbsp;&nbsp;&nbsp; Estado de operaciones
                        </TextGeneric>
                      </li>
                      <li className="li-indicator">
                        <TextGeneric semi_bold color="#666666" size={0.6}>
                          &nbsp;&nbsp;&nbsp; Liquidaciones
                        </TextGeneric>
                      </li>
                      <li className="li-indicator">
                        <TextGeneric semi_bold color="#666666" size={0.6}>
                          &nbsp;&nbsp;&nbsp; Mucho más
                        </TextGeneric>
                      </li>
                    </ul>
                  </Row>
                </Col>

                <Col>
                  <Row style={{ backgroundColor: "#fdd135", width: "230px" }}>
                    <TextGeneric className="ml-4" size={1.2}>
                      Leasing
                    </TextGeneric>
                    <img
                      style={{
                        position: "absolute",
                        left: "-3px",
                        top: "21px",
                      }}
                      src={Esquina2}
                      height={10}
                      alt=""
                    />
                  </Row>
                  <Row>
                    <ul
                      style={{
                        color: "gray",
                        fontWeight: "bold",
                        lineHeight: "0.8rem",
                        padding: "10px 15px 0",
                      }}
                    >
                      <li className="li-indicator">
                        <TextGeneric semi_bold size={0.6} color="#666666">
                          &nbsp;&nbsp;&nbsp; Operaciones y contratos
                        </TextGeneric>
                      </li>
                      <li className="li-indicator">
                        <TextGeneric semi_bold size={0.6} color="#666666">
                          &nbsp;&nbsp;&nbsp; Diferencia tipo de cambio por
                          cobrar
                        </TextGeneric>
                      </li>
                      <li className="li-indicator">
                        <TextGeneric semi_bold size={0.6} color="#666666">
                          &nbsp;&nbsp;&nbsp; Status de pago
                        </TextGeneric>
                      </li>
                      <li className="li-indicator">
                        <TextGeneric semi_bold size={0.6} color="#666666">
                          &nbsp;&nbsp;&nbsp; Mucho más
                        </TextGeneric>
                      </li>
                    </ul>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col style={{ height: "250px" }}>
              <div
                style={{
                  margin: "-80px -30px -20px",
                  padding: 0,
                  position: "relative",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  style={{
                    position: "absolute",
                    top: "-110px",
                  }}
                  width="100%"
                  height="430px"
                  src={ListaWizard}
                  alt=""
                />
              </div>
            </Col>
          </Row>
        </Col>
      ) : (
        <Col
          style={{
            backgroundColor: "white",
            marginTop: "40px",
            marginLeft: "15px",
            marginRight: "15px",
            borderRadius: "15px",
          }}
        >
          <Row className="">
            <Col
              xs={1}
              style={{
                marginRight: "15px",
                textAlign: "left",
                padding: 0,
                flexGrow: 0,
                flexShrink: 1,
              }}
            >
              <img alt="" width={65} src={Logo3} />
            </Col>
            <Col
              className=""
              style={{ textAlign: "left", margin: "20px 30px 10px" }}
            >
              <Row className="justify-content-center">
                <TextGeneric size={1.2}>
                  <b>¡Bienvenido a nuestro</b>
                </TextGeneric>
              </Row>
              <Row className="justify-content-center">
                {" "}
                <TextGeneric color={"#fdd135"} size={1.5}>
                  Portal de Clientes!
                </TextGeneric>
              </Row>
              <Row className="justify-content-center">
                {" "}
                <TextGeneric size={0.6}>
                  <b>Aquí encontrarás la información detallada de</b>
                </TextGeneric>
              </Row>
              <Row className="justify-content-center">
                {" "}
                <TextGeneric size={1.0}>
                  <b>tus servicios contratados</b>
                </TextGeneric>
              </Row>
            </Col>
          </Row>

          <Row style={{ flexWrap: "nowrap" }} className="mr-2">
            <Col
              xs={6}
              className="ml-2 mr-2 mb-2"
              style={{
                backgroundImage: "linear-gradient(#fdd135,#fdd135,white)",
              }}
            >
              <Row style={{ width: "130px" }}>
                <TextGeneric className="ml-4" size={1.2}>
                  Factoring
                </TextGeneric>
                <img
                  style={{
                    position: "absolute",
                    top: "0px",
                    right: "0px",
                  }}
                  src={Esquina2}
                  height={10}
                  alt=""
                />
              </Row>
              <Row>
                <ul
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    lineHeight: "0.8rem",
                    padding: "10px 10px 0",
                  }}
                >
                  <li className="li-indicator">
                    <TextGeneric semi_bold size={0.6} color="#000000">
                      &nbsp;&nbsp;&nbsp; Detalle de cada operación
                    </TextGeneric>
                  </li>
                  <li className="li-indicator">
                    <TextGeneric semi_bold size={0.6} color="#000000">
                      &nbsp;&nbsp;&nbsp; Estado de operaciones
                    </TextGeneric>
                  </li>
                  <li className="li-indicator">
                    <TextGeneric semi_bold color="#000000" size={0.6}>
                      &nbsp;&nbsp;&nbsp; Liquidaciones
                    </TextGeneric>
                  </li>
                  <li className="li-indicator">
                    <TextGeneric semi_bold color="#000000" size={0.6}>
                      &nbsp;&nbsp;&nbsp; Mucho más
                    </TextGeneric>
                  </li>
                </ul>
              </Row>
            </Col>
            <Col
              xs={6}
              className="mr-2 mb-2"
              style={{
                backgroundImage: "linear-gradient(#fdd135,#fdd135,white)",
              }}
            >
              <Row>
                <TextGeneric className="ml-4" size={1.2}>
                  Leasing
                </TextGeneric>
                <img
                  style={{
                    position: "absolute",
                    top: "0px",
                    right: "0px",
                  }}
                  src={Esquina2}
                  height={10}
                  alt=""
                />
              </Row>
              <Row>
                <ul
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    lineHeight: "0.8rem",
                    padding: "10px 15px 0",
                  }}
                >
                  <li className="li-indicator">
                    <TextGeneric semi_bold size={0.6} color="#000000">
                      &nbsp;&nbsp;&nbsp; Operaciones y contratos
                    </TextGeneric>
                  </li>
                  <li className="li-indicator">
                    <TextGeneric semi_bold size={0.6} color="#000000">
                      &nbsp;&nbsp;&nbsp; Diferencia tipo de cambio por cobrar
                    </TextGeneric>
                  </li>
                  <li className="li-indicator">
                    <TextGeneric semi_bold size={0.6} color="#000000">
                      &nbsp;&nbsp;&nbsp; Status de pago
                    </TextGeneric>
                  </li>
                  <li className="li-indicator">
                    <TextGeneric semi_bold size={0.6} color="#000000">
                      &nbsp;&nbsp;&nbsp; Mucho más
                    </TextGeneric>
                  </li>
                </ul>
              </Row>
            </Col>
          </Row>
          <Row>
            {/* <Row> */}
            <div
              style={{
                marginRight: "10px",
                marginLeft: "10px",
                // padding: 0,
                // position: "relative",
                // width: "100%",
                // height: "100%",
              }}
            >
              <img
                style={
                  {
                    // position: "absolute",
                    // top: "-110px",
                  }
                }
                width="100%"
                // height="430px"
                src={ListaWizard2}
                alt=""
              />
            </div>
            {/* </Row> */}
          </Row>
        </Col>
      )}
    </>
  );
}
