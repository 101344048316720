import React, { useEffect, useState } from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import RenderDropDownItem from "./RenderDropDownItem";
import useWindowDimensions from "../../helpers/useWindowDimensions";
import RenderAccordionMenu from "./RenderAccordionMenu";
import { PORTALSAPI } from "../../helpers/url";
import { api } from "../../helpers/api";

function RenderChildDropDown({
  item,
  openModal,
  user,
  setOpen,
  rut,
  setShowLoading,
}) {
  const { width } = useWindowDimensions();
  const [mobile, setMobile] = useState(width < 801);
  const [url, setUrl] = useState(item.url);
  const handlePostFunction = (url) => {
    switch (url) {
      case "PAGOS":
        return async () => {
          setShowLoading({ display: "block" });
          try {
            const res = await api.post(PORTALSAPI.POST.REDIRECTWEBPAGOS + "1");
            setShowLoading({ display: "none" });
            window.open(res.data, "_blank");
          } catch (error) {
            setShowLoading({ display: "none" });
          }
        };
      default:
        break;
    }
  };
  switch (item.type) {
    case "dropdown":
      return mobile ? (
        <RenderAccordionMenu
          item={item}
          openModal={openModal}
          user={user}
          setOpen={setOpen}
          rut={rut}
          setShowLoading={setShowLoading}
        />
      ) : (
        <NavDropdown
          title={`${item.title}`}
          id={`nav-dropdown-${item.id}`}
          drop={"down"}
          className={`${mobile ? "my-3" : ""} mx-3 ${
            item.highlight
              ? mobile
                ? "dropdown-highlight-mobile"
                : "dropdown-highlight"
              : ""
          }`}
        >
          {item.submenu.map((child, i) => {
            return (
              <RenderDropDownItem
                item={child}
                openModal={openModal}
                user={user}
                mobile={false}
                rut={rut}
                setShowLoading={setShowLoading}
                key={`item-${i}`}
              />
            );
          })}
        </NavDropdown>
      );
    case "modal":
      return mobile ? (
        <div className={`${item.highlight ? "dropdown-highlight-mobile" : ""}`}>
          <Nav.Item
            onClick={() =>
              openModal({ title: item.modal, data: user.idcontacto })
            }
            className={`d-flex align-items-center menu-mobile`}
            style={{ cursor: "pointer" }}
          >
            <span>{item.title}</span>
          </Nav.Item>
        </div>
      ) : (
        <Nav.Item
          onClick={() =>
            openModal({ title: item.modal, data: user.idcontacto })
          }
          className={`d-flex align-items-center mx-3 px-3 ${
            item.highlight ? "dropdown-highlight" : ""
          }`}
          style={{ cursor: "pointer" }}
        >
          <span>{item.title}</span>
        </Nav.Item>
      );
    case "link":
      return mobile ? (
        <div className={`${item.highlight ? "dropdown-highlight-mobile" : ""}`}>
          <Nav.Item className={`menu-mobile`}>
            <Nav.Link
              eventKey={item.id}
              target={`${url.includes("http") ? "_blank" : ""}`}
              href={`${
                url.includes("http")
                  ? url
                  : "javascript:window.scrollTo({ top : document.getElementById('" +
                    url +
                    "')?.offsetTop - " +
                    (mobile ? "100" : "124") +
                    ", behavior : 'smooth'});"
              }`}
              onClick={() => {
                setOpen(false);
              }}
            >
              {item.title}
            </Nav.Link>
          </Nav.Item>
        </div>
      ) : (
        <Nav.Item
          className={`mx-3 ${item.highlight ? "dropdown-highlight" : ""}`}
        >
          <Nav.Link
            eventKey={item.id}
            target={`${url.includes("http") ? "_blank" : ""}`}
            href={url}
          >
            {item.title}
          </Nav.Link>
        </Nav.Item>
      );
    case "post":
      return mobile ? (
        <div className={`${item.highlight ? "dropdown-highlight-mobile" : ""}`}>
          <Nav.Item
            onClick={handlePostFunction(item.url)}
            className={`d-flex align-items-center menu-mobile`}
            style={{ cursor: "pointer" }}
          >
            <span>{item.title}</span>
          </Nav.Item>
        </div>
      ) : (
        <Nav.Item
          onClick={handlePostFunction(item.url)}
          className={`d-flex align-items-center mx-3 px-3 ${
            item.highlight ? "dropdown-highlight" : ""
          }`}
          style={{ cursor: "pointer" }}
        >
          <span>{item.title}</span>
        </Nav.Item>
      );
    default:
      return <></>;
  }
}

export default RenderChildDropDown;
