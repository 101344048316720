import React, { useEffect } from "react";
import Footer from "../../components/footer/Footer";
import HeaderClientes from "../../components/header-clientes/HeaderClientes";
import SeccionClientes from "../../components/seccion-clientes/seccion-clientes";
import SeccionClientes2 from "../../components/seccion-clientes2/seccion-clientes2";
import SeccionClientes3 from "../../components/seccion-clientes3/seccion-clientes3";
import ClientePendiente from "../../components/clientes/ClientePendiente";

const ClientesConfirmacion = () => {
  useEffect(() => { }, []);
  return (
    <>
      <HeaderClientes />
      <SeccionClientes>
        <ClientePendiente></ClientePendiente>
      </SeccionClientes>
      <SeccionClientes2 />
      <SeccionClientes3 />

      <Footer margin={false} />
    </>
  );
};
export default ClientesConfirmacion;
