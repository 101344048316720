// Librerias
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// Helpers - Hooks
import useWindowDimensions from "../../../helpers/useWindowDimensions";

// Componentes - Assets
import { Modal } from "react-bootstrap";
import TablaFacturas from "../../autoatencion/TablaFacturas";
import { TextColored } from "../../typography";
import { AiOutlineEye } from "react-icons/ai";
import CustomButton from "../../buttons/Button";

const ModalCesionRechazada = ({ show }) => {
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const { width } = useWindowDimensions();

  return (
    <Modal
      show={show}
      dialogClassName={width <= 800 ? "modal-100w" : "modal-60w"}
      centered={true}
    >
      <Modal.Header className="failed-header">
        <div className="d-flex flex-column w-100">
          <TextColored size={2} weight={600} type="primary">
            Cesión rechazada
          </TextColored>
          <div>
            <TextColored
              size={1}
              weight={400}
              type="light"
              moreStyles={{ paddingRight: "5px" }}
            >
              Para ver más detalles acerca del rechazo de cada factura, puedes
              pulsar el botón
            </TextColored>
            <AiOutlineEye size={25} color="#f7cf46" />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column" style={{ gap: "1rem" }}>
        <div
          style={{
            minHeight:
              document.getElementById("tabla-facturas")?.clientHeight ??
              0 + 130 + "px",
            border: "1px solid #cfcece66",
          }}
        >
          <TablaFacturas
            type="facturas-rechazadas"
            rows={rows}
            setRows={setRows}
            loading={loading}
            setLoading={setLoading}
          />
        </div>
        <CustomButton
          children="Finalizar"
          onClick={() => {
            history.replace("/");
            history.push("/dashboard");
          }}
          disabled={loading}
          style={{ maxWidth: "30%", alignSelf: "center" }}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ModalCesionRechazada;
