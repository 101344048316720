import { validateModeloCar, validatePatenteCar} from "../helpers/validators";
import IconHelp from '../assets/icons/forms/info-circle.svg';
import moment from "moment";
const dateSortSolicitud = (rowA, rowB) => {
    const a = moment(rowA.fecha_crea_soli,"DD/MM/YYYY").toDate();
    const b = moment(rowB.fecha_crea_soli,"DD/MM/YYYY").toDate();
    if (a > b) {
        return -1;
    }

    if (b > a) {
        return 1;
    }

    return 0;
};
const stateSortSolicitud = (rowA, rowB) => {
    const a = rowA.estado_descripcion.props.children;
    const b = rowB.estado_descripcion.props.children;
    if (a > b) {
        return -1;
    }

    if (b > a) {
        return 1;
    }

    return 0;
};

export const columnasFactoring = [
	{
		name: 'Nº Op.',
		selector: row=>row.numero,//to v8 row=>row.numero
		style: { justifyContent: 'center' },
	},
	{
		name: 'Operación',
		selector: row=>row.estado,
		style: { justifyContent: 'center' },
	},
	// {
	// 	name: 'Estado de cartera',
	// 	selector: 'estado_cartera',
	// 	style: { justifyContent: 'center' },
	// },
	{
		name: 'Fecha operación',
		selector: row=>row.fecha,
		wrap: true,
		maxWidth: '190px',
		minWidth: '190px',
		style: { justifyContent: 'center' },
	},
	{
		name: 'Monto de Operación',
		selector: row=>row.monto,
		wrap: true,
		maxWidth: '250px',
		minWidth: '160px',
		style: { justifyContent: 'center' },
	},
	{
		name: 'Monto Financiado',
		selector: row=>row.financiado,
		maxWidth: '250px',
		minWidth: '160px',
		wrap: true,
		style: { justifyContent: 'center' },
	},
	// {
	// 	name: 'Excedente ',
	// 	selector: 'excedente',
	// 	wrap: true,
	// 	style: { justifyContent: 'center' },
	// },
	// {
	// 	name: 'Cuentas por cobrar',
	// 	selector: 'cxc',
	// 	wrap: true,
	// 	style: { justifyContent: 'center' },
	// },
	// {
	// 	name: 'Cobranza',
	// 	selector: 'cobranza',
	// 	style: { justifyContent: 'center' },
	// 	maxWidth: '250px',
	// 	minWidth: '160px',
	// },
	{
		name: 'Liquidación',
		selector: row=>row.liquidacion,
		style: { justifyContent: 'center' },
		maxWidth: '250px',
		minWidth: '160px',
	},
	{
		name: 'Detalle Operación',
		selector: row=>row.detalle,
		style: { justifyContent: 'center' },
		maxWidth: '250px',
		minWidth: '160px',
	},
];

export const columnasLeasing = [
	{
		name: 'Nº ',
		selector: row=>row.numero,
		style: { justifyContent: 'center' },
		maxWidth : '75px',
		minWidth : '60px',
		compact : true
	},
	{
		name: <span style={{ whiteSpace : "normal"}}>Estado de Operación</span>,
		selector: row=>row.estado,
		style: { justifyContent: 'center' },
		compact : true,
		maxWidth : '200px',
		minWidth : '80px',
	},
	{
		name: 'Bienes ',
		selector: row=>row.bienes,
		style: { justifyContent: 'center' },
		maxWidth : '200px',
		minWidth : '80px',
		compact : true
	},
	{
		name: <span style={{ whiteSpace : "normal"}}>Monto Total Financiado</span>,
		selector: row=>row.financiado,
		maxWidth: '150px',
		minWidth: '95px',
		style: { justifyContent: 'center' },
		compact : true
	},
	{
		name: <span style={{ whiteSpace : "normal"}}>Fecha de Contrato</span>,
		selector: row=>row.fecha,
		style: { justifyContent: 'center' },
		maxWidth : '150px',
		minWidth : '80px',
		compact : true
	},
	{
		name: <span style={{ whiteSpace : "normal"}}>Diferencia tipo cambio por cobrar ($,IVA inc.)</span>,
		maxWidth: '150px',
		minWidth: '80px',
		selector: row=>row.diferencia_tipo_cambio,
		style: { justifyContent: 'center' },
		compact : true
	},
	{
		name: <span style={{ whiteSpace : "normal"}}>Venc. próxima cuota</span>,
		selector: row=>row.vencimiento,
		style: { justifyContent: 'center' },
		compact : true,
		maxWidth : '150px',
		minWidth : '80px',
	},
	{
		name: <span style={{ whiteSpace : "normal"}}>Cuotas<br></br>Pagadas</span>,
		selector: row=>row.cuotas_pagadas,
		style: { justifyContent: 'center' },
		maxWidth : '130px',
		minWidth : '60px',
		compact : true
	},
	{
		name: <span style={{ whiteSpace : "normal"}}>Cuotas<br></br>Morosas</span>,
		selector: row=>row.cuotas_morosas,
		style: { justifyContent: 'center' },
		maxWidth : '130px',
		minWidth : '60px',
		compact : true
	},
	{
		name: <span style={{ whiteSpace : "normal"}}>Cuota/
		Pagos</span>,
		selector: row=>row.cuotapago,
		style: { justifyContent: 'center' },
		maxWidth : '100px',
		minWidth:'40px',
		compact : true
	},
	{
		name: 'Archivos',
		selector: row=>row.archivos,
		style: { justifyContent: 'center' },
		maxWidth : '100px',
		minWidth:'80px',
		compact : true
	},
];

export const columnasGarantias = [
	{
		name: 'Bien',
		selector: row=>row.bien,
		style: { justifyContent: 'center' },
	},
	{
		name: 'RNVM',
		selector: row=>row.rnvm,
		style: { justifyContent: 'center' },
	},
	{
		name: 'Año',
		selector: row=>row.anio,
		style: { justifyContent: 'center' },
	},
];

export const columnasAvales = [
	{
		name: 'Nombre persona',
		selector: row=>row.nombre_aval,
		style: { justifyContent: 'center' },
	},
	{
		name: 'Rut',
		selector: row=>row.rut_aval,
		style: { justifyContent: 'center' },
	},
];

export const columnasDownloadFacturas = [
	{
		name: 'Nº Documento ',
		selector: row=>row.numero,
		style: { justifyContent: 'center' },
	},
	{
		name: 'Fecha Emisión ',
		selector: row=>row.fecha,
		style: { justifyContent: 'center' },
	},
	{
		name: 'Monto ',
		selector: row=>row.monto,
		style: { justifyContent: 'center' },
	},
	{
		name: 'Detalle ',
		selector: row=>row.detalle,
		style: { justifyContent: 'center' },
	},
	{
		name: 'Servicio ',
		selector: row=>row.servicio,
		style: { justifyContent: 'center' },
	},
	{
		name: 'Descarga Doc.',
		selector: row=>row.acciones,
		style: { justifyContent: 'center' },
	},
];
export const columnasExcedentes = [
	{
		name: 'Nº Factura ',
		selector: row=>row.factura,
		style: { justifyContent: 'center' },
	},
	{
		name: 'Monto Factura ',
		selector: row=>row.monto,
		style: { justifyContent: 'center' },
	},
	{
		name: 'Monto Excedentes',
		selector: row=>row.excedentes,
		style: { justifyContent: 'center' },
	},
	{
		name: 'Estado Liberación de Excedentes ',
		selector: row=>row.estado_liberacion,
		style: { justifyContent: 'center' },
	},
	{
		name: 'Observaciones ',
		selector: row=>row.observaciones,
		style: { justifyContent: 'center' },
	}
];
export const columnasCobranza = [
	{
		name: 'Rut Deudor ',
		selector: row=>row.rut,
		maxWidth: '250px',
		minWidth: '200px',
		style: { justifyContent: 'center' },
	},
	{
		name: 'Deudor ',
		selector: row=>row.deudor,
		wrap: true,
		maxWidth: '250px',
		minWidth: '200px',
		style: { justifyContent: 'center' },
	},
	{
		name: 'Tipo Documento ',
		selector: row=>row.tipo_documento,
		style: { justifyContent: 'center' },
	},
	{
		name: 'Nro Documento ',
		selector: row=>row.documento,
		style: { justifyContent: 'center' },
	},
	{
		name: 'Vencimiento ',
		selector: row=>row.vencimiento,
		wrap: true,
		maxWidth: '250px',
		minWidth: '200px',
		style: { justifyContent: 'center' },
	},
	{
		name: 'Monto ',
		selector: row=>row.monto,
		wrap: true,
		style: { justifyContent: 'center' },
	},
	{
		name: 'Anticipo ',
		selector: row=>row.anticipo,
		wrap: true,
		style: { justifyContent: 'center' },
	},
	{
		name: 'Saldo',
		selector: row=>row.saldo,
		wrap: true,
		style: { justifyContent: 'center' },
	},
	{
		name: 'Estado cartera',
		selector: row=>row.estado_cartera,
		style: { justifyContent: 'center' },
	},
	{
		name: 'Estado cobranza',
		wrap: true,
		maxWidth: '250px',
		minWidth: '200px',
		selector: row=>row.estado_cobranza,
		style: { justifyContent: 'center' },
	},
	{
		name: 'Detalle',
		selector: row=>row.detalle,
		style: { justifyContent: 'center' },
		maxWidth: '250px',
		minWidth: '160px',
	},
];
export const columnasGestionCobranza = [
	{
		name: 'Fecha Gestión ',
		selector: row=>row.fecha_gestion,
		maxWidth: '150px',
		minWidth: '150px',
		style: { justifyContent: 'center' },
	},
	{
		name: 'Comentario Gestión',
		wrap: true,
		selector: row=>row.comentario_gestion,
		style: { justifyContent: 'center' },
	},
	{
		name: 'Comentarios',
		selector: row=>row.comentarios,
		maxWidth: '200px',
		minWidth: '200px',
		style: { justifyContent: 'center' },
	},
];
export const columnasDetalleOperacion = [
	{
		name: 'Tipo Documento',
		wrap: true,
		selector: row=>row.tipo,
		style: { justifyContent: 'center' },
	},
	{
		name: 'Nro. Documento ',
		selector: row=>row.numero,
		style: { justifyContent: 'center' },
	},
	{
		name: 'Rut Deudor ',
		selector: row=>row.rut,
		style: { justifyContent: 'center' },
	},
	{
		name: 'Deudor ',
		selector: row=>row.deudor,
		style: { justifyContent: 'center' },
		wrap: true,
		maxWidth: '100px',
		minWidth: '100px',
	},
	{
		name: 'Monto ',
		selector: row=>row.monto,
		wrap: true,
		style: { justifyContent: 'center' },
	},
	{
		name: 'Fecha Emisión ',
		selector: row=>row.fecha,
		style: { justifyContent: 'center' },
	},
	{
		name: 'Fecha Vencimiento ',
		selector: row=>row.vencimiento,
		style: { justifyContent: 'center' },
	},
	{
		name: 'Documento válido ante SII',
		selector: row=>row.validez,
		style: { justifyContent: 'center' },
	},
	{
		name: 'Documento Cedido a Progreso',
		selector: row=>row.cedido,
		style: { justifyContent: 'center' },
	},
];

export const columnasCuotaPagos = [
	{
		name: 'Nº Cuota ',
		selector: row=>row.total,
		style: { justifyContent: 'center' },
	},
	{
		name: 'Valor Cuota',
		selector: row=>row.valor,
		style: { justifyContent: 'center' },
	},
	{
		name: 'Fecha Vencimiento ',
		selector: row=>row.fecha_vencimiento,
		style: { justifyContent: 'center' },
	},
	{
		name: 'Fecha de Pago ',
		selector: row=>row.fecha_pago,
		style: { justifyContent: 'center' },
	},
	{
		name: 'Estado de pago ',
		selector: row=>row.estado,
		style: { justifyContent: 'center' },
	},
	{
		name: 'Nº Factura',
		selector: row=>row.factura,
		style: { justifyContent: 'center' },
	}
];
export const columnasArchivosDocumentos = [
	{
		name: 'Tipo de Documento',
		selector: row=>row.tipodocumento,
		style: { justifyContent: 'center' },
	},
	{
		name: 'Archivos',
		selector: row=>row.documento,
		style: { justifyContent: 'center' },
		
	},
	{	name: 'Fecha Creación ',
		selector: row=>row.fecha_creacion,
		style: { justifyContent: 'center' },
	}
];
export const columnasSolicitudes = [
	{
		name: 'N°',
		selector: row=>row.numero,
		style: { justifyContent: 'center' },
		maxWidth : "150px",
		sortable : true
	},
	{
		name: 'Tipo de Solicitud',
		selector: row=>row.tiposolicitud,
		style: { justifyContent: 'center' },
		//maxWidth : "150px",
		sortable : true
	},
	{
		name: 'Fecha',
		selector: row=>row.fecha_crea_soli,
		style: { justifyContent: 'center' },
		//maxWidth : "130px",
		sortable : true,
		sortFunction : dateSortSolicitud
	},
	{
		name: 'Estado',
		selector: row=>row.estado_descripcion,
		style: { justifyContent: 'center' },
		//maxWidth : "130px",
		minWidth : "100px",
		sortable : true,
		sortFunction : stateSortSolicitud

	},
	{
		name: 'Documento/Archivo',
		selector: row=>row.documento,
		style: { justifyContent: 'center' },
	},
];

export const paisesCertificadoInternacional = [
		{ value : "Perú", label :"Perú"}, 
		{ value : "Argentina", label :"Argentina"}, 
		{ value : "Paraguay", label :"Paraguay"}, 
		{ value : "Uruguay", label :"Uruguay"}, 
		{ value : "Brasil", label :"Brasil"}, 
];
export const tipoSolicitudes = [
	{
		name : "Certificado de Permisos Internacional",
		id : 1,
		formulario : [
			{
				label : "País de destino:",
				name : "paises",
				controlId : "permisoPaises",
				type_as : "select",
				required : true,
				select_options : "paises",
				select_isMulti : true,
				select_closeMenuOnSelect : false,
				select_captureMenuScroll : false,
				orden : 1
			},
			{
				label : "Patente:",
				name : "placa",
				controlId : "permisoPatente",
				type : "text",
				max : 150,
				placeholder : "Ingrese la patente.",
				//onkeydown : validateModeloCar,
				required : true,
				helpText:"Se sugiere indicar dicho dato con la finalidad de gestionar de manera correcta su requerimiento",
				orden : 2
			},
			{
				label : "Marca:",
				name : "marca",
				controlId : "permisoMarca",
				type : "text",
				required : false,
				max : 150,
				placeholder : "Ingrese la marca.",
				//onkeydown : validateModeloCar
				orden : 3
			},
			{
				label : "Modelo:",
				name : "modelo",
				controlId : "permisoModelo",
				type : "text",
				required : false,
				max : 150,
				placeholder : "Ingrese el modelo.",
				//onkeydown : validateModeloCar
				orden : 4
			},
			{
				label : "Correo de Contacto:",
				name : "correo",
				controlId : "permisoCorreo",
				type : "text",
				defaultValue : "correoContacto",
				required : true,
				max : 150,
				placeholder : "Ingrese el correo.",
				orden : 5
			},
			{
				label : "Fecha Inicio:",
				name : "fecha_inicio",
				controlId : "permisoFechaInicio",
				type : "date",
				required : true,
				placeholder : "Ingrese la fecha de inicio.",
				min : "now",
				max :"fecha_fin",
				onkeydown :"preventDefault",
				orden : 6
		
			},
			{
				label : "Fecha Fin:",
				name : "fecha_fin",
				controlId : "permisoFechaFin",
				type : "date",
				required : true,
				placeholder : "Ingrese la fecha de fin.",
				min : "fecha_inicio",
				onkeydown :"preventDefault",
				orden : 7
			},
		]
	},
	{
		name : "Certificado de Cobertura de Seguros",
		id : 2,
		formulario : [
			{
				label : "Comentario:",
				name : "comentario",
				controlId : "seguroComentario",
				type_as : "textarea",
				required : false,
				max : 1000,
				placeholder : "Ingrese un comentario",
				rows : 3,
				orden : 1
			},
			{
				label : "Correo de Contacto:",
				name : "correo",
				controlId : "seguroCorreo",
				type : "text",
				defaultValue : "correoContacto",
				required : true,
				max : 150,
				placeholder : "Ingrese el correo.",
				orden : 2
			}
		]
	},
	{
		name : "Certificado de Tarjeta Estudiantil",
		id : 3,
		formulario :[
			{
				label : "Nombre de la persona que realiza el tramite:",
				name : "nombre",
				controlId : "estudiantilNombre",
				type : "text",
				required : true,
				max : 30,
				placeholder : "Ingrese el nombre.",
				orden : 1
		
			},
			{
				label : "Correo de Contacto:",
				name : "correo",
				controlId : "estudiantilCorreo",
				type : "text",
				defaultValue : "correoContacto",
				required : true,
				max : 150,
				placeholder : "Ingrese el correo.",
				orden : 2
			},
			{
				label : "Rut de la persona que realiza el tramite:",
				name : "rut",
				controlId : "estudiantilRut",
				type : "text",
				required : true,
				maxLength : 12,
				placeholder : "Ingrese el rut",
				onkeydown : "rut",
				orden : 3
			}
		]
	},
	{
		name : "Certificado de Tarjeta de Combustible",
		id : 4,
		formulario : [
			{
				label : "Tipo Vehiculo:",
				name : "tipo_vehiculos",
				controlId : "combustibleTipoVehiculo",
				type_as : "select",
				required : false,
				select_options : "tipo_vehiculos",
				select_isMulti : false,
				select_closeMenuOnSelect : true,
				select_captureMenuScroll : false,
				orden : 1
			},
			{
				label : "Marca:",
				name : "marca",
				controlId : "combustibleMarca",
				type : "text",
				required : false,
				placeholder : "Ingrese la marca.",
				max : 150,
				orden : 2
				//onkeydown : validateModeloCar
		
			},
			{
				label : "Modelo:",
				name : "modelo",
				controlId : "combustibleModelo",
				type : "text",
				required : false,
				placeholder : "Ingrese el modelo.",
				max : 150,
				orden : 3
				//onkeydown : validateModeloCar
		
			},
			{
				label : "Año:",
				name : "anio",
				controlId : "combustibleAnio",
				type : "number",
				required : false,
				placeholder : "Ingrese el año.",
				onkeydown : "limit4",
				onpaste : false,
				orden : 4
			},
			{
				label : "Motor:",
				name : "motor",
				controlId : "combustibleMotor",
				type : "text",
				required : false,
				placeholder : "Ingrese el motor.",
				max : 150,
				orden : 5
				//onkeydown : validatePatenteCar
		
			},
			{
				label : "Chasis:",
				name : "chasis",
				controlId : "combustibleChasis",
				type : "text",
				required : false,
				placeholder : "Ingrese el chasis.",
				max : 150,
				orden : 6
				//onkeydown : validatePatenteCar
		
			},
			{
				label : "Patente:",
				name : "patente",
				controlId : "combustiblePatente",
				type : "text",
				required : false,
				placeholder : "Ingrese la patente.",
				max : 150,
				//onkeydown : validateModeloCar,
				helpText:"Se sugiere indicar dicho dato con la finalidad de gestionar de manera correcta su requerimiento",
				orden : 7

		
			},
			{
				label : "Correo de Contacto:",
				name : "correo",
				controlId : "combustibleCorreo",
				type : "text",
				defaultValue : "correoContacto",
				required : true,
				max : 150,
				placeholder : "Ingrese el correo.",
				orden : 8
			}
		]
	},
	{
		name : "Certificado de Alzamiento de Prenda",
		id : 5,
		formulario:[
			{
				label : "Comentario:",
				name : "comentario",
				controlId : "prendaComentario",
				type_as : "textarea",
				required : false,
				max : 1000,
				placeholder : "Ingrese un comentario",
				rows : 3,
				orden : 1
			},
			{
				label : "Patente:",
				name : "prenda_patente",
				controlId : "prendaPatente",
				type : "text",
				required : false,
				max : 150,
				placeholder : "Ingrese la patente.",
				//onkeydown : validateModeloCar,
				helpText:"Se sugiere indicar dicho dato con la finalidad de gestionar de manera correcta su requerimiento",
				orden : 2
			},
			{
				label : "Marca:",
				name : "prenda_marca",
				controlId : "prendaMarca",
				type : "text",
				required : false,
				max : 150,
				placeholder : "Ingrese la marca.",
				//onkeydown : validateModeloCar
				orden : 3
		
			},
			{
				label : "Modelo:",
				name : "prenda_modelo",
				controlId : "prendaModelo",
				type : "text",
				required : false,
				max : 150,
				placeholder : "Ingrese el modelo.",
				//onkeydown : validateModeloCar
				orden : 4
		
			},
			{
				label : "Correo de Contacto:",
				name : "correo",
				controlId : "prendaCorreo",
				type : "text",
				defaultValue : "correoContacto",
				required : true,
				max : 150,
				placeholder : "Ingrese el correo.",
				orden : 5
			}
		]
	},
	{
		name : "Certificado Otros",
		id : 6,
		formulario:[
			{
				label : "Comentario:",
				name : "comentario",
				controlId : "otrosComentario",
				type_as : "textarea",
				required : false,
				max : 1000,
				placeholder : "Ingrese un comentario",
				rows : 3,
				orden : 1
			},
			{
				label : "Correo de Contacto:",
				name : "correo",
				controlId : "otrosCorreo",
				type : "text",
				defaultValue : "correoContacto",
				required : true,
				max : 150,
				placeholder : "Ingrese el correo.",
				orden : 2
			}
		]
	},
	]
