import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Esquina1 from "../../assets/images/esquina1.png";
import { TextGeneric } from "../../components/typography";

function Notificaciones({ notificaciones, openTopModal }) {
  const userModal = {
    padding: "10px",
    borderBottom: "1px solid #dee2e6",
  };

  const goToUrl = (url) => {
    window.open(url, "_blank");
  };

  const goTo = (notificacion) => {
    if (notificacion.tipo === 2) {
      switch (notificacion.enlace) {
        case "modal_sii":
          return openTopModal("datos_SII");
        default:
          break;
      }
    } else if (notificacion.tipo === 1) {
      goToUrl(notificacion.enlace);
    }
  };
  return (
    <>
      <Container style={userModal}>
        {notificaciones.map((notificacion) => {
          return (
            <>
              <Row
                className="justify-content-center"
                onClick={() => goTo(notificacion)}
                style={{
                  cursor: "pointer",
                }}
              >
                <Col>
                  <Row>
                    <ul>
                      <li style={{ color: "#fdd135" }}>
                        <Row style={{ marginLeft: "20px" }}>
                          <TextGeneric>
                            <b> {notificacion.titulo}</b>
                          </TextGeneric>
                        </Row>
                        <Row style={{ marginLeft: "20px" }}>
                          <TextGeneric>{notificacion.descripcion}</TextGeneric>
                        </Row>
                      </li>
                    </ul>
                  </Row>
                </Col>
                <Col lg={2}>
                  <img
                    style={{
                      height: "10px",
                      transform: "rotate(40deg)",
                    }}
                    alt=""
                    src={Esquina1}
                  />
                </Col>
              </Row>
              <hr />
            </>
          );
        })}
      </Container>
    </>
  );
}
export default Notificaciones;
