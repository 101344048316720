import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 350px;
  background: white;
  position: fixed;
  top: 0;
  right: 0;
  margin-right: ${({ show }) => (show ? "0" : "-351px")};
  transition: 0.5s margin-right;
  z-index: 100;

  -webkit-box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000,
    5px 5px 6px -13px rgba(153, 153, 153, 0);
  box-shadow: -10px 0px 13px -7px #00000085, 10px 0px 13px -7px #000000,
    5px 5px 6px -13px rgb(153 153 153 / 0%);

  .closeButton {
    position: absolute;
    color: grey;
    border: none;
    background: none;
    padding: 0px 7px 3px 7px;
    border-radius: 100px;
    font-size: 22px;
    :hover {
      color: black;
    }
    :focus {
      border: none;
      box-shadow: none !important;
      background-color: transparent !important;
    }
  }

  .header {
    margin-top: 3%;
    text-align: center;
    border-bottom: 3px solid #f7cf46;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  margin-top: 5%;
  width: fit-content;

  /* .type-selector {
    background: black;
    border-color: grey;
    width: 125px;
    height: 45px;
    box-shadow: none;
    :hover {
      background: #fcd44f;
      border: #fcd44f;
      color: black;
    }
    :disabled {
      background: #fcd44f;
      border: #fcd44f;
      color: black;
      font-weight: bolder;
    }
  } */
`;

export const FilterType = styled.div`
  display: ${({ type, showType }) => (showType === type ? "flex" : "none")};
  flex-direction: column;
  > label {
    font-size: 1.2rem;
  }
  > input[type="date"] {
    border: 1px solid #f7cf46;
    height: 35px;
    padding: 5px;
    width: 250px;
    transition: 0.5s border;
    outline: none !important;
    :focus {
      border-color: black;
    }
  }
`;
