import React from "react";
import { propTypes } from "react-bootstrap/esm/Image";

function Input({
  icon,
  value,
  type,
  side,
  placeholder,
  name,
  onChange,
  error = false,
  containerClass = "input-container",
  ref = {},
  ...otherProps
}) {
  const container = {
    borderBottom: side === "top" ? "1px solid #b9b5b5" : "",
  };
  return (
    <div style={container} className={containerClass}>
      {icon}
      <input
        value={value}
        onChange={onChange}
        className={error === true ? 'input-field-error mb-2' : `input-field mb-2`}
        type={type}
        placeholder={placeholder}
        name={name}
        {...otherProps}
      />
    </div>
  );
}

propTypes.InputLogin = {
  icon: propTypes.node,
  type: propTypes.string,
  sided: propTypes.string,
  placeholder: propTypes.string,
  name: propTypes.string,
  value: propTypes.string,
  onChange: propTypes.func,
};
export default Input;
