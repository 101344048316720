import React, { useEffect, useState } from "react";
import { Fade, Form, InputGroup, Modal, Row } from "react-bootstrap";
import {
  AiFillCloseCircle,
  AiFillEye,
  AiFillEyeInvisible,
  AiOutlineUserAdd,
} from "react-icons/ai";
import CustomButton from "../../buttons/Button";
import { validateEmail } from "../../../helpers/validators";
import { TextColored } from "../../typography";
import { api } from "../../../helpers/api";
import { AUTOATENCIONAPI } from "../../../helpers/url";
import { fireAlert } from "../../../helpers/autoAtencionHelpers";
import Exito from "../../../assets/images/exito2.png";

import "./styles.css";
import IconButton from "../../buttons/IconButton";

  //Texto de error, se usa el useEffect para manejar la animacion del texto
  const ErrorText = ({ name, error }) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
      if (error[name]) {
        setShow(true);
      } else {
        setShow(false);
      }
    }, [name, error]);

    return (
      <Fade in={show}>
        <Form.Text muted>{error[name]}</Form.Text>
      </Fade>
    );
  };

const ModalActualizarDatos = ({
  show,
  setShow,
  dbValues = null,
  // Si se busca realizar una accion luego de la actualizacion se envia la funcion por next
  next = null,
}) => {
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  // Formulario, es importante que si se busca precargar datos dbValue debe tener el mismo formato
  const [form, setForm] = useState({
    nombres: "",
    celular: "",
    correo: "",
    direccion: "",
    siipassword: "",
    region: "",
    idcliente: 0,
    idcontacto: 0,
  });
  const { nombres, celular, correo, direccion, siipassword } = form;
  
  const formIsCompleted =
    nombres.length > 0 &&
    celular.toString().length === 9 &&
    validateEmail(correo) &&
    direccion.length > 0 &&
    siipassword.length > 0;

  // Control de input password
  const [showPassword, setShowPassword] = useState("password");

  // Control de errores y formulario
  const [error, setErrors] = useState({
    nombres: false,
    correo: false,
    celular: false,
  });

  const isNoErrors = !error.nombres && !error.correo && !error.celular;

  const errorChecker = (value, name) => {
    switch (name) {
      case "nombres": {
        setErrors((e) => {
          if (value.length > 0) {
            if (
              !/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u.test(
                value
              )
            ) {
              return { ...e, [name]: "Introduzca un nombre valido." };
            }
          }
          return { ...e, [name]: null };
        });
        break;
      }
      case "correo": {
        setErrors((e) => {
          if (value.length > 0) {
            if (!validateEmail(value)) {
              return { ...e, [name]: "Introduzca un e-mail valido." };
            }
          }
          return { ...e, [name]: null };
        });
        break;
      }
      case "celular": {
        setErrors((e) => {
          if (value.toString().length > 9) {
            return {
              ...e,
              [name]: "El numero no debe contener mas de 9 caracteres",
            };
          }
          return { ...e, [name]: false };
        });
        break;
      }
      default:
        break;
    }
  };

  const handleChange = (e) => {
    const {
      target: { value, name },
    } = e;
    errorChecker(value, name);
    setForm((f) => ({ ...f, [name]: value }));
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const payload = form;
      const strPhone = celular.toString();
      payload["celular"] = `+56 ${strPhone.substring(
        0,
        1
      )} ${strPhone.substring(1)}`;

      const { status } = await api.put(
        AUTOATENCIONAPI.PUT.ACTUALIZAR_DATOS,
        payload
      );
      setLoading(false);
      if (status === 200) {
        return setCompleted(true);
      }
      return await fireAlert({
        title: "¡Ha habido un error al actualizar sus datos!",
        icon: "error",
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleFinish = () => {
    setShow(false);
    return next ? next() : true;
  };

  // Si se envia data se actualiza el estado
  useEffect(() => {
    if (dbValues) {
      setForm({ ...dbValues });
    }
  }, [dbValues]);

  return (
    <Modal show={show} centered>
      <Fade in={completed}>
        <Modal.Body
          className="d-flex flex-column align-items-center justify-content-between position-absolute w-100 h-100"
          style={{
            background: "white",
            paddingBottom: "10%",
            paddingTop: "20%",
            borderRadius: "0.3rem",
            zIndex: completed ? 2 : -1,
          }}
        >
          <img src={Exito} alt="confirmed" height="250px" />
          <div className="text-center" style={{ marginTop: "-12%" }}>
            <TextColored
              children="¡Datos actualizados correctamente!"
              type="dark"
              weight={600}
              size={1.5}
            />
            <br />
            <TextColored
              children="Los cambios se veran reflejados en su perfil comercial."
              weight={500}
              size={1}
            />
          </div>

          <div style={{ positionItems: "center", width: "60%" }}>
            <CustomButton
              children={
                <TextColored
                  children="Continuar"
                  weight={600}
                  size={1.1}
                  type="dark"
                />
              }
              onClick={handleFinish}
            />
          </div>
        </Modal.Body>
      </Fade>
      <Modal.Header className="d-flex flex-column">
        <Row className="flex-row-reverse w-100 ml-0">
          <IconButton
            Icon={<AiFillCloseCircle size={30}/>}
            onClick={() => setShow(false)}
          />
        </Row>
        <div className="header-modal-container">
          <div className="header-icono">
            <AiOutlineUserAdd size={40} />
          </div>
          <div className="header-titulo">
            <TextColored
              children="Completar datos"
              size={1.5}
              weight={600}
              type="dark"
            />
          </div>
          <div className="header-subtitulo">
            <TextColored
              children="Para iniciar el proceso de Autoatencion de factoring es necesario contar con tus datos completos."
              size={1}
            />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form
          className="charge-info-form"
          onChange={handleChange}
          onSubmit={handleSubmit}
        >
          <Form.Group>
            <Form.Label htmlFor="nombres">Nombre Completo:</Form.Label>
            <Form.Control
              type="text"
              name="nombres"
              isInvalid={error["nombres"]}
              value={nombres}
            />
            <ErrorText name="nombres" error={error}/>
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="direccion">Dirección:</Form.Label>
            <Form.Control type="text" name="direccion" value={direccion} />
            <ErrorText name="direccion" error={error}/>
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="celular">Telefono:</Form.Label>
            <InputGroup>
              <InputGroup.Text>+56</InputGroup.Text>
              <Form.Control
                type="number"
                name="celular"
                maxLength={9}
                value={celular}
                isInvalid={error["celular"]}
              />
            </InputGroup>
            <ErrorText name="celular" error={error}/>
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="correo">Correo:</Form.Label>
            <Form.Control
              type="text"
              name="correo"
              isInvalid={error["correo"]}
              value={correo}
            />
            <ErrorText name="correo" error={error}/>
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="siipassword">Clave SII:</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type={showPassword}
                name="siipassword"
                value={siipassword}
              />
              <button
                onClick={() =>
                  setShowPassword(showPassword === "text" ? "password" : "text")
                }
                type="button"
                style={{
                  border: "none",
                  backgroundColor: "#eceaea",
                  borderRadius: "0 0.25rem 0.25rem 0",
                }}
              >
                {showPassword === "text" ? (
                  <AiFillEye />
                ) : (
                  <AiFillEyeInvisible />
                )}
              </button>
            </InputGroup>
          </Form.Group>
          <div className="d-flex align-items-center">
            <Form.Check type="checkbox" onChange={() => setChecked(!checked)} />
            <TextColored
              children={
                <>
                  Acepto las{" "}
                  <a
                    href="https://www.progreso.cl/compania/politica-privacidad/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Politicas de privacidad.
                  </a>
                </>
              }
              type="dark"
              size={0.9}
            />
          </div>
          <br />
          <CustomButton
            type="submit"
            children={
              <TextColored
                size={1}
                type="dark"
                weight={600}
                children="Cargar datos"
              />
            }
            disabled={!formIsCompleted || !isNoErrors || !checked}
            loading={loading}
          />
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalActualizarDatos;
