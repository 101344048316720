import React from 'react';
import { Container } from 'react-bootstrap';
import useWindowDimensions from '../../helpers/useWindowDimensions';

function ClienteLoginSeccion({ children }) {

    const { width } = useWindowDimensions();

    const container = {
        width: '100%',
        height: '100%',
        padding: '20px'
    };

    const containerMobile = {
        width: '100%',
        height: width >= 375 ? '100%' : '',
        background: 'white',
        padding: '20px',
    };

    return (
        <Container className="login-clientes" id="login-body" style={width >= 701 ? container : containerMobile} fluid>
            {children}
        </Container>
    );
}

export default ClienteLoginSeccion;
