import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { TextButton } from '../typography';
import { ThemeContext } from '../../theming';

function ButtonContact({ onClick, children, size = 'md', disabled = false }) {
    const app = useContext(ThemeContext);
    const { body12 } = app.theme.font
    const button = {
        background: 'white',
        color: app.theme.textDark,
        borderColor: app.theme.primary,
        borderRadius: '7px',
        marginRight: '10px'
    };
    return (
        <Button disabled={disabled} onClick={onClick} style={button} size={size}>
            <TextButton bold size={body12} type='primary'>{children}</TextButton>
        </Button>
    );
}

export default ButtonContact;
