import React, { useContext, useEffect, useRef, useState } from "react";
import {
  mobileXSWidth,
  mobileSMWidth,
} from "../../helpers/responsiveVariables";
import { useForm } from "react-hook-form";
import { Row, Container, Col, Card, Form } from "react-bootstrap";
import { ThemeContext } from "../../theming";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";
import { DecryptData, EncryptData } from "../../helpers/encrypt";
import { AUTOATENCIONAPI, PORTALSAPI } from "../../helpers/url";
import { api } from "../../helpers/api";
import Loading from "../../components/loading/Loading";
import RegistroSII from "../../components/registro-sii/registro-sii";
import Notificaciones from "../../components/notificaciones/notificaciones";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
/* Components */
import Header from "../../components/header/Header";
import Slider from "../../components/slider/Slider";
import Footer from "../../components/footer/Footer";
import Tabla from "../../components/tabla/Tabla";
import CardDetalle from "../../components/cards/Card";
import CardContact from "../../components/cards/CardContact";
import CardNegocio from "../../components/cards/CardNegocio";
import Button from "../../components/buttons/Button";
import {
  CustomModal as Modal,
  ModalSuccess,
  ModalSuccessSolicitud,
  ModalSuccess2,
} from "../../components/modal/Modal";
import DashedItem from "../../components/list/DashedItem";
import {
  TextColored,
  Text,
  Title,
  TextCustomColored,
  CustomTextWithoutPadding,
} from "../../components/typography";
import Cloud from "../../assets/icons/cloud.svg";

import {
  columnasFactoring,
  columnasLeasing,
  columnasCobranza,
  columnasGestionCobranza,
  columnasDetalleOperacion,
  columnasCuotaPagos,
  columnasExcedentes,
  columnasArchivosDocumentos,
  columnasSolicitudes,
  tipoSolicitudes,
} from "../../constants/dashboard";

/* Modal */

/* Iconos */
import {
  Liquidacion,
  Excedente,
  Cobranza,
  Ejecutivo,
  Usuario,
  User,
  Ejecutivo1,
  Reloj,
  Confirmado,
  Error,
  Logo4,
  Bocina,
  Computadora2,
  Operacion,
  PanelControl,
  FileIcon,
  CheckGreenIcon,
} from "../../assets/icons/dashboard";
import eye from "../../assets/images/eye.png";
import eye2 from "../../assets/images/eye2.png";
import exito from "../../assets/images/exito.png";
import error from "../../assets/images/error.png";
import empty from "../../assets/images/empty.png";
import archivo from "../../assets/images/archivo.png";

import useWindowDimensions from "../../helpers/useWindowDimensions";
import { validateEmail, validatePhone } from "../../helpers/validators";
import { abrir } from "../../helpers/termino_condiciones";
import {
  fireAlert,
  generateCdlObj,
  getProveedor,
  getRut,
  sincronizarDTEs,
} from "../../helpers/autoAtencionHelpers";
import { download } from "../../helpers/functions";
import { getComertialInfo } from "../../helpers/autoAtencionHelpers";
import RenderContent2 from "../../components/renderContent/RenderContent2";
import Seccion2CDL from "../../components/dashboard-clientes/Seccion2";
import Seccion2DM from "../../components/dashboard-clientes/Seccion2_old";
import ModalSuscripcionCDL from "../../components/modal/modal-autoatencion/ModalSuscripcion";
import useStorage from "../../helpers/useStorage";

/* Config */
// const bodyHeight = 87;
let idcobranza = 0;
const textFactoring =
  "Al existir facturas en mora el sistema no puede generar excedentes";

const icons = {
  cartola:  <Liquidacion />,
  liquidacion: <Liquidacion />,
  excedente: <Excedente />,
  cobranza: <Cobranza />,
  gestion: <Cobranza />,
  detalle: <Cobranza />,
  cuota: <Excedente />,
  usuario: <Usuario />,
  ejecutivo: <Ejecutivo />,
  contrasenia: <Ejecutivo />,
  operacion: <Operacion />,
  logo4: <Logo4 />,
  computadora2: <Computadora2 />,
  bocina: <Bocina />,
  panel_control: <PanelControl />,
};

/* Styles */
const container = {
  // borderRightWidth: "50px",
  // borderRightColor: "black",
  // borderRightStyle: "solid",
  width: "100%",
  display: "flex",
  justifyContent: "center",
};

const section = {
  width: "100%",
};

// const body = {
// 	width: `${bodyHeight}%`,
// };

const userModal = {
  padding: "10px",
  borderBottom: "1px solid #dee2e6",
};
const modalInfo = {
  title: "",
  subtitle: "",
  icon: null,
  size: "xl",
  content: null,
  table: { columns: [], rows: [] },
  tables: [],
};

const Dashboard = () => {
  const app = useContext(ThemeContext);
  const { body12, body14, body25, body28, body32 } = app.theme.font;
  const history = useHistory();
  const { width } = useWindowDimensions();
  const [nombres, setNombres] = useState("");
  const [rut, setRut] = useState("");
  const [showLoading, setShowLoading] = useState({ display: "none" });
  const [menus, setMenus] = useState([]);
  const [tipoBanner, setTipoBanner] = useState(null);
  const [contactado, setContactado] = useState("");
  const [resumen_row1, setResumenRow1] = useState({});
  const [resumen_row2, setResumenRow2] = useState({});

  // Control del estado de suscriptor
  const [{ status, prevStatus }, setStatus] = useState({
    status: null,
    prevStatus: null,
  });
  const [isEditingData, setUserDashboardFlag] = useState(false);

  const handleStatusChange = (newStatus) => {
    setStatus((p) => ({
      status: newStatus,
      prevStatus: p.status || newStatus,
    }));
  };

  const [notificacion, setNotificacion] = useState(0);
  const [notificaciones, setNotificaciones] = useState([]);

  const [modalData, setModalData] = useState({});

  const [images, setImages] = useState([]);

  const [tipoVehiculos, setTipoVehiculos] = useState([]);
  const [tipoSolicitudesMain, setTipoSolicitudesMain] = useState([]);
  const [tipoSolicitudesFormulario, setTipoSolicitudesFormulario] = useState(
    []
  );
  const [show, setShow] = useState(false);
  const [instructivos, setInstructivos] = useState([]);
  const [user, setUser] = useState({});

  const [contactarEjecutivo, setContactarEjecutivo] = useState({
    idcontacto: 0,
    contactos: [],
    mensaje: "",
  });

  const { register, handleSubmit, reset } = useForm();
  const validaRut = (obj) => {
    var largo, crut, rut, dv, i, suma, mul, res, dvi;
    var tmpstr = "";
    var intlargo = obj;
    if (intlargo.length > 0) {
      crut = obj;
      largo = crut.length;
      if (largo < 2) {
        return false;
      }
      for (i = 0; i < crut.length; i++)
        if (
          crut.charAt(i) != " " &&
          crut.charAt(i) != "." &&
          crut.charAt(i) != "-"
        ) {
          tmpstr = tmpstr + crut.charAt(i);
        }
      rut = tmpstr;
      crut = tmpstr;
      largo = crut.length;
      if (largo > 2) rut = crut.substring(0, largo - 1);
      else rut = crut.charAt(0);
      dv = crut.charAt(largo - 1);
      if (rut == null || dv == null) return 0;
      var dvr = "0";
      suma = 0;
      mul = 2;
      for (i = rut.length - 1; i >= 0; i--) {
        suma = suma + rut.charAt(i) * mul;
        if (mul == 7) mul = 2;
        else mul++;
      }
      res = suma % 11;
      if (res == 1) dvr = "k";
      else if (res == 0) dvr = "0";
      else {
        dvi = 11 - res;
        dvr = dvi + "";
      }
      if (dvr != dv.toLowerCase()) {
        return false;
      }
      return true;
    }
  };
  const selecionarEjecutivo = (checked, ejecutivo) => {
    let ejecutivos_lista = contactarEjecutivo;
    let existe = ejecutivos_lista.contactos.find((x) => x.id === ejecutivo.id);
    if (existe && checked === false) {
      ejecutivos_lista.contactos = ejecutivos_lista.contactos.filter(
        (x) => x.id !== ejecutivo.id
      );
      setContactarEjecutivo(ejecutivos_lista);
      return;
    }
    ejecutivos_lista.contactos.push({ id: ejecutivo.id });
    setContactarEjecutivo(ejecutivos_lista);
  };

  const goTo = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    window.open(user.url_comentarios, "_blank");
  };

  const enviarContacto = (contactar) => {
    setShowLoading({ display: "block" });
    let user = JSON.parse(localStorage.getItem("user"));
    let publicURL = PORTALSAPI.GET.PUBLIC_KEY.replace(
      "{idcontacto}",
      user.idcontacto
    ).replace("{tipo}", user.tipo_acceso);
    api.get(publicURL).then((response) => {
      let ejecutivos_lista = {
        ...contactarEjecutivo,
        contactos: contactarEjecutivo.contactos.map((contacto) => ({
          id: parseInt(DecryptData(contacto.id, response.data)),
        })),
      };
      if (contactar.ejecutivo_unico) {
        ejecutivos_lista.contactos.push({
          id: parseInt(DecryptData(contactar.ejecutivo_unico, response.data)),
        });
      }
      //setContactarEjecutivo(ejecutivos_lista);

      if (ejecutivos_lista.contactos.length === 0) {
        setShowLoading({ display: "none" });
        setModalData({
          messageLine: false,
          title: "Contactar Ejecutivos",
          subtitle: "Error",
          message: "Debe escoger al menos 1 ejecutivo.",
          color: "red",
          icon: (
            <>
              <Error />
            </>
          ),
        });
        handleShowModalError();
        return;
      }

      let dataform = ejecutivos_lista;
      dataform.idcontacto = JSON.parse(localStorage.getItem("user")).idcontacto;
      dataform.mensaje = contactar.comentario;
      let url = PORTALSAPI.POST.CONTACTAR;
      api.post(url, dataform).then(
        (res) => {
          setShowLoading({ display: "none" });
          setModalData({
            messageLine: true,
            title: "Información Enviada",
            subtitle: "EXITOSAMENTE",
            message2: "¡Muchas Gracias!",
            color: "yellow",
            icon: (
              <>
                <Confirmado />
              </>
            ),
            message: (
              <>
                <Row>
                  <Col xs={2} className="mt-2">
                    <Reloj />
                  </Col>
                  <Col style={{ paddingLeft: "0px" }}>
                    <CustomTextWithoutPadding
                      className="text-justify"
                      bold
                      size={body12}
                      type="primary"
                    >
                      {res.message}
                    </CustomTextWithoutPadding>
                  </Col>
                </Row>
              </>
            ),
          });
          setContactarEjecutivo({
            idcontacto: 0,
            contactos: [],
            mensaje: "",
          });
          handleShowModalError();
          handleCloseTopModal();
          handleClose();
        },
        () => {
          setShowLoading({ display: "none" });
          setModalData({
            messageLine: false,
            title: "Contactar Ejecutivos",
            color: "red",
            subtitle: "Error",
            message: "No se pudo enviar su solicitud de contacto.",
            icon: (
              <>
                <Error />
              </>
            ),
          });
          handleShowModalError();
        }
      );
    });
  };

  const enviarComentario = (data) => {
    if (data.comentario.trim() === "") {
      setModalData({
        messageLine: true,
        title: "Enviar Comentario",
        subtitle: "Error",
        color: "red",
        message: "El comentario no puede estar vacío.",
        icon: (
          <>
            <Error />
          </>
        ),
      });
      handleShowModalError();
      setShowTopModal(false);
      handleShow();
      return;
    }

    let idcontacto = JSON.parse(localStorage.getItem("user")).idcontacto;
    let formComentario = {
      idcontacto: idcontacto,
      idcobranza: idcobranza,
      mensaje: data.comentario,
    };

    api.post(PORTALSAPI.POST.COMETARIOS, formComentario).then(
      () => {
        setModalData({
          messageLine: false,
          title: "Enviar Comentario",
          subtitle: "Confirmado",
          color: "yellow",
          message: "Su comentario ha sido enviado.",
          icon: (
            <>
              <Confirmado />
            </>
          ),
        });
        handleShowModalError();
        setShowTopModal(false);
        handleShow();
      },
      () => {
        setModalData({
          messageLine: false,
          title: "Enviar Comentario",
          subtitle: "Error",
          color: "red",
          message: "Su comentario no se pudo enviar.",
          icon: (
            <>
              <Error />
            </>
          ),
        });
        handleShowModalError();
        setShowTopModal(false);
        handleShow();
      }
    );
  };

  const onSubmit = (data) => {
    if (validatePhone(data.celular) === false) {
      setModalData({
        messageLine: false,
        title: "Actualización de datos",
        subtitle: "Error",
        color: "red",
        message: "Fono inválido.",
        icon: (
          <>
            <Error />
          </>
        ),
      });
      handleShowModalError();
      return;
    }

    if (validateEmail(data.correo) === false) {
      setModalData({
        messageLine: false,
        title: "Actualización de datos",
        subtitle: "Error",
        color: "red",
        message: "Email inválido.",
        icon: (
          <>
            <Error />
          </>
        ),
      });
      handleShowModalError();
      return;
    }

    if (data.terminos === false) {
      setModalData({
        messageLine: false,
        title: "Actualización de datos",
        subtitle: "Error",
        color: "red",
        message:
          "Debe aceptar los términos y condiciones para poder actualizar sus datos.",
        icon: (
          <>
            <Error />
          </>
        ),
      });
      handleShowModalError();
      return;
    }

    let url = PORTALSAPI.PUT.ACTUALIZAR_DATOS;
    data.idcontacto = JSON.parse(localStorage.getItem("user")).idcontacto;
    data.idcliente = JSON.parse(localStorage.getItem("user")).idcliente;
    api.put(url, data).then(
      () => {
        const user = JSON.parse(localStorage.getItem("user"));
        let public_key = JSON.parse(sessionStorage.getItem("temporal"));
        user.nombres = EncryptData(data.nombres, public_key);
        localStorage.setItem("user", JSON.stringify(user));
        setNombres(data.nombres);
        setShowTopModal(false);
        setModalData({
          messageLine: false,
          title: "Datos Actualizados",
          subtitle: "EXITOSAMENTE",
          color: "yellow",
          message: "¡Muchas Gracias!",
          icon: (
            <>
              <Confirmado />
            </>
          ),
        });
        handleShowModalError();
      },
      () => {
        setModalData({
          messageLine: false,
          title: "Actualización de datos",
          subtitle: "Error",
          color: "red",
          message: "Las contraseñas no coinciden.",
          icon: (
            <>
              <Error />
            </>
          ),
        });
        handleShowModalError();
      }
    );
  };
  const onSubmitSolicitar = (data) => {
    setShowLoading({ display: "block" });
    if (data.meta_data.some((meta) => meta.value == "correo")) {
      if (data?.correo?.trim() !== "") {
        if (!validateEmail(data.correo)) {
          setModalData({
            messageLine: false,
            title: "Error al registrar",
            subtitle: "Error",
            color: "red",
            icon: (
              <>
                <Error />
              </>
            ),
            message: "Email inválido.",
          });
          setShowLoading({ display: "none" });
          handleShowModalError();
          return;
        }
      }
    }
    if (data.meta_data.some((meta) => meta.value === "paises")) {
      if (data.paises.trim() == "") {
        setModalData({
          messageLine: false,
          title: "Error al registrar",
          subtitle: "Error",
          color: "red",
          icon: (
            <>
              <Error />
            </>
          ),
          message: "Debe elegir un país como mínimo.",
        });
        setShowLoading({ display: "none" });
        handleShowModalError();
        return;
      }
    }
    if (data.meta_data.some((meta) => meta.value === "bienes")) {
      if (data.bienes_id?.trim() == "" || data.bienes_id?.trim() == null) {
        setModalData({
          messageLine: false,
          title: "Error al registrar",
          subtitle: "Error",
          color: "red",
          icon: (
            <>
              <Error />
            </>
          ),
          message: "Debe elegir un bien como mínimo.",
        });
        setShowLoading({ display: "none" });
        handleShowModalError();
        return;
      }
    }
    if (data.meta_data.some((meta) => meta.value === "tipo_personas")) {
      if (
        data.tipo_personas?.trim() == "" ||
        data.tipo_personas?.trim() == null
      ) {
        setModalData({
          messageLine: false,
          title: "Error al registrar",
          subtitle: "Error",
          color: "red",
          icon: (
            <>
              <Error />
            </>
          ),
          message: "Debe elegir un tipo de persona.",
        });
        setShowLoading({ display: "none" });
        handleShowModalError();
        return;
      }
    }
    if (data.meta_data.some((meta) => meta.value === "rut")) {
      if (!validaRut(data.rut)) {
        setModalData({
          messageLine: false,
          title: "Error al registrar",
          subtitle: "Error",
          color: "red",
          icon: (
            <>
              <Error />
            </>
          ),
          message: "Rut inválido.",
        });
        setShowLoading({ display: "none" });
        handleShowModalError();
        return;
      }
    }
    if (data.formato) {
      var url = PORTALSAPI.POST.GENERE_FORMATO_CESION;
      data = {
        ...data,
        nrooperacion: parseInt(data.nrooperacion),
        correo_notificacion: data?.correo,
        metadata: [
          `Operación Fintec: ${data.nrooperacion}`,
          //`Bien: ${data.bien}`,
          ...data.meta_data.map((meta) => `${meta.label} ${data[meta.value]}`),
        ],
      };
      api.post(url, data).then(
        (res) => {
          res.data.forEach((file) => {
            download("msword", file.base64, file.archivo);
          });
          setShowLoading({ display: "none" });
          setShowTopModal(false);
          setModalData({
            messageLine: false,
            title: `Generación de documento`,
            subtitle: "EXITOSAMENTE",
            color: "yellow",
            message: res.message,
            icon: (
              <>
                <Confirmado />
              </>
            ),
          });
          handleShowModalError();
        },
        (error) => {
          setModalData({
            messageLine: false,
            title: "Generación de documento",
            subtitle: "Error",
            color: "red",
            icon: (
              <>
                <Error />
              </>
            ),
            message: "Error al generar documento",
          });
          setShowLoading({ display: "none" });
          handleShowModalError();
          return;
        }
      );
    } else {
      var url = PORTALSAPI.POST.REGISTRAR_SOLICITUD;
      data = {
        ...data,
        nrooperacion: parseInt(data.nrooperacion),
        correo_notificacion: data?.correo,
        metadata: [
          `Operación Fintec: ${data.nrooperacion}`,
          //`Bien: ${data.bien}`,
          ...data.meta_data
            .filter((meta) => meta.value != "bienes")
            .map((meta) => `${meta.label} ${data[meta.value]}`),
        ],
      };
      api.post(url, data).then(
        (res) => {
          setShowLoading({ display: "none" });
          setShowTopModal(false);
          setModalData({
            title: `Solicitud enviada`,
            subtitle: "EXITOSAMENTE",
            color: "green",
            message: (
              <div>
                <span style={{ fontWeight: 600, display: "block" }}>
                  N° DE SOLICITUD{" "}
                </span>
                <span
                  style={{
                    fontWeight: 600,
                    display: "block",
                    fontSize: "2.4rem",
                  }}
                >
                  {res.data?.ticket}
                </span>
                <span style={{ fontSize: "12px", display: "block" }}>
                  Tu solicitud será respondida dentro de{" "}
                </span>
                <b style={{ fontSize: "12px", display: "block" }}>
                  {res.data?.sla} hábil
                  {res.data?.sla.indexOf("días") != -1 ? "es" : ""} máximo.
                </b>
              </div>
            ),
            icon: (
              <>
                <CheckGreenIcon />
              </>
            ),
          });
          handleShowModalSuccessSolicitud();
        },
        (error) => {
          setModalData({
            messageLine: false,
            title: "Error al registrar",
            subtitle: "Error",
            color: "red",
            icon: (
              <>
                <Error />
              </>
            ),
            message: error.response.data.message,
          });
          setShowLoading({ display: "none" });
          handleShowModalSuccessSolicitud();
          return;
        }
      );
    }
  };

  const onSubmitPassword = (data) => {
    if (data.password !== data.password_confirmacion) {
      setModalData({
        messageLine: false,
        title: "Cambio de contraseña",
        subtitle: "Error",
        color: "red",
        message: "Las contraseñas no coinciden.",
        icon: (
          <>
            <Error />
          </>
        ),
      });
      handleShowModalError();
      return;
    }

    let url = PORTALSAPI.PUT.ACTUALIZAR_PASSWORD;
    data.idcontacto = JSON.parse(localStorage.getItem("user")).idcontacto;
    data.password = data.password;
    data.password_confirmacion = data.password_confirmacion;
    api.put(url, data).then(
      (res) => {
        setShowTopModal(false);
        setModalData({
          messageLine: false,
          title: "Datos Actualizados",
          subtitle: "EXITOSAMENTE",
          color: "yellow",
          message: "¡Muchas Gracias!",
          icon: (
            <>
              <Confirmado />
            </>
          ),
        });
        handleShowModalError();
      },
      (error) => {
        setModalData({
          messageLine: false,
          title: "Cambio de contraseña",
          subtitle: "Error",
          color: "red",
          icon: (
            <>
              <Error />
            </>
          ),
          message: error.response.data.message,
        });
        handleShowModalError();
        return;
      }
    );
  };

  /* Data */
  const [dataLeasing, setDataLeasing] = useState([]);
  /* Tables */
  const [factoring, setTablaFactoring] = useState({});
  const [leasing, setTablaLeasing] = useState({});

  /* Sucess modal*/
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const handleCloseModalSuccess = () => setShowModalSuccess(false);
  const handleShowModalSuccess = () => {
    handleCloseTopModal();
    handleClose();
    setShowModalSuccess(true);
  };

  /* Error modal*/
  const [showModalError, setShowModalError] = useState(false);
  const [showModalSuccessSOlicitud, setShowModalSuccessSOlicitud] =
    useState(false);
  const handleCloseModalError = () => setShowModalError(false);
  const handleCloseModalSuccessSolicitud = () =>
    setShowModalSuccessSOlicitud(false);
  const handleShowModalError = () => {
    setShowModalError(true);
  };
  const handleShowModalSuccessSolicitud = () => {
    setShowModalSuccessSOlicitud(true);
  };

  /* Low level modal */
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [modal, setModal] = useState(modalInfo);

  /* Top level modal */
  const [showTopModal, setShowTopModal] = useState(false);
  const handleCloseTopModal = () => {
    setShowTopModal(false);
    handleShow();
  };
  const handleShowTopModal = () => {
    handleClose();
    setShowTopModal(true);
  };
  const [topModal, setTopModal] = useState(modalInfo);

  const openComentario = (data) => {
    idcobranza = data;
    openModal({ title: "comentario", data: { data } });
  };

  const imprimir = (data) => {
    let user = JSON.parse(localStorage.getItem("user"));

    if (user === null) {
      window.location.href = "/";
      return;
    }

    let url = PORTALSAPI.GET.EXPORTAR_LIQUIDACION.replace(
      "{idfactoring}",
      data.idfactoring
    ).replace("{idcontacto}", user.idcontacto);

    api.get(url).then((x) => {
      const linkSource = `data:application/pdf;base64,${x.data.archivo}`;
      const downloadLink = document.createElement("a");
      const fileName = x.data.nombre;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    });
  };
  const exportarResumenCartola = (data) => {
    setShowLoading({ display: "block" });
    let user = JSON.parse(localStorage.getItem("user"));

    if (user === null) {
      window.location.href = "/";
      return;
    }

    let url = PORTALSAPI.GET.EXPORTAR_RESUMEN_CARTOLA.replace("{idcontacto}", user.idcontacto);

    api.get(url).then((x) => {
      const linkSource = `data:application/pdf;base64,${x.data.archivo}`;
      const downloadLink = document.createElement("a");
      const fileName = x.data.nombre;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }).catch(err=>{
      setModalData({
        messageLine: false,
        title: "Descargar Resumen",
        color: "red",
        subtitle: "Error",
        message: err.response.data.message,
        icon: (
          <>
            <Error />
          </>
        ),
      });
      handleShowModalError();
    }).finally(()=>{
      setShowLoading({ display: "none" });
    });

  };
  const cronograma = (data) => {
    let user = JSON.parse(localStorage.getItem("user"));

    if (user === null) {
      window.location.href = "/";
      return;
    }

    let url = PORTALSAPI.GET.EXPORTAR_CRONOGRAMA.replace(
      "{idleasing}",
      data.idleasing
    ).replace("{idcontacto}", user.idcontacto);
    setShowLoading({ display: "block" });
    api
      .get(url)
      .then((x) => {
        const linkSource = `data:application/pdf;base64,${x.data.archivo}`;
        const downloadLink = document.createElement("a");
        const fileName = x.data.nombre;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      })
      .catch((ex) => {
        setModalData({
          messageLine: false,
          title: "Generar Cronograma",
          color: "red",
          subtitle: "Error",
          message: "No se pudo generar el cronograma.",
          icon: (
            <>
              <Error />
            </>
          ),
        });
        handleShowModalError();
      })
      .finally(() => {
        setShowLoading({ display: "none" });
      });
  };
  // const

  const RenderContent = (typeobj) => {
    const [isInvalidPhone, setIsInvalidPhone] = React.useState(false);
    const [isInvalidPhoneFina, setIsInvalidPhoneFina] = React.useState(false);
    const [isInvalidEmail, setIsInvalidEmail] = React.useState(false);
    const mountedRef = React.useRef(false);
    const handleKeyPress = (e) => {
      const numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
      const alts = ["Backspace", "Tab", "Enter", "ArrowLeft", "ArrowRight"];

      if (numbers.indexOf(e.key) === -1 && alts.indexOf(e.key) === -1) {
        e.preventDefault();
        return;
      }

      if (e.target.value.length === 9 && alts.indexOf(e.key) === -1) {
        e.preventDefault();
        return;
      }
    };

    let type = typeobj.type;
    switch (type) {
      case "notificacion": {
        return (
          <Notificaciones
            notificaciones={notificaciones}
            openTopModal={openTopModal}
          />
        );
      }

      case "operacion": {
        /// 1 = verde; 2 = naranja 3 = gris
        let status1 = 0;
        let status2 = 0;
        let status3 = 0;

        if (typeobj.data === "TRAMITE") {
          status1 = 1;
          status2 = 3;
          status3 = 3;
        } else if (typeobj.data === "RECHAZADA") {
          status1 = 1;
          status2 = 2;
          status3 = 3;
        } else if (typeobj.data === "GIRADA") {
          status1 = 1;
          status2 = 3;
          status3 = 1;
        } else {
          status1 = 1;
          status2 = 3;
          status3 = 3;
        }

        return (
          <Container style={userModal}>
            <Stepper>
              <Step status={status1} title={"EN TRÁMITE"} content="">
                <div className="Content">
                  <button>Prev</button>
                  <button>Next</button>
                </div>
              </Step>
              <Step status={status2} title="RECHAZADA" content="">
                <div className="Content">
                  <button>Prev</button>
                  <button>Next</button>
                </div>
              </Step>
              <Step status={status3} title="GIRADA" content="">
                <div className="Content">
                  <button>Prev</button>
                  <button>Next</button>
                </div>
              </Step>
            </Stepper>
          </Container>
        );
      }

      case "comentario": {
        return (
          <Container style={userModal}>
            <Form>
              <Form.Group
                className="justify-content-center mt-3"
                as={Row}
                controlId="formUser"
              >
                <Col
                  className="d-flex align-items-center justify-content-start"
                  xs={10}
                >
                  <Form.Control
                    name="comentario"
                    rows={3}
                    as="textarea"
                    placeholder=""
                    ref={register}
                  />
                </Col>
              </Form.Group>
              <Row className="justify-content-center mt-3">
                <Col xs={4}>
                  <Button onClick={handleSubmit(enviarComentario)}>
                    Enviar Información
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>
        );
      }

      case "usuario": {
        return (
          <>
            <Container style={userModal}>
              <Row>
                <Col xs={{ span: 2, offset: 1 }}>
                  <User />
                </Col>
                <Col xs={5}>
                  <Row>
                    <Text bold size={body14} type="primary">
                      {DecryptData(
                        typeobj.data.data.usuario.cliente,
                        typeobj.public_key
                      )}
                    </Text>
                  </Row>
                  <Row>
                    <Text bold size={body12} type="primary">
                      {DecryptData(
                        typeobj.data.data.usuario.rut,
                        typeobj.public_key
                      )}
                    </Text>
                  </Row>
                  <Row>
                    <TextColored
                      className="mr-2"
                      bold
                      size={body12}
                      type="gray"
                    >
                      • Email:
                    </TextColored>
                    <TextColored size={body12} type="gray">
                      {DecryptData(
                        typeobj.data.data.usuario.correo,
                        typeobj.public_key
                      )}
                    </TextColored>
                  </Row>
                  <Row>
                    <TextColored
                      className="mr-2"
                      bold
                      size={body12}
                      type="gray"
                    >
                      • Fono:
                    </TextColored>
                    <TextColored size={body12} type="gray">
                      {DecryptData(
                        typeobj.data.data.usuario.celular,
                        typeobj.public_key
                      )}
                    </TextColored>
                  </Row>
                  <br />
                  <Row>
                    <TextColored
                      className="mr-2"
                      bold
                      size={body12}
                      type="gray"
                    >
                      • Nombre encargado de Finanzas:
                    </TextColored>
                    <TextColored size={body12} type="gray">
                      {DecryptData(
                        typeobj.data.data.usuario.finanzas_nombre,
                        typeobj.public_key
                      )}
                    </TextColored>
                  </Row>
                  <Row>
                    <TextColored
                      className="mr-2"
                      bold
                      size={body12}
                      type="gray"
                    >
                      • Email encargado de Finanzas:
                    </TextColored>
                    <TextColored size={body12} type="gray">
                      {DecryptData(
                        typeobj.data.data.usuario.finanzas_correo,
                        typeobj.public_key
                      )}
                    </TextColored>
                  </Row>
                  <Row>
                    <TextColored
                      className="mr-2"
                      bold
                      size={body12}
                      type="gray"
                    >
                      • Fono encargado de Finanzas:
                    </TextColored>
                    <TextColored size={body12} type="gray">
                      {DecryptData(
                        typeobj.data.data.usuario.finanzas_telefono,
                        typeobj.public_key
                      )}
                    </TextColored>
                  </Row>
                </Col>
                <Col xs={width > mobileSMWidth ? 4 : 12}>
                  <Row
                    className={
                      width > mobileSMWidth
                        ? "justify-content-center"
                        : "justify-content-center mt-2"
                    }
                  >
                    <Col xs={10}>
                      <Button
                        className="w-100"
                        onClick={() => openTopModal("datos")}
                      >
                        Actualizar Datos
                      </Button>
                    </Col>
                  </Row>
                  <Row className="mt-3 justify-content-center">
                    <Col xs={10}>
                      <Button
                        className="w-100"
                        onClick={() => openTopModal("contrasenia")}
                      >
                        Cambiar Contraseña
                      </Button>
                    </Col>
                  </Row>

                  <Row className="mt-3 justify-content-center">
                    <Col xs={10}>
                      <Button
                        className="w-100"
                        onClick={() => {
                          openTopModal("datos_SII");
                        }}
                        disabled={tipoBanner === 1}
                      >
                        Actualizar Contraseña SII
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
            <Container className='border-0' style={userModal}>
              <Row>
                <Col xs={{ span: 2, offset: 1 }}>
                  <Usuario />
                </Col>
                {typeobj?.data?.data?.ejecutivos ? (
                  <>
                    <Col xs={5}>
                      {typeobj?.data?.data?.ejecutivos?.map((ejecutivo) => {
                        return (
                          <>
                            <Row>
                              <TextColored bold size={body12} type="gray">
                                • Ejecutivo Progreso de{" "}
                                {DecryptData(
                                  ejecutivo.negocio,
                                  typeobj.public_key
                                )}
                                :
                              </TextColored>
                            </Row>
                            <Row>
                              <TextColored size={body12} type="gray">
                                {DecryptData(
                                  ejecutivo.nombres,
                                  typeobj.public_key
                                )}
                              </TextColored>
                            </Row>
                            <Row>
                              <TextColored size={body12} type="gray">
                                {DecryptData(
                                  ejecutivo.correo,
                                  typeobj.public_key
                                )}
                              </TextColored>
                            </Row>
                            <Row>
                              <TextColored size={body12} type="gray">
                                {DecryptData(
                                  ejecutivo.celular,
                                  typeobj.public_key
                                )}
                              </TextColored>
                            </Row>
                          </>
                        );
                      })}
                    </Col>
                    <Col xs={width > mobileSMWidth ? 4 : 12}>
                      <Row
                        className={
                          width > mobileSMWidth
                            ? "justify-content-center"
                            : "justify-content-center mt-2"
                        }
                      >
                        <Col xs={10}>
                          <Button
                            className="w-100"
                            onClick={() =>
                              openModal({
                                title: "ejecutivo",
                                data: typeobj.data.data.usuario.idcontacto,
                              })
                            }
                          >
                            Contactar Ejecutivos
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </>
                ) : null}
              </Row>
            </Container>
          </>
        );
      }
      case "datos": {
        if (!mountedRef.current) {
          mountedRef.current = true;
          reset({
            nombres: DecryptData(
              typeobj.data?.usuario?.nombres,
              typeobj.public_key
            ),
            celular: DecryptData(
              typeobj.data?.usuario?.celular,
              typeobj.public_key
            ),
            correo: DecryptData(
              typeobj.data?.usuario?.correo,
              typeobj.public_key
            ),
            direccion: DecryptData(
              typeobj.data?.usuario?.direccion,
              typeobj.public_key
            ),
            region: DecryptData(
              typeobj.data?.usuario?.region,
              typeobj.public_key
            ),
            finanzas_correo: DecryptData(
              typeobj.data?.usuario?.finanzas_correo,
              typeobj.public_key
            ),
            finanzas_nombre: DecryptData(
              typeobj.data?.usuario?.finanzas_nombre,
              typeobj.public_key
            ),
            finanzas_telefono: DecryptData(
              typeobj.data?.usuario?.finanzas_telefono,
              typeobj.public_key
            ),
          });
        }
        return (
          <Container>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group
                className="justify-content-center"
                as={Row}
                controlId="formUser2"
              >
                <Col xs={8}>
                  <Form.Control
                    name="nombres"
                    type="text"
                    placeholder="Nombre Usuario"
                    ref={register({ required: true })}
                  />
                </Col>
              </Form.Group>
              <Form.Group
                className="justify-content-center"
                as={Row}
                controlId="formContact"
              >
                <Col xs={4}>
                  <Form.Control
                    name="celular"
                    type="text"
                    placeholder="Fono"
                    ref={register({ required: true })}
                    style={{
                      border: isInvalidPhone ? "2px solid red" : "",
                    }}
                    onKeyUp={(e) =>
                      setIsInvalidPhone(e.target.value.length !== 9)
                    }
                    onKeyDown={handleKeyPress}
                  />
                </Col>
                <Col xs={4}>
                  <Form.Control
                    name="correo"
                    type="text"
                    placeholder="Email"
                    ref={register({ required: true })}
                    readOnly
                  />
                </Col>
              </Form.Group>
              <Form.Group
                className="justify-content-center"
                as={Row}
                controlId="formAdress"
              >
                <Col xs={8}>
                  <Form.Control
                    name="direccion"
                    type="text"
                    placeholder="Dirección"
                    ref={register}
                  />
                </Col>
              </Form.Group>
              <Form.Group
                className="justify-content-center"
                as={Row}
                controlId="formAdress2"
              >
                <Col xs={8}>
                  <Form.Control
                    name="region"
                    type="text"
                    placeholder="Región"
                    ref={register}
                  />
                </Col>
              </Form.Group>
              <Form.Group
                className="justify-content-center"
                as={Row}
                controlId="formUserFinanzas"
              >
                <Col xs={8}>
                  <Form.Control
                    name="finanzas_nombre"
                    type="text"
                    placeholder="Nombre Finanzas"
                    ref={register}
                  />
                </Col>
              </Form.Group>
              <Form.Group
                className="justify-content-center"
                as={Row}
                controlId="formContactFinanzas"
              >
                <Col xs={4}>
                  <Form.Control
                    name="finanzas_telefono"
                    type="text"
                    placeholder="Fono Finanzas"
                    ref={register}
                    style={{
                      border: isInvalidPhoneFina ? "1px solid red" : "",
                    }}
                    onKeyUp={(e) =>
                      setIsInvalidPhoneFina(e.target.value.length !== 9)
                    }
                    onKeyDown={handleKeyPress}
                  />
                </Col>
                <Col xs={4}>
                  <Form.Control
                    name="finanzas_correo"
                    type="text"
                    placeholder="Email Finanzas"
                    ref={register}
                    style={{
                      border: isInvalidEmail ? "2px solid red" : "",
                    }}
                    onKeyUp={(e) =>
                      setIsInvalidEmail(
                        !e.target.value.match(
                          /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
                        )
                      )
                    }
                  />
                </Col>
              </Form.Group>

              <Row className="justify-content-center">
                <Col xs={4}>
                  <Row className="justify-content-center">
                    <Form.Check
                      className="mr-1"
                      type="checkbox"
                      label=""
                      ref={register()}
                      name="terminos"
                      aria-label="terminos"
                    />
                    <TextColored type="gray">
                      Acepto Política de Privacidad
                    </TextColored>
                  </Row>
                  <Row className="justify-content-center">
                    <Text type="primary">
                      <u style={{ cursor: "pointer" }} onClick={() => abrir()}>
                        Leer detalle
                      </u>
                    </Text>
                  </Row>
                  <Row className="mt-3">
                    <Button
                      disabled={
                        isInvalidPhone || isInvalidPhoneFina || isInvalidEmail
                      }
                      onClick={handleSubmit(onSubmit)}
                    >
                      Enviar Información
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Container>
        );
      }
      case "contrasenia": {
        return (
          <Container>
            <Form onSubmit={handleSubmit(onSubmitPassword)}>
              <Form.Group
                className="justify-content-center"
                as={Row}
                controlId="formUser2"
              >
                <Col xs={8}>
                  <Form.Control
                    name="password"
                    type="password"
                    autoComplete="off"
                    placeholder="Contraseña nueva"
                    ref={register({ required: true })}
                  />
                </Col>
              </Form.Group>
              <Form.Group
                className="justify-content-center"
                as={Row}
                controlId="formContact"
              >
                <Col xs={8}>
                  <Form.Control
                    className="justify-content-center"
                    name="password_confirmacion"
                    type="password"
                    autoComplete="off"
                    placeholder="Confirmación Contraseña nueva"
                    ref={register({ required: true })}
                  />
                </Col>
              </Form.Group>

              <Row className="justify-content-center">
                <Col xs={4}>
                  <Row className="mt-3">
                    <Button onClick={handleSubmit(onSubmitPassword)}>
                      Cambiar Contraseña
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Container>
        );
      }
      case "liquidacion": {
        return (
          <div id="modal_liquidacion">
            <Container>
              <DashedItem
                shadow
                title="Cantidad de Documentos"
                subtitle={typeobj.data ? typeobj.data.documentos : ""}
              />
              <DashedItem
                title="Monto Documento "
                subtitle={
                  <NumberFormat
                    className="pull-right"
                    value={typeobj.data ? typeobj.data.monto : 0}
                    displayType={"text"}
                    prefix={"$"}
                    thousandSeparator={"."}
                    decimalScale={2}
                    decimalSeparator={","}
                  />
                }
              />
              <DashedItem
                shadow
                title="Precio de la cesión "
                subtitle={
                  <NumberFormat
                    className="pull-right"
                    value={typeobj.data ? typeobj.data.precio_cesion : 0}
                    displayType={"text"}
                    prefix={"$"}
                    thousandSeparator={"."}
                    decimalScale={2}
                    decimalSeparator={","}
                  />
                }
              />
              <DashedItem
                title="Diferencia de Precio "
                subtitle={
                  <NumberFormat
                    className="pull-right"
                    value={typeobj.data ? typeobj.data.diferencia_precio : 0}
                    displayType={"text"}
                    prefix={"$"}
                    thousandSeparator={"."}
                    decimalScale={2}
                    decimalSeparator={","}
                  />
                }
              />
              <DashedItem
                shadow
                title="Monto Anticipado "
                subtitle={
                  <NumberFormat
                    className="pull-right"
                    value={typeobj.data ? typeobj.data.anticipado : 0}
                    displayType={"text"}
                    prefix={"$"}
                    thousandSeparator={"."}
                    decimalScale={2}
                    decimalSeparator={","}
                  />
                }
              />
              <DashedItem
                title="Monto no Financiado "
                subtitle={
                  <NumberFormat
                    className="pull-right"
                    value={typeobj.data ? typeobj.data.no_financiado : 0}
                    displayType={"text"}
                    prefix={"$"}
                    thousandSeparator={"."}
                    decimalScale={2}
                    decimalSeparator={","}
                  />
                }
              />
              <DashedItem
                shadow
                title="Cuentas por Cobrar "
                subtitle={
                  <NumberFormat
                    className="pull-right"
                    value={typeobj.data ? typeobj.data.cxc : 0}
                    displayType={"text"}
                    prefix={"$"}
                    thousandSeparator={"."}
                    decimalScale={2}
                    decimalSeparator={","}
                  />
                }
                color="red"
              />
              <DashedItem
                title="Documentos por Aplicar "
                subtitle={typeobj.data ? typeobj.data.documento_aplicar : ""}
                color="red"
              />
              <DashedItem
                shadow
                title="Cuotas de Leasing "
                subtitle={typeobj.data ? typeobj.data.cuota_leasing : ""}
                color="red"
              />
              <DashedItem
                title="Gastos "
                subtitle={
                  <NumberFormat
                    className="pull-right"
                    value={typeobj.data ? typeobj.data.gastos : 0}
                    displayType={"text"}
                    prefix={"$"}
                    thousandSeparator={"."}
                    decimalScale={2}
                    decimalSeparator={","}
                  />
                }
                color="red"
              />
              <DashedItem
                shadow
                title="Comisión "
                subtitle={
                  <NumberFormat
                    className="pull-right"
                    value={typeobj.data ? typeobj.data.comision : 0}
                    displayType={"text"}
                    prefix={"$"}
                    thousandSeparator={"."}
                    decimalScale={2}
                    decimalSeparator={","}
                  />
                }
              />
              <DashedItem
                title="IVA "
                subtitle={
                  <NumberFormat
                    className="pull-right"
                    value={typeobj.data ? typeobj.data.iva : 0}
                    displayType={"text"}
                    prefix={"$"}
                    thousandSeparator={"."}
                    decimalScale={2}
                    decimalSeparator={","}
                  />
                }
              />
              <DashedItem
                shadow
                title="Líquido a Pagar "
                subtitle={
                  <NumberFormat
                    className="pull-right"
                    value={typeobj.data ? typeobj.data.liquido_pagar : 0}
                    displayType={"text"}
                    prefix={"$"}
                    thousandSeparator={"."}
                    decimalScale={2}
                    decimalSeparator={","}
                  />
                }
              />
              <Row className="mt-4 justify-content-center">
                <Col xs={6}>
                  <Button
                    className="noprint"
                    onClick={() => imprimir(typeobj.data)}
                  >
                    Imprimir certificado en PDF
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
        );
      }
      case "ejecutivo": {
        return (
          <Container>
            <Row className="justify-content-center">
              <Col
                className="d-flex align-items-center justify-content-start"
                xs={10}
              >
                <TextColored bold type="gray">
                  {typeobj.data.length > 1
                    ? "¿Con cuál de nuestros servicios le podemos ayudar?"
                    : ""}
                </TextColored>
              </Col>
            </Row>

            <Form>
              {typeobj.data.length > 1
                ? typeobj.data.map((ejecutivo) => {
                    return (
                      <Row className="justify-content-center mt-3">
                        <Col
                          className="d-flex align-items-center justify-content-end"
                          xs={2}
                        >
                          <Form.Check
                            type={"checkbox"}
                            onChange={(e) =>
                              selecionarEjecutivo(e.target.checked, ejecutivo)
                            }
                          />
                          <Ejecutivo1 />
                        </Col>
                        <Col className="d-flex align-items-center" xs={9}>
                          <Row>
                            <Col xs={12}>
                              <TextColored type="gray">
                                {DecryptData(
                                  ejecutivo.nombres,
                                  typeobj.public_key
                                )}{" "}
                                para consultas de{" "}
                                {DecryptData(
                                  ejecutivo.negocio,
                                  typeobj.public_key
                                )}
                              </TextColored>
                            </Col>
                            <Col xs={12}>
                              <TextColored type="gray">
                                {DecryptData(
                                  ejecutivo.correo,
                                  typeobj.public_key
                                )}{" "}
                                /{" "}
                                {DecryptData(
                                  ejecutivo.celular,
                                  typeobj.public_key
                                )}
                              </TextColored>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })
                : typeobj.data.map((ejecutivo) => {
                    return (
                      <Row className="justify-content-center mt-3">
                        <Col
                          className="d-flex align-items-center justify-content-end"
                          xs={2}
                        >
                          <Form.Control
                            name="ejecutivo_unico"
                            value={ejecutivo.id}
                            ref={register}
                            hidden={true}
                          />

                          <Form.Check
                            type={"checkbox"}
                            checked={true}
                            hidden={true}
                          />
                          <Ejecutivo1 />
                        </Col>
                        <Col className="d-flex align-items-center" xs={9}>
                          <Row>
                            <Col xs={12}>
                              <TextColored type="gray">
                                {DecryptData(
                                  ejecutivo.nombres,
                                  typeobj.public_key
                                )}{" "}
                                para consultas de{" "}
                                {DecryptData(
                                  ejecutivo.negocio,
                                  typeobj.public_key
                                )}
                              </TextColored>
                            </Col>
                            <Col xs={12}>
                              <TextColored type="gray">
                                {DecryptData(
                                  ejecutivo.correo,
                                  typeobj.public_key
                                )}{" "}
                                {DecryptData(
                                  ejecutivo.celular,
                                  typeobj.public_key
                                ).length > 0 &&
                                  `/ ${DecryptData(
                                    ejecutivo.celular,
                                    typeobj.public_key
                                  )}`}
                              </TextColored>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })}

              <Row className="justify-content-center mt-3">
                <Col
                  className="d-flex align-items-center justify-content-start"
                  xs={10}
                >
                  <TextColored bold type="gray">
                    ¿En qué podemos ayudarle?:
                  </TextColored>
                </Col>
              </Row>

              <Form.Group
                className="justify-content-center mt-3"
                as={Row}
                controlId="formUser3"
              >
                <Col
                  className="d-flex align-items-center justify-content-start"
                  xs={10}
                >
                  <Form.Control
                    name="comentario"
                    rows={3}
                    as="textarea"
                    placeholder=""
                    ref={register({ required: true })}
                  />
                </Col>
              </Form.Group>

              <Row className="justify-content-center mt-3">
                <Col xs={width > mobileXSWidth ? 4 : 12}>
                  {" "}
                  <Button onClick={handleSubmit(enviarContacto)}>
                    Enviar Información
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>
        );
      }
      case "cartola": {
        return (
          <div id="modal_cartola">
            <Container>
              <DashedItem
                shadow
                header
                title="CONCEPTOS"
                subtitle="MONTOS TOTALES ($)"
              />
              <DashedItem
                color={typeobj.data ? 'greenDark' :'gray'}
                title="Cartera vigente "
                subtitle={
                  <NumberFormat
                    className="pull-right"
                    value={typeobj.data ? typeobj.data.mt_saldo_financiado : 0}
                    displayType={"text"}
                    prefix={"$"}
                    thousandSeparator={"."}
                    decimalScale={0}
                    decimalSeparator={","}
                  />
                }
              />
              <DashedItem
                shadow
                color={typeobj.data ? 'red':'gray'}
                title="Cartera morosa "
                subtitle={
                  <NumberFormat
                    className="pull-right"
                    value={typeobj.data ? typeobj.data.mt_saldo_moroso : 0}
                    displayType={"text"}
                    prefix={"$"}
                    thousandSeparator={"."}
                    decimalScale={0}
                    decimalSeparator={","}
                  />
                }
              />
              <DashedItem
                color={typeobj.data ? 'red':'gray'}
                title="Interés por Mora "
                subtitle={
                  <NumberFormat
                    className="pull-right"
                    value={typeobj.data ? typeobj.data.mt_saldo_cxc : 0}
                    displayType={"text"}
                    prefix={"$"}
                    thousandSeparator={"."}
                    decimalScale={0}
                    decimalSeparator={","}
                  />
                }
              />
              <DashedItem
                shadow
                color={typeobj.data ? 'greenDark' :'gray'}
                title="Excedentes disponibles "
                subtitle={
                  <NumberFormat
                    className="pull-right"
                    value={typeobj.data ? typeobj.data.mt_saldo_exe : 0}
                    displayType={"text"}
                    prefix={"$"}
                    thousandSeparator={"."}
                    decimalScale={0}
                    decimalSeparator={","}
                  />
                }
              />
              <DashedItem
                color={typeobj.data ? 'greenDark' :'gray'}
                title="Documentos no cedidos "
                subtitle={
                  <NumberFormat
                    className="pull-right"
                    value={typeobj.data ? typeobj.data.mt_saldo_dnc : 0}
                    displayType={"text"}
                    prefix={"$"}
                    thousandSeparator={"."}
                    decimalScale={0}
                    decimalSeparator={","}
                  />
                }
              />
              <Row className="mt-4 justify-content-center">
                <Col xs={7}>
                  <Button
                    className="noprint"
                    onClick={() => exportarResumenCartola(typeobj.data)}
                  >
                    Descargar Resumen en PDF
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
        );
      }
      case "datos_SII": {
        return (
          <RegistroSII
            closeModal={(response) => {
              if (response) {
                setModalData(response);
                setShowTopModal(false);
                handleShowModalSuccess();
              }
            }}
            endpoint={PORTALSAPI.POST.ACTUALIZARSII_DASHBOARD}
            setTipoBanner={setTipoBanner}
            setStatus={handleStatusChange}
          ></RegistroSII>
        );
      }
      default: {
        break;
      }
    }
  };

  // SECCION 2
  const openSuscriptionModal = () => {
    setShowSuscription(true);
  };

  const checkSuscription = async () => {
    try {
      const body = await generateCdlObj();
      const { idcontacto } = JSON.parse(localStorage.getItem("user"));
      const urlSuscriber = AUTOATENCIONAPI.POST.ESTADO_ENROLAMIENTO_CDL.replace(
        "{idcontacto}",
        idcontacto
      );

      const { data } = await api.post(urlSuscriber, body);

      return data;
    } catch (err) {
      console.log(err);
    }
  };

  const [isBlocked, setBlocked] = useState(null);
  const [proveedor, setProveedor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showSuscriptionModal, setShowSuscription] = useState(false);
  const [{ tipo_acceso }] = useStorage("user", "", "local");

  const handleSuscriptionModal = async (s) => {
    try {
      setLoading(true);
      const flag = s ? s : status;
      switch (flag) {
        // Flujo no enrolado
        case 0: {
          openSuscriptionModal();
          break;
        }

        // Flujo - Enrolado
        case 1: {
          const { enrolado, fe_estimada_desbloqueo, bloqueado } =
            await checkSuscription();

          if (fe_estimada_desbloqueo?.length > 0 && bloqueado) {
            setBlocked(fe_estimada_desbloqueo);
          }
          if (!enrolado) {
            handleStatusChange(2);
            openSuscriptionModal();
            break;
          }
          if (enrolado && fe_estimada_desbloqueo?.length > 0 && bloqueado) {
            openSuscriptionModal();
            break;
          }
          await sincronizarDTEs(await getRut());
          sessionStorage.setItem("current-step", 1);
          history.push("autoatencion/seleccion-facturas");
          break;
        }

        // Flujo - Actualizar datos
        case 2: {
          openSuscriptionModal();
          break;
        }

        case 4: {
          openSuscriptionModal();
          break;
        }

        default: {
          break;
        }
      }
      setShowLoading({ display: "none" });
      setLoading(false);
    } catch {
      setShowLoading({ display: "none" });
      await fireAlert({ icon: "warning" });
      setLoading(false);
    }
  };

  /* Modal Primer Nivel */
  const openModal = (data_modal) => {
    setShowLoading({ display: "block" });
    let type = data_modal.title;
    const _modalInfo = { table: { columns: [], rows: [] }, tables: [] };
    _modalInfo["size"] = "xl";
    _modalInfo["padding"] = true;
    _modalInfo["buttonsHeader"] = null;
    let data = [];

    switch (type) {
      case "cobranza": {
        _modalInfo["title"] = (
          <Title pipe={false} title="Cobranza" subtitle="" />
        );
        _modalInfo["table"]["columns"] = columnasCobranza;
        const user = JSON.parse(localStorage.getItem("user"));

        if (user === null) {
          window.location.href = "/";
          return;
        }

        let url = PORTALSAPI.GET.COBRANZA.replace(
          "{idfactoring}",
          data_modal.data
        ).replace("{idcontacto}", user.idcontacto);

        api.get(url).then((response) => {
          data = response.data.map((el) => ({ ...el }));
          let user = JSON.parse(localStorage.getItem("user"));
          let publicURL = PORTALSAPI.GET.PUBLIC_KEY.replace(
            "{idcontacto}",
            user.idcontacto
          ).replace("{tipo}", user.tipo_acceso);
          api.get(publicURL).then((responsePublicURL) => {
            data.forEach((el) => {
              el["rut"] = DecryptData(el["rut"], responsePublicURL.data);
              el["deudor"] = DecryptData(el["deudor"], responsePublicURL.data);
              if (el["muestra_detalle"] === false) {
                el["detalle"] = (
                  <img
                    onClick={() => {
                      openTopModal("gestion", el.idcobranza);
                    }}
                    src={eye2}
                    alt=""
                    width={width > mobileSMWidth ? "33px" : "40px"}
                    height={width > mobileSMWidth ? "33px" : "40px"}
                  />
                );
              } else {
                el["detalle"] = el["detalle"] = (
                  <img
                    alt=""
                    onClick={() => {
                      openTopModal("gestion", el.idcobranza);
                    }}
                    src={eye2}
                    width={width > mobileSMWidth ? "33px" : "40px"}
                    height={width > mobileSMWidth ? "33px" : "40px"}
                    onMouseOver={(e) => (e.currentTarget.src = eye)}
                    onMouseOut={(e) => (e.currentTarget.src = eye2)}
                  />
                );
              }
              el["monto"] = (
                <NumberFormat
                  className="pull-right"
                  value={el.monto}
                  displayType={"text"}
                  prefix={"$"}
                  thousandSeparator={"."}
                  decimalScale={2}
                  decimalSeparator={","}
                />
              );
              el["anticipo"] = (
                <NumberFormat
                  className="pull-right"
                  value={el.anticipo}
                  displayType={"text"}
                  prefix={"$"}
                  thousandSeparator={"."}
                  decimalScale={2}
                  decimalSeparator={","}
                />
              );
              el["saldo"] = (
                <NumberFormat
                  className="pull-right"
                  value={el.saldo}
                  displayType={"text"}
                  prefix={"$"}
                  thousandSeparator={"."}
                  decimalScale={2}
                  decimalSeparator={","}
                />
              );
            });
            _modalInfo["table"]["rows"] = data;
            _modalInfo["icon"] = icons[type];
            setModal(_modalInfo);
            setShowLoading({ display: "none" });
            handleShow();
          });
        });
        break;
      }
      case "excedente": {
        _modalInfo["title"] = (
          <Title pipe={false} title="Excedente" subtitle="" />
        );
        _modalInfo["table"]["columns"] = columnasExcedentes;
        const user = JSON.parse(localStorage.getItem("user"));

        if (user === null) {
          window.location.href = "/";
          return;
        }

        let url = PORTALSAPI.GET.EXCEDENTES.replace(
          "{idfactoring}",
          data_modal.data
        ).replace("{idcontacto}", user.idcontacto);
        api.get(url).then((response) => {
          data = response.data.map((el) => ({ ...el }));
          data.forEach((el) => {
            el["monto"] = (
              <NumberFormat
                className="pull-right"
                value={el.monto}
                displayType={"text"}
                prefix={"$"}
                thousandSeparator={"."}
                decimalScale={2}
                decimalSeparator={","}
              />
            );
            el["excedentes"] = (
              <NumberFormat
                className="pull-right"
                value={el.excedentes}
                displayType={"text"}
                prefix={"$"}
                thousandSeparator={"."}
                decimalScale={2}
                decimalSeparator={","}
              />
            );
          });
          _modalInfo["table"]["rows"] = data;
          _modalInfo["icon"] = icons[type];
          setModal(_modalInfo);
          setShowLoading({ display: "none" });
          handleShow();
        });
        break;
      }
      case "liquidacion": {
        _modalInfo["title"] = (
          <Title title="Liquidación" subtitle="Factoring" />
        );
        _modalInfo["size"] = "md";
        _modalInfo["table"] = null;
        const user = JSON.parse(localStorage.getItem("user"));

        if (user === null) {
          window.location.href = "/";
          return;
        }

        let url = PORTALSAPI.GET.LIQUIDACION.replace(
          "{idfactoring}",
          data_modal.data
        ).replace("{idcontacto}", user.idcontacto);
        api.get(url).then((response) => {
          let liquidacion = response.data;
          liquidacion.idfactoring = data_modal.data;
          _modalInfo["content"] = (
            <RenderContent type={type} data={liquidacion} />
          );
          _modalInfo["icon"] = icons[type];
          setModal(_modalInfo);
          setShowLoading({ display: "none" });
          handleShow();
        });
        break;
      }
      case "usuario": {
        _modalInfo["title"] = (
          <Title
            size={body25}
            title="Mi panel de Control"
            pipe={false}
            subtitle=""
          />
        );
        _modalInfo["subtitle"] =
          "Bienvenido a su panel de control, acá podrá cambiar sus datos de cliente Progreso.";
        _modalInfo["size"] = "lg";
        _modalInfo["table"] = null;
        _modalInfo["padding"] = false;
        let url = PORTALSAPI.GET.PANEL_CONTROL.replace(
          "{idcontacto}",
          data_modal.data.idcontacto
        );

        api.get(url).then((response) => {
          let publicURL = PORTALSAPI.GET.PUBLIC_KEY.replace(
            "{idcontacto}",
            data_modal.data.idcontacto
          ).replace("{tipo}", user.tipo_acceso);
          api.get(publicURL).then((responsePublicKey) => {
            _modalInfo["content"] = (
              <RenderContent
                type={type}
                public_key={responsePublicKey.data}
                data={response}
              />
            );
            _modalInfo["icon"] = icons["panel_control"];
            setModal(_modalInfo);
            setShowLoading({ display: "none" });
            handleShow();
          });
        });
        break;
      }

      case "ejecutivo": {
        _modalInfo["title"] = (
          <Title title="Cuente con Nosotros" subtitle="Contacto" />
        );
        _modalInfo["subtitle"] =
          "Por este medio podrá ponerse en contacto de forma directa con su(s) ejecutivo(s) asignado(s).";
        _modalInfo["size"] = "lg";
        _modalInfo["table"] = null;

        let url = PORTALSAPI.GET.CONTACTOS.replace(
          "{idcontactos}",
          data_modal.data
        );

        api.get(url).then((response) => {
          let user = JSON.parse(localStorage.getItem("user"));
          let publicURL = PORTALSAPI.GET.PUBLIC_KEY.replace(
            "{idcontacto}",
            user.idcontacto
          ).replace("{tipo}", user.tipo_acceso);
          api.get(publicURL).then((responsePublicKey) => {
            _modalInfo["content"] = (
              <RenderContent
                type={type}
                public_key={responsePublicKey.data}
                data={response.data}
              />
            );
            _modalInfo["icon"] = icons[type];
            setModal(_modalInfo);
            setShowLoading({ display: "none" });
            handleShow();
          });
        });
        break;
      }
      case "cartola": {
        _modalInfo["title"] = (
          <Title title="Cartola Informativa" subtitle="Resumen" />
        );
        _modalInfo["subtitle"] =
          "";
        _modalInfo["size"] = "md";
        _modalInfo["table"] = null;

        const user = JSON.parse(localStorage.getItem("user"));

        if (user === null) {
          window.location.href = "/";
          return;
        }

        let url = PORTALSAPI.GET.VER_RESUMEN_CARTOLA.replace("{idcontacto}", user.idcontacto);
        api.get(url).then((response) => {
          let cartola = response.data;
          if(cartola=== null){
            setModalData({
              messageLine: false,
              titleSize: 1.3,
              title: "Acceso a cartola informativa",
              color: "yellow",
              subtitle: "",
              message: response.message,
              icon: (
                <>
                  <Error />
                </>
              ),
              buttonModal:  { click : () => {
                handleCloseModalError();
                openModal({title : "ejecutivo", data : user.idcontacto})
              },
              text : "Contactar Ejecutivos"
              }
            });
            handleShowModalError();
          }else{
            _modalInfo["content"] = (
              <RenderContent type={type} data={cartola} />
            );
            _modalInfo["icon"] = icons[type];
            setModal(_modalInfo);
            handleShow();
          }
        }).catch(err=>{
          setModalData({
            messageLine: false,
            titleSize: 1.3,
            messageSize: 1.3,
            title: "Acceso a cartola informativa",
            color: "red",
            subtitle: "",
            message: "No se pudo mostrar el resumen.",
            icon: (
              <>
                <Error />
              </>
            ),
          });
          handleShowModalError();
        }).finally(()=>{
          setShowLoading({ display: "none" });
        });
        break;
      }

      case "detalle": {
        _modalInfo["title"] = (
          <Title title="Detalle de Operaciones" subtitle="Factoring" />
        );
        _modalInfo["table"]["columns"] = columnasDetalleOperacion;
        const user = JSON.parse(localStorage.getItem("user"));

        if (user === null) {
          window.location.href = "/";
          return;
        }

        let url = PORTALSAPI.GET.DETALLE.replace(
          "{idfactoring}",
          data_modal.data
        ).replace("{idcontacto}", user.idcontacto);
        api.get(url).then((response) => {
          data = response.data.map((el) => ({ ...el }));

          let user = JSON.parse(localStorage.getItem("user"));
          let publicURL = PORTALSAPI.GET.PUBLIC_KEY.replace(
            "{idcontacto}",
            user.idcontacto
          ).replace("{tipo}", user.tipo_acceso);
          api.get(publicURL).then((responsePublicKey) => {
            data.forEach((el) => {
              el["monto"] = (
                <NumberFormat
                  className="pull-right"
                  value={el.monto}
                  displayType={"text"}
                  prefix={"$"}
                  thousandSeparator={"."}
                  decimalScale={2}
                  decimalSeparator={","}
                />
              );
              el["rut"] = DecryptData(el["rut"], responsePublicKey.data);
              el["deudor"] = DecryptData(el["deudor"], responsePublicKey.data);
            });
            _modalInfo["table"]["rows"] = data;
            _modalInfo["icon"] = icons[type];
            setModal(_modalInfo);
            setShowLoading({ display: "none" });
            handleShow();
          });
        });
        break;
      }

      case "cuota": {
        _modalInfo["title"] = <Title title="Cuotas" subtitle="Pagos" />;
        _modalInfo["buttonsHeader"] = (
          <button
            type="button"
            class="btn mb-2"
            style={{
              marginRight: 46,
              fontWeight: 400,
              backgroundColor: "rgb(253, 209, 53)",
            }}
            onClick={() =>
              cronograma({
                idleasing: data_modal.data,
                idcontacto: user.idcontacto,
              })
            }
          >
            Cronograma
            <img src={Cloud} alt="" height="32px" class="ml-3" />
          </button>
        );
        _modalInfo["subtitle"] = "En esta sección se mostrará el detalle de las cuotas y podrás descargar el cronograma respectivo.";
        _modalInfo["search"] = true;
        _modalInfo["filas"] = 10;
        _modalInfo["table"]["columns"] = columnasCuotaPagos;
        const user = JSON.parse(localStorage.getItem("user"));

        if (user === null) {
          window.location.href = "/";
          return;
        }

        let url = PORTALSAPI.GET.CUOTAS.replace(
          "{idleasing}",
          data_modal.data
        ).replace("{idcontacto}", user.idcontacto);
        api.get(url).then((response) => {
          data = response.data.map((el) => ({ ...el }));
          data.forEach((el) => {
            let factura = Number(el.factura);
            let tipodoc = Number(el.tipodoc);
            el["valor"] = (
              <NumberFormat
                className="pull-right"
                value={el.valor}
                displayType={"text"}
                prefix={el["moneda"]}
                thousandSeparator={"."}
                decimalScale={2}
                decimalSeparator={","}
              />
            );
            el["total"] = `${el["numero"]}/${el["total"]}`;
            el["factura"] =
              factura === 0 ? (
                <span>{factura}</span>
              ) : (
                <span
                  onClick={() => openPDFFactura(tipodoc, factura, 2)} // 2 : Obtiene URL de PDF
                  style={{
                    cursor: "pointer",
                    color: "blue",
                    textDecoration: "underline",
                  }}
                >
                  {factura}
                </span>
              );
          });
          _modalInfo["table"]["rows"] = data;
          _modalInfo["icon"] = icons[type];
          setModal(_modalInfo);
          setShowLoading({ display: "none" });
          handleShow();
        });
        break;
      }

      case "archivo": {
        _modalInfo["title"] = <Title title="Archivos" subtitle="Documentos" />;
        _modalInfo["subtitle"] = "";
        _modalInfo["table"]["columns"] = columnasArchivosDocumentos;
        const user = JSON.parse(localStorage.getItem("user"));

        if (user === null) {
          window.location.href = "/";
          return;
        }

        let url = PORTALSAPI.GET.ARCHIVOS.replace(
          "{idleasing}",
          data_modal.data
        ).replace("{idcontacto}", user.idcontacto);
        api.get(url).then((response) => {
          data = response.data.map((el) => ({ ...el }));
          data.forEach((el) => {
            el["documento"] = (
              <a href={el.ruta} rel="noopener noreferrer" target="_blank">
                {el.nombre_documento}
              </a>
            );
          });
          _modalInfo["table"]["rows"] = data;
          _modalInfo["icon"] = icons[type];
          _modalInfo["search"] = true;
          setModal(_modalInfo);
          setShowLoading({ display: "none" });
          handleShow();
        });
        break;
      }
      case "comentario": {
        _modalInfo["title"] = <Title title="Enviar " subtitle="Comentario" />;
        _modalInfo["subtitle"] = "";
        _modalInfo["size"] = "lg";
        _modalInfo["table"] = null;
        _modalInfo["content"] = (
          <RenderContent type={type} data={data_modal.data} />
        );
        _modalInfo["icon"] = icons["ejecutivo"];
        setModal(_modalInfo);
        setShowLoading({ display: "none" });
        handleCloseTopModal();
        handleClose();
        handleShow();
        break;
      }

      case "operacion": {
        _modalInfo["title"] = <Title title="Etapas " subtitle="Operación" />;
        _modalInfo["subtitle"] =
          "En esta sección podrás conocer en qué etapa se encuentra tu solicitud.";
        _modalInfo["size"] = "lg";
        _modalInfo["table"] = null;
        _modalInfo["content"] = (
          <RenderContent type={type} data={data_modal.data} />
        );
        _modalInfo["icon"] = icons["operacion"];
        setModal(_modalInfo);
        setShowLoading({ display: "none" });
        handleCloseTopModal();
        handleClose();
        handleShow();
        break;
      }
      case "notificacion": {
        _modalInfo["title"] = (
          <Title title="Centro de Notificaciones" pipe={false} subtitle="" />
        );
        _modalInfo["subtitle"] =
          "En esta sección podrás conocer notificaciones importantes para que no te pierdas de nada.";
        _modalInfo["size"] = "xs";
        _modalInfo["table"] = null;
        _modalInfo["content"] = (
          <RenderContent type={type} data={notificacion} />
        );
        _modalInfo["icon"] = icons["bocina"];
        setTopModal(_modalInfo);
        setShowLoading({ display: "none" });
        handleShowTopModal();
        // });
        break;
      }
      case "solicitudes": {
        _modalInfo["title"] = (
          <Title title="Solicitudes de documentos" pipe={false} />
        );
        _modalInfo["subtitle"] =
          "En esta sección podrás realizar nuevas solicitudes de documentos, descargarlos y revisar tus solicitudes históricas.";
        _modalInfo["table"] = [];
        _modalInfo["tables"] = null;
        _modalInfo["search"] = true;
        _modalInfo["icon"] = <FileIcon />;
        _modalInfo["buttonsHeader"] = (
          <button
            type="button"
            className="btn btn-warning mb-2"
            style={{ marginRight: 46 }}
            onClick={() => openTopModal("solicitar")}
          >
            Nueva solicitud
          </button>
        );
        _modalInfo["table"] = {
          columns: columnasSolicitudes,
          id: "TablaSolicitudes",
        };
        const user = JSON.parse(localStorage.getItem("user"));

        if (user === null) {
          window.location.href = "/";
          return;
        }

        let url = PORTALSAPI.GET.SOLICITUDES.replace(
          "{idcontacto}",
          user.idcontacto
        );
        api.get(url).then((response) => {
          let data = [
            ...response.data.sort(
              (a, b) => Number(b.ticket) - Number(a.ticket)
            ),
          ];
          data.forEach((el) => {
            let colorEstado =
              el?.estado == 4
                ? "#5DB30C"
                : el?.estado == 0
                ? "#FF6900"
                : el?.estado == 2
                ? "#FF2B54"
                : "#4257B3";
            el["documento"] = (
              <button
                type="button"
                class="btn btn-warning"
                onClick={() => window.open(el.ruta, "_blank")}
                disabled={!el?.nombre_documento}
              >
                Descargar
              </button>
            );
            el["numero"] = Number(el.ticket);
            el["estado_descripcion"] = (
              <div
                style={{
                  width: "93px",
                  color: colorEstado,
                  border: `1px solid ${colorEstado}`,
                  padding: "6px",
                  borderRadius: "16px",
                  textAlign: "center",
                }}
              >
                {el?.estado == 4 ? "Disponible" : el?.estado_descripcion}
              </div>
            );
            el["tiposolicitud"] = el.name_tipo;
          });
          let tableData = [...data];
          _modalInfo["table"]["rows"] = tableData;
          /* _modalInfo["tables"] = _modalInfo["tables"].map(table=>({
            ...table,
            rows : [...data[table.id]]
          })) */

          setModal(_modalInfo);
          setShowLoading({ display: "none" });
          handleShow();
        });
        break;
      }
      case "datos_SII": {
        if (Number(proveedor) === 1) {
          _modalInfo["title"] = (
            <Title pipe={false} title="Registro Datos Portal SII" subtitle="" />
          );
          _modalInfo["size"] = "xs";
          _modalInfo["headerColor"] = "#f7cf46";
          _modalInfo["table"] = null;
          _modalInfo["content"] = <RenderContent type={type} data={{}} />;
          _modalInfo["icon"] = icons["logo4"];
          setTopModal(_modalInfo);
          setShowLoading({ display: "none" });
          handleShowTopModal();
          break;
        }
        if (Number(proveedor) === 2) {
          setShowLoading({ display: "none" });
          setUserDashboardFlag(true);
          if (status === 1) {
            handleStatusChange(2);
            handleSuscriptionModal(2);
          } else {
            handleSuscriptionModal();
          }
          handleClose();
        }
        break;
      }
      case "CDL":
        handleSuscriptionModal();
      default: {
        break;
      }
    }
  };

  const openPDFFactura = async (tipodoc, folio, tipores) => {
    let url = PORTALSAPI.POST.OBTENERURLFACTURA;
    const user = JSON.parse(localStorage.getItem("user"));
    let body = {
      idcontacto: user.idcontacto,
      tipodoc,
      folio,
      tipores,
    };
    try {
      setShowLoading({ display: "block" });
      const { data } = await api.post(url, body);
      window.open(data, "_blank");
    } catch (error) {
      setModalData({
        messageLine: false,
        title: "Recuperar Factura",
        color: "red",
        subtitle: "Error",
        message: "No se pudo encontrar la factura.",
        icon: (
          <>
            <Error />
          </>
        ),
      });
      handleShowModalError();
    } finally {
      setShowLoading({ display: "none" });
    }
  };
  const openTopModal = (type, dataModal) => {
    setShowLoading({ display: "block" });
    const _modalInfo = { table: { columns: [], rows: [] } };
    _modalInfo["padding"] = true;
    _modalInfo["size"] = "xl";
    let data = [];
    let user = JSON.parse(localStorage.getItem("user"));
    switch (type) {
      case "gestion": {
        _modalInfo["title"] = (
          <Title pipe={false} title="Gestión de " subtitle="Cobranza" />
        );
        _modalInfo["table"]["columns"] = columnasGestionCobranza;
        const user = JSON.parse(localStorage.getItem("user"));

        if (user === null) {
          window.location.href = "/";
          return;
        }

        let url = PORTALSAPI.GET.COBRANZA_DETALLE.replace(
          "{idcobranza}",
          dataModal
        ).replace("idcontacto", user.idcontacto);
        api.get(url).then((x) => {
          data = x.data.map((el) => ({ ...el }));
          data.forEach(
            (el) =>
              (el["comentarios"] = (
                <Button
                  onClick={() => {
                    openComentario(dataModal);
                  }}
                >
                  Enviar comentarios
                </Button>
              ))
          );
          _modalInfo["table"]["rows"] = data;
          _modalInfo["icon"] = icons[type];
          setTopModal(_modalInfo);
          setShowLoading({ display: "none" });
          handleShowTopModal();
        });
        break;
      }

      case "datos_SII": {
        if (Number(proveedor) === 1) {
          _modalInfo["title"] = (
            <Title pipe={false} title="Registro Datos Portal SII" subtitle="" />
          );
          _modalInfo["size"] = "xs";
          _modalInfo["headerColor"] = "#f7cf46";
          _modalInfo["table"] = null;
          _modalInfo["content"] = <RenderContent type={type} data={{}} />;
          _modalInfo["icon"] = icons["logo4"];
          setTopModal(_modalInfo);
          setShowLoading({ display: "none" });
          handleShowTopModal();
        }
        if (Number(proveedor) === 2) {
          setShowLoading({ display: "none" });
          setUserDashboardFlag(true);
          if (status === 1) {
            handleStatusChange(2);
            handleSuscriptionModal(2);
          } else {
            handleSuscriptionModal();
          }
          handleClose();
        }

        break;
      }

      case "datos": {
        _modalInfo["title"] = (
          <Title
            title="Actualización de Datos Cliente"
            pipe={false}
            subtitle=""
          />
        );
        _modalInfo["subtitle"] =
          "Completa los antecedentes solicitados en el siguiente formulario para actualizar tus datos registrados.";
        _modalInfo["size"] = "lg";
        _modalInfo["table"] = null;

        let urlMain = PORTALSAPI.GET.PANEL_CONTROL.replace(
          "{idcontacto}",
          user.idcontacto
        );
        api.get(urlMain).then((response) => {
          let user = JSON.parse(localStorage.getItem("user"));
          let publicURL = PORTALSAPI.GET.PUBLIC_KEY.replace(
            "{idcontacto}",
            user.idcontacto
          ).replace("{tipo}", user.tipo_acceso);
          api.get(publicURL).then((responsePublicKey) => {
            _modalInfo["content"] = (
              <RenderContent
                type={type}
                public_key={responsePublicKey.data}
                data={response.data}
              />
            );
            _modalInfo["icon"] = icons["usuario"];
            setTopModal(_modalInfo);
            setShowLoading({ display: "none" });
            handleShowTopModal();
          });
        });
        break;
      }
      case "contrasenia": {
        _modalInfo["title"] = (
          <Title title="Cambio de contraseña" pipe={false} subtitle="" />
        );
        _modalInfo["subtitle"] =
          "Completa los antecedentes solicitados en el siguiente formulario para actualizar tus datos registrados.";
        _modalInfo["size"] = "lg";
        _modalInfo["table"] = null;
        _modalInfo["content"] = <RenderContent type={type} data={{}} />;
        _modalInfo["icon"] = icons[type];
        setTopModal(_modalInfo);
        setShowLoading({ display: "none" });
        handleShowTopModal();
        break;
      }
      case "solicitar": {
        _modalInfo["title"] = (
          <Title title="Solicitud de documentos" pipe={false} subtitle="" />
        );
        _modalInfo["subtitle"] =
          "Completa los campos solicitados en el siguiente formulario para registrar su solicitud";
        /*  <>Completa los campos solicitados en el siguiente formulario para registrar su solicitud.<br></br>
          <b style={{fontSize : "0.7rem"}}>Recuerda que solo los contratos Nº 140000 en adelante pueden crear solicitud.</b></> */
        _modalInfo["size"] = "lg";
        _modalInfo["table"] = null;

        let urlMain = PORTALSAPI.GET.PANEL_CONTROL.replace(
          "{idcontacto}",
          user.idcontacto
        );
        api.get(urlMain).then((response) => {
          let user = JSON.parse(localStorage.getItem("user"));
          let publicURL = PORTALSAPI.GET.PUBLIC_KEY.replace(
            "{idcontacto}",
            user.idcontacto
          ).replace("{tipo}", user.tipo_acceso);
          api.get(publicURL).then((responsePublicKey) => {
            //response.data["leasing"] = dataLeasing;
            response.data["tipo_vehiculo"] = tipoVehiculos;
            response.data["tipo_solicitudes"] = tipoSolicitudesMain;
            response.data["tipo_solicitudes_formularios"] =
              tipoSolicitudesFormulario;
            _modalInfo["content"] = (
              <RenderContent2
                type={type}
                public_key={responsePublicKey.data}
                data={response.data}
                onSubmitSolicitar={onSubmitSolicitar}
              />
            );
            //_modalInfo["icon"] = icons["usuario"];
            setTopModal(_modalInfo);
            setShowLoading({ display: "none" });
            handleShowTopModal();
          });
        });
        break;
      }
      default: {
        break;
      }
    }
  };

  const loadProveedor = async () => {
    try {
      if (!proveedor) {
        setProveedor(await getProveedor());
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setShowLoading({ display: "block" });
    let user = JSON.parse(localStorage.getItem("user"));
    getComertialInfo();
    loadProveedor();
    sessionStorage.removeItem("current-step");
    sessionStorage.removeItem("isEmailSended");

    if (user === undefined || user === null) {
      localStorage.removeItem("user");
      localStorage.removeItem("rtoken");
      history.push("/");
      return;
    }

    let urlMain = PORTALSAPI.GET.MAIN.replace(
      "{idcliente}",
      user.idcliente
    ).replace("{idcontacto}", user.idcontacto);

    api.get(urlMain).then((response) => {
      setDataLeasing(JSON.parse(JSON.stringify(response.data["leasing"])));
      let publicURL = PORTALSAPI.GET.PUBLIC_KEY.replace(
        "{idcontacto}",
        user.idcontacto
      ).replace("{tipo}", user.tipo_acceso);
      api.get(publicURL).then((responsePublicKey) => {
        // Data Factoring
        response.data["factoring"].forEach((el) => {
          el["estado"] = (
            <TextCustomColored
              size={body12}
              color={"#0E6AFE"}
              onClick={() =>
                openModal({ title: "operacion", data: el.estado_portal })
              }
            >
              <u
                style={{
                  cursor: "pointer",
                }}
              >
                {el.estado}
              </u>
            </TextCustomColored>
          );
          el["estado_cartera"] = (
            <TextColored
              size={body12}
              type={el.estado_cartera === "Vigente" ? "green" : "red"}
            >
              {el.estado_cartera}
            </TextColored>
          );

          el["monto"] = (
            <NumberFormat
              style={{ fontWeight: 400 }}
              className="pull-right"
              value={el.monto}
              displayType={"text"}
              prefix={"$"}
              thousandSeparator={"."}
              decimalScale={2}
              decimalSeparator={","}
            />
          );
          el["financiado"] = (
            <NumberFormat
              style={{ fontWeight: 400 }}
              className="pull-right"
              value={el.financiado}
              displayType={"text"}
              prefix={"$"}
              thousandSeparator={"."}
              decimalScale={2}
              decimalSeparator={","}
            />
          );

          if (el["color_excedente"] === 1) {
            el["excedente"] = (
              <a href="#">
                {" "}
                <u>
                  {" "}
                  <NumberFormat
                    style={{ fontWeight: 400 }}
                    className="pull-right excedente1"
                    onClick={() =>
                      openModal({ title: "excedente", data: el.idfactoring })
                    }
                    value={el.excedente}
                    displayType={"text"}
                    prefix={"$"}
                    thousandSeparator={"."}
                    decimalScale={2}
                    decimalSeparator={","}
                  />
                </u>
              </a>
            );
          } else if (el["color_excedente"] === 2) {
            el["excedente"] = (
              <a href="#">
                {" "}
                <u>
                  {" "}
                  <NumberFormat
                    style={{ fontWeight: 400 }}
                    className="pull-right excedente2"
                    onClick={() =>
                      openModal({ title: "excedente", data: el.idfactoring })
                    }
                    value={el.excedente}
                    displayType={"text"}
                    prefix={"$"}
                    thousandSeparator={"."}
                    decimalScale={2}
                    decimalSeparator={","}
                  />
                </u>
              </a>
            );
          } else {
            el["excedente"] = (
              <a href="#">
                {" "}
                <u>
                  {" "}
                  <NumberFormat
                    style={{ fontWeight: 400 }}
                    className="pull-right"
                    onClick={() =>
                      openModal({ title: "excedente", data: el.idfactoring })
                    }
                    value={el.excedente}
                    displayType={"text"}
                    prefix={"$"}
                    thousandSeparator={"."}
                    decimalScale={2}
                    decimalSeparator={","}
                  />
                </u>
              </a>
            );
          }

          el["cxc"] = (
            <NumberFormat
              style={{ fontWeight: 400 }}
              className="pull-right"
              value={el.cxc}
              displayType={"text"}
              prefix={"$"}
              thousandSeparator={"."}
              decimalScale={2}
              decimalSeparator={","}
            />
          );
          el["cobranza"] = (
            <img
              alt=""
              width={width > mobileSMWidth ? "33px" : "40px"}
              height={width > mobileSMWidth ? "33px" : "40px"}
              onClick={() =>
                openModal({ title: "cobranza", data: el.idfactoring })
              }
              src={eye2}
              onMouseOver={(e) => (e.currentTarget.src = eye)}
              onMouseOut={(e) => (e.currentTarget.src = eye2)}
            />
          );
          el["liquidacion"] = (
            <img
              alt=""
              width={width > mobileSMWidth ? "33px" : "40px"}
              height={width > mobileSMWidth ? "33px" : "40px"}
              onClick={() =>
                openModal({ title: "liquidacion", data: el.idfactoring })
              }
              src={eye2}
              onMouseOver={(e) => (e.currentTarget.src = eye)}
              onMouseOut={(e) => (e.currentTarget.src = eye2)}
            />
          );
          el["detalle"] = (
            <img
              alt=""
              width={width > mobileSMWidth ? "33px" : "40px"}
              height={width > mobileSMWidth ? "33px" : "40px"}
              onClick={() =>
                openModal({ title: "detalle", data: el.idfactoring })
              }
              src={eye2}
              onMouseOver={(e) => (e.currentTarget.src = eye)}
              onMouseOut={(e) => (e.currentTarget.src = eye2)}
            />
          );
        });

        // Data Leasing
        response.data["leasing"].forEach((el) => {
          const renderTooltip = (props) => (
            <Tooltip id="button-tooltip" {...props}>
              {el.bienes}
            </Tooltip>
          );

          el["estado"] = (
            <TextColored
              size={body12}
              type={el.estado === "Activo" ? "blue" : ""}
            >
              {el.estado}
            </TextColored>
          );

          el["bienes"] = (
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <p
                style={{
                  width: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                className="pull-right mt-3"
              >
                {el.bienes}
              </p>
            </OverlayTrigger>
          );

          el["cuotas_pagadas"] = `${el.cuotas_pagadas}/${el.cuotas_total}`;
          el["financiado"] = (
            <NumberFormat
              className="pull-right"
              value={el.financiado}
              displayType={"text"}
              prefix={`${el.moneda} `}
              thousandSeparator={"."}
              decimalScale={el.moneda == "$" ? 0 : 2}
              decimalSeparator={","}
            />
          );
          el["diferencia_tipo_cambio"] = (
            <NumberFormat
              className="pull-right"
              value={el.diferencia_tipo_cambio}
              displayType={"text"}
              prefix={"$"}
              thousandSeparator={"."}
              decimalScale={2}
              decimalSeparator={","}
            />
          );
          el["cxc"] = (
            <NumberFormat
              className="pull-right"
              value={el.cxc}
              displayType={"text"}
              prefix={"$"}
              thousandSeparator={"."}
              decimalScale={2}
              decimalSeparator={","}
            />
          );
          el["cuotapago"] = (
            <img
              alt=""
              width={width > mobileSMWidth ? "33px" : "40px"}
              height={width > mobileSMWidth ? "33px" : "40px"}
              onClick={() => openModal({ title: "cuota", data: el.idleasing })}
              src={eye2}
              onMouseOver={(e) => (e.currentTarget.src = eye)}
              onMouseOut={(e) => (e.currentTarget.src = eye2)}
            />
          );
          el["archivos"] = (
            <img
              alt=""
              width={width > mobileSMWidth ? "33px" : "40px"}
              height={width > mobileSMWidth ? "33px" : "40px"}
              onClick={() =>
                openModal({ title: "archivo", data: el.idleasing })
              }
              src={archivo}
              /* onMouseOver={(e) => (e.currentTarget.src = eye)}
              onMouseOut={(e) => (e.currentTarget.src = eye2)} */
            />
          );
        });

        var currentUser = response.data.usuario;
        currentUser.token = JSON.parse(localStorage.getItem("user")).token;
        currentUser.tipo_acceso = user.tipo_acceso;

        localStorage.setItem("user", JSON.stringify(currentUser));
        setUser(currentUser);
        setRut(DecryptData(response.data.usuario.rut, responsePublicKey.data));
        setNombres(
          DecryptData(response.data.usuario.nombres, responsePublicKey.data)
        );
        setImages(response.data["imagenes"]);
        setTipoVehiculos(response.data["tipo_vehiculo"]);
        setTipoSolicitudesMain(response.data["tipo_solicitud"]);
        setTipoSolicitudesFormulario(
          response.data["tipo_solicitud_formulario"]
        );
        setInstructivos(response.data["instructivos"]);
        setTipoBanner(response.data["resumen"]?.tipo_banner);
        setContactado(response.data["resumen"]?.contactado);
        setMenus(response.data["menus"]);
        setNotificaciones(response?.data["notificacion"]);
        setNotificacion(
          response.data["notificacion"] === null
            ? 0
            : response.data["notificacion"].length
        );
        setResumenRow1({
          mostrar: response.data["resumen"].muestra_factoring,
          resumen: [
            {
              descripcion: "Operaciones giradas",
              total: response.data["resumen"].girada,
            },
            {
              descripcion: "Documentos en Mora",
              total: response.data["resumen"].mora,
            },
          ],
        });
        setResumenRow2({
          mostrar: response.data["resumen"].muestra_leasing,
          resumen: [
            {
              descripcion: "Operaciones Activas",
              total: response.data["resumen"].activa,
            },
            {
              descripcion: "Operaciones Terminadas",
              total: response.data["resumen"].terminada,
            },
          ],
        });
        setTablaFactoring({
          columns: columnasFactoring,
          rows: [...response.data["factoring"]],
        });
        setTablaLeasing({
          columns: columnasLeasing,
          rows: [...response.data["leasing"]],
        });
        setShowLoading({ display: "none" });
      });
    });
  }, [body32, body12, history]);

  // Step
  const Step = (props) => (
    <div className="Stepper__step">
      <div className="Stepper__indicator">
        {props.status === 1 && <img alt="" src={exito}></img>}
        {props.status === 2 && <img alt="" src={error}></img>}
        {props.status === 3 && <img alt="" src={empty}></img>}
      </div>
      <div className="Stepper__label">
        <Col style={{ marginTop: "20px" }}>
          <Row
            style={{
              justifyContent: "center",
              color:
                props.status === 1
                  ? "#07C814"
                  : props.status === 2
                  ? "#EC8700"
                  : "#858585",
            }}
          >
            {props.title}
          </Row>
          <Row
            style={{
              justifyContent: "center",
              color: "#858585",
              marginTop: "10px",
            }}
          >
            {props.content}
          </Row>
        </Col>
      </div>
      <div className="Stepper__panel">{props.children}</div>
    </div>
  );

  // Overlay
  const Stepper = (props) => (
    <div
      className={`Stepper ${props.isVertical ? "Stepper--vertical" : ""} ${
        props.isInline ? "Stepper--inline" : ""
      }`}
    >
      {props.children}
    </div>
  );
  useEffect(()=>{
    const DeleteMenuCDL = menus.find(m=>(m.modal === "CDL" && (Number(proveedor) === 1 || status === 0 )));
    if(DeleteMenuCDL !== undefined){
      setMenus((prevStatus)=>prevStatus.filter(p=>p.id !== DeleteMenuCDL.id))
    }
  },[status,menus]);
  return (
    <>
      <Loading estilo={showLoading}></Loading>
      <section style={section}>
        <Header
          nombres={nombres}
          rut={rut}
          openModal={openModal}
          notificaciones={notificacion}
          menus={menus}
        ></Header>

        <div
          style={{
            ...container,
            marginBottom: width > 801 && !!menus.length ? "40px" : "",
          }}
        >
          <div
            className="popup-btn"
            onClick={() => {
              goTo();
            }}
          >
            <a href="#" style={{ fontSize: "1rem" }}>
              Comentarios
            </a>
          </div>
        </div>

        <div style={container}>
          <Row
            style={{
              width: width >= 701 ? "87%" : "100%",
              marginBottom:
                tipoBanner !== 0
                  ? width > mobileXSWidth
                    ? "45px"
                    : "50px"
                  : "26px",
            }}
          >
            <Slider images={images} openModal={openModal}></Slider>
          </Row>
        </div>

        <div style={container}>
          <Row
            style={{
              width: width >= 701 ? "87%" : "100%",
              marginBottom: "20px",
            }}
          >
            {proveedor ? (
              Number(proveedor) === 1 ? (
                <Seccion2DM
                  re_ingreso={tipoBanner}
                  modal="ejecutivo"
                  contactado={contactado}
                  openModal={openModal}
                  registroHandle={() => openTopModal("datos_SII")}
                  status={status}
                  setStatus={handleStatusChange}
                />
              ) : (
                <>
                  {showSuscriptionModal ? (
                    <ModalSuscripcionCDL // Encargado de manejar el bloqueo y la actualizacion / registro de datos
                      show={showSuscriptionModal}
                      setStatus={handleStatusChange}
                      setShow={setShowSuscription}
                      status={status}
                      isBlocked={isBlocked}
                      // Parametros necesarios para la apertura del modal de suscripcion desde dashboard o menu de leasing
                      previousState={prevStatus}
                      contactado={tipo_acceso === 1}
                      isEditingData={isEditingData}
                      setUserDashboardFlag={setUserDashboardFlag}
                    />
                  ) : null}
                  <Seccion2CDL
                    modal="ejecutivo"
                    openModal={openModal}
                    status={status}
                    setStatus={handleStatusChange}
                    loading={loading}
                    setLoading={setLoading}
                    handleSubmit={handleSuscriptionModal}
                    contactado={contactado}
                  />
                </>
              )
            ) : null}
          </Row>
        </div>

        <div style={container}>
          <Row style={{ width: width >= mobileSMWidth ? "87%" : "100%" }}>
            {resumen_row1.mostrar === true ? (
              resumen_row2.mostrar === true ? (
                <Col xs={12} lg={12} xl={6} className="mb-4">
                  <CardDetalle
                    style={{ color: "red" }}
                    data={resumen_row1}
                    title="Factoring"
                  />
                </Col>
              ) : (
                <>
                  <Col xs={12} lg={12} xl={6} className="mb-4">
                    <CardDetalle data={resumen_row1} title="Factoring" />
                  </Col>
                  <Col>
                    <CardContact
                      title="Leasing"
                      onClick={() =>
                        openModal({ title: "ejecutivo", data: user.idcontacto })
                      }
                    />
                  </Col>
                </>
              )
            ) : (
              <></>
            )}

            {resumen_row2.mostrar === true ? (
              resumen_row1.mostrar === true ? (
                <Col>
                  <CardDetalle data={resumen_row2} title="Leasing" />
                </Col>
              ) : (
                <>
                  <Col>
                    <CardDetalle data={resumen_row2} title="Leasing" />
                  </Col>
                  <Col>
                    <CardContact
                      onClick={() =>
                        openModal({ title: "ejecutivo", data: user.idcontacto })
                      }
                      title="Factoring"
                    />
                  </Col>
                </>
              )
            ) : (
              <></>
            )}
          </Row>
        </div>

        {resumen_row1.mostrar === true ? (
          <div style={container} id="factoring">
            <Row
              className="mt-4"
              style={{ width: width >= 701 ? "87%" : "100%" }}
            >
              <Col>
                <Card>
                  <Card.Body>
                    <CardNegocio
                      title="Factoring"
                      table={factoring}
                      text="En esta sección podrás conocer detalladamente la información relacionada con tus operaciones de Factoring. También
                      conocerás los avances realizados en los diversos procesos de cobranza."
                      subtitle={textFactoring}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        ) : (
          <></>
        )}

        {resumen_row2.mostrar === true ? (
          <div style={container} id="leasing">
            <Row
              className="mt-4"
              style={{ width: width >= 701 ? "87%" : "100%" }}
            >
              <Col>
                <Card>
                  <Card.Body>
                    <CardNegocio
                      title="Leasing"
                      table={leasing}
                      subtitle={""}
                      text="Acá podrás revisar en detalle la información relevante vinculada a tus contratos de Leasing Progreso. También estarás 
                      informado del status de tus cuotas y pagos realizados a la fecha."
                      //instructivos = {instructivos.filter(instructivo=>instructivo.negocio==="LEASING")}
                      footer={
                        <div className="container mx-0 mw-100">
                          <div className="row justify-content-between align-items-center">
                            <Text size={body28} type="primary">
                              Contratos
                            </Text>
                            {/* <button type="button" className="btn btn-warning mb-2 " 
                        onClick={()=>openModal({title : "solicitudes", data: user.idcontacto})}>Solicitud Documentos Leasing</button> */}
                          </div>
                        </div>
                      }
                    />
                    {/* <Row className={width >= 701 ? "m-2" : ""}>
                      <Col className={width >= 701 ? "ml-4" : ""}>
                        <Text size={body28} type="primary">
                          Garantías
                        </Text>
                        <Tabla
                          paginacion={false}
                          busqueda={false}
                          datos={garantias}
                        ></Tabla>
                      </Col>
                      <Col className={width >= 701 ? "mr-4" : ""}>
                        <Text size={body28} type="primary">
                          Avales
                        </Text>
                        <Tabla
                          paginacion={false}
                          busqueda={false}
                          datos={avales}
                        ></Tabla>
                      </Col>
                    </Row> */}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        ) : (
          <></>
        )}

        <Footer />
        <Modal
          padding={modal.padding}
          size={modal.size}
          show={show}
          icon={modal.icon}
          title={modal.title}
          subtitle={modal.subtitle}
          handleClose={handleClose}
        >
          {modal.table ? (
            <>
              {modal.buttonsHeader && (
                <div className="row justify-content-end">
                  {modal.buttonsHeader}
                </div>
              )}
              <Tabla
                paginacion={true}
                numeroFilas={modal.filas ? modal.filas : 5}
                busqueda={!!modal.search}
                datos={modal.table}
              ></Tabla>
            </>
          ) : !modal.tables?.length ? (
            modal.content
          ) : (
            <div className="container">
              <div className="row justify-content-end">
                {modal.buttonsHeader}
              </div>
              <div className="row row-cols-1 row-cols-lg-2">
                {modal.tables.map((table) => (
                  <div className="col mb-2">
                    <Title pipe={false} title={table.title} subtitle="" />
                    <Tabla
                      paginacion={true}
                      numeroFilas={5}
                      busqueda={!!table.search}
                      datos={table}
                    ></Tabla>
                  </div>
                ))}
              </div>
            </div>
          )}
        </Modal>
        {/* Top level modal */}
        <Modal
          headerColor={topModal.headerColor}
          padding={topModal.padding}
          size={topModal.size}
          show={showTopModal}
          icon={topModal.icon}
          title={topModal.title}
          subtitle={topModal.subtitle}
          handleClose={() =>
            topModal.table ? handleCloseTopModal : setShowTopModal(false)
          }
        >
          {topModal.table ? (
            <Tabla
              paginacion={true}
              numeroFilas={5}
              busqueda={false}
              datos={topModal.table}
            ></Tabla>
          ) : (
            topModal.content
          )}
        </Modal>

        <ModalSuccess
          show={showModalError}
          icon={modalData.icon}
          messageLine={modalData.messageLine}
          title={
            <TextColored size={modalData.titleSize??body28} type={modalData.color} moreStyles={{textAlign : "center"}}>
              {modalData.title}
            </TextColored>
          }
          subtitle={
            <TextColored size={body32} bold type={modalData.color}>
              {modalData.subtitle}
            </TextColored>
          }
          message={
            <Text type="primary" size={modalData.messageSize??body25}>
              {modalData.message}
            </Text>
          }
          handleClose={() => handleCloseModalError()}
          buttonModal={modalData.buttonModal}
        />

        <ModalSuccess
          show={showModalSuccess}
          icon={<Confirmado />}
          title={
            <TextColored size={body28} type="yellow">
              Datos Actualizados
            </TextColored>
          }
          subtitle={
            <TextColored size={body32} bold type="yellow">
              EXITOSAMENTE
            </TextColored>
          }
          message={
            <Text type="primary" size={body25}>
              Muchas Gracias!
            </Text>
          }
          handleClose={() => handleCloseModalSuccess()}
        />
        <ModalSuccessSolicitud
          show={showModalSuccessSOlicitud}
          icon={modalData.icon}
          title={
            <TextColored size={body32} type={modalData.color}>
              {modalData.title}
            </TextColored>
          }
          subtitle={
            <TextColored size={body32} bold type={modalData.color}>
              {modalData.subtitle}
            </TextColored>
          }
          message={
            <Text type="primary" size={body25}>
              {modalData.message}
            </Text>
          }
          handleClose={() => handleCloseModalSuccessSolicitud()}
        />

        <ModalSuccess2
          show={showModalSuccess}
          icon={icons["computadora2"]}
          iconHeader={icons["logo4"]}
          headerColor="#f7cf46"
          headerTitle={
            <Title pipe={false} title="Registro Datos Portal SII" subtitle="" />
          }
          size={"xs"}
          padding={true}
          title={<Title pipe={false} title="¡Felicidades!" subtitle="" />}
          subtitle={
            <TextColored className="text-center" size={1.1} bold type="black">
              TUS DATOS HAN SIDO RECIBIDOS
              <br />
              EXITOSAMENTE
            </TextColored>
          }
          message={
            <Text className="mt-2 text-center" type="primary" size={0.7}>
              Nuestro ejecutivo comercial se contactará contigo a la brevedad
            </Text>
          }
          handleClose={() => handleCloseModalSuccess()}
        />
      </section>
    </>
  );
};
export default Dashboard;
